import React from "react";
import { Flex, Box, Image, Text, Button, } from "noshery-ui";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useDeviceType from "../../../hooks/useDeviceType";
import useWindowSize from "../../../hooks/useWindowSize";
import { ALT_IMGS } from "../../../constants";
import { CircularProgress } from "@mui/material";

const API_URL = process.env.REACT_APP_API_URL;

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const imgBase = "https://res.cloudinary.com/dwbvlpae2/image/upload/v1607410008";

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 80) / 100}px`,
		borderBottom: `${`${(width * 1.5) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};


const Blog = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();
	const [blogs, setBlogs] = React.useState([]);

	React.useEffect(() => {
		(async () => {
			let res = await fetch(`${API_URL}/listBlogs`); 
			res = await res.json();
			if(!res["blog"]) return;
			setBlogs(res["blog"]);
		})();
	}, []);

	const boxParams = {
	};

	const imageParams = { height: `${(width * 40) / 100}px !important` };
	const coverHeight = parseInt((width * 40) / 100);

	return (
		<Box {...boxParams}>
			<Flex style={{flexDirection: "column"}}>
				<Text style={{
					color: "#344734",
					fontSize: `${(width * 12) / 100}px`,
					marginLeft: `${(width * 5.1) / 100}px`,
					marginTop: `${(width * 3) / 100}px`,
					fontFamily:  "Uni Neue Heavy !important",
					fontStyle: "normal",
					fontWeight: "bolder",
					alignSelf: "center"
				}}> The Nosh Blog </Text>

				<Line />

				<Text style={{
					color: "#344734",
					fontSize: `${(width * 12) / 100}px`,
					marginLeft: `${(width * 5.1) / 100}px`,
					marginTop: `${(width * 3) / 100}px`,
					fontFamily:  "Uni Neue Heavy !important",
					fontStyle: "normal",
					fontWeight: "bolder",
					alignSelf: "center"
				}}> Blog Post </Text>
				
				{
					blogs.length === 0 ? ( <Box style={{ width: `${(width * 100) / 100}px`, height: `${(width * 25) / 100}px`, alignContent: "center", alignItems: "center", display: "flex", justifyContent: "center" }}> 
						<CircularProgress  />
					</Box>   ) : (
						<Box style={{ 
							marginTop: `${(width * 2) / 100}px`, 
							marginBottom: `${(width * 5.1) / 100}px`, 
							marginLeft: `${(width * 5.1) / 100}px`,
							 marginRight: `${(width * 5.1) / 100}px`, 
							 display: "flex", 
							 flexDirection: "column" }}>
							{blogs.map((blog, i) => {
								return (
									<BlogCard
										key={i}
										blog={blog}
									/>
								);
							})}
						</Box>
					)
				
				}
			</Flex>
		</Box>
	);
};

export default Blog;


const BlogCard = ({ blog }) => {
	const { width } = useWindowSize();

	const params = {
		fontSize: `${(width * 8) / 100}px`,
		lineHeight: `${(width * 10) / 100}px`,
	};

	return (
		<Box
			style={{
				paddingLeft: `${(width * 4) / 100}px`,
				paddingRight: `${(width * 4) / 100}px`,
				marginBottom: `${(width * 4) / 100}px`,
				width: `${(width * 90) / 100}px`,
				height: `${(width * 155) / 100}px`,
				borderRadius: `${(width * 4) / 100}px`,
				backgroundColor: "#344734",
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				alignContent: "center",
			}}
		>
			<Box style={{
				backgroundColor: "white",
				width: `${(width * 80) / 100}px`,
				height: `${(width * 130) / 100}px`,
				borderRadius: `${(width * 4) / 100}px`,
				marginTop: `${(width * 5) / 100}px`,
				display: "flex",
				flexDirection: "column",
			}}>
				<div style={{height: "30%", maxHeight: "30%", overflow: "hidden"}}>
					<Image
						alt={ALT_IMGS["faq"].altImg}
						src={`${imgBase}${blog["ImagePath"]}`}
						style={{ objectFit: "cover", width: "100%", height: "100% !important", borderRadius: `${(width * 4) / 100}px ${(width * 4) / 100}px 0 0`, }}
					/>
				</div>
				<Text style={{
					color: "#F1A637",
					paddingLeft: `${(width * .5) / 100}px`,
					paddingRight: `${(width * .5) / 100}px`,
				}} {...params} bold>
					{blog.title}
				</Text>
				<Text style={{
					color: "#344734",
					paddingLeft: `${(width * .5) / 100}px`,
					paddingRight: `${(width * .5) / 100}px`,
					fontSize: `${(width * 5) / 100}px`,
				}}>
					{blog["summary"]}
				</Text>
			</Box>
			<Link to={`/blog/${blog.id}`}> 
				<Button
					style={{
						backgroundColor: "#F1A637",
						color: "white",
						width: `${(width * 50) / 100}px`,
						height: `${(width * 15) / 100}px`,
						borderRadius: `${(width * 4) / 100}px`,
						marginTop: `${(width * 2) / 100}px`,
						marginBottom: `${(width * 2) / 100}px`,
						marginRight: `${(width * 30) / 100}px`,
						fontSize: `${(width * 6) / 100}px`,
						fontFamily:  "Uni Neue Heavy !important",
						fontStyle: "normal",
						fontWeight: "bolder",
					}}
				> READ MORE </Button>
			</Link>
		</Box>
	);
};

const ImageText = () => {
	const { width } = useWindowSize();
	const imageTextParams = {
		top: `${(width * 25) / 100}px`,
		right: `${(width * -45) / 100}px`,
	};

	const titleParams = {
		fontSize: `${(width * 4.1) / 100}px`,
		lineHeight: `${(width * 0) / 100}px`,
		textAlign: "left", 
	};

	const blogTextParams = { textAlign: "left", fontSize: `${(width * 12) / 100}px` };

	return (
		<StyledBox {...imageTextParams}>
			<Box>
				<Text color="white" {...titleParams} bold>
         The Nosh
				</Text>
			</Box>
			<Box>
				<Text color="white" {...blogTextParams} bold>
          Blog
				</Text>
			</Box>
		</StyledBox>
	);
};
