import React from "react";
import { Flex, Box, Image, Text } from "noshery-ui";
import { ALT_IMGS, DraperUniversity } from "../../constants";
import styled from "styled-components";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

const StyledImage = styled(Image)`
  object-fit: cover;
  box-shadow: 7.11px 7.11px 35.57px 17.78px rgba(0, 0, 0, 0.25);
`;

const FadeIn = styled(Box)`
  opacity: ${(p) => (p.visible ? 1 : 0)};
  visibility: ${(p) => (p.visible ? "visible" : "hidden")};
  transition: opacity 1.8s ease-out;
  will-change: opacity, visibility;
  ${(p) => (p.visible ? "transform: none;" : "")};
`;

const WhatWeDo = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();
	const [isVisible, setVisible] = React.useState(true);
	const componentRef = React.useRef();

	React.useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => setVisible(entry.isIntersecting));
		});
		observer.observe(componentRef.current);
		let current = componentRef.current;
		return () => observer.unobserve(current);
	}, []);

	const titleParams = isDesktop
		? {
			fontSize: `${(width * 2.9) / 100}px`,
			lineHeight: `${(width * 3.75) / 100}px`,
			color: "#344734",
		}
		: {
			fontSize: `${(width * 5) / 100}px`,
			lineHeight: `${(width * 8) / 100}px`,
			color: "#344734",
		};

	const backgroundProps = {
		backgroundSize: `${width * 2}px`,
		backgroundColor: "#F1A638",
	};

	const descriptionParams = isDesktop
		? {
			fontSize: `${(width * 1.66) / 100}px`,
			lineHeight: `${(width * 2.2) / 100}px`,
			color: "#ffffff",
		}
		: {
			fontSize: `${(width * 3) / 100}px`,
			lineHeight: `${(width * 4.3) / 100}px`,
			color: "#ffffff",
		};

	const wrapperParams = isDesktop
		? {
			marginTop: `${(width * 4.6) / 100}px`,
			padding: `${(width * 3) / 100}px`,
		}
		: {
			marginTop: `${(width * 6.4) / 100}px`,
			padding: `${(width * 2) / 100}px`,
		};

	const imageSize = ((isDesktop ? 6 : 7) * width) / 10;
	const imageRight = (60 * imageSize) / 100;
	const imageTop = ((isDesktop ? 20 : 8) * imageSize) / 100;

	const imageProps = isDesktop
		? {
			height: `${(width * 43.5) / 100}px !important`,
			width: `${(width * 43.5) / 100}px !important`,
			maxWidth: `${(width * 43.5) / 100}px !important`,
			objectFit: "cover",
		}
		: {
			height: `${(width * 90) / 100}px !important`,
			width: `${(width * 90) / 100}px !important`,
			maxWidth: `${(width * 90) / 100}px !important`,
			objectFit: "cover",
			position: "absolute",
			top: imageTop,
			right: imageRight,
		};
	const contentW = isDesktop ? 34 : 38;

	const contentProps = { 
		width: `${(width * contentW) / 100}px !important`,
		paddingBottom: `${(width * 10) / 100}px !important`,
	};
  
	const imageSrc = `https://res.cloudinary.com/dwbvlpae2/image/upload/w_${parseInt(
		((width * 43.5) / 100) ? ((width * 43.5) / 100) : 1
	)},h_${parseInt(
		((width * 43.5) / 100) ? ((width * 43.5) / 100) : 1
	)},c_fill,g_auto,f_auto/DraperUni/draperstudents_jm1iix.webp`;

	return (
		<Flex
			position={isDesktop ? "static" : "relative"}
			justifyContent={isDesktop ? "center" : "flex-end"}
			alignItems={isDesktop ? "center" : ""}
			overflow="hidden"
			style={backgroundProps}
			ref={componentRef}
			{...wrapperParams}
		>
			{isDesktop && (
				<>
					<FadeIn visible={isVisible}>
						<Box {...contentProps}>
							<Box>
								<Text {...titleParams} bold>
                  Noshery and Draper University
								</Text>
							</Box>
							<Box>
								<Text {...descriptionParams}>
									{DraperUniversity.split("read more here.")} 
									<a target="_blank" rel="noopener noreferrer" href="https://www.bytekitchen.io/about-us"> read more here.</a>
								</Text>
							</Box>
						</Box>
					</FadeIn>{" "}
					<FadeIn visible={isVisible} paddingLeft={`${(width * 5.3) / 100}px`} mr={`${(width * 2.3) / 100}px`}>
						<StyledImage
							alt={ALT_IMGS["whatwedo"].altImg}
							{...imageProps}
							src={imageSrc}
							borderRadius="50%"
						/>
					</FadeIn>
				</>
			)}
			{!isDesktop && (
				<>
					<FadeIn visible={isVisible} mr={`${(width * 2.3) / 100}px`}>
						<StyledImage
							style={{transform: "scaleX(-1)"}}
							alt={ALT_IMGS["whatwedo"].altImg}
							{...imageProps}
							src={imageSrc}
							borderRadius="50%"
						/>
					</FadeIn>
					<FadeIn visible={isVisible}>
						<Flex flexDirection="column" alignItems="center">
							<Box {...contentProps}>
								<Text {...titleParams} bold>
                Noshery and Draper University
								</Text>
								<Box>
									<Text {...descriptionParams}>                  
										{DraperUniversity.split("read more here.")} 
										<a target="_blank" rel="noopener noreferrer" href="https://www.bytekitchen.io/about-us"> read more here.</a>
									</Text>
								</Box>
							</Box>
						</Flex>
					</FadeIn>
				</>
			)}
		</Flex>
	);
};

export default WhatWeDo;
