import React from "react";
import { Link } from "react-router-dom";
import {  Box } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import { ALT_IMGS } from "../../constants";

const NosheryLogo = styled.img`
  width: ${(p) => p.width};
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  filter: brightness(0) invert(1);
`;


const MobileLogo = () => {
	const { width } = useWindowSize();
	// logo width = 15 % of total width
	const ratio = 48;
	const logoWidth = (width * ratio) / 100;

	return (
		<Box zIndex={2}>
			<Link to="/">
				<NosheryLogo
					alt={ALT_IMGS["logo"].altImg}
					src={"/logowithtext.png"}
					width={`${logoWidth}px`}
					height={`${logoWidth / 2}px`}
				/>
			</Link>
		</Box>
	);
};

export default MobileLogo;
