import React from "react";
import { Flex, Box, Text } from "noshery-ui";
import { whatwedo, mixandmatch } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";

const WhatWeDo = (isDraper, isLander) => {
	const { width } = useWindowSize();

	const wrapperParams = {
		marginTop: `${(width * (isDraper ? 20 : 6.4)) / 100}px`,
		height: `${(width * (isLander ? 195 : 225)) / 100}px`,
		
	};

	return (
		<Flex
			position={"relative"}
			alignItems={"center"}
			overflow={isLander ? "show" : "hidden"}
			{...wrapperParams}
		>
			<MixandMatch />
			<WhatWeDoCircle /> 
		</Flex>
	);
};

const MixandMatch = () => {
	const { width } = useWindowSize();
	const yy = 90;

	const bgSrc = `https://res.cloudinary.com/dwbvlpae2/image/upload/w_${parseInt(
		((width * yy) / 100) ? ((width * yy) / 100) : 1
	)},h_${parseInt(
		((width * yy) / 100) ? ((width * yy) / 100) : 1
	)},c_fill,g_auto,f_auto//Landing/whatwedo/whatwedobg_odojii.webp`;
 
	const backgroundProps = {
		backgroundSize: `${width * 1}px`,
		backgroundColor: "#344734",
		backgroundImage: `url(${bgSrc})`,
		borderRadius: "50%",
		position: "absolute",
		overflow: "hidden",
		height: `${(width * 135) / 100}px`,
		width: `${(width * 135) / 100}px`,
		display: "flex",
		alignItems: "center",
		right: `-${(width * 18) / 100}px`,
		top: `${(width * 85) / 100}px`
	};

	const titleParams = {
		fontSize: `${(width * 8) / 100}px`,
		lineHeight: `${(width * 11) / 100}px`,
		color: "#F1A638",
		textAlign: "left"
	};
	const descriptionParams = {
		fontSize: `${(width * 4) / 100}px`,
		lineHeight: `${(width * 6) / 100}px`,
		color: "#ffffff",
		textAlign: "left"
	};

	const textWrapper = {
		paddingX: `${(width * 27) / 100}px`,
		paddingBottom: `${(width * 39) / 100}px`,
		paddingTop: `${(width * 39) / 100}px`
	};

	return (
		<Box style={backgroundProps} > 
			<Box {...textWrapper}>
				<Box>
					<Text {...titleParams} bold>
                        Mix and Match
					</Text>
				</Box>
				<Box>
					<Text {...descriptionParams}>{mixandmatch}</Text>
				</Box>
			</Box>
		</Box>
	);
};

const WhatWeDoCircle = () => {
	const { width } = useWindowSize();

	// const bgSrc = `https://res.cloudinary.com/dwbvlpae2/image/upload/w_${parseInt(
	//     (width * 43.5) / 100
	//   )},h_${parseInt(
	//     (width * 43.5) / 100
	//   )},c_fill,g_auto,f_auto/Landing/whatwedo/whatwedo_swfky2.webp`;

 
	const backgroundProps = {
		backgroundSize: "cover",
		backgroundColor: "#F1A738",
		borderRadius: "50%",
		position: "absolute",
		overflow: "hidden",
		height: `${(width * 135) / 100}px`,
		width: `${(width * 200) / 100}px`,
		display: "flex",
		alignItems: "center",
		right:  `-${(width * 50) / 100}px`,
		top: 0,
		zIndex: -1 
	};

	const titleParams = {
		fontSize: `${(width * 8) / 100}px`,
		lineHeight: `${(width * 11) / 100}px`,
		color: "#344734",
		textAlign: "left",
		filter: "brightness(100%) !important",
	};
	const descriptionParams = {
		fontSize: `${(width * 4) / 100}px`,
		lineHeight: `${(width * 6) / 100}px`,
		color: "#ffffff",
		textAlign: "left",
		filter: "brightness(100%) !important",
	};

	const textWrapper = {
		paddingX: `${(width * 60) / 100}px`,
		paddingBottom: `${(width * 39) / 100}px`,
		filter: "brightness(85%) !important",
	};

	return (
		<Box style={backgroundProps} > 
			<Box {...textWrapper}>
				<Box>
					<Text {...titleParams} bold>
                         What We Do
					</Text>
				</Box>
				<Box>
					<Text {...descriptionParams}>{whatwedo}</Text>
				</Box>
			</Box>
		</Box>
	);
};

export default WhatWeDo;
