import React, { useState } from "react";
import { Box, Flex, Text } from "noshery-ui";
import styled from "styled-components";
import {qAsLoyalty} from "../../constants/faq";
import useWindowSize from "../../hooks/useWindowSize";
import { animated, useSpring } from "react-spring";

const Questions = () => {
	const { width } = useWindowSize();
	const [index, setIndex] = useState();

	const wrapperParams = {
		marginTop: `${(width * 15) / 100}px`,
		marginBottom: `${(width * 5.1) / 100}px`,
		flexDirection: "column"
	};

	const boxParams = {
		width: `${(width * 94) / 100}px`,
	};

	const title = {
		paddingBottom: `${(width * 4.2) / 100}px !important`
	};

	return (
		<Flex justifyContent="center" alignItems="center" {...wrapperParams}>
			<Flex {...title} justifyContent="center"> 
				<Text color="#344734" bold fontSize={`${(width * 8.86) / 100}px !important`}> Rewards FAQ </Text>
			</Flex>
			<Box {...boxParams}>
				{qAsLoyalty.map(({ q, a }, i) => {
					return (
						<SingleQA
							key={i}
							q={q}
							a={a}
							onClick={() => setIndex(i)}
							isOpen={index === i}
						/>
					);
				})}
			</Box>
		</Flex>
	);
};

export default Questions;

const BoxQ = styled(Box)`
  background: ${(p) => (p.isOpen ? "#BC8329" : "#f1a638")};
  color: #ffffff;
`;

const BoxA = styled(Box)`
  background: #ededed;
  color: #000000;
`;

const AnimatedBox = animated(BoxA);

const AniamtedWrapper = animated(Box); 

const SingleQA = ({ q, a, isOpen, onClick }) => {
	const { width } = useWindowSize();
	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1) / 100}px`;

	const question = {
		fontSize: `${(width * 6) / 100}px`,
		lineHeight: `${(width * 6) / 100}px`,
		padding: `${(width * 2.1) / 100}px ${(width * 2.4) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 2) / 100}px`,
		height: `${(width * 17) / 100}px`,
		alignItems: "center",
		display: "flex",
		width: "100%"
	};

	const {height, lineHeight, padding, paddingTop, opacity} = useSpring({
		from: {
			height: "0px",
			fontSize: "0px",
			lineHeight: "0px",
			padding: "0px",
			paddingTop: "0px",
			opacity: 0
		},
		to: {
			height: isOpen ? `${(width * (30)) / 100}px` : "0px",
			opacity: isOpen ? 1 : 0,
			fontSize:`${(width * 4) / 100}px`,
			lineHeight: isOpen ? `${(width * 4 ) / 100}px` : "0px",
			padding: 
        isOpen ? `${(width * 2.1) / 100}px ${(width * 2.4) / 100}px` : "0px",
			paddingTop: isOpen ? `${(width * 2) / 100}px` : "0px",
		}
	});

	const answerWrapper = {
		top: `-${(width * 3) / 100}px`,
		position: "relative",
		zIndex: isOpen ? 0 : -1,
	};    

	const wrapper = {
		position: "relative",
	};

	const {height: WrapperH} = useSpring({
		from: { height: `${(width * (30)) / 100}px`},
		to: {
			height: isOpen && `${(width * (50)) / 100}px`
		}
	});

	return (
		<AniamtedWrapper 
			{...wrapper} a 
			onClick={onClick} 
			style={{ 
				cursor: "pointer",
				height: WrapperH
			}}>
			<BoxQ {...question} isOpen={isOpen} mb={`${(width * 2.5) / 100}px`}>
				<Text bold>{q}</Text>
			</BoxQ>
			<BoxA {...answerWrapper}> 
				<AnimatedBox  
					style={{
						opacity: opacity,
						fontSize: `${(width * 4) / 100}px`,
						lineHeight: lineHeight,
						padding: padding,
						boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
						borderRadius: `${(width * 2) / 100}px`,
						height: height,
						paddingTop: paddingTop, 
						width: "100%",
					}}
				>
					{
						(q === "How do I register for rewards?" &&
              <HowToRegister a={a} />) 
            ||
              (q === "How do I check my rewards balance?" &&
              <HowDoICheck a={a} />)
            ||
              (q === "How do I earn Nosh points?" &&
              <HowDoIEarn a={a} />)
            ||
            a
					}
				</AnimatedBox>
			</BoxA>
		</AniamtedWrapper>
	);
};


const HowToRegister = ({a}) => {
	return (
		<span style={{position: "relative", zIndex: 100}}> 
        Register for rewards
			<a target="_blank" rel="noopener noreferrer" href="https://my.spendgo.com/index.html#/register/noshery"> here. </a>
			{a}
		</span>
	);
};
  
const HowDoICheck = ({a}) => {
	return (
		<span>
			{a}
			<a target="_blank" rel="noopener noreferrer" href="https://my.spendgo.com/index.html#/signIn/noshery"> {" "} here. </a>
		</span>
	);
};
  
const HowDoIEarn = ({a}) => {
	let answer = a.split("website purchases");
	return (
		<span>
			{answer[0]}
			<span style={{fontWeight: "900"}}> website purchases </span>
			{answer[1]}
		</span>
	);
};

