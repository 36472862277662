import React from "react";
import styled from "styled-components";
import { Box, Flex, Text, Image, Button } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import Nav from "./Nav";

import { ORDER_URL } from "../../constants";

const BackgroundImage = styled(Image)`
  min-height: ${(p) => p.size};
  min-width: ${(p) => p.size};
  z-index: -1; 
  border-radius: 50%;
  right: ${(p) => p.right};
  top: ${(p) => p.top};
  box-shadow: ${(p) => p.shadow};
  position: absolute;
  object-fit: cover;
`;

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
`;

const MobileBrandHeader = ({
	title,
	bgBrand,
	longDescription,
	menuId,
	name,
	shortDescription,
	pageImgAlts,
	sidebarOpen,
	setSidebarOpen, 
	onScroll,
	headerOpen
}) => {
	const { width } = useWindowSize();

	// desktop image 60%, tablet & mobile 88%
	const imageSize = parseInt(((15) * width) / 10);
	// desktop top is 24.5% of image width, tablet & mobile 15%
	const imageTop = parseInt(((45) * width) / 100);
	// desktop right is 18.5% of image width, tablet & mobile 12%
	const imageRight = parseInt((25 * width)  / 100);

	return (
		<Box position="relative" overflowX="hidden">
			<Nav 
				sidebarOpen={sidebarOpen} 
				setSidebarOpen={setSidebarOpen} 
				scrolling={onScroll}
				headerOpen={headerOpen} 
			/>
			<Flex flexDirection="column" justifyContent='center'>
				<Box
					justifyContent="flex-end"
					borderRadius="50%"
					position="relative"
					height={`${1.5 * imageTop}px`}
					marginBottom={"6%"}
				>
					<BackgroundImage
						alt={pageImgAlts["header"]}
						src={bgBrand(imageSize, imageSize)}
						size={`${imageSize}px`}
						top={`-${imageTop}px`}
						right={`-${imageRight}px`}
						shadow={
							"1px 1px 4px 2px rgba(0, 0, 0, 0.25)"
						}
					/>
				</Box>
				<Content title={title} description={longDescription} />
			</Flex>
		</Box>
	);
};

export default MobileBrandHeader;

const Content = ({ title, description }) => {
	const { width } = useWindowSize();

	const styledHeadingParams = {
		fontSize: `${(width * 9) / 100}px`,
		lineHeight: `${(width * 13) / 100}px`,
		marginTop: "0px",
		marginBottom: "0px",
	};
	const styledTextParams = {
		fontSize: `${(width * 4) / 100}px`,
		lineHeight: `${(width * 5) / 100}px`,
		textAlign: "center"
	};

	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1.3) / 100}px`;

	const buttonWidthPercentage = 70;
	const buttonHeightPercentage = 14;

	const minButtonWidth = (width * buttonWidthPercentage) / 100;
	const minButtonHeigth = (width * buttonHeightPercentage) / 100;

	const buttonParams = {
		fontSize: `${(width * 9) / 100}px`,
		lineHeight: `${(width * 9) / 100}px`,
		textAlign: "center",
		color: "white !important",
		height: `${minButtonHeigth}px !important`,
		width: `${minButtonWidth}px !important`,
		borderRadius: `${(width * 4) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const wrapperParams = {
		marginLeft: `${(width * 3.1) / 100}px`,
		marginTop: `${(width * 35) / 100}px`,
		flexDirection: "column",
		alignItems: "center"
	};

	const buttonWrapperParams = {
		marginTop: `${(width * 1.7) / 100}px`,
		marginBottom: `${(width * 1.7) / 100}px`,
	};

	return (
		<Flex {...wrapperParams}>
			<Flex flexDirection="column" alignItems='center'>
				<Text as="h1" bold {...styledHeadingParams}>
					{title}
				</Text>
			</Flex>
			<Box paddingBottom="5%">
				<Text fontWeight={500} {...styledTextParams}>
					{description}
				</Text>
			</Box>

			<Flex
				flexDirection="column"
				justifyContent={"flex-start"}
				alignItems={"center"}
				{...buttonWrapperParams}
			>
				<Box pb={`${(width * 2.6) / 100}px`}>
					<StyledButton
						onClick={() => window.open(ORDER_URL)}
						isDesktop={false}
						{...buttonParams}
					>
            Order Now
					</StyledButton>
				</Box>
			</Flex>
		</Flex>
	);
};

