import React from "react";
import { Box, Flex, Image, Text } from "noshery-ui";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";
import "./quote.css";

const TheFood = ({
	name,
	quote,
	author,
	pageImgAlts,
	food1,
	food2,
	food3,
	food4,
	food5,
}) => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();

	const boxParams = {
		marginTop: `${(width * 4) / 100}px`,
	};

	const boxShadow1 = `${(width * 0.24) / 100}px`;
	const boxShadow2 = `${(width * 1.2) / 100}px`;

	const f1W = isDesktop ? 26 : 36.2;
	const f1H = isDesktop ? 18.75 : 26.1;

	const f1Width = parseInt((width * f1W) / 100);
	const f1Height = parseInt((width * f1H) / 100);

	const food1Params = {
		width: `${f1Width}px !important`,
		height: `${f1Height}px !important`,
		maxWidth: `${f1Width}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 2.7) / 100}px`,
		marginBottom: `${(width * 1.1) / 100}px !important`,
	};

	const f2H = isDesktop ? 48 : 67.7;

	const f2Width = parseInt((width * f1W) / 100);
	const f2Height = parseInt((width * f2H) / 100);

	const food2Params = {
		width: `${f2Width}px !important`,
		height: `${f2Height}px !important`,
		maxWidth: `${f2Width}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 2.7) / 100}px`,
		marginBottom: `${(width * 1.1) / 100}px !important`,
	};

	return (
		<Box textAlign="center" {...boxParams}>
			<Box mb={`${(width * 2.8) / 100}px !important`}>
				<Box></Box>
				<Line />
			</Box>
			{isDesktop ? (
				<Flex justifyContent="center">
					<Box
						textAlign="right"
						width={food1Params.width}
						mr={`${(width * 1.1) / 100}px !important`}
					>
						<Image
							alt={pageImgAlts["thefood"][0]}
							style={{ objectFit: "cover" }}
							src={food1(f1Width, f1Height)}
							{...food1Params}
						/>
						{quote ? (
							<Box>
								<Quote quote={quote} author={author} />
							</Box>
						) : (
							<Image
								alt={`${name} image5`}
								style={{ objectFit: "cover" }}
								src={food5(f1Width, f1Height)}
								{...food1Params}
							/>
						)}
					</Box>
					<Box
						width={food2Params.width}
						mr={`${(width * 1.1) / 100}px !important`}
					>
						<Image
							alt={pageImgAlts["thefood"][1]}
							style={{ objectFit: "cover" }}
							src={food2(f2Width, f2Height)}
							{...food2Params}
						/>
					</Box>
					<Box textAlign="left" width={food1Params.width}>
						<Image
							alt={pageImgAlts["thefood"][3]}
							style={{ objectFit: "cover" }}
							src={food3(f1Width, f1Height)}
							{...food1Params}
						/>
						<Image
							alt={pageImgAlts["thefood"][2]}
							style={{ objectFit: "cover" }}
							src={food4(f1Width, f1Height)}
							{...food1Params}
						/>
					</Box>
				</Flex>
			) : (
				<Flex justifyContent="center">
					<Box
						textAlign="right"
						width={food1Params.width}
						mr={`${(width * 1.1) / 100}px`}
					>
						<Image
							alt={pageImgAlts["thefood"][0]}
							style={{ objectFit: "cover" }}
							src={food1(f1Width, f1Height)}
							{...food1Params}
						/>

						{quote ? (
							<Box>
								<Quote quote={quote} author={author} />
							</Box>
						) : (
							<Image
								alt={`${name} image5`}
								style={{ objectFit: "cover" }}
								src={food5(f1Width, f1Height)}
								{...food1Params}
							/>
						)}

						<Image
							alt={pageImgAlts["thefood"][3]}
							style={{ objectFit: "cover" }}
							src={food3(f1Width, f1Height)}
							{...food1Params}
						/>
					</Box>
					<Flex
						flexDirection="column"
						textAlign="left"
						width={food2Params.width}
					>
						<Image
							alt={pageImgAlts["thefood"][1]}
							style={{ objectFit: "cover" }}
							src={food2(f2Width, f2Height)}
							{...food2Params}
						/>
						<Image
							alt={pageImgAlts["thefood"][2]}
							style={{ objectFit: "cover" }}
							src={food4(f1Width, f1Height)}
							{...food1Params}
						/>
					</Flex>
				</Flex>
			)}
		</Box>
	);
};

export default TheFood;

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 54) / 100}px`,
		borderBottom: `${`${(width * 0.34) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};

const Quote = ({ quote, author }) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();
	const yelpImg = "yelp.png";

	return (
		<Box mb={isDesktop ? "0px" : `${(width * 2.5) / 100}px`}>
			<Text bold fontSize={`${(width * (isDesktop ? 1.9 : 3.05)) / 100}px`}>
				<q style={{lineHeight: 0}}> {quote} </q>
			</Text>

			<Box mt={`${(width * 3) / 100}px`}>
				<Text bold fontSize={`${(width * 1.9) / 100}px`}>
          - {author}
				</Text>
			</Box>
			<Box>
				<Image style={{ width: `${(width * 12) / 100}px` }} src={yelpImg} />
			</Box>
		</Box>
	);
};
