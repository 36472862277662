import React from "react";
import { Box, Flex, Text, Heading, Image } from "noshery-ui";
import styled from "styled-components";
import { ALT_IMGS, locations } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
import { animated, useSpring } from "react-spring";

const Title = styled(Heading)`
  text-align: center;
  font-weight: 800;
`;

const AnimatedBox = animated(Box); 

const OurLocations = ({ contactUs, mobilePaddingTopLocation }) => {
	const { width } = useWindowSize();
	const [chosen, setChosen] = React.useState(1);

	let wrapperParams = { 
		marginTop: `${(width * mobilePaddingTopLocation) / 100}px`,
		paddingBottom: `${(width * 50) / 100}px`
	};

	if (contactUs) {
		wrapperParams = { marginTop: `${(width * 3) / 100}px` };
	}

	const switchCard = (i) => {
		setChosen(i);
	};

	const titleParams = {
		fontSize: `${(width * (9)) / 100}px`,
		lineHeight: `${(width * (9)) / 100}px`,
	};

	const {zIndex: zIndex1, top: top1 } = useSpring({
		config: {duration: 200},
		from: {
			top: 1,
			zIndex: 0,
		},
		top: chosen === 0 ? (width * 40) / 100 : chosen === 1 ? (width * 20) / 100 : 1, 
		zIndex: chosen === 0 ? 10 : chosen === 1 ? 2 : 0, 
	});

	const {zIndex: zIndex2, top: top2 } = useSpring({
		config: {duration: 200},
		from: {
			top: (width * 20) / 100,
			zIndex: 1,
		},
		top: chosen === 1 ? (width * 40) / 100 : (width * 20) / 100 , 
		zIndex: chosen === 1 ? 10 : 1
	});

	return (
		<Box {...wrapperParams}>
			<Title {...titleParams} bold>
        Our Locations
			</Title>
			<Flex
				mt={`${(width * 1.7) / 100}px`}
				alignItems="center"        
				height={`${(width * 105) / 100}px`}
				justifyContent="center"
				flexDirection="column"
				position="relative"
			>
				<AnimatedBox
					onClick={() => switchCard(1)}
					style={{ 
						position: "absolute", 
						top: top1,
						zIndex: zIndex1,
						padding: `${(width * 1.7) / 100}px`
					}}
				>
					<SingleLocation {...locations[0]} active={chosen === 1}  />
				</AnimatedBox>
			</Flex>
		</Box>
	);
};

export default OurLocations;

const AnimatedFlex = animated(Flex);

const SingleLocation = ({
	src,
	title,
	address1,
	address2,
	phone,
	openHours,
	value,
	active
}) => {
	const { width } = useWindowSize();
	const boxShadow1 = `${(width * 0.24) / 100}px`;
	const boxShadow2 = `${(width * 1.2) / 100}px`;
	//
	//title.includes("Mateo") ? "#344734" : "#F1A638",
	const boxParams = {
		backgroundColor: "#FADDB6",
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 5.6) / 100}px`,
		width: `${(width * 79) / 100}px`,
		height: `${(width)}px`,
	};

	const imageW = parseInt((width * (79.22)) / 100);
	const imageH = parseInt((width * (50)) / 100);

	const imageParams = {
		width: `${(width * (79)) / 100}px !important`,
		height: `${(width * (44)) / 100}px !important`,
		top: `${(width * 15.94) / 100}px`,
		position: "absolute",
	};
	//title.includes("Mateo") ? "#F1A638" :"#344734",
	const titleParams = {
		fontSize: `${(width * 7.7) / 100}px !important`,
		lineHeight: `${(width * 8) / 100}px`,
		textAlign: "center",
		position: "absolute",
		top:  `${(width * 5) / 100}px`,
		color: "black",
		right: `${(width * 8) / 100}px`,
	};

	const textSize = (width * 5) / 100;
	const addressParams = {
		fontSize: `${textSize}px`,
		lineHeight: `${(width * 5) / 100}px`,
		textAlign: "left",
		color: "black",
	};

	const {opacity} = useSpring({
		config: {duration: 1000},
		from: {
			opacity: 0
		},
		opacity: active ? 1 : 0
	});

	return (
		<Box {...boxParams}>
			<Text {...titleParams} bold>
          Noshery {title}
			</Text>
			<Flex alignItems="center">
				<Image
					alt={ALT_IMGS["locations"][value].altImg}
					src={src(imageW, imageH)}
					{...imageParams}
					style={{ objectFit: "cover" }}
				/>
			</Flex>
			<AnimatedFlex 
				padding={`${(width * 3) / 100}px`} 
				flexDirection="column"
				style={{
					opacity: opacity,
					position: "absolute",
					top:  `${(width * 62.31) / 100}px`,
				}}  
			>
				<Box>
					<Text {...addressParams}>{address1}</Text>
				</Box>
				<Box>
					<Text {...addressParams}>{address2}</Text>
				</Box>
				<Box>
					<Text {...addressParams}>{phone}</Text>
				</Box>
				<br />
				<Box>
					{openHours.map((hours, i) => {
						return (
							<animated.div 
								key={`#hours-${i}`}> 
								<Text
									style={{ textAlign: "left !important" }}
									{...addressParams}
								>
									<span>{hours.days} </span>
									{hours.time}
								</Text>
							</animated.div>
						);
					})}
				</Box>
			</AnimatedFlex>
		</Box>
	);
};
