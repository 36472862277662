import React from "react";
import { Flex, Box, Image, Text } from "noshery-ui";
import styled from "styled-components";
import Questions from "./Questions";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";
import { qAs } from "../../constants/faq";
import { ALT_IMGS } from "../../constants";

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const Faq = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();
	const [chosenQ, setChosenQ] = React.useState(0);

	const boxParams = {
		marginTop: `${(width * 7.2) / 100}px`,
	};

	const imageParams = { height: `${(width * 30) / 100}px !important` };
	const coverHeight = parseInt((width * 30) / 100);
	const imageCoverParams = {
		width: "100%",
		height: `${coverHeight}px !important`,
		backgroundColor: "rgba(241, 166, 56, 0.5)",
	};

	return (
		<Box {...boxParams}>
			<Box position="relative">
				<Box {...imageCoverParams} position="absolute" top={0} />
				<Image
					alt={ALT_IMGS["faq"].altImg}
					{...imageParams}
					src={`https://res.cloudinary.com/dwbvlpae2/image/upload/h_${coverHeight ? coverHeight : 1},c_fill,g_auto,f_auto/Landing/LP_FAQ_nuej6n.webp`}
					width="100%"
					style={{ objectFit: "cover" }}
				/>
				<ImageText />
			</Box>
			<Flex>
				<Box style={{ marginTop: `${(width * 5.1) / 100}px` }}>
					{qAs.map((qna, i) => {
						return (
							<SingleQ
								key={i}
								chosen={chosenQ === i}
								value={i}
								setChosenQ={setChosenQ}
								q={qna.q}
							/>
						);
					})}
				</Box>
				<Box>
					<Questions chosenQ={chosenQ} />
				</Box>
			</Flex>
		</Box>
	);
};

export default Faq;

const BoxQ = styled(Box)`
  color: ${(p) => (p.chosen ? "#8B6017" : "rgba(0, 0, 0, 0.35)")};
`;

const SingleQ = ({ q, setChosenQ, value, chosen }) => {
	const { width } = useWindowSize();

	const params = {
		fontSize: `${(width * 1.5) / 100}px`,
		lineHeight: `${(width * 3) / 100}px`,
	};

	return (
		<Box
			onClick={() => {
				setChosenQ(value);
			}}
			a
			style={{
				paddingLeft: `${(width * 4) / 100}px`,
				paddingRight: `${(width * 6) / 100}px`,
				cursor: "pointer",
			}}
		>
			<BoxQ {...params} chosen={chosen} mb={`${(width * 3) / 100}px`}>
				<Text bold>{q}</Text>
			</BoxQ>
		</Box>
	);
};

const ImageText = () => {
	const { width } = useWindowSize();
	const imageTextParams = {
		top: `${(width * 7) / 100}px`,
	};

	const titleParams = {
		fontSize: `${(width * 4.1) / 100}px`,
		lineHeight: `${(width * 5.6) / 100}px`,
	};

	const faqTextParams = { fontSize: `${(width * 8.8) / 100}px` };

	return (
		<StyledBox {...imageTextParams}>
			<Box>
				<Text color="white" {...titleParams} bold>
          How can we help you?
				</Text>
			</Box>
			<Box>
				<Text color="white" {...faqTextParams} bold>
          FAQ
				</Text>
			</Box>
		</StyledBox>
	);
};
