import React from "react";
import { Flex, Box, Image, Text } from "noshery-ui";

import { whynoshery1, whynoshery2 } from "../../constants";
import styled from "styled-components";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

const StyledImage = styled(Image)`
  object-fit: cover;
  height: 100%;
`;

const WhyNoshery = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();

	const titleParams = isDesktop
		? {
			fontSize: `${(width * 5.1) / 100}px`,
			lineHeight: `${(width * 3.75) / 100}px`,
			color: "#ffffff",
		}
		: {
			fontSize: `${(width * 5.5) / 100}px`,
			lineHeight: `${(width * 3.75) / 100}px`,
			color: "#ffffff",
		};
	const backgroundProps = {
		backgroundColor: "#F1A638",
	};

	const descriptionParams = isDesktop
		? {
			fontSize: `${(width * 1.8) / 100}px`,
			lineHeight: `${(width * 2.2) / 100}px`,
			color: "#ffffff",
		}
		: {
			fontSize: `${(width * 2.7) / 100}px`,
			lineHeight: `${(width * 2.7) / 100}px`,
			color: "#ffffff",
		};

	const imageW = parseInt((width * 59) / 100);
	const imageH = parseInt((width * (isDesktop ? 43.5 : 63)) / 100);

	const imageProps = {
		height: `${imageH}px !important`,
		width: `${imageW}px !important`,
		maxWidth: `${imageW}px !important`,
		objectFit: "cover",
	};

	const tileProps = {
		height: `${(width * 43.5) / 100}px !important`,
		width: `${(width * 54) / 100}px !important`,
		maxWidth: `${(width * 54) / 100}px !important`,
		objectFit: "cover",
		backgroundColor: "#F1A638",
		zIndex: 20,
		opacity: 0.25,
		position: "absolute",
	};

	const contentParams = {
		paddingLeft: `${(width * 2) / 100}px`,
		zIndex: 25,
	};

	const contentW = isDesktop ? 34 : 38;

	const contentProps = { width: `${(width * contentW) / 100}px !important` };

	return (
		<Flex
			position={"static"}
			justifyContent={"space-between"}
			alignItems={"center"}
			overflow="hidden"
			style={backgroundProps}
		>
			{isDesktop && (
				<>
					<Flex flexDirection="column" {...contentParams}>
						<Box
							style={{ paddingBottom: `${(width * 3) / 100}px` }}
							{...contentProps}
						>
							<Text {...titleParams} bold>
                Why Noshery?
							</Text>
						</Box>
						<Box
							style={{ paddingBottom: `${(width * 3) / 100}px` }}
							{...contentProps}
						>
							<Box>
								<Text {...descriptionParams}>{whynoshery1}</Text>
							</Box>
						</Box>
						<Box
							style={{ paddingBottom: `${(width * 3) / 100}px` }}
							{...contentProps}
						>
							<Box>
								<Text {...descriptionParams}>{whynoshery2}</Text>
							</Box>
						</Box>
					</Flex>
					<Box {...tileProps} />
					<StyledImage
						{...imageProps}
						src={`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${imageW},h_${imageH},c_fill,g_auto,f_auto/careers/careersWhatWeDo_s1cs1c.webp`}
						alt="A person holding a noshery bag in the kitchen"
					/>
				</>
			)}
			{!isDesktop && (
				<>
					<Flex flexDirection="column" {...contentParams}>
						<Box
							style={{ paddingBottom: `${(width * 3) / 100}px` }}
							{...contentProps}
						>
							<Text {...titleParams} bold>
                Why Noshery?
							</Text>
						</Box>
						<Box
							style={{ paddingBottom: `${(width * 3) / 100}px` }}
							{...contentProps}
						>
							<Box>
								<Text {...descriptionParams}>{whynoshery1}</Text>
							</Box>
						</Box>
						<Box
							style={{ paddingBottom: `${(width * 3) / 100}px` }}
							{...contentProps}
						>
							<Box>
								<Text {...descriptionParams}>{whynoshery2}</Text>
							</Box>
						</Box>
					</Flex>
					<Box {...tileProps} />
					<StyledImage
						{...imageProps}
						src={`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${imageW},h_${imageH},c_fill,g_auto,f_auto/careers/careersWhatWeDo_s1cs1c.webp`}
					/>
				</>
			)}
		</Flex>
	);
};

export default WhyNoshery;
