import React from "react";
import { Box, Image, Text } from "noshery-ui";
import styled from "styled-components";

import Logo from "../Logo2";
import CateringForm from "../CateringForm";
import Footer from "../Footer";
import useDeviceType from "../../../hooks/useDeviceType";
import useWindowSize from "../../../hooks/useWindowSize";

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const Catering = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();
	const imageHeight = parseInt((width * (isDesktop ? 20 : 35)) / 100);
	const imageParams = {
		height: `${imageHeight}px !important`,
	};

	const imageCoverParams = {
		height: `${imageHeight}px !important`,
		backgroundColor: "rgba(241, 166, 56, 0.5)",
	};

	return (
		<Box position="relative">
			<Logo />
			<Box position="relative">
				<Box {...imageCoverParams} width="100%" position="absolute" top={0} />
				<Image
					{...imageParams}
					src={`https://res.cloudinary.com/dwbvlpae2/image/upload/h_${imageHeight},c_fill,g_auto,f_auto/Landing/LP_FAQ_nuej6n.webp`}
					width="100%"
					alt="Catering page background image."
					style={{ objectFit: "cover" }}
				/>
				<ImageText />
			</Box>
			<CateringForm />
			<Footer />
		</Box>
	);
};

export default Catering;

const ImageText = () => {
	const { width } = useWindowSize();

	const { isDesktop } = useDeviceType();
	const imageTextParams = {
		marginTop: `-${(width * 3.3) / 100}px`,
	};

	const titleParams = {
		fontSize: `${(width * (isDesktop ? 6.6 : 8)) / 100}px`,
		marginTop: "0px",
		marginBottom: "0px",
	};

	return (
		<StyledBox {...imageTextParams} top="50%">
			<Box>
				<Text as='h1' color="white" {...titleParams} bold>
          Catering
				</Text>
			</Box>
		</StyledBox>
	);
};
