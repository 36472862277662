import React from "react";
import { Box, Flex, Image, Text } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";

const perks = [
	{
		title: "Dining Options", 
		description: "We offer delivery, pickup, and casual dine-in. Start your online order for San Mateo here and San Carlos here",
		img: "https://res.cloudinary.com/dwbvlpae2/image/upload/v1663221911/Perks/options_joijmk.svg",
	},
	{
		title: "Family Friendly", 
		description: "Kids love our grilled cheese sandwiches, quesadillas, french fries, and cookies. Parents love that they can get flavors and dishes more interesting than what their kids are eating.",
		img: "https://res.cloudinary.com/dwbvlpae2/image/upload/v1663221911/Perks/family_eudesh.svg",
	},
	{
		title: "Gluten-Free Options", 
		description: "Whether you desire a gluten-free bun for your delicious MeltBurger, a gluten-free tortilla for a Little Chihuahua burrito, or healthy wheat-free salad options, you'll find it at Noshery",
		img: "https://res.cloudinary.com/dwbvlpae2/image/upload/v1663221911/Perks/gf_yenwxn.svg",
	},
	{
		title: "Vegetarian & Vegan", 
		description: "Impossible burger patties, sweet potato fries, crispy tofu banh mi sandwiches, Indian curries, Mediterranean plates, dairy-free ice cream, and more. We've got vegan & vegetarian cravings covered!",
		img: "https://res.cloudinary.com/dwbvlpae2/image/upload/v1663221911/Perks/vegan_rdfpfh.svg",
	}
];

const ArrowLeft = styled(Box)`
  width: 0; 
  height: 0; 
  border-top:  ${(p) => `${((p.w * 4) /100)}px solid transparent`};
  border-bottom: ${(p) => `${((p.w * 4) /100)}px solid transparent`};
  border-right: ${(p) => `${((p.w * 4) /100)}px solid #F1A637`};
`;

const ArrowRight = styled(Box)`
  width: 0; 
  height: 0; 
  border-top:  ${(p) => `${((p.w * 4) /100)}px solid transparent`};
  border-bottom: ${(p) => `${((p.w * 4) /100)}px solid transparent`};
  border-left: ${(p) => `${((p.w * 4) /100)}px solid #F1A637`}; 
`;

const LanderPerks = ({mobilePaddingTopPerks, mobilePerksHeight}) => {
	const { width } = useWindowSize();

	const boxParams = {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		paddingTop: `${(width * mobilePaddingTopPerks)/100}px`
	};

	const titleParams1 = {
		fontSize: `${(width * 4)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
		paddingTop: "2%"
	};

	const titleParams2 = {
		fontSize: `${(width * 6)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
	};

	const buttonParams1 = {
		color: "#F1A637",
		position: "absolute", 
		left: `${(width * 1)/100}px`,
		top: `${(width * 30) / 100}px`,
		backgroundColor: "rgb(0,0,0,0)",
		border: "rgba(0,0,0,0)",
	};

	const buttonParams2 = {
		color: "#F1A637",
		position: "absolute", 
		right: `${(width * 1)/100}px`,
		top: `${(width * 30) / 100}px`,
		backgroundColor: "rgb(0,0,0,0)",
		border: "rgba(0,0,0,0)",

	};

	return (
		<Box height={`${(width * mobilePerksHeight) / 100}px !important`} textAlign="center" bg="#FADDB6" {...boxParams}>
			<Box paddingBottom="5%"> 
				<Box {...titleParams1} mb={`${(width * 1) / 100}px !important`}>
					<Text bold> CUSTOMER PERKS </Text>
				</Box>
				<Box {...titleParams2} mb={`${(width * 1) / 100}px !important`}>
					<Text bold> Kids? Dietary Preferences? </Text>
				</Box>
				<Box {...titleParams2} mb={`${(width * 1) / 100}px !important`}>
					<Text bold> We love to make everyone happy </Text>
				</Box>
				<Line />
			</Box>

			<CarouselProvider
				style={{
					width: `${(width * 80)/100}px`,
					position: "relative",
					height: `${(width * 20)/100}px`,
					paddingLeft: `${(width * 10)/100}px`,
					paddingRight: `${(width * 10)/100}px`,
				}}
				infinite={true}
				isPlaying={true}
				interval={2300}
				step={1}
				naturalSlideWidth={100}
				visibleSlides={1}
				naturalSlideHeight={150}
				totalSlides={4}>
				<ButtonBack style={buttonParams1}> 
					<ArrowLeft w={width} /> 
				</ButtonBack>
				<ButtonNext style={buttonParams2}> 
					<ArrowRight w={width} /> 
				</ButtonNext>
				<Slider> 
					{
						perks.map((item) => {
							const {title, description, img} = item; 
							return (
								<Slide key={`${title}-slides`}> 
									<ItemCard title={title} description={description} img={img} />    
								</Slide>         
							);
						})
					}
				</Slider>
			</CarouselProvider>
		</Box>
	);
};

export default LanderPerks;

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 80) / 100}px`,
		borderBottom: `${`${(width * .5) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};

const ItemCard = ({title, description, img}) => {
	const { width } = useWindowSize();
	const wrapperParams = {
		width: `${(width * 50.5) / 100}px !important`,
		height: `${(width * 65.5) / 100}px !important`,
		borderRadius: `${(width * 2) / 100}px !important`,
		backgroundColor: "#8B6017",
	};

	const textParams = {
		color: "white",
		fontSize: `${(width * 6) / 100}px !important`,
		paddingX: `${(width * 1) / 100}px`,
	};

	const textParams2 = {
		color: "white",
		textAlign: "center !important",
		fontSize: `${(width * 3) / 100}px !important`,
		fontWeight: `${(width * 10) / 100}`,
		padding: `${(width * 1) / 100}px`,
	};

	const cardParams = {
		paddingX: `${(width * 15) / 100}px !important`,
	};

	const logoPrams = {
		height: `${(width * 15) / 100}px`,
		width: `${(width * 15) / 100}px`,
		paddingTop: `${(width * 4) / 100}px`,
	};
  
	return (
		<Flex {...cardParams} >
			<Box {...wrapperParams}>
				<Image {...logoPrams} src={img} /> 
				<Box paddingTop="10%"> 
					<Text bold {...textParams}>
						{title.includes("&") ? title.split("&")[0] + " &": title.split(" ")[0]}
					</Text>
					<Text bold {...textParams}>
						{title.includes("&") ? title.split("&")[1]: title.split(" ")[1]}
					</Text>
				</Box>
				<Box>
					<Text {...textParams2}>
						{description}
					</Text>
				</Box>
			</Box>
		</Flex>
	);
};
