import {Box, Text} from "noshery-ui";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";
import Snackbar from "@mui/material/Snackbar";
import ClearIcon from "@mui/icons-material/Clear";
import * as MUI from "@mui/material";
import styled from "styled-components";
import React from "react";

const StyledBox = styled(Box)`
  &:hover {
    color: red;
  }
`;

export const HolidayHours = () => {
  
	const { width } = useWindowSize();
	const { isDesktop, isTablet } = useDeviceType();

	const holidayBannerParams = isDesktop 
		? {
			fontSize: `${(width * 1.4) / 100}px`,
			lineHeight: `${(width * 2) / 100}px`,
			marginTop: "0px",
			marginBottom: "0px",
			fontWeight: "bold",
			textAlign: "center",
			fontFamily: "Uni Neue Heavy",
		}
		: {
			fontSize: `${(width * (isDesktop ? 1 : 1)) / 100}px`,
			lineHeight: `${(width * (isDesktop ? 1.5 :  1)) / 100}px`,
			marginTop: "10px",
			marginBottom: "0px",
			fontWeight: "bold",
			textAlign: "center",
			fontFamily: "Uni Neue Heavy",
		};

	const subText = {
		fontSize: `${(width * (isDesktop ? 1 : .5)) / 100}px`,
		lineHeight: `${(width *1.2) / 100}px`,
		color: "white",
		textAlign: "center",
		alignContent: "center",
		alignSelf: "center",
		fontFamily: "Uni Neue"
	};

	const turkeyParams = {
		width: `${(width * (isDesktop ? 4 : 4)) / 100}px`,
		height: `${(width * (isDesktop ? 4 : 4)) / 100}px`,
	};

	const boxShadow1 = `${(width * 0.24) / 100}px`;
	const boxShadow2 = `${(width * 1) / 100}px`;

	const boxShadow3 = `${(width * .1) / 100}px`;
	const boxShadow4 = `${(width * .1) / 100}px`;

	const hover = { 
		transform: "scale(1.01)",
		transition: ".3s",
	};
	
	return (
		<MUI.Box onClick={() => window.open("https://partiful.com/e/vR36eqjUQXH5bNvkkIPC")} sx={{
			transition: ".1s",
			"&:hover": {
				...hover,
			},
			position: "absolute",
			top: `${(width * (isDesktop ? 11 : 17))/100}px`,
			left: `${(width * 3)/100}px`,
			backgroundColor: "rgb(52 71 52)",
			borderRadius: `${(width * 1.5)/100}px`,
			width: `${(width * (isDesktop ? 28 : 31))/100}px`,
			height: `${(width * (isDesktop ? 5 : 4))/100}px !important`,
			padding: `${(width * 1)/100}px`,
			display: "flex",
			justifyContent: "center",
			flexDirection: isDesktop ? "row" : "row",
			alignItems: "center",
			alignContent: "center",
			cursor: "pointer"
		}}>          
			<img style={turkeyParams} src="/firefork.png" alt="Noshery Fireworks" />
			<Box paddingRight="3%" display="flex" justifyContent="center" flexDirection="column" paddingLeft="3%" width={ `${(width * (isDesktop ? 36 : 30))/100}px`}> 
	  
				<Text style={{...holidayBannerParams, color: "white"}}>
					{"New Year's Hours"}
				</Text> 
				<Text style={{...subText, color: "white"}}>
					<b> 
Sunday, Dec 31st - 11:00AM - 9:00PM
					</b>
				</Text> 
				<Text style={subText}> 
					<b> 
Monday, Jan 1st - Normal Hours
						 </b>
				</Text> 
				{/* <Text style={subText}> 
					<b> Come on in and treat yourself to some delicious food while getting a chance to win the tickets! Offer valid on direct orders for delivery, pick up or dine-in at all Noshery locations. One entry per customer.  </b>
				</Text> */}
			</Box>
			<img style={turkeyParams} src="/firefork.png" alt="Noshery Fireworks" />
		</MUI.Box> 
         
	);
};

export const HolidayHoursLander = () => {  
	const { width } = useWindowSize();
	const { isDesktop, isTablet } = useDeviceType();
	const [open, setOpen] = React.useState(true);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};
	const holidayBannerParams = isDesktop 
		? {
			fontSize: `${(width * .9) / 100}px`,
			lineHeight: `${(width * 1) / 100}px`,
			marginTop: "0px",
			marginBottom: "0px",
			fontWeight: "bold",
			textAlign: "center"
		}
		: {
			fontSize: `${(width * (isDesktop ? 1.5 : 2.5)) / 100}px`,
			lineHeight: `${(width * (isDesktop ? 1.5 : 2.5)) / 100}px`,
			marginTop: "10px",
			marginBottom: "0px",
			fontWeight: "bold",
			textAlign: "center"
		};

	const subText = {
		fontSize: `${(width * (isDesktop ? .9 : 1.2)) / 100}px`,
		lineHeight: `${(width * 1.8) / 100}px`,
		color: "white",
		textAlign: "center",
	};

	const turkeyParams = {
		width: `${(width * (isDesktop ? 10 : 8)) / 100}px`,
		height: `${(width * (isDesktop ? 4 : 8)) / 100}px`,
		marginBottom: `${(width * 1)/100}px`,
	};
	return (
		<Snackbar
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			open={open} autoHideDuration={10000} onClose={handleClose}>
			<Box style={{
				backgroundColor: "rgb(52 71 52)",
				marginBottom: "5%",
				borderRadius: `${(width * (isDesktop ? .5 : 1))/100}px`,
				width: `${(width * (isDesktop ? 35 : 60))/100}px`,
				height: `${(width * (isDesktop ? 10 : 15))/100}px`,
				padding: `${(width * 1)/100}px`,
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
				alignItems: "center",
				alignContent: "center",
				zIndex: "1000"
			}}>        
				{/* <StyledBox onClick={handleClose} style={{ fontSize: `${(width * (isDesktop ? 1 : 1.2)) / 100}px`, alignSelf: "start", cursor: "pointer",}}> <ClearIcon sx={{color: "#B53737", "&:hover": {
					color: "red"
				},}} /> </StyledBox> */}
				{/* <img style={turkeyParams} src="/nosheryBaseballBat.png" alt="Noshery Fireworks" /> */}
				<Box paddingRight="3%" paddingLeft="1%" width={ `${(width * (isDesktop ? 35 : 60))/100}px`}> 
					{/* <Text style={{...holidayBannerParams, color: "white"}}>
            NOSHERY GIFT CARDS ARE NOW AVAILABLE
					</Text> 
					<Text style={subText}> 
            Select Gift Cards in the top right for e-Gift Cards or visit us in store for physical gift cards 
					</Text> */} 
					<Text style={{...holidayBannerParams, color: "white"}}>
						{"New Year's Hours"}
					</Text> 
					<Text style={{...subText, color: "white"}}>
						<b> 
Sunday, Dec 31st - 11:00AM - 9:00PM
						</b>
					</Text> 
					<Text style={subText}> 
						<b> 
Monday, Jan 1st - Normal Hours
						 </b>
					</Text> 
					{/* <Text style={subText}> 
						<b> San Carlos 02/23: 4:30pm - 11:00pm  </b>
					</Text>
					<Text style={subText}> 
						<b> San Mateo 02/23: Regular Hours  </b>
					</Text> */}
				</Box>
				{/* <img style={turkeyParams} src="/rainfork.png" alt="Noshery Fireworks" /> */}

			</Box>
		</Snackbar> 
	);
};