import React, { useState } from "react";
import { Box, Flex, Text } from "noshery-ui";
import styled from "styled-components";
import {qAs} from "../../constants/faq";
import useWindowSize from "../../hooks/useWindowSize";
import { animated, useSpring } from "react-spring";

const Questions = () => {
	const { width } = useWindowSize();
	const [index, setIndex] = useState();

	const wrapperParams = {
		marginTop: `${(width * 5.1) / 100}px`,
		marginBottom: `${(width * 5.1) / 100}px`,
	};

	const boxParams = {
		width: `${(width * 94) / 100}px`,
	};

	return (
		<Flex justifyContent="center" alignItems="center" {...wrapperParams}>
			<Box {...boxParams}>
				{qAs.map(({ q, a }, i) => {
					return (
						<SingleQA
							key={i}
							q={q}
							a={a}
							onClick={() => setIndex(i)}
							isOpen={index === i}
						/>
					);
				})}
			</Box>
		</Flex>
	);
};

export default Questions;

const BoxQ = styled(Box)`
  background: ${(p) => (p.isOpen ? "#BC8329" : "#f1a638")};
  color: #ffffff;
`;

const BoxA = styled(Box)`
  background: #ededed;
  color: #000000;
`;

const AnimatedBox = animated(BoxA);

const AniamtedWrapper = animated(Box); 

const SingleQA = ({ q, a, isOpen, onClick }) => {
	const { width } = useWindowSize();
	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1) / 100}px`;

	const question = {
		fontSize: `${(width * 6) / 100}px`,
		lineHeight: `${(width * 6) / 100}px`,
		padding: `${(width * 2.1) / 100}px ${(width * 2.4) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 2) / 100}px`,
		height: `${(width * 17) / 100}px`,
		alignItems: "center",
		display: "flex",
		width: "100%"
	};

	const {height, lineHeight, padding, paddingTop, opacity} = useSpring({
		from: {
			height: "0px",
			fontSize: "0px",
			lineHeight: "0px",
			padding: "0px",
			paddingTop: "0px",
			opacity: 0
		},
		to: {
			height: isOpen ? `${(width * (35)) / 100}px` : "0px",
			opacity: isOpen ? 1 : 0,
			fontSize:`${(width * 4) / 100}px`,
			lineHeight: isOpen ? `${(width * 4 ) / 100}px` : "0px",
			padding: 
        isOpen ? `${(width * 2.1) / 100}px ${(width * 2.4) / 100}px` : "0px",
			paddingTop: isOpen ? `${(width * 9) / 100}px` : "0px",
		}
	});

	const answerWrapper = {
		top: `-${(width * 9) / 100}px`,
		position: "relative",
		zIndex: -1,
	};    

	const wrapper = {
		position: "relative",
	};

	const {height: WrapperH} = useSpring({
		from: { height: `${(width * (35)) / 100}px`},
		to: {
			height: isOpen && `${(width * (55)) / 100}px`
		}
	});

	return (
		<AniamtedWrapper 
			{...wrapper} a 
			onClick={onClick} 
			style={{ 
				cursor: "pointer",
				height: WrapperH
			}}>
			<BoxQ {...question} isOpen={isOpen} mb={`${(width * 2.5) / 100}px`}>
				<Text bold>{q}</Text>
			</BoxQ>
			<BoxA {...answerWrapper}> 
				<AnimatedBox  
					style={{
						opacity: opacity,
						fontSize: `${(width * 4) / 100}px`,
						lineHeight: lineHeight,
						padding: padding,
						boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
						borderRadius: `${(width * 2) / 100}px`,
						height: height,
						paddingTop: paddingTop, 
						width: "100%",
					}}
				>
					{a}
				</AnimatedBox>
			</BoxA>
		</AniamtedWrapper>
	);
};



