import React from "react";
import { Box, Text } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import { ALT_IMGS } from "../../constants";

const NosheryLogo = styled.img`
  width: ${(p) => p.width};
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

const DraperMobileLogo = () => {
	const { width } = useWindowSize();
	const ratio = 56;
	const logoWidth = (width * ratio) / 100;

	const logoWrapperParams = {
		top: `${(logoWidth * 53) / 100}px`,
		left: `${(logoWidth * 35) / 100}px`,
	};

	return (
		<Box {...logoWrapperParams} position="absolute" zIndex={2}>
			<Box>
				<Text
					fontSize="20px"
					lineHeight="27px"
					color="white"
					fontWeight="800"
					textAlign="center"
				>
          &
				</Text>
			</Box>

			<NosheryLogo
				alt={ALT_IMGS["logo"].altImg}
				src={
					"https://res.cloudinary.com/dwbvlpae2/image/upload/v1666118832/DraperUni/drapermobilelogo_hjzmcp.webp"
				}
				width={`${logoWidth + 10}px`}
				height={`${logoWidth / 3 + 10}px`}
			/>
		</Box>
	);
};

export default DraperMobileLogo;
