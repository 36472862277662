import React from "react";
import {Box, Text, Flex} from "noshery-ui";  
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import Logo from "./Logo2";
import SocialIcons from "./SocialIcons"; 
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { ORDER_URL } from "../../constants";

const AnimatedBox = animated(Box); 

const SidebarContainer = styled(AnimatedBox)` 
    width: ${(p) => `${(p.w * 63)/100}px`};
    height: 100vh; 
    background-color: #F1A738;
    border-radius: 0% -50% -50% 0%;
    position: fixed;
    top: 0; 
    left: 0;
    z-index: 25;
    display: flex; 
    justify-content: center;
    padding:  ${(p) => `${(p.w * 3.9)/100}px`};
`;

const VStack = styled(Flex)`
    justify-content: ${(p) => p.space}; 
    flex-direction: column; 
    height: 100%;
`;

const TopChip = styled(Box)`
  width: ${(p) => `${(p.w * 24.15)/100}px`};
  border-top-left-radius: ${(p) => `${(p.w * 7.24)/100}px`};
  height: ${(p) => `${(p.w * 12.07)/100}px`}; 
  position: absolute;
  top: 0px;
  z-index: 50;
  left: ${(p) => `${(p.w * 62.80)/100}px`};
  box-shadow: ${(p) => `0 -${(p.w * 6.03)/100}px 0 0 #F1A738`};
`;

const BottomChip = styled(Box)`
  width: ${(p) => `${(p.w * 24.15)/100}px`};
  border-top-left-radius: ${(p) => `${(p.w * 7.24)/100}px`};
  height: ${(p) => `${(p.w * 12.07)/100}px`}; 
  position: absolute;
  bottom: 0px;
  z-index: 50;
  left: ${(p) => `${(p.w * 62.80)/100}px`};
  box-shadow: ${(p) => `0 -${(p.w * 6.03)/100}px 0 0 #F1A738`};
  transform: scaleY(-1);
`;
const Sidebar = ({sidebarOpen, setSidebarOpen, isLanding}) => {
	const { width } = useWindowSize();
	const { left } = useSpring({
		config: { duration: 200},
		from: { left: "-63%" },
		left: sidebarOpen ? "0%" : "-63%",
	});

	const textparams = {
		fontSize: `${(width * 8.6)/100}px`,
		lineHeight:  `${(width * 12)/100}px`,
		color: "white",
		textDecoration: "none" 
	};

	const { opacity } = useSpring({
		config: {duration: 10},
		from: {
			opacity: 0
		},
		opacity: sidebarOpen ? 1 : 0
	});

	return (
		<SidebarContainer style={{ height: "100%", left: left }} zIndex={3} w={width}> 
			<BottomChip as={animated.div} style={{opacity: opacity}} w={width} /> 
			<TopChip as={animated.div} style={{opacity: opacity}} w={width} />
			<VStack space="space-between">
				<Logo /> 
				<VStack >
					<Link to=""
						style={{ 
							textDecoration: "none",
							paddingTop: `${(width * 2)/100}px`,
							paddingBottom: `${(width * 2)/100}px`,
						}}
						onClick={() => window.open(ORDER_URL)}
					> 
						<Text bold {...textparams}> Order Now </Text> 
					</Link>
					<Link onClick={() =>{
						window.open("https://noshery.securetree.com/"
						);}}   
					to="/"                     
					style={{ 
						textDecoration: "none",
						paddingTop: `${(width * 2)/100}px`,
						paddingBottom: `${(width * 2)/100}px`,
					}}
					> 
						<Text bold {...textparams}> Gift Cards </Text> 
					</Link>
					<Link to="/rewards"
						style={{ 
							textDecoration: "none",
							paddingTop: `${(width * 2)/100}px`,
							paddingBottom: `${(width * 2)/100}px`,
						}}
					> 
						<Text bold {...textparams}> Rewards </Text> 
					</Link>
					{ isLanding && <Link to="#mobilefaq" 
						style={{ 
							textDecoration: "none",
							paddingTop: `${(width * 2)/100}px`,
							paddingBottom: `${(width * 2)/100}px`,
						}}
						onClick={(e) => {
							e.preventDefault();
                    
							window.scrollTo({
								top: document.documentElement.scrollHeight,
								behavior: "smooth",
							});
							setSidebarOpen(!sidebarOpen);
						}}
					> 
						<Text bold {...textparams}> FAQ </Text> 
					</Link>}
					<Link to="/careers"
						style={{ textDecoration: "none",
							paddingTop: `${(width * 2)/100}px`,
							paddingBottom: `${(width * 2)/100}px`,
						}}> 
						<Text bold {...textparams}> Jobs </Text> 
					</Link>
					<Link to="/blog"
						style={{ textDecoration: "none",
							paddingTop: `${(width * 2)/100}px`,
							paddingBottom: `${(width * 2)/100}px`,
						}}> 
						<Text bold {...textparams}> Blog </Text> 
					</Link>
					<Link to="/press-release"
						style={{ textDecoration: "none",
							paddingTop: `${(width * 2)/100}px`,
							paddingBottom: `${(width * 2)/100}px`,
						}}> 
						<Text bold {...textparams}> Press </Text> 
					</Link>
				</VStack>
				<Box>
					<Link to="/contact-us" 
						style={{ textDecoration: "none",
							paddingBottom: `${(width * 4)/100}px`,
						}}> 
						<Text bold {...textparams}> Contact Us </Text> 
					</Link>   
					<SocialIcons />                 
				</Box> 
			</VStack> 
		</SidebarContainer>         
	);
};

export default Sidebar; 