import React from "react";
import { Flex, Box, Text } from "noshery-ui";
import useFirebaseStorage from "../../hooks/useFirebaseStorage";
import styled from "styled-components";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";
import { whoarewe } from "../../constants";

const ThreeRows = styled(Box)`
    display: grid;
    grid-template-columns: 33% 33% 33%;
`;

const WhoAreWe = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();
	const imageUrls = useFirebaseStorage("landing/whatwedo");

	const titleParams = isDesktop ?
		{
			fontSize: `${(width * 6) / 100}px`,
			lineHeight: `${(width * 6) / 100}px`,
			color: "#ffffff",
			textAlign: "center"
		}
		: 
		{
			fontSize: `${(width * 6) / 100}px`,
			lineHeight: `${(width * 6) / 100}px`,
			color: "#ffffff",
			textAlign: "center"
		}
  ;

	const backgroundProps = { 
		backgroundSize: `${width*2}px`, 
		backgroundColor: "#344734", 
		backgroundImage: `url(${imageUrls[0]})` 
	};

	const wrapperParams = isDesktop
		? { marginTop: `${(width * 4.6) / 100}px`, padding: `${(width * 3) / 100}px` }
		: { marginTop: `${(width * 6.4) / 100}px`, padding: `${(width * 2) / 100}px` };


	const contentW = isDesktop ? 45 : 45;

	const contentProps = { width: `${(width * contentW) / 100}px !important` };

	return (
		<Flex
			justifyContent={"center"} alignItems={"center"}
			style={backgroundProps} {...wrapperParams}>
      
			{isDesktop && <>
				<Flex flexDirection='column' alignItems='center'>
					<Box {...contentProps}>
						<Flex alignItems='center' justifyContent='center' >
							<Text {...titleParams} bold>
              WE ARE
							</Text>
						</Flex>
					</Box>
					<ThreeRows>
						{whoarewe.map((item, i) => {
							return <Card key={`#whoweare${i}`} title={item.title}  text={item.text} />; 
						})}
					</ThreeRows> 
				</Flex> </>}
			{!isDesktop && <>
				<Flex flexDirection='column' alignItems='center'>
					<Box {...contentProps}>
						<Flex alignItems='center' justifyContent='center' >
							<Text {...titleParams} bold>
              WHO ARE WE?
							</Text>
						</Flex>
					</Box>
					<ThreeRows>
						{whoarewe.map((item, i) => {
							return <Card key={`#whoweare${i}`} title={item.title}  text={item.text} />; 
						})}
					</ThreeRows> 
				</Flex>
			</>
			}
		</Flex>
	);
};

export default WhoAreWe;


const Card = ({title, text}) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();
	const headerParams = {
		backgroundColor: "#ffffff",
		width: `${(width * (isDesktop ? 25 : 25)) / 100}px !important`,
		height: `${(width * (isDesktop ? 10 : 15)) / 100}px !important`,
		borderRadius: "15px",
		padding: `${(width * (isDesktop ? 1.2 : 1.2)) / 100}px !important`,
		textAlign: "center",
		color: "#344734",
		justifyContent: "center", flexDirection: "column"
	};

	const titleText = {
		fontSize: `${(width * (isDesktop ? 2 : 2.6)) / 100}px`,
		padding: `${(width * (isDesktop ? 2 : 1)) / 100}px !important`,
	};

	const descriptionParams = {
		fontSize: `${(width * (isDesktop ? 1.8 : 2.5)) / 100}px`,
		color: "#ffffff",
		textAlign: "center",
		width: `${(width * (isDesktop ? 25 : 25)) / 100}px !important`,
		paddingTop: `${(width * (isDesktop ? 3 : 4)) / 100}px !important`,
	};

	return (
		<Box padding={`${(width * (isDesktop ? 2 : 2)) / 100}px !important`}>
			<Flex {...headerParams}> 
				<Text bold {...titleText}>
					{title}
				</Text>
			</Flex>

			<Flex alignItems='center' justifyContent='center' flexDirection='column' {...descriptionParams}> 
				<Text>  
					{text}
				</Text>
			</Flex>
		</Box>
	);
};


