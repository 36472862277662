import React from "react";
import { Box, Flex, Image } from "noshery-ui";
import { Link } from "react-router-dom";
import useDeviceType from "../../hooks/useDeviceType";
import { landingBrands } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";

const BrandList = ({ name }) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();
	const logos = Object.keys(landingBrands).filter((l) => l !== name);

	const wrapperParams = {
		padding: `${(width * 2.7) / 100}px 0px !important`,
		marginTop: `${(width * 8.3) / 100}px`,
	};

	const lW = parseInt((width * (isDesktop ? 6.5 : 9)) / 100);
	const lH = parseInt((width * (isDesktop ? 6.5 : 9)) / 100);

	const logoPrams = {
		width: `${lW}px !important`,
		height: `${lH}px !important`,
	};

	return (
		<Flex
			backgroundColor="#FADDB6"
			justifyContent="center"
			{...wrapperParams}
			overflowX="scroll"
		>
			{logos.map((l, i) => {
				if(landingBrands[l].name === "comingsoon") return false; 
				return <Box key={`#brandlist${i}`} mr={`${(width * 2 ) / 100}px`}>
					<LogoLink
						l={l}
						src={landingBrands[l].logo(lW, lH)}
						logoPrams={logoPrams}
					/>
				</Box>;
			})}
		</Flex>
	);
};

const LogoLink = ({ l, src, logoPrams }) => {
	const [hover, setHover] = React.useState(false);
	return (
		<div
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={() => setHover(false)}
			style={{
				transform: hover ? "scale(1.2)" : "scale(1)",
				transition: ".5s",
			}}
		>
			<Link
				key={landingBrands[l].to}
				to={`/${landingBrands[l].to}`}
				style={{ textDecoration: "none" }}
			>
				<Image src={src} {...logoPrams} alt={`${l} logo`} />
			</Link>
		</div>
	);
};

export default BrandList;
