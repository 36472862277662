import React, {useState} from "react";
import { Box, Image, Text } from "noshery-ui";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import Nav from "../Nav";
import CateringForm from "../CateringForm";
import useWindowSize from "../../../hooks/useWindowSize";
import MobileSidebar from "../Sidebar";
import {useSwipeable} from "react-swipeable";

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const AnimatedBox = animated(Box); 

const Catering = () => {
	const { width } = useWindowSize();
	const imageHeight = parseInt((width * 55) / 100);
	const imageParams = {
		height: `${imageHeight}px !important`,
	};

	const [sidebarOpen, setSidebarOpen] = useState(false); 
	const [headerOpen, setHeaderOpen] = useState(true); 
	const [offset, setOffset] = useState(0);

	const { left } = useSpring({
		config: { duration: 200 },
		from: { left: "0%"},
		left: sidebarOpen ? "60%" : "0%",
	});

	React.useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	const handlers = useSwipeable({
		onSwipedLeft: () => setSidebarOpen(false),
		onSwipedRight: () => setSidebarOpen(true),
		onSwipedDown: () => setHeaderOpen(true),
		onSwipedUp: () => setHeaderOpen(false),
		swipeDuration: 500,
		delta: { up: 0, down: 0, left: 80, right: 100 },
		trackMouse: true
	});

	return (
		<Box {...handlers} overflow="hidden" position="relative">
			<MobileSidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
			<Box position="relative" overflow="hidden">
				<AnimatedBox style={{ left: left, position: "relative"}}> 
					<Nav 
						sidebarOpen={sidebarOpen} 
						setSidebarOpen={setSidebarOpen} 
						scrolling={offset}
						headerOpen={headerOpen}
					/>
					<Box position="relative">
						<Image
							{...imageParams}
							src={`https://res.cloudinary.com/dwbvlpae2/image/upload/h_${900},w_${900},c_fill,g_auto,f_auto/Landing/LP_FAQ_nuej6n.webp`}
							width="100%"
							alt="Catering page background image."
							style={{ objectFit: "cover" }}
						/>
						<ImageText />
					</Box>
					<CateringForm />
				</AnimatedBox>
			</Box>
		</Box>
	);
};

export default Catering;

const ImageText = () => {
	const { width } = useWindowSize();
	const imageTextParams = {
		marginTop: `-${(width * 1) / 100}px`,
		paddingTop: `${(width * 3) / 100}px`,
	};

	const titleParams = {
		fontSize: `${(width * 17) / 100}px`,
		marginTop: "0px",
		marginBottom: "0px",
	};

	return (
		<StyledBox {...imageTextParams} top="50%">
			<Box>
				<Text as="h1" color="white" {...titleParams} bold>
          Catering
				</Text>
			</Box>
		</StyledBox>
	);
};
