import React from "react";
import styled from "styled-components";
import { Box, Flex, Text, Image, Heading, Button } from "noshery-ui";
import Logo from "./Logo";
import NavButtons from "./NavButtons";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

import { ORDER_URL } from "../../constants";

const BackgroundImage = styled(Image)`
  min-height: ${(p) => p.size};
  min-width: ${(p) => p.size};
  border-radius: 50%;
  right: ${(p) => p.right};
  top: ${(p) => p.top};
  box-shadow: ${(p) => p.shadow};
  position: absolute;
  object-fit: cover;
`;

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
`;

const Header = ({
	title,
	bgBrand,
	longDescription,
	menuId,
	name,
	shortDescription,
	pageImgAlts,
}) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();

	// desktop image 60%, tablet & mobile 88%
	const imageSize = parseInt(((isDesktop ? 7 : 9.2) * width) / 10);
	// desktop top is 24.5% of image width, tablet & mobile 15%
	const imageTop = parseInt(((isDesktop ? 20 : 12) * imageSize) / 100);
	// desktop right is 18.5% of image width, tablet & mobile 12%
	const imageRight = parseInt(((isDesktop ? 28 : 28) * imageSize) / 100);

	return (
		<Box position="relative" overflowX="hidden">
			<Logo />
			<NavButtons />
			<Flex
				alignItems="center"
				justifyContent={isDesktop ? "space-between" : "flex-end"}
			>
				<Box mb={`${(width * 5) / 100}px`}>
					{isDesktop && (
						<Content
							title={title}
							longDescription={longDescription}
							menuId={menuId}
							width={width}
						/>
					)}
				</Box>
				<Box
					justifyContent="flex-end"
					borderRadius="50%"
					position="relative"
					height={`${imageSize - imageTop}px`}
				>
					<BackgroundImage
						alt={pageImgAlts["header"]}
						src={bgBrand(imageSize, imageSize)}
						size={`${imageSize}px !important`}
						top={`-${imageTop}px !important`}
						right={`-${imageRight}px !important`}
						shadow={
							isDesktop
								? "4px 4px 20px 10px rgba(0, 0, 0, 0.25)"
								: "1px 1px 4px 2px rgba(0, 0, 0, 0.25)"
						}
					/>
				</Box>
			</Flex>
			{!isDesktop && (
				<Content
					title={title}
					longDescription={longDescription}
					shortDescription={shortDescription}
					width={width}
				/>
			)}
		</Box>
	);
};

export default Header;

const Content = ({ title, longDescription, menuId, shortDescription }) => {
	const { width } = useWindowSize();
	const { isDesktop, isTablet } = useDeviceType();

	const styledTextParams = isDesktop
		? {
			fontSize: `${(width * 1.6) / 100}px`,
		}
		: {
			fontSize: `${(width * 2.4) / 100}px`,
			lineHeight: `${(width * 3.3) / 100}px`,
			textAlign: "center",
		};

	const styledHeadingParams = isDesktop
		? {
			fontSize: `${(width * 3.3) / 100}px`,
		}
		: {
			fontSize: `${(width * 6) / 100}px`,
			lineHeight: `${(width * 6.5) / 100}px`,
			textAlign: "center",
		};

	const buttonTextParams = isDesktop
		? {
			fontSize: `${(width * 2.3) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,
		}
		: {
			fontSize: `${(width * 4) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,
			xtAlign: "center",
		};

	const boxShadow1 = `${(width * 0.1) / 100}px`;
	const boxShadow2 = `${(width * 1.0) / 100}px`;

	const buttonWidthPercentage = isDesktop || isTablet ? 17.1 : 28;
	const minButtonWidth = (width * buttonWidthPercentage) / 100;
	const buttonRightMargin = (width * 1.9) / 100;

	const buttonParams = {
		...buttonTextParams,
		minWidth: `${2 * minButtonWidth + buttonRightMargin}px`,
		marginBottom: `${(width * 100) / 100}px`,
		borderRadius: `${(width * 0.72) / 100}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const wrapperWidth = isDesktop
		? `${(width * 29.5) / 100}px`
		: `${(width * 76) / 100}px`;

	const wrapperParams = isDesktop
		? {
			width: wrapperWidth,
			marginLeft: `${(width * 8.3) / 100}px`,
		}
		: {
			marginLeft: `${(width * 10.7) / 100}px`,
			marginRight: `${(width * 10.7) / 100}px`,
			textAlign: "center",
		};

	const wrapperParams2 = isDesktop
		? {
			width: `${(width * 34) / 100}px`,
			marginLeft: `${(width * 8.3) / 100}px`,
			marginTop: `${(width * 1) / 100}px`,
		}
		: {
			marginLeft: `${(width * 10.7) / 100}px`,
			marginRight: `${(width * 10.7) / 100}px`,
			textAlign: "center",
		};

	const buttonWrapperParams = isDesktop
		? {
			width: `${(width * 45) / 100}px`,
			marginTop: `${(width * 3) / 100}px`,
			marginLeft: `${(width * 8.3) / 100}px`,
		}
		: {
			marginTop: `${(width * 1.7) / 100}px`,
			marginBottom: `${(width * 1.7) / 100}px`,
			marginLeft: `${(width * 8.3) / 100}px`,
		};

	return (
		<Box>
			<Box>
				<Box {...wrapperParams}>
					<Heading as="h1" bold {...styledHeadingParams}>
						{title}
					</Heading>
				</Box>
				<Box {...wrapperParams2}>
					<Text fontWeight={500} {...styledTextParams}>
						{shortDescription || longDescription}
					</Text>
				</Box>
			</Box>
			<Flex
				justifyContent={isDesktop ? "flex-start" : "center"}
				{...buttonWrapperParams}
			>
				<Box mb={`${(width * 1.5) / 100}px`}>
					<StyledButton
						isDesktop={isDesktop}
						onClick={() => window.open(ORDER_URL)}
						{...buttonParams}
					>
            Order Now
					</StyledButton>
				</Box>
			</Flex>
		</Box>
	);
};
