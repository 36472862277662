import React, {useState} from "react";
import { Box, Image, Flex, Text, Button } from "noshery-ui";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import FAQ from "../FAQLoyalty";
import RewardsCards from "../RewardsCards";
import useWindowSize from "../../../hooks/useWindowSize";
import MobileSidebar from "../Sidebar";
import Nav from "../Nav";
import { useSwipeable } from "react-swipeable";

const AnimatedBox = animated(Box); 

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const BackgroundImage = styled(Image)`
  min-height: ${(p) => p.size};
  min-width: ${(p) => p.size};
  z-index: -1; 
  border-radius: 50%;
  right: ${(p) => p.right};
  top: ${(p) => p.top};
  box-shadow: ${(p) => p.shadow};
  position: absolute;
  object-fit: cove;
`;

const Rewards = () => {
	const { width } = useWindowSize();
  
	const imageSize = parseInt((13 * width) / 10);
	const imageTop = parseInt((45 * width) / 100);
	const imageRight = parseInt((15 * width)  / 100);

	const [sidebarOpen, setSidebarOpen] = useState(false); 
	const [headerOpen, setHeaderOpen] = useState(true); 
	const [offset, setOffset] = useState(0);

	const { left } = useSpring({
		config: { duration: 200 },
		from: { left: "0%"},
		left: sidebarOpen ? "60%" : "0%",
	});

	React.useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	const handlers = useSwipeable({
		onSwipedLeft: () => setSidebarOpen(false),
		onSwipedRight: () => setSidebarOpen(true),
		onSwipedDown: () => setHeaderOpen(true),
		onSwipedUp: () => setHeaderOpen(false),
		swipeDuration: 500,
		delta: { up: 0, down: 0, left: 80, right: 100 },
		trackMouse: true
	});

	return (
		<Box {...handlers} overflow="hidden" position="relative">
			<MobileSidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
			<Box overflow="hidden">
				<AnimatedBox style={{ left: left, position: "relative"}}> 
					<Nav 
						sidebarOpen={sidebarOpen} 
						setSidebarOpen={setSidebarOpen} 
						scrolling={offset}
						headerOpen={headerOpen}
					/>
					<ImageText />
					<Flex flexDirection="column" justifyContent='center'>
						<Box 
							justifyContent="flex-end"
							borderRadius="50%"
							position="relative"
							height={`${1.5 * imageTop}px`}
							marginBottom={"6%"}>
							<BackgroundImage
								alt="nosh points background image"
								size={`${imageSize}px`}
								src={"https://res.cloudinary.com/dwbvlpae2/image/upload/v1661544505/Rewards/rewardsbg_ddnko4.webp"}
								width="100%"
								top={`-${imageTop}px`}
								right={`-${imageRight}px`}
								style={{ objectFit: "cover" }} />
						</Box>
					</Flex>
					<RewardsCards />
					<FAQ /> 
				</AnimatedBox>
			</Box>
		</Box>
	);
};

export default Rewards;

const ImageText = () => {
	const { width } = useWindowSize();

	const imageTextParams = {
		marginTop: `${(width * 38) / 100}px`,
		width: `${width}px`
	};

	const titleParams = {
		fontSize: `${(width * 17) / 100}px`,
		lineHeight: `${(width * 23) / 100}px`,
		width: "100%",
		marginTop: "0px",
		marginBottom: "0px",
	};

	return (
		<StyledBox {...imageTextParams}>
			<Text as="h1" color="white" {...titleParams} bold>
         Nosh Points
			</Text>
		</StyledBox>
	);
};
