import React from "react";
import { Helmet } from "react-helmet";

const MetadataHelmet = ({ metaTitle, metaDescription, metaTags, to }) => {
	return (
		<Helmet>
			<meta charSet="utf-8" />
			<meta name="keywords" content={metaTags} />
			<meta
				data-react-helmet="true"
				property="og:description"
				content={metaDescription}
			/>
			<meta
				data-react-helmet="true"
				name="description"
				content={metaDescription}
			/>
			<meta
				data-react-helmet="true"
				name="keywords"
				content={metaDescription}
			/>
			<title>{metaTitle}</title>
			<link rel="canonical" href={`${window.location.origin}/${to}`} />
		</Helmet>
	);
};

export default MetadataHelmet;
