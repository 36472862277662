import React, {useState} from "react";
import { Box, Image, Text, Flex, Button } from "noshery-ui";
import styled from "styled-components";
import useDeviceType from "../../../hooks/useDeviceType";
import useWindowSize from "../../../hooks/useWindowSize";
import Select from "react-select";
import Perks from "../Perks";
import WhyNoshery from "../WhyNoshery";
import WhoAreWe from "../WhoAreWe";
import {
	jobs,
	jobCategoryOptions,
	jobLocationOptions,
} from "../../../constants/index";
import MobileSidebar from "../Sidebar";
import Nav from "../Nav";
import { useSpring, animated } from "react-spring";
import { useSwipeable } from "react-swipeable";

const AnimatedBox = animated(Box); 

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const BackgroundImage = styled(Image)`
  min-height: ${(p) => p.size};
  min-width: ${(p) => p.size};
  z-index: -1; 
  border-radius: 50%;
  right: ${(p) => p.right};
  top: ${(p) => p.top};
  box-shadow: ${(p) => p.shadow};
  position: absolute;
  object-fit: cover;
`;

const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const Careers = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();
	const [filterLocation, setfilterLocation] = React.useState(null);
	const [filterCategory, setfilterCatergory] = React.useState(null);

	const imageHeight = parseInt((width * (isDesktop ? 45 : 80)) / 100);

	const textParams = {
		fontSize: `${(width * 7) / 100}px !important`,
		color: "#344734",
		padding: `${(width * 2) / 100}px`,
		marginTop: "0px",
		marginBottom: "0px",
	};

	const smallTitleParams = {
		fontSize: `${(width * 7) / 100}px !important`,
		color: "#344734",
		paddingTop: `${(width * 2.5) / 100}px`,
	};

	const imageSize = parseInt((13 * width) / 10);
	const imageTop = parseInt((45 * width) / 100);
	const imageRight = parseInt((15 * width)  / 100);

	const [sidebarOpen, setSidebarOpen] = useState(false); 
	const [headerOpen, setHeaderOpen] = useState(true); 
	const [offset, setOffset] = useState(0);

	const { left } = useSpring({
		config: { duration: 200 },
		from: { left: "0%"},
		left: sidebarOpen ? "60%" : "0%",
	});

	React.useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	const handlers = useSwipeable({
		onSwipedLeft: () => setSidebarOpen(false),
		onSwipedRight: () => setSidebarOpen(true),
		onSwipedDown: () => setHeaderOpen(true),
		onSwipedUp: () => setHeaderOpen(false),
		swipeDuration: 500,
		delta: { up: 0, down: 0, left: 80, right: 110 },
		trackMouse: true
	});

	return (
		<Box {...handlers} position="relative" overflowX="hidden">
			<MobileSidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
			<Box overflow="hidden">
				<AnimatedBox style={{ left: left, position: "relative"}}> 
					<Nav 
						sidebarOpen={sidebarOpen} 
						setSidebarOpen={setSidebarOpen} 
						scrolling={offset}
						headerOpen={headerOpen}
					/>
					<ImageText />
					<Flex flexDirection="column" justifyContent='center'>
						<Box 
							justifyContent="flex-end"
							borderRadius="50%"
							position="relative"
							height={`${1.5 * imageTop}px`}
							marginBottom={"6%"}>
							<BackgroundImage
								alt="contact us pagw background image"
								size={`${imageSize}px`}
								src={`https://res.cloudinary.com/dwbvlpae2/image/upload/h_${900},w_auto,c_fill,g_auto,f_auto/careers/JOB_H_uxflip.webp`}
								width="100%"
								top={`-${imageTop}px`}
								right={`-${imageRight}px`} />
						</Box>
					</Flex>
					<Flex paddingTop={`${(width * 15)/100}px`} flexDirection="column" alignItems="center">
						<Text as="h1" bold {...textParams}>
							{" "}
                Careers{" "}
						</Text>
						<Flex>
							<Box
								style={{ paddingRight: `${(width * (isDesktop ? 8 : 4)) / 100}px` }}
							>
								<Dropdown
									filter={filterCategory}
									setFilter={setfilterCatergory}
									title={"CATEGORY"}
									options={jobCategoryOptions}
								/>
							</Box>
							<Dropdown
								filter={filterLocation}
								setFilter={setfilterLocation}
								title={"LOCATIONS"}
								options={jobLocationOptions}
							/>
						</Flex>
					</Flex>
					<Flex flexDirection="column" alignItems="center">
						<Text bold {...smallTitleParams}>
							{" "}
                Featured Positions{" "}
						</Text>
						<GridBox>
							{jobs.map((job, i) => {
								if (
									(filterLocation && filterLocation.value !== job.locationValue) ||
                    (filterCategory && filterCategory.value !== job.positionValue)
								)
									return false;
								return (
									<JobsCard
										key={`#job${i}`}
										title={job.position}
										location={job.location}
										link={job.link}
									/>
								);
							})}
						</GridBox>
					</Flex>
					<WhyNoshery />
					<Perks />
					<WhoAreWe />
				</AnimatedBox>
			</Box>
		</Box>
	);
};

export default Careers;

const ImageText = () => {
	const { width } = useWindowSize();

	const { isDesktop } = useDeviceType();
	const imageTextParams = {
		marginTop: `-${(width * 3.3) / 100}px`,
	};

	const topTextParams = {
		fontSize: `${(width *  7) / 100}px`,
		width: `${(width * 40) / 100}px`,
	};

	const bottomTextParams = {
		fontSize: `${(width * 25) / 100}px`,
	};

	const topText = `${(width * 30) / 100}px`;
	const leftText = `${(width * 70) / 100}px`;

	return (
		<StyledBox
			{...imageTextParams}
			left={`${leftText} !important`}
			top={topText}
		>
			<Flex position="relative">
				<Box
					top={`${(width * 20) / 100}px`}
					right={`${(width *-15) / 100}px`}
					position="absolute"
				>
					<Text color="white" {...topTextParams} bold>
            JOIN THE
					</Text>
				</Box>
				{isDesktop ? (
					<Text color="white" {...bottomTextParams} bold>
            Team
					</Text>
				) : (
					<Box
						top={`${(width * (isDesktop ? 1.5 : 20)) / 100}px`}
						right={`${(width * (isDesktop ? 13 : -10)) / 100}px`}
						position="absolute"
					>
						<Text color="white" {...bottomTextParams} bold>
              Team
						</Text>
					</Box>
				)}
			</Flex>
		</StyledBox>
	);
};

const JobsCard = ({ title, location, link }) => {
	const { width } = useWindowSize();
	const cardParams = {
		backgroundColor: "#344734",
		width: `${(width * 36) / 100}px !important`,
		height: `${(width * 25) / 100}px !important`,
		borderRadius: `${(width * 1.4) / 100}px !important`,
		padding: `${(width * 2) / 100}px !important`,
	};
	const titleParams = {
		color: "#F1A638",
		wordWrap: "break-word",
		fontSize: `${(width * 3) / 100}px !important`,
	};
	const buttonParams = {
		borderRadius: `${(width * 1.3) / 100}px !important`,
		width: `${(width * 20) / 100}px !important`,
		height: `${(width * 6.8) / 100}px !important`,
	};
	const buttonTextParams = {
		fontWeight: "bold",
		fontSize: `${(width * 2) / 100}px !important`,
	};
	const locationParams = {
		color: "white",
		fontSize: `${(width * 2.5) / 100}px !important`,
	};
	return (
		<Box padding={`${(width * 2) / 100}px !important`}>
			<Flex position="relative" flexDirection="column" {...cardParams}>
				<Text bold {...titleParams}>
					{title}
				</Text>
				<Text {...locationParams}>{location}</Text>
				<Flex
					bottom={`${(width * 2) / 100}px`}
					right={`${(width * 2) / 100}px`}
					position="absolute"
				>
					<Button onClick={() => window.open(link)} {...buttonParams}>
						<Box {...buttonTextParams}> APPLY NOW </Box> 
					</Button>
				</Flex>
			</Flex>
		</Box>
	);
};

const Dropdown = ({ title, options, filter, setFilter }) => {
	const { width } = useWindowSize();

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			color: "black",
			fontFamily: "Uni Neue",
			textAlign: "left",
			paddingLeft: "5px",
			fontWeight: "bold",
			fontSize: `${(width * 4) / 100}px !important`,
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "white",
			fontFamily: "Uni Neue",
			textAlign: "left",
			paddingLeft: "5px",
			fontWeight: "bold",
			fontSize: `${(width * 4) / 100}px !important`,
		}),
		control: () => ({
			backgroundColor: "#F1A638",
			borderRadius: "10px",
			display: "flex",
			padding: `${(width * 0.1) / 100}px !important`,
			height: `${(width * 8) / 100}px !important`,
			width: `${(width * 30) / 100}px !important`,
			alignItems: "center",
		}),
		dropdownIndicator: () => ({
			color: "white",
		}),
		clearIndicator: () => ({
			paddingRight: "10px",
			transform: `${"scale(1)"}`,
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "white",
				fontFamily: "Uni Neue",
				textAlign: "left",
				paddingRight: "8px",
				fontWeight: "bold",
				fontSize: `${(width *4) / 100}px !important`,
			};
		},
	};
	return (
		<Box>
			<Select
				isClearable
				defaultValue={filter}
				options={options}
				onChange={setFilter}
				styles={customStyles}
				placeholder={title}
			/>
		</Box>
	);
};
