import React from "react";
import { Flex, Box, Image, Text } from "noshery-ui";

import { whynoshery1, whynoshery2 } from "../../constants";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";

const StyledImage = styled(Image)`
  object-fit: cover;
  height: 100%;
`;

const WhyNoshery = () => {
	const { width } = useWindowSize();

	const titleParams = {
		fontSize: `${(width * 7.7) / 100}px`,
		lineHeight: `${(width * 10) / 100}px`,
		color: "#ffffff",
		width: "100%"
	};
	const backgroundProps = {
		backgroundColor: "#F1A638",
		height: `${(width *  96) / 100}px`
	};

	const descriptionParams = {
		fontSize: `${(width * 3.3) / 100}px`,
		lineHeight: `${(width * 4.5) / 100}px`,
		color: "#ffffff",
	};

	const imageW = parseInt((width * 45) / 100);
	const imageH = parseInt((width *  96) / 100);

	const imageProps = {
		height: `${imageH}px !important`,
		width: `${imageW}px !important`,
		maxWidth: `${imageW}px !important`,
		objectFit: "cover",
	};

	const contentContainer = {
		paddingLeft: `${(width * 1) / 100}px`,
		zIndex: 10,
		width: `${(width * 55) / 100}px !important`,
	};

	const contentParams = {
		paddingLeft: `${(width * 2) / 100}px`,
		zIndex: 10,
		width: `${(width * 56) / 100}px !important`,
	};

	const contentW = 47;

	const contentProps = { 
		width: `${(width * contentW) / 100}px !important`,
	};

	const titleProps = {
		width: `${(width * 52) / 100}px !important`,
		paddingBottom: `${(width * 3) / 100}px`,
	};

	return (
		<Flex
			position={"static"}
			justifyContent={"space-between"}
			alignItems={"center"}
			overflow="hidden"
			style={backgroundProps}
		>
			<>
				<Flex flexDirection="column" {...contentContainer}>
					<Box {...contentParams} width={`${(width * 430) / 100}px !important`}> 
						<Box {...titleProps}>
							<Text {...titleParams} bold>
                    Why Noshery?
							</Text>
						</Box>
						<Box {...contentProps} style={{paddingBottom: `${(width * 3) / 100}px`, }}>
							<Text {...descriptionParams}>{whynoshery1}</Text>
						</Box>
                
						<Box {...contentProps} style={{paddingBottom: `${(width * 1) / 100}px`, }} >
							<Text {...descriptionParams}>{whynoshery2}</Text>
						</Box>    
					</Box>
				</Flex>
				<StyledImage
					{...imageProps}
					src={`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${imageW},h_${imageH},c_fill,g_auto,f_auto/careers/careersWhatWeDo_s1cs1c.webp`}
				/>
			</>
		</Flex>
	);
};

export default WhyNoshery;
