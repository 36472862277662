import React from "react";
import styled from "styled-components";
import { Box, Flex, Text, Image, Button } from "noshery-ui";
import Nav from "./Nav"; 
import useWindowSize from "../../hooks/useWindowSize";
import { ORDER_URL, SAN_MATEO_ORDER_URL, ALT_IMGS } from "../../constants";
 
const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
`;

const MobileHeader = ({
	sidebarOpen,
	setSidebarOpen,
	onScroll,
	headerOpen,
	isDraper,
}) => { 
	const { width } = useWindowSize();
	const boxShadow1 = `${(width * 0.24) / 100}px`;
	const boxShadow2 = `${(width * 1.2) / 100}px`;

	return (
		<Box position="relative" overflowX="hidden">
			<Nav
				sidebarOpen={sidebarOpen}
				setSidebarOpen={setSidebarOpen}
				scrolling={onScroll}
				headerOpen={headerOpen}
				isDraper={isDraper}
			/>
			<Flex flexDirection="column" justifyContent="center"> 
				<Content isDraper={isDraper} />
				<button
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						if(window !== undefined) window.open("mailto:contact@ordernoshery.com");
					  }} 
					style={{
						width: "50%",
						alignSelf: "center",
						height: "50px",
						backgroundColor: "white", 
						borderRadius: "15px",
						border: "none",
						boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
						marginBottom: "10px",
						fontSize: "26px",
						fontWeight: "bold",
					}}> 
					Contact Us
				</button>
			</Flex>
		</Box>
	);
};

export default MobileHeader;

const Content = ({ title, description, isDraper }) => {
	const { width } = useWindowSize();
	const styledHeadingParams = {
		fontSize: `${(width * 6) / 100}px !important`,
		lineHeight: `${(width * 8) / 100}px !important`,
		marginTop: "0px",
		marginBottom: "0px", 
		textAlign: "start"
	};
	const styledTextParams = {
		fontSize: `${(width * 4.4) / 100}px`,
		lineHeight: `${(width * 6) / 100}px`,
		textAlign: "start"
	}; 
	const wrapperParams = {
		marginLeft: `${(width * 3.1) / 100}px`,
		marginTop: `${(width * 40.7) / 100}px`,
		flexDirection: "column",
		alignItems: "center",
	};

	return (
		<Flex {...wrapperParams}>
			<Flex flexDirection="column"  > 
				<Text as="h1" bold {...styledHeadingParams}>
				We’re excited to introduce Swing Shift, a new operation from an old friend, who has taken over our San Mateo location.  
				</Text>
				<Text as="h1" {...styledTextParams}>
				Swing Shift features a diverse menu of urban comfort food (think all of your favorites, in one place).
				</Text>
				<Text as="h1" {...styledTextParams}>
				Learn more here: <a href="https://myswingshift.com/" target="_blank" rel="noreferrer"> https://myswingshift.com/ </a>
				</Text> 
				<Text as="h1" bold {...styledHeadingParams} style={{
					marginTop: "30px"
				}} >
					The Noshery Team 
				</Text>
			</Flex>
			<Box paddingBottom="5%">
				<Text fontWeight={500} {...styledTextParams}>
					{description}
				</Text>
			</Box>
		</Flex>
	);
};

Content.defaultProps = {
	title: "Eight Restaurants. One Order.",
	description: "",
};
