import React from "react";
import styled from "styled-components";
import { Box, Flex, Text, Image, Button } from "noshery-ui";
import Nav from "./Nav";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";
import { ORDER_URL, SAN_MATEO_ORDER_URL, ALT_IMGS } from "../../constants";
import { HolidayHours } from "./Banners";

const BackgroundImage = styled(Image)`
  min-height: ${(p) => p.size};
  min-width: ${(p) => p.size};
  z-index: -1;
  border-radius: 50%;
  right: ${(p) => p.right};
  top: ${(p) => p.top};
  box-shadow: ${(p) => p.shadow};
  position: absolute;
  object-fit: cover;
`;



const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
`;

const MobileHeader = ({
	sidebarOpen,
	setSidebarOpen,
	onScroll,
	headerOpen,
	isDraper,
}) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();

	// desktop image 60%, tablet & mobile 88%
	const imageSize = parseInt((15 * width) / 10);
	// desktop top is 24.5% of image width, tablet & mobile 15%
	const imageTop = parseInt((45 * width) / 100);
	// desktop right is 18.5% of image width, tablet & mobile 12%
	const imageRight = parseInt((25 * width) / 100);

	return (
		<Box position="relative" overflowX="hidden">
			<Nav
				sidebarOpen={sidebarOpen}
				setSidebarOpen={setSidebarOpen}
				scrolling={onScroll}
				headerOpen={headerOpen}
				isDraper={isDraper}
			/>
			<Flex flexDirection="column" justifyContent="center">
				<Box
					justifyContent="flex-end"
					borderRadius="50%"
					position="relative"
					height={`${1.5 * imageTop}px`}
					marginBottom={"6%"}
				>
					<BackgroundImage
						alt={ALT_IMGS["landingHeader"].altImg}
						src={`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${parseInt(
							imageSize ? imageSize : 1
						)},h_${parseInt(
							imageSize ? imageSize : 1
						)},c_fill,g_auto,f_auto/Landing/LP_H_yzzghe.webp`}
						size={`${imageSize}px`}
						top={`-${imageTop}px`}
						right={`-${imageRight}px`}
						shadow={
							// REVISIT HERE
							isDesktop
								? "4px 4px 20px 10px rgba(0, 0, 0, 0.25)"
								: "1px 1px 4px 2px rgba(0, 0, 0, 0.25)"
						}
					/>
				</Box>
				<Content isDraper={isDraper} />
			</Flex>
		</Box>
	);
};

export default MobileHeader;

const Content = ({ title, description, isDraper }) => {
	const { width } = useWindowSize();


	const styledHeadingParams = {
		fontSize: `${(width * 9) / 100}px !important`,
		lineHeight: `${(width * 13) / 100}px !important`,
		marginTop: "0px",
		marginBottom: "0px",
	};
	const styledTextParams = {
		fontSize: `${(width * 6) / 100}px`,
		lineHeight: `${(width * 6) / 100}px`,
		textAlign: "center",
	};

	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1.3) / 100}px`;

	const buttonWidthPercentage = 90;
	const buttonHeightPercentage = 14;

	const minButtonWidth = (width * buttonWidthPercentage) / 100;
	const minButtonHeigth = (width * buttonHeightPercentage) / 100;

	const buttonParams = {
		fontSize: `${(width * 6) / 100}px`,
		lineHeight: `${(width * 9) / 100}px`,
		textAlign: "center",
		color: "white !important",
		height: `${minButtonHeigth}px !important`,
		width: `${minButtonWidth}px !important`,
		borderRadius: `${(width * 4) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const wrapperParams = {
		marginLeft: `${(width * 3.1) / 100}px`,
		marginTop: `${(width * 40.7) / 100}px`,
		flexDirection: "column",
		alignItems: "center",
	};

	const buttonWrapperParams = {
		marginTop: `${(width * 1.7) / 100}px`,
		marginBottom: `${(width * 1.7) / 100}px`,
	};

	return (
		<Flex {...wrapperParams}>
			<Flex flexDirection="column" alignItems="center">
				<HolidayHours /> 
				<Text as="h1" bold {...styledHeadingParams}>
					{" We're Sorry"} 
				</Text>
				<Text as="h1" bold {...styledHeadingParams}>
  
				</Text>
			</Flex>
			<Box paddingBottom="5%">
				<Text fontWeight={500} {...styledTextParams}>
				This promotion is currently suspended due to technical difficulties
				</Text>
			</Box>

			<Flex
				flexDirection="column"
				justifyContent={"flex-start"}
				alignItems={"center"}
				{...buttonWrapperParams}
			>
				<Box pb={`${(width * 2.6) / 100}px`}>
					<StyledButton
						onClick={() => window.open("https://www.instagram.com/ordernoshery/")}
						isDesktop={false}
						{...buttonParams}
					>
					Checkout out our Instagram
					</StyledButton>
				</Box>
				<Box pb={`${(width * 2.6) / 100}px`}>
					<StyledButton
						onClick={() => window.open("https://www.facebook.com/ordernoshery")}
						isDesktop={false}
						{...buttonParams}
					>
					Checkout out our Facebook
					</StyledButton>
				</Box>
				<Box pb={`${(width * 2.6) / 100}px`}>
					<StyledButton
						onClick={() => window.open("https://www.tiktok.com/@ordernoshery")}
						isDesktop={false}
						{...buttonParams}
					>
					Checkout out our TikTok
					</StyledButton>
				</Box>

			</Flex>
		</Flex>
	);
};

Content.defaultProps = {
	title: "Eight Restaurants. One Order.",
	description: "Now in San Mateo & San Carlos",
};
