import React from "react";
import {  Box } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import { ALT_IMGS } from "../../constants";

const HamBurger = styled.img`
  width: ${(p) => p.width};
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  filter: brightness(0) invert(.9);
  z-index: 50;
`;

const Burger = ({sidebarOpen, setSidebarOpen, variant}) => {
	//Give hamburger a z-index of 2 so that it can pickup
	const { width } = useWindowSize();
	// logo width = 15 % of total width
	const ratio = 9;
	const logoWidth = (width * ratio) / 100;
	const logoHeight = (width * 7) /100;

	// 9.8 and 20.5 of the logo width
	const logoWrapperParams = {
		top: `${(width * 14) / 100}px`,
		left: `${(width * 6) / 100}px`,
	};


	return (
		<Box onClick={() => setSidebarOpen(!sidebarOpen)} {...logoWrapperParams} position="absolute" zIndex={50}>
			<HamBurger
				alt={ALT_IMGS["logo"].altImg}
				src={"/HamburgerButton-01.png"}
				width={`${logoWidth}px`}
				height={`${logoHeight}px`}
			/>
		</Box>
	);
};

export default Burger;
