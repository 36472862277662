import React from "react";
import { Flex, Box, Text, Image } from "noshery-ui";
import styled from "styled-components";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

const FourRows = styled(Box)`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
`;

const ThreeRows = styled(Box)`
  display: grid;
  grid-template-columns: 33% 33% 33%;
`;

const rewards = [
	"1 point earned for every $1 spent",
	"Every 100 points will convert to $10 off!",
	"Use your rewards during checkout for great deals!",
];


const Perks = () => {

	const image = [
		"https://res.cloudinary.com/dwbvlpae2/image/upload/v1661535372/Rewards/step1_ggx8ts.webp",
		"https://res.cloudinary.com/dwbvlpae2/image/upload/v1661535372/Rewards/step2_ivojy1.webp",
		"https://res.cloudinary.com/dwbvlpae2/image/upload/v1661535372/Rewards/step3_elbu9h.webp"
	];

	const backgroundProps = {
		backgroundColor: "#ffffff",
	};

	return (
		<Flex justifyContent={"center"} style={backgroundProps}>
			<>
				<Flex
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<ThreeRows>
						{rewards.map((perk, i) => {
							return <Card key={`#perk${i}`} img={image[i]} text={perk} />;
						})}
					</ThreeRows>
				</Flex>
			</>
		</Flex>
	);
};

const Card = ({ text, img }) => {
	const { width } = useWindowSize();
	const wrapperParams = {
		width: `${(width * 26) / 100}px`,
		height: `${(width * 6) / 100}px`,
		alignItems: "center",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",

	};

	const textParams = {
		color: "#344734",
		fontSize: `${(width * 2.4) / 100}px`,
	};
  
	const imgParams = {
		width: `${(width * 24.14) / 100}px`,
		height: `${(width * 24.14) / 100}px`,
	};

	return (
		<Flex flexDirection="column" justifyContent="center" padding={`${(width * 1.5) / 100}px`}>
			<Image {...imgParams} src={img} /> 
			<Box {...wrapperParams}>
				<Text bold {...textParams}>
					{text}
				</Text>
			</Box>
		</Flex>
	);
};

export default Perks;
