import React from "react";
import { Link } from "react-router-dom";
import {  Box } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import { ALT_IMGS } from "../../constants";
import { animated, useSpring } from "react-spring";

const NosheryLogo = styled.img`
    width: ${(p) => p.width};
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`;


const MobileLogo = ({variant}) => {
	const { width } = useWindowSize();
	const ratio = 48;
	const logoWidth = (width * ratio) / 100;

	const logoWrapperParams = {
		top: `${(logoWidth * 9.8) / 100}px`,
		left: `${(logoWidth * 50) / 100}px`,
	};

	const AnimatedLogo = animated(NosheryLogo); 

	const {filter} = useSpring({
		config: {duration: 100},
		from: {
			filter:"brightness(0) invert(1)"
		},
		filter: variant === 1 ? "brightness(1) invert(0)" : "brightness(0) invert(1)"
	});

	return (
		<Box {...logoWrapperParams} position="absolute" zIndex={2}>
			<Link to="/">
				<AnimatedLogo
					style={{   
					}}
					alt={ALT_IMGS["logo"].altImg}
					src={"/logowithtext.png"}
					width={`${logoWidth}px`}
					height={`${logoWidth / 2}px`}
				/>
			</Link>
		</Box>
	);
};

export default MobileLogo;
