import * as ReactGA from "react-ga";
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^(\+0?1\s)?\(?\d{3}\)\d{3}[\s.-]\d{4}$/;

export const validateEmail = (email) => {
	return EMAIL_REGEX.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
	return PHONE_REGEX.test(String(phone));
};

export const initGA = (id) => {
	if (process.env.NODE_ENV === "production") {
		ReactGA.initialize(id);
	}
};
