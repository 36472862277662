import React from "react";
import { Box, Flex, Text, Image } from "noshery-ui";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

import { ORDER_URL } from "../../constants";

const externalLinks = [
	{
		href: "https://www.instagram.com/ordernoshery/?hl=en",
		src: "instagram.png",
	},
	{ href: "https://www.facebook.com/ordernoshery", src: "facebook.png" },
	{ href: "https://www.tiktok.com/@ordernoshery", src: "tiktok.png" },
];

const StyledLink = styled(Link)``;

const links = [
	{
		title: "Home",
		to: "/",
	},
	{
		title: "Contact",
		to: "/contact-us",
	},
	{
		title: "Order",
		to: "/order",
		href: ORDER_URL,
	},
	{
		title: "Catering",
		to: "/catering",
	},
	{
		title: "Careers",
		to: "/careers",
	},

];


const links2 = [
	{
		title: "Blog",
		to: "/blog",
	},
	{
		title: "Press",
		to: "/press-release",
	},
];


const addressLines = [
	"Noshery",
	"5 South Ellsworth Avenue",
	"San Mateo, CA 94401",
];

const Footer = () => {
	const { width } = useWindowSize();
	const { isDesktop, isMobile } = useDeviceType();
	const [hover, setHover] = React.useState(false);
	const boxParams = {
		height: `${(width * 18) / 100}px !important`,
		padding: `${(width * 2.6) / 100}px ${(width * 1.9) / 100}px`,
	};

	const imageParams = {
		width: `${(width * 10.9) / 100}px`,
		height: `${(width * 5.6) / 100}px`,
	};

	const marginTop = isDesktop ? (width * 2.2) / 100 : (width * 1) / 100;

	const extLinkWrapper = {
		marginTop: `${marginTop}px`,
	};

	const byteKitchenTextParams = {
		fontSize: `${(width * 1.6) / 100}px`,
		lineHeight: `${(width * 2.2) / 100}px`,
		fontStyle: "normal"
	};

	const linkWrapperParams = {
		marginBottom: `${(width * 0.4) / 100}px`,
	};

	const googleMaps = "https://goo.gl/maps/npjgFuZtNnWM4TMN9";

	return (
		<Box {...boxParams} backgroundColor="#344734">
			<Flex>
				<Flex
					flexDirection="column"
					id="column1"
					width={`${(width * 20) / 100}px`}
				>
					<Box>
						<Image
							src="/logowithtext.png"
							alt="noshery logo with text"
							{...imageParams}
						/>
					</Box>
					<Flex flexDirection="column" {...extLinkWrapper}>
						<Flex alignItems="center">
							{externalLinks.map(({ src, href }) => (
								<a
									key={href}
									target="blank"
									href={href}
									style={{
										textDecoration: "none",
										marginRight: `${(width * 0.48) / 100}px`,
										width: `${(width * 2) / 100}px`,
										height: `${(width * 3) / 100}px`,
										paddingBottom: `${isMobile ? (width * 2) / 100 : 0}px`,
									}}
								>
									<Image src={`/${src}`} />
								</a>
							))}
						</Flex>

						<Box>
							<Text bold color="white" {...byteKitchenTextParams}>
                by Byte Kitchen
							</Text>
						</Box>
					</Flex>
				</Flex>
				<Flex justifyContent="space-between" width="100%">
					
					<Flex width="100%">
						<Flex mr="2%" flexDirection="column">
							{links.map((l, i) => {
								return (
									<FooterLinks
										key={`#footerLinks${i}`}
										l={l}
										linkWrapperParams={linkWrapperParams}
										byteKitchenTextParams={byteKitchenTextParams}
									/>
								);
							})}
						</Flex>
						<Flex flexDirection="column">
							{links2.map((l, i) => {
								return (
									<FooterLinks
										key={`#footerLinks${i}`}
										l={l}
										linkWrapperParams={linkWrapperParams}
										byteKitchenTextParams={byteKitchenTextParams}
									/>
								);
							})}
						</Flex>
					</Flex>
					<Flex as="address" flexDirection="column">
						<a
							href={googleMaps}
							rel="noopener noreferrer"
							target="_blank"
							style={{
								textDecoration: hover ? "underline" : "none",
								color: "white",
							}}
							onMouseEnter={() => setHover(true)}
							onMouseLeave={() => setHover(false)}
						>
							{addressLines.map((a) => {
								return (
									<Box key={a} {...linkWrapperParams}>
										<Text color="white" {...byteKitchenTextParams}>
											{a}
										</Text>
									</Box>
								);
							})}
						</a>
						<Box {...linkWrapperParams}>
							<Text color="white" {...byteKitchenTextParams}>
                (650) 393-5547
							</Text>
						</Box>
						<Box {...linkWrapperParams}>
							<Text color="white" {...byteKitchenTextParams}>
                contact@ordernoshery.com
							</Text>
						</Box>
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
};

const FooterLinks = ({ l, linkWrapperParams, byteKitchenTextParams }) => {
	const [hover, setHover] = React.useState(false);
	return (
		<Box key={l.title} {...linkWrapperParams}>
			{l.href ? (
				<Text
					color="white"
					{...byteKitchenTextParams}
					onClick={() => window.open(l.href)}
					style={{
						cursor: "pointer",
						textDecoration: hover ? "underline" : "none",
					}}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					{l.title}
				</Text>
			) : (
				<StyledLink
					to={l.to}
					style={{
						textDecoration: "none",
					}}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					<Text
						color="white"
						{...byteKitchenTextParams}
						style={{
							textDecoration: hover ? "underline" : "none",
						}}
					>
						{l.title}
					</Text>
				</StyledLink>
			)}
		</Box>
	);
};

export default React.memo(Footer);
