import React from "react";
import styled from "styled-components";
import { Box, Flex, Text, Image, Button, Link } from "noshery-ui";
import Logo from "./Logo";
import DraperLogo from "./DraperLogo";
import DraperLogo2 from "./DraperLogo2";
import NavButtons from "./NavButtons";
import DraperNav from "./DraperNav";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

import { ALT_IMGS } from "../../constants";

const BackgroundImage = styled(Image)`
  min-height: ${(p) => p.size};
  min-width: ${(p) => p.size};
  border-radius: 50%;
  right: ${(p) => p.right};
  top: ${(p) => p.top};
  box-shadow: ${(p) => p.shadow};
  position: absolute;
  object-fit: cover;
`;

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
`;


const Header = ({isDraper}) => {
	const { width } = useWindowSize();
	const { isDesktop, isTablet } = useDeviceType();
  
	// desktop image 60%, tablet & mobile 88%
	const imageSize = ((isDesktop ? 6 : 7.3) * width) / 10;
	// desktop top is 24.5% of image width, tablet & mobile 15%
	const imageTop = ((isDesktop ? 24.5 : 24) * imageSize) / 100;
	// desktop right is 18.5% of image width, tablet & mobile 12%
	const imageRight = ((isDesktop ? 18.5 : 25) * imageSize) / 100;



	return (
		<Box position="relative" overflowX="hidden">
			<Logo />
			{isDraper && isDesktop && <DraperLogo />}
			{isDraper && !isDesktop && <DraperLogo2 />}
			{isDraper ? <DraperNav /> : <NavButtons />}
			<Flex justifyContent={"space-between"} position="relative">
				{isDesktop && <Content isDraper={isDraper} />}
				{!isDesktop && <Content isDraper={isDraper} />}
				<Box
					justifyContent="flex-end"
					borderRadius="50%"
					position="relative"
					height={`${imageSize - imageTop}px`}
					marginBottom={"2%"}
				>
					<BackgroundImage
						alt={ALT_IMGS["landingHeader"].altImg}
						src={`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${parseInt(
							imageSize ? imageSize : 1
						)},h_${parseInt(
							imageSize ? imageSize : 1
						)},c_fill,g_auto,f_auto/Landing/LP_H_yzzghe.webp`}
						size={`${imageSize}px`}
						top={`-${imageTop}px`}
						right={`-${imageRight}px`}
						shadow={
							// REVISIT HERE
							isDesktop
								? "4px 4px 20px 10px rgba(0, 0, 0, 0.25)"
								: "1px 1px 4px 2px rgba(0, 0, 0, 0.25)"
						}
					/>
				</Box>
			</Flex>
		</Box>
	);
};

export default Header;

const Content = ({ title, description, isDraper }) => {
	const { width } = useWindowSize();
	const { isDesktop, isTablet } = useDeviceType();

	const styledHeadingParams =
    isDesktop || isTablet
    	? {
    		fontSize: `${(width * 3.7) / 100}px`,
    		lineHeight: `${(width * 3.75) / 100}px`,
    		marginTop: "0px",
    		marginBottom: "0px",
    	}
    	: {
    		fontSize: `${(width * 5.8) / 100}px`,
    		lineHeight: `${(width * 8) / 100}px`,
    		marginTop: "0px",
    		marginBottom: "0px",
    	};
	const styledTextParams =
    isDesktop || isTablet
    	? {
    		fontSize: `${(width * 2.18) / 100}px !important`,
    		lineHeight: `${(width * 3) / 100}px !important`,
    	}
    	: {
    		fontSize: `${(width * 3.5) / 100}px !important`,
    		lineHeight: `${(width * 3.5) / 100}px !important`,
    	};

	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1.3) / 100}px`;

	const buttonWidthPercentage = isDesktop || isTablet ? 17.1 : 28;

	const minButtonWidth = (width * buttonWidthPercentage) / 100;

	const buttonRightMargin = (width * 1.9) / 100;

	const buttonParams = {
		...styledTextParams,
		textAlign: "center !important",
		color: "white !important",
		width: `${2 * minButtonWidth + buttonRightMargin}px !important`,
		borderRadius: `${(width * 0.72) / 100}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25) !important`,
	};

	const wrapperParams = isDesktop
		? {
			marginLeft: `${(width * 3.1) / 100}px`,
			marginTop: `${(width * 15.7) / 100}px`,
		}
		: {
			marginLeft: `${(width * 3.1) / 100}px`,
			marginTop: `${(width * 40.7) / 100}px`,
		};

	const buttonWrapperParams = isDesktop
		? {
			marginTop: `${(width * 1.7) / 100}px`,
			marginBottom: `${(width * 1.7) / 100}px`,
		}
		: {
			marginTop: `${(width * 1.7) / 100}px`,
			marginBottom: `${(width * 1.7) / 100}px`,
		};

	return (
		<Box {...wrapperParams}>
			<Flex width={`${2 * minButtonWidth + buttonRightMargin}px !important`} flexDirection="column">
				<Text as="h1" bold {...styledHeadingParams}>
					{" We're Sorry"} 
				</Text>
				<Text as="h1" bold {...styledHeadingParams}>
				</Text>
			</Flex>
			<Box width={`${2 * minButtonWidth + buttonRightMargin}px !important`} >
				<Text fontWeight={500} {...styledTextParams}>
				This promotion is currently suspended due to technical difficulties
				</Text>
			</Box>

			<Flex
				flexDirection="column"
				justifyContent={"flex-start"}
				alignItems={isDesktop ? "center" : ""}
				{...buttonWrapperParams}
			>
				<Box pb={`${(width * 1.5) / 100}px`}>
					<StyledButton
						onClick={() => window.open("https://www.instagram.com/ordernoshery/")}
						isDesktop={isDesktop}
						{...buttonParams}
					>
            Checkout out our Instagram
					</StyledButton>
				</Box>
				<Box pb={`${(width * 1.5) / 100}px`}>
					<StyledButton
						onClick={() => window.open("https://www.facebook.com/ordernoshery")}
						isDesktop={isDesktop}
						{...buttonParams}
					>
            Checkout out our Facebook
					</StyledButton>
				</Box>
				<Box pb={`${(width * 1.5) / 100}px`}>
					<StyledButton
						onClick={() => window.open("https://www.tiktok.com/@ordernoshery")}
						isDesktop={isDesktop}
						{...buttonParams}
					>
            Checkout out our TikTok
					</StyledButton>
				</Box>
			</Flex>
		</Box>
	);
};

Content.defaultProps = {
	title: "Eight Restaurants. One Order.",
	description: "Now in San Mateo & San Carlos",
};
