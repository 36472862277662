import React, {useState} from "react";
import { Box, Image, Flex, Text, Button } from "noshery-ui";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import ContactUsForm from "../ContactUsForm";
import useWindowSize from "../../../hooks/useWindowSize";
import MobileSidebar from "../Sidebar";
import Nav from "../Nav";
import { useSwipeable } from "react-swipeable";

const AnimatedBox = animated(Box); 

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const BackgroundImage = styled(Image)`
  min-height: ${(p) => p.size};
  min-width: ${(p) => p.size};
  z-index: -1; 
  border-radius: 50%;
  right: ${(p) => p.right};
  top: ${(p) => p.top};
  box-shadow: ${(p) => p.shadow};
  position: absolute;
  object-fit: cove;
`;

const BackgroundCover = styled(Box)`
    min-height: ${(p) => p.size};
    min-width: ${(p) => p.size};
    border-radius: 50%;
    right: ${(p) => p.right};
    top: ${(p) => p.top};
    box-shadow: ${(p) => p.shadow};
    position: absolute;
    object-fit: cover;
    background-color: rgba(241, 166, 56, 0.4);
`;

const StyledButton = styled(Button)`
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: ${(p) => `${(p.w * 1)/100}px`};
  width: ${(p) => `${(p.w * 25)/100}px !important`};
  height: ${(p) => `${(p.w * 15)/100}px !important`};
  font-size: ${(p) => `${(p.w * 4)/100}px !important`};
  text-align: 'center';
  font-weight: bold;
`;

const ContactUs = () => {
	const { width } = useWindowSize();
  
	const imageSize = parseInt((13 * width) / 10);
	const imageTop = parseInt((45 * width) / 100);
	const imageRight = parseInt((15 * width)  / 100);

	const [sidebarOpen, setSidebarOpen] = useState(false); 
	const [headerOpen, setHeaderOpen] = useState(true); 
	const [offset, setOffset] = useState(0);

	const { left } = useSpring({
		config: { duration: 200 },
		from: { left: "0%"},
		left: sidebarOpen ? "60%" : "0%",
	});

	React.useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

 
	return (
		<Box  overflow="hidden" position="relative">
			<MobileSidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
			<Box overflow="hidden">
				<AnimatedBox style={{ left: left, position: "relative"}}> 
					<Nav 
						sidebarOpen={sidebarOpen} 
						setSidebarOpen={setSidebarOpen} 
						scrolling={offset}
						headerOpen={headerOpen}
					/> 
					<ImageText /> 
					<Flex flexDirection="column" justifyContent='center'>
						<Box 
							justifyContent="flex-end"
							borderRadius="50%"
							position="relative"
							height={`${1.5 * imageTop}px`}
							marginBottom={"1%"}> 
						</Box>
					</Flex> 
					<ContactUsForm />
				</AnimatedBox>
			</Box>
		</Box>
	);
};

export default ContactUs;

const ImageText = () => {
	const { width } = useWindowSize();

	const imageTextParams = {
		marginTop: `${(width * 48) / 100}px`,
		width: `${width}px`
	};

	const titleParams = {
		fontSize: `${(width * 17) / 100}px`,
		lineHeight: `${(width * 24) / 100}px`,
		width: "100%",
		marginTop: "0px",
		marginBottom: "0px",
	};

	return (
		<StyledBox {...imageTextParams}>
			<Text as="h1" color="black" {...titleParams} bold>
          Contact Us
			</Text>
		</StyledBox>
	);
};
