import React from "react";
import { Box, Flex, Button, Text } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
  background-color: #344734;
`;

const BrandList = ({mobilepaddingTopBranList}) => {
	const { width } = useWindowSize();

	const containerParams = {
		padding: `${(width * mobilepaddingTopBranList) / 100}px 0px !important`,
		height: `${(width * 30) / 100}px !important`,
		alignItems: "center",
		flexDirection: "column",
		marginTop: "5%"
	};

	const textParams = {
		fontSize: `${(width * 8) / 100}px !important`,
	};

	const textParams2 = {
		fontSize: `${(width * 3.5) / 100}px !important`,
	};

	const buttonWidthPercentage = 70;
	const buttonHeightPercentage = 14;

	const minButtonWidth = (width * buttonWidthPercentage) / 100;
  
	const minButtonHeigth = (width * buttonHeightPercentage) / 100;

	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1.3) / 100}px`;

	const buttonParams = {
		fontSize: `${(width * 8) / 100}px`,
		lineHeight: `${(width * 9) / 100}px`,
		textAlign: "center",
		color: "white !important",
		height: `${minButtonHeigth}px !important`,
		width: `${minButtonWidth}px !important`,
		borderRadius: `${(width * 4) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};


	return (
		<Flex 
			{...containerParams}>
			<Box textAlign="center" paddingBottom="3%"> 
				<Text bold {...textParams}> Use Code </Text> 
				<Text bold {...textParams}> THANKSGIVING20 </Text> 
				<Text bold {...textParams2}> for 20% off ANY order of over $40 - this weekend only. Mix and match from any restaurant brand partner at Noshery, all in one order. Valid November 25-28, 2022. </Text>
				<Line />
			</Box>
			<StyledButton
				onClick={() => window.open("https://online.ordernoshery.com/")}
				isDesktop={false}
				{...buttonParams}
			>
            Order Now
			</StyledButton>
		</Flex>
	);
};

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 90) / 100}px`,
		borderBottom: `${`${(width * 0.5) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};

export default BrandList;
