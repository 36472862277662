import React from "react";
import { Box, Flex, Heading } from "noshery-ui";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";

const Title = styled(Heading)`
  text-align: center;
  font-weight: 800;
`;

const Socials = () => {
	const { width } = useWindowSize();

	let wrapperParams = { 
		paddingBottom: `${(width * 14.49) / 100}px`
	};

	const titleParams = {
		fontSize: `${(width * (9)) / 100}px`,
		lineHeight: `${(width * (9)) / 100}px`,
		paddingBottom: `${(width * 3.8) / 100}px`,
	};

	return (
		<Box {...wrapperParams}>
			<Title {...titleParams} bold>
        Check out our socials!
			</Title>
			<Flex
				alignItems="center"        
				justifyContent="center"
				flexDirection="column"
				position="relative"
			>
				<SocialCard />
			</Flex>
		</Box>
	);
};

export default Socials;

const SocialCard = () => {
	const { width } = useWindowSize();

	const boxParams = {
		borderRadius: `${(width * 8.2) / 100}px`,
		width: `${(width * 90.33) / 100}px`,
		height: `${(width * 119)/100}px`,
		display: "flex",
		justifyContent: "center"
	};


	return (
		<Box {...boxParams}>
			<Post1 width={width} /> 
		</Box>
	);
};


export const Post1 = ({width}) => (
	<iframe
		title="instagramPost1"
		className="instagram-media instagram-media-rendered"
		id="instagram-embed-0"
		src="https://www.instagram.com/p/ChFi3GaMvL-/embed/captioned/?cr=1&v=14&wp=792&rd=http%3A%2F%2Flocalhost%3A3000&rp=%2F#%7B%22ci%22%3A0%2C%22os%22%3A1003.5%2C%22ls%22%3A961.3000000119209%2C%22le%22%3A983.3000000119209%7D"
		allowFullScreen="true"
		frameBorder={0}
		height={`${(width * 119)/100}px`}
		data-instgrm-payload-id="instagram-media-payload-0"
		scrolling="no"
		style={{
			background: "white",
			maxWidth: 540,
			width: "calc(100% - 2px)",
			borderRadius: `${(width * 8.2) / 100}px`,
			border: "1px solid rgb(219, 219, 219)",
			boxShadow: "none",
			display: "block",
			margin: "0px 0px 12px",
			minWidth: 326,
			padding: 0
		}}
	/>
);

