import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Image } from "noshery-ui";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

const SingleBrand = ({ title, description, logo, bg, to, altImg, isLocations }) => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();
	const [onHoover, setOnHoover] = React.useState(false);

	const w = isDesktop ? 20.8 : 38.9;
	const h = isDesktop ? 25.2 : 47.1;

	const params = {
		width: `${(width * w) / 100}px`,
		height: `${(width * h) / 100}px`,
		borderRadius: `${(width * 3.1) / 100}px`,
		marginLeft: `${(width * (isDesktop ? 0 : 2.5)) / 100}px`,
		marginRight: `${(width * 2.5) / 100}px`,
		marginTop: `${(width * (isDesktop ? 0 : 2.5)) / 100}px`,
		marginBottom: `${(width * 2.5) / 100}px`,
	};

	const logoW = isDesktop ? 10.6 : 20.1;

	const logoWidth = parseInt((width * logoW) / 100);
	const logoPrams = {
		width: `${logoWidth}px !important`,
		maxWidth: `${logoWidth}px !important`,
		height: `${logoWidth}px !important`,
	};

	const bgW = isDesktop ? 18.3 : 34.5;
	const bgH = isDesktop ? 14 : 26.2;

	const bgWidth = parseInt((width * bgW) / 100);
	const bgHeight = parseInt((width * bgH) / 100);

	const bgParams = {
		width: `${bgWidth}px !important`,
		maxWidth: `${bgWidth}px !important`,
		height: `${bgHeight}px !important`,
		borderRadius: `${(width * 3.1) / 100}px !important`,
	};

	const mtW = isDesktop ? 13.2 : 24;

	return (
		<Flex
			{...params}
			alignItems="center"
			flexDirection="column"
			style={{
				backgroundColor: "#FADDB6",
				position: "relative",
				filter: "drop-shadow(0px 7.114px 7.114px rgba(0, 0, 0, 0.25))",
				transform: onHoover ? "scale(1.04)" : "scale(1)",
				transition: ".5s",
			}}
			onDoubleClick={() => {
				window.location.replace(to);
			}}
			onMouseEnter={() => {
				setOnHoover(true);
			}}
			onMouseLeave={() => {
				setOnHoover(false);
			}}
		>
			<Box mt={`${(width * 1.3) / 100}px`}>
				<Image
					alt={altImg}
					src={bg(bgWidth, bgHeight)}
					{...bgParams}
					style={{ objectFit: "cover" }}
				/>
			</Box>
			<Box position="absolute" top={`${(width * mtW) / 100}px`}>
				<Image
					alt={title}
					src={logo(logoWidth, logoWidth)}
					{...logoPrams}
					style={{ objectFit: "cover" }}
				/>
			</Box>
		</Flex>
	);
};

export default React.memo(SingleBrand);
