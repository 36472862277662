import React from "react";
import { Button, Flex, Box, Text, Image } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import { altLanders } from "../../constants/landers";

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
  background-color: #344734;
`;

const ORDER_SAN_CARLOS = "https://online.ordernoshery.com/noshery-san-carlos?menu=7e53e925-a316-47e3-a718-5907227147b1";
const ORDER_SAN_MATEO = "https://online.ordernoshery.com/noshery-san-mateo?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6";


const WhatWeDo = ({whatWeDo2, whatWeDoImg, title, to}) => {
	const { width } = useWindowSize();

	const wrapperParams = {
		marginTop: `${(width * 6.4) / 100}px`,
		height: `${(width * (whatWeDo2.text2 ? 290 : 260)) / 100}px`,
	};


	return (
		<Flex
			position={"relative"}
			alignItems={"center"}
			overflow="hidden"
			{...wrapperParams}
		>
			<MixandMatch {...whatWeDo2} to={to} whatWeDoImg={whatWeDoImg} title={title} /> 
		</Flex>
	);
};

const MixandMatch = ({mainTitle, text2, header, mainText, whatWeDoImg, title, to}) => {
	const { width } = useWindowSize();

	const backgroundProps = {
		backgroundSize: `${width * 1}px`,
		backgroundColor: "#F1A638",
		position: "absolute",
		flexDirection: "column",
		height: `${(width * (text2 ? 250 : 220)) / 100}px`,
		width: `${(width * 135) / 100}px`,
		display: "flex",
		alignItems: "center",
		right: `-${(width * 18) / 100}px`,
		top:  0,
	};

	const titleParams1 = {
		fontSize: `${(width * 8) / 100}px`,
		lineHeight: `${(width * 8) / 100}px`,
		color: "#344734",
		textAlign: "left",
		paddingTop: `${(width * 5) / 100}px`,
	};
	const titleParams2 = {
		fontSize: `${(width * 6) / 100}px`,
		lineHeight: `${(width * 5) / 100}px`,
		color: "#344734",
		textAlign: "left",
		filter: "brightness(100%) !important",
		paddingBottom: `${(width * 5) / 100}px`,
	};
	const descriptionParams = {
		fontSize: `${(width * 4) / 100}px`,
		lineHeight: `${(width * 6) / 100}px`,
		color: "#344734",
		textAlign: "left"
	};

	const textWrapper = {
		paddingX: `${(width * 27) / 100}px`,
		paddingBottom: `${(width * 10) / 100}px`,
	};

	const buttonWrapperParams = {
		marginTop: `${(width * 1.7) / 100}px`,
		marginBottom: `${(width * 1.7) / 100}px`,
	};

	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1.3) / 100}px`;

	const buttonWidthPercentage = text2 ? 80 : 70;
	const buttonHeightPercentage = text2 ? 20 : 14;

	const minButtonWidth = (width * buttonWidthPercentage) / 100;
	const minButtonHeigth = (width * buttonHeightPercentage) / 100;

	const buttonParams = {
		fontSize: `${(width * (text2 ? 6 : 8)) / 100}px`,
		lineHeight: `${(width * 9) / 100}px`,
		textAlign: "center",
		color: "white !important",
		height: `${minButtonHeigth}px !important`,
		width: `${minButtonWidth}px !important`,
		borderRadius: `${(width * 4) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const imageSrc = whatWeDoImg.imgSrc(
		parseInt((width * 120) / 100),
		parseInt((width * 120) / 100)
	);
      
	const imageParams = {
		position: "absolute", 
		bottom: `-${(width * 37) / 100}px !important`, 
		borderRadius: "50%",
		height: `${(width * 120) / 100}px !important`,
		width: `${(width * 120) / 100}px !important`,
		objectFit: "cover",
		zIndex: 2
	};

	return (
		<Box style={backgroundProps} > 
			<Box {...textWrapper}>
				<Box>
					<Text {...titleParams1} bold>
						{mainTitle}
					</Text>
					<Text {...titleParams2}>
						{header}
					</Text>
				</Box>
				<Box>
					<Text {...descriptionParams}>{mainText}</Text>       
					<Text {...descriptionParams}>{text2}</Text>
				</Box>
			</Box>
			<Flex
				flexDirection="column"
				justifyContent={"flex-start"}
				alignItems={"center"}
				{...buttonWrapperParams}
			>
				<Box pb={`${(width * 2.6) / 100}px`}>
					<StyledButton
						onClick={() => window.open(title === "Place Your Group Order Today!" ? "https://catering.ordernoshery.com/?location=san-mateo" : ORDER_SAN_MATEO)}
						isDesktop={false}
						{...buttonParams}
					>
                  Order San Mateo
					</StyledButton>
				</Box>
				<Box pb={`${(width * 2.6) / 100}px`}>
					<StyledButton
						onClick={() => window.open(title === "Place Your Group Order Today!" ? "https://catering.ordernoshery.com/?location=san-carlos" : ORDER_SAN_CARLOS)}
						isDesktop={false}
						{...buttonParams}
					>
                  Order San Carlos
					</StyledButton>
				</Box>
			</Flex>
			<Image {...imageParams} alt={altLanders["Orens"]["whatWeDo2"]} src={imageSrc} />
		</Box>
	);
};

export default WhatWeDo;
