import React from "react";
import styled from "styled-components";
import { Box, Flex, Text, Image, Heading, Button } from "noshery-ui";
import Logo from "./Logo";
import NavButtons from "./NavButtons";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";
import { altLanders } from "../../constants/landers";
import { HolidayHours } from "./Banners";

const ORDER_SAN_CARLOS = "https://online.ordernoshery.com/noshery-san-carlos?menu=7e53e925-a316-47e3-a718-5907227147b1";
const ORDER_SAN_MATEO = "https://online.ordernoshery.com/noshery-san-mateo?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6";

const BackgroundImage = styled(Image)`
  min-height: ${(p) => p.size};
  min-width: ${(p) => p.size};
  border-radius: 50%;
  right: ${(p) => p.right};
  top: ${(p) => p.top};
  box-shadow: ${(p) => p.shadow};
  position: absolute;
  object-fit: cover;
`;

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
`;

const StyledBox = styled(Box)`
  &:hover {
    color: red;
  }
`;

const Header = ({
	title,
	bgBrand,
	longDescription,
	menuId,
	name,
	shortDescription,
	brandAlts,
	subTitle,
	menuRef,
	isThanksGiving,
	to, 
	popup
}) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();

	// desktop image 60%, tablet & mobile 88%
	const imageSize = parseInt(((isDesktop ? 7 : 9.2) * width) / 10);
	// desktop top is 24.5% of image width, tablet & mobile 15%
	const imageTop = parseInt(((isDesktop ? 20 : 12) * imageSize) / 100);
	// desktop right is 18.5% of image width, tablet & mobile 12%
	const imageRight = parseInt(((isDesktop ? 28 : 28) * imageSize) / 100);


	return (
		<Box position="relative" overflowX="hidden">
			<Logo />
			<NavButtons />
			<Flex
				alignItems="center"
				position="relative"
				justifyContent={isDesktop ? "space-between" : "flex-end"}
			>
				{/* {popup && <HolidayHours />} */}
				<Box mb={`${(width * 5) / 100}px`}>
					{isDesktop && (
						<Content
							title={title}
							longDescription={longDescription}
							menuId={menuId}
							width={width}
							subTitle={subTitle}
							menuRef={menuRef}
							isThanksGiving={isThanksGiving}
							to={to}
						/>
					)}
				</Box>
				<Box
					justifyContent="flex-end"
					borderRadius="50%"
					position="relative"
					height={`${imageSize - imageTop}px`}
				>
					<BackgroundImage
						alt={altLanders[brandAlts]["header"]}
						src={bgBrand(imageSize, imageSize)}
						size={`${imageSize}px !important`}
						top={`-${imageTop}px !important`}
						right={`-${imageRight}px !important`}
						shadow={
							isDesktop
								? "4px 4px 20px 10px rgba(0, 0, 0, 0.25)"
								: "1px 1px 4px 2px rgba(0, 0, 0, 0.25)"
						}
					/>
				</Box>
			</Flex>
			{!isDesktop && (
				<Content
					title={title}
					longDescription={longDescription}
					shortDescription={shortDescription}
					width={width}
					subTitle={subTitle}
					menuRef={menuRef}
					isThanksGiving={isThanksGiving}
					to={to}
				/>
			)}
		</Box>
	);
};

export default Header;

const Content = ({ title, longDescription, menuId, shortDescription, subTitle, menuRef, isThanksGiving, to}) => {
	const { width } = useWindowSize();
	const { isDesktop, isTablet } = useDeviceType();

	const styledTextParams = isDesktop
		? {
			fontSize: `${(width * 1.6) / 100}px`,
		}
		: {
			fontSize: `${(width * 2.4) / 100}px`,
			lineHeight: `${(width * 3.3) / 100}px`,
			textAlign: "center",
		};

	const styledHeadingParams = isDesktop
		? {
			fontSize: `${(width * 3) / 100}px`,
			width: `${(width * 50) / 100}px`,
			marginTop: "0px",
			marginBottom: "0px",
			fontWeight: "boler",
			fontFamily: "Uni Neue Heavy !important",
		}
		: {
			fontSize: `${(width * 2.5) / 100}px`,
			lineHeight: `${(width * 6.5) / 100}px`,
			textAlign: "center",
			marginTop: "0px",
			marginBottom: "0px",
			fontWeight: "boler"
		};

	const styledHeadingParamsLocations = isDesktop
		? {
			fontSize: `${(width * 1.5) / 100}px`,
			width: `${(width * 50) / 100}px`,
			marginTop: "0px",
			marginBottom: "0px",
			fontWeight: "boler",
			fontFamily: "Uni Neue Heavy !important",
		}
		: {
			fontSize: `${(width * 2.5) / 100}px`,
			lineHeight: `${(width * 6.5) / 100}px`,
			textAlign: "center",
			marginTop: "0px",
			marginBottom: "0px",
			fontWeight: "boler"
		};

	const buttonTextParams = isDesktop
		? {
			fontSize: `${(width * (title === "Place Your Group Order Today!" ? 1.8 : 2.3)) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,
		}
		: {
			fontSize: `${(width * 4) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,
			textAlign: "center",
		};

	const boxShadow1 = `${(width * 0.1) / 100}px`;
	const boxShadow2 = `${(width * 1.0) / 100}px`;

	const buttonWidthPercentage = isDesktop || isTablet ? to === "locations" ? 30 : (title === "Place Your Group Order Today!" ? 25 : 20.5) : 28;
	const minButtonWidth = (width * buttonWidthPercentage) / 100;
	const buttonRightMargin = (width * 1.9) / 100;

	const buttonParams = {
		...buttonTextParams,
		minWidth: `${minButtonWidth + buttonRightMargin}px`,
		marginBottom: `${(width * 100) / 100}px`,
		borderRadius: `${(width * 0.72) / 100}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const wrapperWidth = isDesktop
		? `${(width * 29.5) / 100}px`
		: `${(width * 76) / 100}px`;

	const wrapperParams = isDesktop
		? {
			width: wrapperWidth,
			marginLeft: `${(width * 8.3) / 100}px`,
		}
		: {
			marginLeft: `${(width * 10.7) / 100}px`,
			marginRight: `${(width * 10.7) / 100}px`,
			textAlign: "center",
		};

	const wrapperParams2 = isDesktop
		? {
			width: `${(width * 34) / 100}px`,
			marginLeft: `${(width * 8.3) / 100}px`,
			marginTop: `${(width * 1) / 100}px`,
		}
		: {
			marginLeft: `${(width * 10.7) / 100}px`,
			marginRight: `${(width * 10.7) / 100}px`,
			textAlign: "center",
		};

	const wrapperParams3 = isDesktop
		? {
			width: `${(width * 34) / 100}px`,
			marginLeft: `${(width * 8.3) / 100}px`,
			marginTop: `${(width * 2) / 100}px`,
		}
		: {
			marginLeft: `${(width * 10.7) / 100}px`,
			marginRight: `${(width * 10.7) / 100}px`,
			textAlign: "center",
		};

	const buttonWrapperParams = isDesktop
		? {
			width: `${(width * 20.5) / 100}px`,
			marginTop: `${(width * 3) / 100}px`,
			marginLeft: `${(width * 8.3) / 100}px`,
		}
		: {
			marginTop: `${(width * 1.7) / 100}px`,
			marginBottom: `${(width * 1.7) / 100}px`,
			marginLeft: `${(width * 8.3) / 100}px`,
		};

	const styledHeadingParams2 = isDesktop
		? {
			fontSize: `${(width * 1.8) / 100}px`,
			width: `${(width * 25) / 100}px`,
			lineHeight: `${(width * 2.5) / 100}px`,
			marginTop: "0px",
			marginBottom: "0px",
		}
		: {
			fontSize: `${(width * 2) / 100}px`,
			lineHeight: `${(width * 6.5) / 100}px`,
			textAlign: "center",
			marginTop: "0px",
			marginBottom: "0px",
		};

	const styledHeadingParams3 = isDesktop
		? {
			fontSize: `${(width * 4) / 100}px`,
			width: `${(width * 37) / 100}px`,
			lineHeight: `${(width * 4) / 100}px`,
			marginTop: "0px",
			marginBottom: "0px",
			fontFamily: "Uni Neue Heavy !important",
		}
		: {
			fontSize: `${(width * 2) / 100}px`,
			lineHeight: `${(width * 6.5) / 100}px`,
			textAlign: "center",
			marginTop: "0px",
			marginBottom: "0px",
		};
	
	return (
		<Box paddingTop={`${(width * 10)/100}px`}>
			<Box>
				<Box {...wrapperParams}>
					<Heading as="h1" bold style={{...styledHeadingParams, 
						paddingBottom: `${(width * .1) / 100}px`,}}>
						{!isThanksGiving ? 
							title === "Place Your Group Order Today!" ?
								<Text as="h1" {...styledHeadingParams}> {title.split("Order")[0]} <br /> Order Today! </Text> : 
								(to === "locations" ? <Text as="h1" {...styledHeadingParamsLocations}> {title} </Text> : <Text as="h1" {...styledHeadingParams}> {title} </Text> )
							:
							<>
								<Text {...styledHeadingParams}> {title.split("Holiday")[0] } </Text>
								<Text as="h1" {...styledHeadingParams}> {"Holiday" + title.split("Holiday")[1]} </Text>
							</>
						}
					</Heading>
					<Heading as="h2"  bold {...styledHeadingParams2}>
						{
							to === "locations" ?
								<>
									<Text as="h1" {...styledHeadingParams3}> {subTitle.split("in")[0]} in </Text>
									<Text as="h1" {...styledHeadingParams3}>  {subTitle.split("in")[1]} </Text>
									{/* <Text as="h1" {...styledHeadingParams3}> {subTitle.split("is")[0]} </Text>
									<Text as="h1" {...styledHeadingParams3}> is {subTitle.split("is")[1]} </Text> */}
								</>
								: 
								<>
									<Text as="h1" {...styledHeadingParams2}> {subTitle.split("(")[0]} </Text>
									<Text as="h1" {...styledHeadingParams2}> {subTitle.includes("THANKSGIVING20") && "("}{subTitle.split("(")[1]} </Text>
								</>
						}
					</Heading>
				</Box>
				<Box {...wrapperParams2}>
					<Text fontWeight={500} {...styledTextParams}>
						{shortDescription || longDescription}
					</Text>
				</Box>
				{title !== "Place Your Group Order Today!" && to !== "locations" && <Box onClick={() => {
					if (isThanksGiving) return; 
					const menuref = menuRef.current; 
					menuref.scrollIntoView({
						behavior: "smooth",
						block: "start",
					});
				}} {...wrapperParams3}>
					<Text fontWeight={500} style={isThanksGiving ? {} : {cursor: "pointer"}} {...styledTextParams}>
						{isThanksGiving ? "Perfect for families or any group gathering this weekend!" : "See full menu"}
					</Text>
				</Box>}
			</Box>
			<Flex
				flexDirection={isDesktop ? "column" : "row"}
				justifyContent={isDesktop ? "flex-start" : "center"}
				{...buttonWrapperParams}
			>
				{
					to === "locations" ? <>
						<Box  mb={`${(width * 1.5) / 100}px`}>
							<StyledButton
								isDesktop={isDesktop}
								onClick={() => window.open("https://ordernoshery.us14.list-manage.com/subscribe?u=ad9b93383200c517614c36bea&id=838272c933")}
								{...buttonParams}
							>
								{"Subscribe for updates"} 
							</StyledButton>
						</Box>
					</> 
						: 
						<>
							<Box paddingRight={isDesktop ? 0 : `${(width * 1.5) / 100}px`} mb={`${(width * 1.5) / 100}px`}>
								<StyledButton
									isDesktop={isDesktop}
									onClick={() => window.open(title === "Place Your Group Order Today!" ? "https://catering.ordernoshery.com/?location=san-mateo" : ORDER_SAN_MATEO)}
									{...buttonParams}
								>
									{"Order San Mateo"} 
								</StyledButton>
							</Box>
							<Box>     
								<StyledButton
									isDesktop={isDesktop}
									onClick={() => window.open(title === "Place Your Group Order Today!" ? "hhttps://catering.ordernoshery.com/?location=san-carlos" : ORDER_SAN_CARLOS)}
									{...buttonParams}
								>
									{"Order San Carlos"} 
								</StyledButton>  
							</Box>
						</> 
				}

			</Flex>
		</Box>
	);
};
