import React from "react";
import { Box, Flex, Text, Heading, Image } from "noshery-ui";
import styled from "styled-components";
import { ALT_IMGS, locations } from "../../constants";

import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

const TwoRows = styled(Box)`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const Title = styled(Heading)`
  text-align: center;
  font-weight: 800;
`;


const OurLocations = ({ contactUs, isLocations }) => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();

	let wrapperParams = { 
		marginTop: `${(width * 5) / 100}px`, 
		marginBottom: `${(width * 5) / 100}px`,
		alignItems: "center",
		display: "flex",
		flexDirection: "column"
	};

	if (contactUs) {
		wrapperParams = { marginTop: `${(width * 3) / 100}px` };
	}

	const titleParams = {
		fontSize: `${(width * (isDesktop ? 3.3 : 5)) / 100}px`,
		lineHeight: `${(width * 3) / 100}px`,
	};

	const titleParams2 = {
		fontSize: `${(width * (isDesktop ? 1.8 : 5)) / 100}px`,
		lineHeight: `${(width * (isDesktop ? 4.4 : 6)) / 100}px`,
		fontWeight:  `${(width * 1) / 100}`,
	};

	return (
		<Box {...wrapperParams}>
			<Title {...titleParams} bold>
				{isLocations ? "Coming Soon Locations" : "Noshery Locations + Location Info"}
			</Title>
			{isLocations ? null : <Title {...titleParams2}>
        Noshery’s San Mateo and San Carlos Restaurants
			</Title>}
			{isLocations ? null : <Line /> } 
			<Flex
				mt={`${(width * 1.7) / 100}px`}
				alignItems="center"
				justifyContent="center"
			>
				<TwoRows>
					{locations.map((location, index) => {
						if(isLocations && index !== locations.length - 1) {
							return null;
						}

						return (
							<Box key={`${index}-locations`} padding={`${(width * 1.7) / 100}px`}>
								<SingleLocation isComingSoon={isLocations} {...location} />
							</Box>
						);
					})}
				</TwoRows>
			</Flex>
		</Box>
	);
};

export default OurLocations;

const SingleLocation = ({
	src,
	title,
	address1,
	address2,
	phone,
	openHours,
	value,
	order,
	isComingSoon,
}) => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();

	const boxShadow1 = `${(width * 0.24) / 100}px`;
	const boxShadow2 = `${(width * 1.2) / 100}px`;

	const boxParams = {
		backgroundColor: "#EDEDED",
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 5.6) / 100}px`,
		marginRight: `${(width * 2.2) / 100}px`,
		padding: `${(width * 2.6) / 100}px`,
		width: `${(width * (isDesktop ? 32 : 43)) / 100}px`,
		height: `${(width * (isDesktop ? 39 : 53)) / 100}px`,
	};

	const imageW = parseInt((width * (isDesktop ? 28.1 : 37.1)) / 100);
	const imageH = parseInt((width * (isDesktop ? 16.2 : 25.2)) / 100);

	const imageParams = {
		width: `${imageW}px !important`,
		height: `${imageH}px !important`,
		borderRadius: `${(width * 3) / 100}px`,
	};

	const titleParams = {
		fontSize: `${(width * 2.4) / 100}px`,
		lineHeight: `${(width * 3.3) / 100}px`,
		textAlign: "center",
	};

	const titleParams2 = {
		fontSize: `${(width * 2.4) / 100}px`,
		lineHeight: `${(width * 3.3) / 100}px`,
		textAlign: "center",
		textDecoration: "underline !important",
		color: "#325EAB !important",
	};

	const textSize = (width * (isDesktop ? 2 : 2.5)) / 100;
	const addressParams = {
		fontSize: `${textSize}px`,
		lineHeight: `${(width * (isDesktop ? 2.7 : 3.5)) / 100}px`,
		textAlign: "center",
	};

	return (
		<Box {...boxParams}>
			<Flex alignItems="center">
				<Image
					alt={ALT_IMGS["locations"][value].altImg}
					src={src(imageW, imageH)}
					{...imageParams}
					style={{ objectFit: "cover" }}
				/>
			</Flex>
			<Flex flexDirection="column">
				<Box>
					<Text {...titleParams} bold>
            Noshery {title}
					</Text>
				</Box>
				<Box>
					<Text {...addressParams}>{address1}</Text>
				</Box>
				<Box>
					<Text {...addressParams}>{address2}</Text>
				</Box>
				<Box>
					<Text {...addressParams}>{phone}</Text>
				</Box>
				<Box>
					{openHours.map((hours, i) => {
						return (
							<Text
								key={`#hours-${i}`}
								style={{ textAlign: "left !important" }}
								{...addressParams}
							>
								<span style={{ fontWeight: "bold" }}>{hours.days} </span>
								{hours.time}
							</Text>
						);
					})}
				</Box>
				{isComingSoon ? null : <Box>
					<Text {...titleParams2} bold>
						<a style={{color: "#325EAB"}} href={order} target="_blank" rel="noopener noreferrer" > Menu </a>
					</Text>
				</Box>}
			</Flex>

		</Box>
	);
};

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 65) / 100}px`,
		borderBottom: `${`${(width * 0.34) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};