import React from "react";
import { Flex, Box, Image, Text, Button } from "noshery-ui";
import styled from "styled-components";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";
import { altLanders } from "../../constants/landers";

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
  background-color: #344734;
`;

const ORDER_SAN_CARLOS = "https://online.ordernoshery.com/noshery-san-carlos?menu=7e53e925-a316-47e3-a718-5907227147b1";
const ORDER_SAN_MATEO = "https://online.ordernoshery.com/noshery-san-mateo?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6";

const StyledImage = styled(Image)`
  object-fit: cover;
  transform: scaleX(-1);
  box-shadow: 7.11px 7.11px 35.57px 17.78px rgba(0, 0, 0, 0.25);
`;

const FadeIn = styled(Box)`
  opacity: ${(p) => (p.visible ? 1 : 0)};
  visibility: ${(p) => (p.visible ? "visible" : "hidden")};
  transition: opacity 1.8s ease-out;
  will-change: opacity, visibility;
  ${(p) => (p.visible ? "transform: none;" : "")};
`;

const WhatWeDo = ({
	whatWeDo2,
	whatWeDoImg,
	brandAlts, 
	title
}) => {
	const { isDesktop, isTablet } = useDeviceType();
	const { width } = useWindowSize();
	const [isVisible, setVisible] = React.useState(true);
	const componentRef = React.useRef();

	React.useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => setVisible(entry.isIntersecting));
		});
		observer.observe(componentRef.current);
		let current = componentRef.current;
		return () => observer.unobserve(current);
	}, []);

	const titleParams = isDesktop
		? {
			fontSize: `${(width * 3) / 100}px`,
			lineHeight: `${(width * 3.9) / 100}px`,
			color: "#344734",
		}
		: {
			fontSize: `${(width * 5) / 100}px`,
			lineHeight: `${(width * 8) / 100}px`,
			color: "#344734",
		};

	const backgroundProps = {
		backgroundSize: `${width * 2}px`,
		backgroundColor: "#F1A638",
	};

	const descriptionParams = isDesktop
		? {
			fontSize: `${(width * 1.7) / 100}px`,
			lineHeight: `${(width * 2.2) / 100}px`,
			color: "#344734",
		}
		: {
			fontSize: `${(width * 1.2) / 100}px`,
			lineHeight: `${(width * 4.3) / 100}px`,
			color: "#344734",
		};

	const wrapperParams = isDesktop
		? {
			padding: `${(width * 3) / 100}px`,
		}
		: {
			padding: `${(width * 2) / 100}px`,
		};
  
	const boxShadow1 = `${(width * 0.1) / 100}px`;
	const boxShadow2 = `${(width * 1.0) / 100}px`;

	const buttonWidthPercentage = isDesktop || isTablet ? 20 : 28;
	const minButtonWidth = (width * buttonWidthPercentage) / 100;
	const buttonRightMargin = (width * 1.9) / 100;

	const buttonTextParams = isDesktop
		? {
			fontSize: `${(width * 2.3) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,
		}
		: {
			fontSize: `${(width * 4) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,
		};

	const buttonParams = {
		...buttonTextParams,
		minWidth: `${minButtonWidth + buttonRightMargin}px`,
		marginBottom: `${(width * 100) / 100}px`,
		borderRadius: `${(width * 0.72) / 100}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const buttonWrapperParams = {
		alignItems: "center",
		justifyContent: "center",
		paddingTop: `${(width * (whatWeDo2.text2 ? 1 : 2.6)) / 100}px`,
	};

	const imageSize = ((isDesktop ? 8 : 7) * width) / 10;
	const imageRight = (60 * imageSize) / 100;
	const imageTop = (20 * imageSize) / 100;
	const imageRightDesktop = (30 * imageSize) / 100;
	const imageTopDesktop = (-10 * imageSize) / 100;

	const imageProps = isDesktop
		? {
			height: `${(width * 80) / 100}px !important`,
			width: `${(width * 80) / 100}px !important`,
			maxWidth: `${(width * 80.8) / 100}px !important`,
			objectFit: "cover",
			position: "absolute",
			top: imageTopDesktop,
			right: -imageRightDesktop,
		}
		: {
			height: `${(width * 90) / 100}px !important`,
			width: `${(width * 90) / 100}px !important`,
			maxWidth: `${(width * 90) / 100}px !important`,
			objectFit: "cover",
			position: "absolute",
			top: imageTop,
			right: -imageRight,
		};
	const contentW = isDesktop ? 34 : 38;

	const contentProps = { 
		width: `${(width * contentW) / 100}px !important`,
		paddingBottom: `${(width * 5) / 100}px !important`
	};
  
	const imageSrc =  whatWeDoImg.imgSrc(
		parseInt((width * 43.5) / 100),
		parseInt((width * 43.5) / 100)
	);

	return (
		<Flex
			position={"relative"}
			justifyContent={"flex-start"}
			overflow="hidden"
			style={backgroundProps}
			ref={componentRef}
			{...wrapperParams}
		>
			<FadeIn visible={isVisible}>
				<Flex flexDirection="column" alignItems="center">
					<Box {...contentProps}>
						<Text {...titleParams} bold>
							{whatWeDo2.mainTitle}
						</Text>
						<Box>
							<Text {...descriptionParams}>{whatWeDo2.mainText.split("ordernoshery.com.")[0]} <a href="https://ordernoshery.com/" target="_blank" rel="noopener noreferrer"> ordernoshery.com. </a></Text>
							<Text style={{...descriptionParams, paddingTop: "5%"}}>{whatWeDo2.text2}</Text>
						</Box>
					</Box>
					<Flex
						flexDirection='column'
						justifyContent={isDesktop ? "flex-start" : "center"}
						{...buttonWrapperParams}
					>
						<Box mb={`${(width * 1.5) / 100}px`}>
							<StyledButton
								isDesktop={isDesktop}
								onClick={() => window.open(title === "Place Your Group Order Today!" ? "https://catering.ordernoshery.com/?location=san-mateo" : ORDER_SAN_MATEO)}
								{...buttonParams}
							>
                  Order San Mateo
							</StyledButton>
						</Box>
						<Box>     
							<StyledButton
								isDesktop={isDesktop}
								onClick={() => window.open(title === "Place Your Group Order Today!" ? "https://catering.ordernoshery.com/?location=san-carlos" : ORDER_SAN_CARLOS)}
								{...buttonParams}
							>
                  Order San Carlos
							</StyledButton>  
						</Box>
					</Flex>
				</Flex>
			</FadeIn>
			<FadeIn visible={isVisible} mr={`${(width * 2.3) / 100}px`}>
				<StyledImage
					alt={altLanders[brandAlts]["whatWeDo2"]}
					{...imageProps}
					src={imageSrc}
					borderRadius="50%"
				/>
			</FadeIn>

		</Flex>
	);
};

export default WhatWeDo;
