import React, { useEffect } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { Box } from "noshery-ui";
import CookieConsent, {
	Cookies,
	getCookieConsentValue,
} from "react-cookie-consent";

import pages from "./pages";
import { initGA } from "./utils";
import useWindowSize from "./hooks/useWindowSize";
import useDeviceType from "./hooks/useDeviceType";

const App = () => {
	const _pages = pages();
	const {width} = useWindowSize();
	const {isDesktop} = useDeviceType(); 

	useEffect(() => {
		const isConsent = getCookieConsentValue();
		if (isConsent === "true") {
			handleAcceptCookie();
		}
	}, []);

	const handleAcceptCookie = () => {
		if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
			initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
		}
	};

	return (
		<Box>
			<Routes>
				{_pages.map(({ path, component }) => (
					<Route key={path} path={path} element={component()} />
				))}
				<Route path="*" element={<Navigate replace to="/" />} />
			</Routes> 
		</Box>
	);
};

export default App;
