import React from "react";
import { Box, Flex, Image, Text } from "noshery-ui";
import { Link } from "react-router-dom";
import useDeviceType from "../../hooks/useDeviceType";
import { landingBrands } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";

const FourRows = styled(Box)`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const OneRows = styled(Box)`
  display: grid;
  grid-template-columns: 100%;
`;

let colors = {
	0: "#F1A638",
	1: "#344734",
	2: "#344734",
	3: "#F1A638",
	4: "#F1A638",
	5: "#344734",
	6: "#344734",
	7: "#F1A638",
	8: "#F1A638",
	9: "#344734",
};

const BrandList = ({mobilepaddingTopBranList}) => {
	const { width } = useWindowSize();
	const logos = Object.keys(landingBrands);

	const wrapperParams = {
		width: "100%",
		alignItems: "center"
	};

	const containerParams = {
		padding: `${(width * mobilepaddingTopBranList) / 100}px 0px !important`,
		height: `${(width * 276.5) / 100}px !important`,
		alignItems: "center",
		flexDirection: "column",
		marginTop: "5%"
	};

	const textParams = {
		fontSize: `${(width * 8) / 100}px !important`,
	};

	const textParams2 = {
		fontSize: `${(width * 3.5) / 100}px !important`,
	};

	const lW = parseInt((width * (18.9)) / 100);
	const lH = parseInt((width * (18.9)) / 100);

	const lW2 = parseInt((width * (41)) / 100);
	const lH2 = parseInt((width * (28)) / 100);

	const logoPrams = {
		width: `${lW}px !important`,
		height: `${lH}px !important`,
		position: "relative",
		top: `${(width * 4) / 100}px !important`
	};

	const logoPrams2 = {
		width: `${lW2}px !important`,
		height: `${lH2}px !important`,
		borderRadius: `${(width * 3) / 100}px !important`,
	};

	return (
		<Flex 
			{...containerParams}>
			<Box textAlign="center" paddingBottom="3%"> 
				<Text bold {...textParams}> Our Celebrated </Text> 
				<Text bold {...textParams}> Restaurant Partners </Text> 
				<Text bold {...textParams2}> A range of cuisines, flavors, and popular dishes </Text>
				<Text bold {...textParams2}> made-to-order by our expert chefs </Text>
				<Line />
			</Box>
      
			<FourRows
				{...wrapperParams}
			>
				{logos.map((l, i) => {
					if(landingBrands[l]["title"] === "Coming Soon") return false; 
					return (
						<LogoLink
							l={l}
							i={i}
							key={`#brandlist${i}`}
							src={landingBrands[l].logo(lW, lH)}
							bg={landingBrands[l].landerBg(lW2, lH2)}
							logoPrams={logoPrams}
							logoPrams2={logoPrams2}
							title={landingBrands[l]["title"]}
						/>
					);
				}
				)}
			</FourRows>
		</Flex>
	);
};

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 90) / 100}px`,
		borderBottom: `${`${(width * 0.5) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};


const LogoLink = ({ l, src, logoPrams, i, bg, logoPrams2, title }) => {
	const { width } = useWindowSize();

	const boxParams = {
		height: `${(width * 60.8) / 100}px`,
		border: "1px solid",
		borderColor: colors[i],
		backgroundColor: colors[i] 
	};

	const textParams = {
		fontSize: `${(width * 5) / 100}px !important`,
		color: "white",
		paddingTop: "10%"
	};

	return (
		<Box {...boxParams} >
			<Flex
				style={{
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column"
				}}
			>
				<Link
					key={landingBrands[l].to}
					to={`/${landingBrands[l].to}`}
					style={{ textDecoration: "none", display: "flex", 
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<Image src={src} alt={`${l} logo`} {...logoPrams} />
					<Image src={bg} alt={`${l} logo`} {...logoPrams2} />
					<Text bold {...textParams}> {title} </Text>
				</Link>
			</Flex>
		</Box>
	);
};


export default BrandList;
