import React from "react";
import { Box, Flex, Image } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";

const TheFood = ({
	name,
	quote,
	author,
	pageImgAlts,
	food1,
	food2,
	food3,
	food4,
	food5,
}) => {
	const { width } = useWindowSize();

	const boxParams = {
		marginTop: `${(width * 4) / 100}px`,
	};

	const boxShadow1 = `${(width * 0.24) / 100}px`;
	const boxShadow2 = `${(width * 1.2) / 100}px`;

	const f1W = 89.3;
	const f1H = 89.3;

	const f1Width = parseInt((width * f1W) / 100);
	const f1Height = parseInt((width * f1H) / 100);

	const food1Params = {
		width: `${f1Width}px !important`,
		height: `${f1Height}px !important`,
		maxWidth: `${f1Width}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 2.7) / 100}px`,
		marginBottom: `${(width * 1.1) / 100}px !important`,
	};

	const f2H = 43.9;
	const f2W = 43.9;

	const f2Width = parseInt((width * f2W) / 100);
	const f2Height = parseInt((width * f2H) / 100);

	const food2Params = {
		width: `${f2Width}px !important`,
		height: `${f2Height}px !important`,
		maxWidth: `${f2Width}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 2.7) / 100}px`,
		marginBottom: `${(width * 1.1) / 100}px !important`,
	};

	const f3H = 89.3;
	const f3W = 43.9;

	const f3Width = parseInt((width * f3W) / 100);
	const f3Height = parseInt((width * f3H) / 100);

	const food3Params = {
		width: `${f3Width}px !important`,
		height: `${f3Height}px !important`,
		maxWidth: `${f3Width}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 2.7) / 100}px`,
		marginBottom: `${(width * 1.1) / 100}px !important`,
	};

	return (
		<Box textAlign="center" {...boxParams}>
			<Box mb={`${(width * 2.8) / 100}px !important`}>

			</Box>
			<Flex justifyContent="center">
				<Flex flexDirection="column">
					<Image
						alt={pageImgAlts["thefood"][0]}
						style={{ objectFit: "cover" }}
						src={food1(f1Width, f1Height)}
						{...food1Params}
					/>
					<Flex
						flexDirection="row"
						textAlign="left"
						width={food2Params.width}
					>
						<Box paddingRight={`${(width * 1.4)/100}px`}> 
							<Image
								alt={pageImgAlts["thefood"][1]}
								style={{ objectFit: "cover" }}
								src={food2(f2Width, f2Height)}
								{...food2Params}
							/>
							<Image
								alt={pageImgAlts["thefood"][1]}
								style={{ objectFit: "cover" }}
								src={food3(f2Width, f2Height)}
								{...food2Params}
							/>
						</Box>

						<Image
							alt={pageImgAlts["thefood"][2]}
							style={{ objectFit: "cover" }}
							src={food4(f3Width, f3Height)}
							{...food3Params}
						/>
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
};

export default TheFood;


