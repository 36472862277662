import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Image } from "noshery-ui";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

const SingleBrand = ({ title, logo, bg, to, altImg, finalImage }) => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize(); 

	const w = 20;
	const h = 7;

	const params = {
		width: `${(width * w) / 100}px`,
		height: `${(width * h) / 100}px`,
		borderRadius: `${(width * 3.1) / 100}px`,
		marginLeft: `${(width * (isDesktop ? 0 : 2.5)) / 100}px`,
		marginRight: `${(width * 1) / 100}px`,
		marginTop: `${(width * (isDesktop ? 0 : 2.5)) / 100}px`,
		marginBottom: `${(width * 2.5) / 100}px`,
	};

	const logoW = 8;

	const logoWidth = parseInt((width * logoW) / 100);
 

	const bgW = 18;
	const bgH = 14;

	const bgWidth = parseInt((width * bgW) / 100);
	const bgHeight = parseInt((width * bgH) / 100);

	const bgParams = {
		width: "auto",
		maxWidth: `${bgWidth}px !important`,
		height: `${logoWidth}px !important`, 
	};

	return (
		<Link onClick={() => {
			window.open(to, "_blank");
		}} to="" style={{ textDecoration: "none" }}>
			<Flex
				{...params}
				alignItems="center"
				flexDirection="column"
				style={{ 
					position: "relative",
					filter: "drop-shadow(0px 7.114px 7.114px rgba(0, 0, 0, 0.25))", 
					transition: ".5s",
				}} 
			>
				<Box mt={`${(width * 1.3) / 100}px`}>
					<Image
						alt={finalImage}
						src={finalImage}
						{...bgParams} 
					/>
				</Box> 
			</Flex>
		</Link>
	);
};

export default React.memo(SingleBrand);
