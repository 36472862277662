import React from "react";
import { Flex, Box, Text } from "noshery-ui";
import styled from "styled-components";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

const FourRows = styled(Box)`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
`;

const ThreeRows = styled(Box)`
  display: grid;
  grid-template-columns: 33% 33% 33%;
`;

const Row1Content = [
	"healthcare insurance",
	"vision insurance",
	"dental insurance",
	"life insurance",
];
const Row2Content = [
	"massive growth potential",
	"free food",
	"flexible scheduling",
];

const Perks = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();

	const titleParams = isDesktop
		? {
			fontSize: `${(width * (isDesktop ? 3.5 : 3.5)) / 100}px !important`,
			lineHeight: `${(width * 3.75) / 100}px`,
			color: "#344734",
		}
		: {
			fontSize: `${(width * (isDesktop ? 3.5 : 3.5)) / 100}px !important`,
			lineHeight: `${(width * 3.75) / 100}px`,
			color: "#344734",
		};
	const backgroundProps = {
		backgroundColor: "#ffffff",
	};

	return (
		<Flex justifyContent={"center"} style={backgroundProps}>
			{isDesktop && (
				<>
					<Flex
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
					>
						<Box style={{ paddingTop: `${(width * 2) / 100}px` }}>
							<Text {...titleParams} bold>
                The Perks
							</Text>
						</Box>
						<FourRows>
							{Row1Content.map((perk, i) => {
								return <Circle key={`#perk${i}`} text={perk} bg={"#344734"} />;
							})}
						</FourRows>
						<ThreeRows>
							{Row2Content.map((perk, i) => {
								return <Circle key={`#perk${i}`} text={perk} bg={"#F1A638"} />;
							})}
						</ThreeRows>
					</Flex>
				</>
			)}
			{!isDesktop && (
				<>
					<Flex
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
					>
						<Box style={{ paddingTop: `${(width * 2) / 100}px` }}>
							<Text {...titleParams} bold>
                The Perks
							</Text>
						</Box>
						<FourRows>
							{Row1Content.map((perk, i) => {
								return <Circle key={`#perk${i}`} text={perk} bg={"#344734"} />;
							})}
						</FourRows>
						<ThreeRows>
							{Row2Content.map((perk, i) => {
								return <Circle key={`#perk${i}`} text={perk} bg={"#F1A638"} />;
							})}
						</ThreeRows>
					</Flex>
				</>
			)}
		</Flex>
	);
};

const Circle = ({ text, bg }) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();
	const circleParams = {
		borderRadius: "50%",
		backgroundColor: bg,
		width: `${(width * (isDesktop ? 15 : 20)) / 100}px`,
		height: `${(width * (isDesktop ? 15 : 20)) / 100}px`,
		alignItems: "center",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
	};

	const textParams = {
		color: "white",
		fontSize: `${(width * (isDesktop ? 1.8 : 3)) / 100}px`,
	};

	return (
		<Box padding={`${(width * 1.5) / 100}px`}>
			<Box {...circleParams}>
				<Text bold {...textParams}>
					{text}
				</Text>
			</Box>
		</Box>
	);
};

export default Perks;
