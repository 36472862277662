import React from "react";
import { Flex, Box, Text } from "noshery-ui";
import useFirebaseStorage from "../../hooks/useFirebaseStorage";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import { whoarewe } from "../../constants";
import { animated, useSpring } from "react-spring";

const WhoAreWe = () => {
	const { width } = useWindowSize();
	const imageUrls = useFirebaseStorage("landing/whatwedo");
	const [index, setIndex] = React.useState();

	const titleParams = {
		fontSize: `${(width * 12) / 100}px`,
		lineHeight: `${(width * 12) / 100}px`,
		color: "#ffffff",
		textAlign: "center",
		width: "100%",
		paddingBottom: `${(width * 9.9) / 100}px`,
		paddingTop: `${(width * 1) / 100}px`
	};

	const backgroundProps = { 
		backgroundSize: `${width*2}px`, 
		backgroundColor: "#344734", 
		backgroundImage: `url(${imageUrls[0]})`,
	};

	const wrapperParams = { 
		marginTop: `${(width * 6.4) / 100}px`, 
		padding: `${(width * 2) / 100}px` 
	};

	const contentProps = { width: "100%" };

	return (
		<Flex
			justifyContent={"center"} alignItems={"center"}
			style={backgroundProps} {...wrapperParams}>
			<Flex flexDirection='column' alignItems='center'>
				<Box {...contentProps}>
					<Flex alignItems='center' width="100%" justifyContent='center' >
						<Text {...titleParams} bold>
                  WHO ARE WE?
						</Text>
					</Flex>
				</Box>

				<Flex paddingBottom={`${(width * 15) / 100}px`} flexDirection="column">
					{whoarewe.map((item, i) => {
						return <Box  key={`${item.title}-${i}`} paddingBottom={`${(width * 5.3) / 100}px`}> 
							<SpringBox 
								key={`#whoweare${i}`} 
								title={item.title}  
								text={item.text} 
								onClick={() => setIndex(i)}
								isOpen={index === i}
							/> 
						</Box>;
					})}
				</Flex> 
			</Flex>
		</Flex>
	);
};

export default WhoAreWe;

const BoxQ = styled(Box)`
  background: white;
  color: #344734;
`;

const BoxA = styled(Box)`
    color: white;
`;

const AnimatedBox = animated(BoxA);

const AniamtedWrapper = animated(Box); 

const SpringBox = ({ title, text, isOpen, onClick }) => {
	const { width } = useWindowSize();
	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1) / 100}px`;
  
	const question = {
		fontSize: `${(width * 6) / 100}px`,
		lineHeight: `${(width * 6) / 100}px`,
		padding: `${(width * 1) / 100}px ${(width * 1) / 100}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 4) / 100}px`,
		height: `${(width * 33) / 100}px !important`,
		alignItems: "center",
		display: "flex",
		width: `${(width * 60) / 100}px !important`,
		textAlign: "center",
		flexDirection: "column",
		justifyContent: "center"
	};
  
	const {height, lineHeight, padding, opacity, paddingTop} = useSpring({
		from: {
			height: "0px",
			lineHeight: "0px",
			padding: "0px",
			paddingTop: "0px",
			opacity: 0, 
		},
		to: {
			height: isOpen ? `${(width * (35)) / 100}px` : "0px",
			lineHeight: isOpen ? `${(width * 5) / 100}px` : "0px",
			padding: 
          isOpen ? `${(width * 2.1) / 100}px ${(width *2.4) / 100}px` : "0px",
			paddingTop: isOpen ? `${(width * 9) / 100}px` : "0px",
			opacity: isOpen ? 1 : 0, 
		}
	});
  
	const answerWrapper = {
		top: `-${(width * 9) / 100}px`,
		position: "relative",
	};    
  
	const {height: WrapperH} = useSpring({
		from: { height: `${(width * (20)) / 100}px`},
		to: {
			height: isOpen && `${(width * 70) / 100}px`
		}
	});

	const weareParams = {
		paddingBottom: `${(width * 4) / 100}px`,
		fontSize: `${(width * 5) / 100}px`
	};
  
	return (
		<AniamtedWrapper  
			onClick={onClick} 
			style={{ 
				display: "flex", 
				flexDirection: "column",
				cursor: "pointer",
				height: WrapperH,
				width: `${(width * 60) / 100}px`
			}}>
			<BoxQ {...question} isOpen={isOpen} mb={`${(width * 2.5) / 100}px`}>
				<Text bold {...weareParams}> WE ARE </Text>
				<Text bold fontSize={`${(width * 8) / 100}px`}>{title}: </Text>
			</BoxQ>
			<BoxA {...answerWrapper}> 
				<AnimatedBox  
					style={{
						opacity: opacity, 
						fontSize: `${(width * 5) / 100}px`,
						lineHeight: lineHeight,
						padding: padding,
						borderRadius: `${(width * 2) / 100}px`,
						height: height,
						textAlign:"center",
						paddingTop: paddingTop, 
						width: "100%",
					}}
				>
					{text}
				</AnimatedBox>
			</BoxA>
		</AniamtedWrapper>
	);
};

