import React from "react";
import { Box, Flex, Image, Text } from "noshery-ui";
import { Link } from "react-router-dom";
import useDeviceType from "../../hooks/useDeviceType";
import { landingBrands } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";

const FourRows = styled(Box)`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
`;


const BrandList = ({ paddingTopBranList }) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();
	const logos = Object.keys(landingBrands);

	const wrapperParams = {
		width: "100%",
		padding: `${(width * 2.7) / 100}px 0px !important`,
		height: `${(width * 25) / 100}px !important`,
		alignItems: "center"
	};

	const containerParams = {
		padding: `${(width * paddingTopBranList) / 100}px 0px !important`,
		height: `${(width * 60) / 100}px !important`,
		alignItems: "center",
		flexDirection: "column",
		paddingBottom: `${(width * 2.7) / 100}px 0px !important`,
	};

	const textParams = {
		fontSize: `${(width * 3.9) / 100}px !important`,
	};

	const textParams2 = {
		fontSize: `${(width * 2) / 100}px !important`,
	};

	const lW = parseInt((width * (isDesktop ? 9 : 9)) / 100);
	const lH = parseInt((width * (isDesktop ? 9 : 9)) / 100);

	const lW2 = parseInt((width * (isDesktop ? 15 : 10)) / 100);
	const lH2 = parseInt((width * (isDesktop ? 10 : 10)) / 100);

	const logoPrams = {
		width: `${lW}px !important`,
		height: `${lH}px !important`,
		position: "relative",
		top: `${(width * 1.2) / 100}px !important`
	};

	const logoPrams2 = {
		width: `${lW2}px !important`,
		height: `${lH2}px !important`,
		borderRadius: `${(width * 1.5) / 100}px !important`,
	};

	return (
		<Flex 
			{...containerParams}>
			<Box textAlign="center" paddingBottom="1%"> 
				<Text bold {...textParams}> Our Celebrated Restaurant Partners </Text> 
				<Text bold {...textParams2}> A range of cuisines, flavors, and popular dishes made-to-order by our expert chefs </Text>
			</Box>
			<Line /> 
			<FourRows
				{...wrapperParams}
			>
				{logos.map((l, i) => {
					if(i > 3) return false; 
					return (
						<LogoLink
							l={l}
							i={i}
							key={landingBrands[l]["title"]}
							src={landingBrands[l].logo(lW, lH)}
							bg={landingBrands[l].landerBg(lW2, lH2)}
							logoPrams={logoPrams}
							logoPrams2={logoPrams2}
							title={landingBrands[l]["title"]}
						/>
					);
				}
				)}
			</FourRows>
			<FourRows
				{...wrapperParams}
			>
				{logos.map((l, i) => {
					if(i < 4 || landingBrands[l]["title"] === "Coming Soon") return false; 
					return (
						<LogoLink
							l={l}
							i={i+1}
							key={landingBrands[l]["title"]}
							src={landingBrands[l].logo(lW, lH)}
							bg={landingBrands[l].landerBg(lW2, lH2)}
							logoPrams={logoPrams}
							logoPrams2={logoPrams2}
							title={landingBrands[l]["title"]}
						/>
					);
				}
				)}
			</FourRows>
		</Flex>
	);
};

const LogoLink = ({ l, src, logoPrams, i, bg, logoPrams2, title }) => {
	const { width } = useWindowSize();
	const [hover, setHover] = React.useState(false);
	const boxParams = {
		height: `${(width * 25.4) / 100}px`,
	};

	const textParams = {
		fontSize: `${(width * 2) / 100}px !important`,
		color: "white",
		paddingTop: "10%"
	};

	return (
		<Box key={`#brandlist${i}`} {...boxParams} bg={i % 2 === 0 ? "#344734" : "#F1A638"} >
			<Flex
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				onClick={() => setHover(false)}
				style={{
					transform: hover ? "scale(1.03)" : "scale(1)",
					transition: ".5s",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column"
				}}
			>
				<Link
					key={landingBrands[l].to}
					to={`/${landingBrands[l].to}`}
					style={{ textDecoration: "none", display: "flex", 
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<Image src={src} alt={`${l} logo`} {...logoPrams} />
					<Image src={bg} alt={`${l} logo`} {...logoPrams2} />
					<Text bold {...textParams}> {title} </Text>
				</Link>
			</Flex>
		</Box>
	);
};

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 65) / 100}px`,
		borderBottom: `${`${(width * 0.34) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};

export default BrandList;
