import React from "react";
import moment from "moment-timezone";
import { Box, Text } from "noshery-ui";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import Snackbar from "@mui/material/Snackbar";
import ClearIcon from "@mui/icons-material/Clear";
import * as MUI from "@mui/material";

const StyledBox = styled(Box)`
  &:hover {
    color: red;
  }
`;

export const HolidayHours = () => {
	const { width } = useWindowSize();

	const holidayBannerParams = {
		fontSize: `${(width * 3.7) / 100}px`,
		lineHeight: `${(width * 3.7) / 100}px`,
		marginTop: "0px",
		marginBottom: "0px",
		fontWeight: "bold",
		textAlign: "center",
		fontFamily: "Uni Neue heavy"
	};

	const subText = {
		fontSize: `${(width * 3) / 100}px`,
		lineHeight: `${(width * 4) / 100}px`,
		color: "white",
		textAlign: "center",
		fontFamily: "Uni Neue"
	};

	const turkeyParams = {
		width: `${(width * (20)) / 100}px`,
		height: `${(width * (20)) / 100}px`,
		marginBottom: `${(width * 2)/100}px`,
	};
	
	return (
		<MUI.Box sx={{
			position: "absolute",
			top: `${(width * 35)/100}px`,
			left: `${(width * 10)/100}px`,
			backgroundColor: "#F1A637",
			borderRadius: `${(width * 5)/100}px`,
			width: `${(width * (65))/100}px`,
			height: `${(width * (20))/100}px`,
			padding: `${(width * 10)/100}px`,
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
			alignItems: "center",
			alignContent: "center",
		}}>
			<img style={turkeyParams} src="/firefork.png" alt="Noshery Firework" />
			<Box paddingRight="2%" paddingLeft="2%" width={ `${(width * 95)/100}px`}> 
				<Text style={{...holidayBannerParams, color: "white"}}>
					{"New Year's Hours"}
				</Text> 
				<Text style={{...subText, color: "white"}}>
					<b> 
Sunday, Dec 31st - 11:00AM - 9:00PM
					</b>
				</Text> 
				<Text style={subText}> 
					<b> 
Monday, Jan 1st - Normal Hours
						 </b>
				</Text> 
			</Box>
			{/* <img style={turkeyParams} src="/noshery_turkey.png" alt="Noshery Firework" /> */}
		</MUI.Box> 
	);
};

export const HolidayHoursLander = () => {
	const { width } = useWindowSize();
	const date = moment(); 
	const [open, setOpen] = React.useState(true);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	const holidayBannerParams = {
		fontSize: `${(width * 4) / 100}px`,
		lineHeight: `${(width * 4) / 100}px`,
		marginTop: "0px",
		marginBottom: "0px",
		fontWeight: "bold",
		textAlign: "center"
	};

	const subText = {
		fontSize: `${(width * (4)) / 100}px`,
		lineHeight: `${(width * 4) / 100}px`,
		color: "white",
		textAlign: "center",
	};

	const turkeyParams = {
		width: `${(width * (30)) / 100}px`,
		height: `${(width * (30)) / 100}px`,
		marginBottom: `${(width * 2)/100}px`,
	};
	
	return (
		<Snackbar 
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={open} autoHideDuration={10000} onClose={handleClose}>
			<Box style={{
				marginTop: "30%",
				backgroundColor: "#F1A637",
				borderRadius: `${(width * 5)/100}px`,
				width: `${(width * (95))/100}px`,
				height: `${(width * (55))/100}px`,
				padding: `${(width * 5)/100}px`,
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
				alignItems: "center",
				alignContent: "center",
			}}>   			
				{/* <StyledBox onClick={handleClose} style={{ 
					fontSize: `${(width * (2.2)) / 100}px`, 
					alignSelf: "start", cursor: "pointer",}}> <ClearIcon sx={{color: "red"}} /> </StyledBox>         */}
				{/* <img style={turkeyParams} src="/nosheryBaseballBat.png" alt="Noshery Firework" /> */}
				<Box width={ `${(width * 100)/100}px`}> 
					{/* <Text style={{...holidayBannerParams, color: "white"}}>
              NOSHERY GIFT CARDS ARE NOW AVAILABLE
					</Text> 
					<Text style={subText}> 
              Select Gift Cards in the sidebar for e-Gift Cards or visit us in store for physical gift cards 
					</Text> */}
					 
					 <Text style={{...holidayBannerParams, color: "white"}}>
						{"New Year's Hours"}
					</Text> 
					<Text style={{...subText, color: "white"}}>
						<b> 
Sunday, Dec 31st - 11:00AM - 9:00PM
						</b>
					</Text> 
					<Text style={subText}> 
						<b> 
Monday, Jan 1st - Normal Hours
						 </b>
					</Text> 
					{/* <Text style={subText}> 
						<b> San Carlos 02/23: 4:30pm - 11:00pm  </b>
					</Text>
					<Text style={subText}> 
						<b> San Mateo 02/23: Regular Hours  </b>
					</Text> */}
				</Box>
				{/* <img style={turkeyParams} src="/rainfork.png" alt="Noshery Rain Fork" /> */}
			</Box>
		</Snackbar>
	);
};