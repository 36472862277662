import React from "react";
import { Link } from "react-router-dom";
import { Box } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import useDeviceType from "../../hooks/useDeviceType";
import { ALT_IMGS } from "../../constants";

const Logo = styled.img`
  width: ${(p) => p.width};
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

const NosheryLogo = () => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();
	// logo width = 15 % of total width
	const ratio = isDesktop ? 25 : 25;
	const logoWidth = (width * ratio) / 100;

	// 9.8 and 20.5 of the logo width
	const logoWrapperParams = {
		top: `${(logoWidth * 70) / 100}px`,
		left: `${(logoWidth * 20) / 100}px`,
	};

    
	const imageSrc = "https://res.cloudinary.com/dwbvlpae2/image/upload/v1666049242/DraperUni/Draper_Logo_xdgj3g.webp";

	return (
		<Box {...logoWrapperParams} position="absolute" zIndex={2}>
			<Logo
				alt={ALT_IMGS["logo"].altImg}
				src={imageSrc}
				width={`${logoWidth}px`}
				height={`${logoWidth / 3}px`}
			/>
		</Box>
	);
};

export default NosheryLogo;
