import React from "react"; 
import useWindowSize from "../../hooks/useWindowSize";
import { Flex, Image } from "noshery-ui";

const externalLinks = [
	{
		href: "https://www.instagram.com/ordernoshery/?hl=en",
		src: "instagram.png",
	},
	{ href: "https://www.facebook.com/ordernoshery", src: "facebook.png" },
	{ href: "https://www.tiktok.com/@ordernoshery", src: "tiktok.png" },
];

const SocialsIcons = () => {
	const { width } = useWindowSize();
	return (
		<Flex alignItems="center">
			{externalLinks.map(({ src, href }) => (
				<a
					key={href}
					target="blank"
					href={href}
					style={{
						textAlign: "center",
						textDecoration: "none",
						paddingRight: "5%",
						paddingTop: "5%",
						width: `${(width * 12) / 100}px`,
						height: `${(width * 13) / 100}px`,
					}}
				>
					<Image
						alt={`${src.split(".")[0]} logo`}
						style={{
							width: `${(width * 11) / 100}px`,
							height: `${(width * 11) / 100}px`,
						}}
						src={`/${src}`}
					/>
				</a>
			))}
		</Flex>
	);
};

export default SocialsIcons;