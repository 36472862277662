import React from "react";
import { Box, Heading, Flex } from "noshery-ui";
import styled from "styled-components";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";

import SingleBrand from "./AnimatedSingleBrand";
import { landingBrands } from "../../constants";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

const Title = styled(Heading)`
  text-align: center;
  font-weight: 800;
`;

const BrandPartners = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();
	const [active, setActive] = React.useState(false);
	const [offsetRadius, setOffset] = React.useState(9);
	const [goToSlide, setGoToSlide] = React.useState(0);

	const wrapperParams = {
		marginTop: `${(width * 3) / 100}px`,
		height: `${(width * 40) / 100}px`,
	};

	const titleParams = {
		fontSize: `${(width * (isDesktop ? 3.3 : 6)) / 100}px`,
		lineHeight: `${(width * (isDesktop ? 4.4 : 10)) / 100}px`,
		paddingBottom: `${(width * (isDesktop ? 3 : 4)) / 100}px`,
	};

	React.useEffect(() => {
		const handleScroll = event => {
			if(active) {
				document.body.style.overflow="hidden";
			}
			else {
				document.body.style.overflow="auto";
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [active]);


	return (
		<Box {...wrapperParams}>
			<Title {...titleParams} bold>
        Our Restaurant Partners 
			</Title>

			<Flex
				flexWrap="wrap"
				alignItems="center"
				justifyContent="center"
				maxWidth={"50%"}
				height="75%"
				margin="auto"
				overflow="hidden"
				onMouseEnter={() => {
					document.body.style.overflow="hidden";
					setActive(true);
				}}
				onMouseLeave={() => {
					setActive(false);
					document.body.style.overflow="auto";
				}}
				onWheel = {(e) => {
					if(active) {
						document.body.style.overflow="hidden";
						e.deltaY > 0 ? setGoToSlide(goToSlide + 1) : setGoToSlide(goToSlide - 1);
					}
					else {
						document.body.style.overflow="auto";
					}
				}}
			>
				<Carousel
					goToSlide={goToSlide}
					offsetRadius={offsetRadius}
					showNavigation
					config={config.gentle}
					slides={Object.keys(landingBrands).filter((l) => landingBrands[l].name !== "comingsoon").map((l, index) => {
						const brand = landingBrands[l];
						if(brand.name === "comingsoon") return false;
						return {key: index, content: <SingleBrand isLocations key={l} {...brand} />, onClick: () => {
							setGoToSlide(index);
						} };
					})} />
			</Flex>
		</Box>
	);
};

export default React.memo(BrandPartners);
