// ------------ Firebase ---- 
import React from "react";
import ReactDOM from "react-dom/client";
import "normalize.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "noshery-ui";

import App from "./App";
import ScrollToTop from "./ScrollToTop";
import reportWebVitals from "./reportWebVitals";
import "pure-react-carousel/dist/react-carousel.es.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Router>
				<ScrollToTop />
				<App />
			</Router>
		</ThemeProvider>
	</React.StrictMode>
);

reportWebVitals();