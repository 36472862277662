import React from "react";
import { Box } from "noshery-ui";

import Header from "../RevereHeader";
import Footer from "../Footer";

const Landing = () => { 

	return (
		<Box>
			<Header isDraper={false}/>          
			<Footer /> 
		</Box>
	);
};

export default Landing;
