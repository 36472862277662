import React from "react";
import { Link } from "react-router-dom";
import { Box } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import useDeviceType from "../../hooks/useDeviceType";
import { ALT_IMGS } from "../../constants";
import styled from "styled-components";

const Logo = styled.img`
  width: ${(p) => p.width};
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-size: contain;
  background-position: center center;
`;

const NosheryLogo = () => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();
	// logo width = 15 % of total width
	const ratio = isDesktop ? 15 : 25;
	const logoWidth = (width * ratio) / 100;

	// 9.8 and 20.5 of the logo width
	const logoWrapperParams = {
		top: `${(logoWidth * 9.8) / 100}px`,
		left: `${(logoWidth * 20.5) / 100}px`,
	};

	return (
		<Box {...logoWrapperParams} position="absolute" zIndex={2}>
			<Link to="/">
				<Logo
					alt={ALT_IMGS["logo"].altImg}
					src={"/nosherylogo.png"}
					width={`${logoWidth}px`}
					height={`${logoWidth / 2}px`}
				/>
			</Link>
		</Box>
	);
};

export default NosheryLogo;
