import React from "react";
import { Box, Image, Text, } from "noshery-ui";
import styled from "styled-components";
import Logo from "../Logo2"; 
import ContactUsForm from "../ContactUsForm"; 
import useDeviceType from "../../../hooks/useDeviceType";
import useWindowSize from "../../../hooks/useWindowSize";

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`; 

const ContactUs = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize(); 
	const imageHeight = parseInt((width * (isDesktop ? 20 : 35)) / 100);
	const imageParams = {
		height: `${imageHeight}px !important`,
	}; 
	const imageCoverParams = {
		height: `${imageHeight}px !important`,
		backgroundColor: "rgba(241, 166, 56, 0.5)",
	}; 
	return (
		<Box position="relative">
			<Logo />
			<Box position="relative">
				<Box {...imageCoverParams} width="100%" position="absolute" top={0} />
				<Image
					alt="contact us page background image"
					{...imageParams}
					src={`https://res.cloudinary.com/dwbvlpae2/image/upload/h_${900},c_fill,g_auto,f_auto/contact-us/CU_H_dzbak6.webp`}
					width="100%"
					style={{ objectFit: "cover" }}
				/>
				<ImageText />
			</Box> 
			<ContactUsForm /> 
		</Box>
	);
};

export default ContactUs;

const ImageText = () => {
	const { width } = useWindowSize(); 
	const { isDesktop } = useDeviceType();
	const imageTextParams = {
		marginTop: `-${(width * 3.3) / 100}px`,
	}; 
	const titleParams = {
		fontSize: `${(width * (isDesktop ? 6.6 : 10)) / 100}px`,
		marginTop: "0px",
		marginBottom: "0px",
	}; 
	return (
		<StyledBox {...imageTextParams} top="50%">
			<Box>
				<Text as="h1" color="white" {...titleParams} bold>
          			Contact Us
				</Text>
			</Box>
		</StyledBox>
	);
};
