import React from "react";
import { Flex, Box, Image, Text, Button } from "noshery-ui";
import styled from "styled-components";
import useDeviceType from "../../../hooks/useDeviceType";
import useWindowSize from "../../../hooks/useWindowSize";
import { ALT_IMGS } from "../../../constants";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import SingleBlogHeader from "./SingleBlogHeader";

const API_URL = process.env.REACT_APP_API_URL;

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const imgBase = "https://res.cloudinary.com/dwbvlpae2/image/upload/v1607410008";

const Blog = ({
	sidebarOpen, 
	setSidebarOpen,
	headerOpen,
	onScroll
}) => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();
	const [blog, setBlog] = React.useState({});
	const { id } = useParams(); 

	React.useEffect(() => {
		(async () => {
			let res = await fetch(`${API_URL}/blog?id=${id}`); 
			res = await res.json();
			if(!res["blog"]) return;
			setBlog(res["blog"]);
		})();
	}, []);

	const boxParams = {
	};

	const imageParams = { height: `${(width * 40) / 100}px !important` };
  
	const imageParamsBody = { height: `${(width * 80) / 100}px !important`, width: `${(width * 40) / 100}px !important` };

	const coverHeight = parseInt((width * 40) / 100);

	return (
		<Box {...boxParams}>

			{
				!("id" in blog) ?  ( <Box style={{ width: `${(width * 90) / 100}px`, height: `${(width * 50) / 100}px`, alignContent: "center", alignItems: "center", display: "flex", justifyContent: "center" }}> 
					<CircularProgress  />
				</Box> ) : (
					<>
						<SingleBlogHeader
			 				sidebarOpen={sidebarOpen} 
							setSidebarOpen={setSidebarOpen}
							headerOpen={headerOpen} 
							imgPath={blog["ImagePath"]}
							onScroll={onScroll} /> 

						{("isMailChamp" in blog) && blog["isMailChamp"] ? <> <div dangerouslySetInnerHTML={{__html: blog["body"]}} /> </> : 
							<Flex style={{
								flexDirection: "column",
								marginLeft: `${(width * 5.1) / 100}px`,
								marginRight: `${(width * 5.1) / 100}px`,
							}}>
								<Text style={{
									color: "#344734",
									fontSize: `${(width * 10) / 100}px`,
									fontFamily:  "Uni Neue Heavy !important",
									fontStyle: "normal",
									fontWeight: "bolder",
								}}> {blog["title"]} </Text>

              	  <Text style={{
									color: "#344734",
									fontSize: `${(width * 6) / 100}px`,
									marginTop: `${(width * 1) / 100}px`,
									fontFamily:  "Uni Neue Heavy !important",
									fontStyle: "normal",
								}}> {blog["subHeadline"]} </Text>
								<Text style={{
									color: "#344734",
									fontSize: `${(width * 8) / 100}px`,
									fontFamily:  "Uni Neue Heavy !important",
									fontStyle: "normal",
									fontWeight: "bolder",
								}}> {blog["subHeader"]}  </Text>
								<Text style={{
									color: "black",
									fontSize: `${(width * 4) / 100}px`,
									fontFamily:  "Uni Neue Heavy !important",
									marginTop: "5%",
									fontStyle: "normal",
								}}> {blog["paragraphs"] && blog["paragraphs"]["1"]}  </Text>

								<Image
									alt={ALT_IMGS["faq"].altImg}
									{...imageParamsBody}
									src={`${imgBase}${blog["bodyImagePath"]}`}
									width="100%"
									style={{ objectFit: "cover", marginTop: "10%", marginBottom: "10%", }}
								/>
								<Box  style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
									marginTop: `${(width * 1) / 100}px`,
								}}>
									{
										blog["paragraphs"] && Object.keys(blog["paragraphs"]).map((key, index) => {
											if(key === "1") return null;
											return (
												<Text key={`${index}-paragraph`} style={{
													color: "black",
													fontSize: `${(width * 4) / 100}px`,
													marginTop: `${(width * 2) / 100}px`,
													marginBottom: `${(width * 2) / 100}px`,
													fontFamily:  "Uni Neue Heavy !important",
													fontStyle: "normal",
												}}> {blog["paragraphs"] && blog["paragraphs"][key]}  </Text>
											);
										})
									}
								</Box>
							</Flex>
						}
					</>
				)
			}

		</Box>
	);
};

export default Blog;


const BlogCard = ({ blog }) => {
	const { width } = useWindowSize();

	const params = {
		fontSize: `${(width * 1.5) / 100}px`,
		lineHeight: `${(width * 1.5) / 100}px`,
	};

	return (
		<Box
			style={{
				paddingLeft: `${(width * 4) / 100}px`,
				paddingRight: `${(width * 4) / 100}px`,
				marginBottom: `${(width * 4) / 100}px`,
				width: `${(width * 20) / 100}px`,
				height: `${(width * 35) / 100}px`,
				borderRadius: `${(width * 1) / 100}px`,
				backgroundColor: "#344734",
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				alignContent: "center",
			}}
		>
			<Box style={{
				backgroundColor: "white",
				width: `${(width * 18) / 100}px`,
				height: `${(width * 28) / 100}px`,
				borderRadius: `${(width * 1) / 100}px`,
				marginTop: `${(width * 1) / 100}px`,
				display: "flex",
				flexDirection: "column",
			}}>
				<Image
					alt={ALT_IMGS["faq"].altImg}
					src={`${imgBase}${blog["ImagePath"]}`}
					style={{ objectFit: "cover", width: "100%", borderRadius: `${(width * 2) / 100}px ${(width * 2) / 100}px 0 0` }}
				/>
				<Text style={{
					color: "#F1A637",
					paddingLeft: `${(width * .5) / 100}px`,
					paddingRight: `${(width * .5) / 100}px`,
				}} {...params} bold>
					{blog.title}
				</Text>
				<Text style={{
					color: "#344734",
					paddingLeft: `${(width * .5) / 100}px`,
					paddingRight: `${(width * .5) / 100}px`,
					fontSize: `${(width * 1.2) / 100}px`,
				}}>
					{blog["summary"]}
				</Text>
			</Box>
			<Button
				style={{
					backgroundColor: "#F1A637",
					color: "white",
					width: `${(width * 10) / 100}px`,
					height: `${(width * 5) / 100}px`,
					borderRadius: `${(width * .5) / 100}px`,
					marginTop: `${(width * 1) / 100}px`,
					marginBottom: `${(width * 1) / 100}px`,
					marginRight: `${(width * 8) / 100}px`,
					fontSize: `${(width * 1.3) / 100}px`,
					fontFamily:  "Uni Neue Heavy !important",
					fontStyle: "normal",
					fontWeight: "bolder",
				}}
			> READ MORE </Button>
		</Box>
	);
};


