/* eslint-disable react/style-prop-object */
import React from "react";
import { Box } from "noshery-ui";

import DesktopLanding from "../components/desktop/pages/Landing";
import MobileLanding from "../components/mobile/pages/Landing";
import useDeviceType from "../hooks/useDeviceType";

const Landing = () => {
	const { isDesktop, isTablet } = useDeviceType();

	return (
		<Box>
			{isDesktop || isTablet ? 
				<DesktopLanding /> : 
				<MobileLanding /> }
		</Box>
	);
};

export default Landing;





