import React from "react";
import styled from "styled-components";
import { Box, Flex, Text, Image, Button } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import Nav from "./Nav";
import { altLanders } from "../../constants/landers";
import { HolidayHours } from "./Banners";


const ORDER_SAN_CARLOS = "https://online.ordernoshery.com/noshery-san-carlos?menu=7e53e925-a316-47e3-a718-5907227147b1";
const ORDER_SAN_MATEO = "https://online.ordernoshery.com/noshery-san-mateo?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6";

const BackgroundImage = styled(Image)`
  min-height: ${(p) => p.size};
  min-width: ${(p) => p.size};
  z-index: -1; 
  border-radius: 50%;
  right: ${(p) => p.right};
  top: ${(p) => p.top};
  box-shadow: ${(p) => p.shadow};
  position: absolute;
  object-fit: cover;
`;

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
`;



const MobileBrandHeader = ({
	title,
	bgBrand,
	longDescription,
	menuRef,
	sidebarOpen,
	setSidebarOpen, 
	onScroll,
	headerOpen,
	isThanksGiving,
	brandAlts,
	to,
	subTitle,
	popup
}) => {
	const { width } = useWindowSize();

	// desktop image 60%, tablet & mobile 88%
	const imageSize = parseInt(((15) * width) / 10);
	// desktop top is 24.5% of image width, tablet & mobile 15%
	const imageTop = parseInt(((45) * width) / 100);
	// desktop right is 18.5% of image width, tablet & mobile 12%
	const imageRight = parseInt((25 * width)  / 100);



	return (
		<Box position="relative" overflowX="hidden">
			<Nav 
				sidebarOpen={sidebarOpen} 
				setSidebarOpen={setSidebarOpen} 
				scrolling={onScroll}
				headerOpen={headerOpen} 
			/>
			<Flex flexDirection="column" justifyContent='center' position="relative">
				{/* {popup && <HolidayHours />} */}
				<Box
					justifyContent="flex-end"
					borderRadius="50%"
					position="relative"
					height={`${1.5 * imageTop}px`}
					marginBottom={"6%"}
				>
					<BackgroundImage
						alt={altLanders[brandAlts]["header"]}
						src={bgBrand(imageSize, imageSize)}
						size={`${imageSize}px`}
						top={`-${imageTop}px`}
						right={`-${imageRight}px`}
						shadow={
							"1px 1px 4px 2px rgba(0, 0, 0, 0.25)"
						}
					/>
				</Box>
				<Content to={to} menuRef={menuRef} title={title} subTitle={subTitle} description={longDescription} isThanksGiving={isThanksGiving} />
			</Flex>
		</Box>
	);
};

export default MobileBrandHeader;

const Content = ({ title, description, menuRef, isThanksGiving, to, subTitle}) => {
	const { width } = useWindowSize();

	const styledHeadingParams = {
		fontSize: `${(width * 9) / 100}px`,
		lineHeight: `${(width * 9) / 100}px`,
		textAlign: "center",
		marginTop: "0px",
		marginBottom: "0px",
		fontFamily: "Uni Neue Heavy !important",
	};
	const styledTextParams = {
		fontSize: `${(width * 4) / 100}px`,
		lineHeight: `${(width * 5) / 100}px`,
		textAlign: "center"
	};
	const styledTextParams2 = {
		fontSize: `${(width * 5) / 100}px`,
		lineHeight: `${(width * 7) / 100}px`,
		textAlign: "center"
	};

	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1.3) / 100}px`;

	const buttonWidthPercentage = 80;
	const buttonHeightPercentage = 14;

	const minButtonWidth = (width * buttonWidthPercentage) / 100;
	const minButtonHeigth = (width * buttonHeightPercentage) / 100;

	const buttonParams = {
		fontSize: `${(width * 6) / 100}px`,
		lineHeight: `${(width * 9) / 100}px`,
		textAlign: "center",
		color: "white !important",
		height: `${minButtonHeigth}px !important`,
		width: `${minButtonWidth}px !important`,
		borderRadius: `${(width * 4) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const wrapperParams = {
		marginLeft: `${(width * 3.1) / 100}px`,
		marginTop: `${(width * 35) / 100}px`,
		flexDirection: "column",
		alignItems: "center"
	};

	const buttonWrapperParams = {
		marginTop: `${(width * 1.7) / 100}px`,
		marginBottom: `${(width * 1.7) / 100}px`,
	};

	return (
		<Flex {...wrapperParams}>
			<Flex flexDirection="column" alignItems='center'>
				{isThanksGiving ? 
					<>
						<Text as="h1" {...styledHeadingParams}> {title.split("Holiday")[0] } </Text> 
						<br />
						<Text as="h1" {...styledHeadingParams}> {"Holiday" + title.split("Holiday")[1]} </Text>
					</>
					:
					title === "Place Your Group Order Today!" ?
						<Text as="h1" {...styledHeadingParams}> {title.split("Order")[0]} <br /> Order Today! </Text> : 
						to === "locations" ? 
							<>
								<Text as="h1" {...styledHeadingParams}> {subTitle.split("in")[0]} in </Text>
								<Text as="h1" {...styledHeadingParams}>  {subTitle.split("in")[1]} </Text>
							</>
							: 
							<Text as="h1" {...styledHeadingParams}> {title} </Text> 
				}
			</Flex>
			<Box paddingBottom="5%">
				<Text fontWeight={500} {...styledTextParams}>
					{description}
				</Text>
			</Box>
			{title !== "Place Your Group Order Today!" && to !== "locations" && <Box onClick={() => {
				if(isThanksGiving) return;
				const menuref = menuRef.current; 
				menuref.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}}>
				<Text fontWeight={500} style={{cursor: isThanksGiving ? "cursor" : "pointer"}} {...styledTextParams2}>
					{isThanksGiving ? " Perfect for families or any group gathering this weekend." : "See full menu"}
				</Text>
			</Box>}
			<Flex
				flexDirection="column"
				justifyContent={"flex-start"}
				alignItems={"center"}
				{...buttonWrapperParams}
			>
				{
					to === "locations" ? 
						<>
							<Box pb={`${(width * 2.6) / 100}px`}>
								<StyledButton
									onClick={() => window.open("https://ordernoshery.us14.list-manage.com/subscribe?u=ad9b93383200c517614c36bea&id=838272c933")}
									isDesktop={false}
									{...buttonParams}
								>
									Sign up for updates
								</StyledButton>
							</Box>
						</>
						:
						<>
							<Box pb={`${(width * 2.6) / 100}px`}>
								<StyledButton
									onClick={() => window.open(title === "Place Your Group Order Today!" ? "https://catering.ordernoshery.com/?location=san-mateo" : ORDER_SAN_MATEO)}
									isDesktop={false}
									{...buttonParams}
								>
           Order San Mateo
								</StyledButton>
							</Box>
							<Box pb={`${(width * 2.6) / 100}px`}>
								<StyledButton
									onClick={() => window.open(title === "Place Your Group Order Today!" ? "https://catering.ordernoshery.com/?location=san-carlos" : ORDER_SAN_CARLOS)}
									isDesktop={false}
									{...buttonParams}
								>
           Order San Carlos 
								</StyledButton>
							</Box>
						</>
				}

			</Flex>
		</Flex>
	);
};

