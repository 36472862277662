import React from "react";
import { Box, Flex, Image } from "noshery-ui";
import { Link } from "react-router-dom";
import { landingBrands } from "../../constants/index";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";

const ArrowLeft = styled(Box)`
  width: 0; 
  height: 0; 
  border-top:  ${(p) => `${((p.w * 2) /100)}px solid transparent`};
  border-bottom: ${(p) => `${((p.w * 2) /100)}px solid transparent`};
  border-right: ${(p) => `${((p.w * 2) /100)}px solid #F1A637`};
`;

const ArrowRight = styled(Box)`
  width: 0; 
  height: 0; 
  border-top:  ${(p) => `${((p.w * 2) /100)}px solid transparent`};
  border-bottom: ${(p) => `${((p.w * 2) /100)}px solid transparent`};
  border-left: ${(p) => `${((p.w * 2) /100)}px solid #F1A637`}; 
`;

const BrandList = ({ name }) => {
	const { width } = useWindowSize();
	const logos = Object.keys(landingBrands).filter((l) => l !== name);

	const lW = parseInt((width * 18 ) / 100);
	const lH = parseInt((width * 18 ) / 100);

	const container = {
		padding: `${(width * 2.5) / 100}px 0px !important`,
		marginTop: `${(width * 8.3) / 100}px`,
		height: `${(width * 23) / 100}px`,
	};

	const logoParams = {
		width: `${lW}px !important`,
		height: `${lH}px !important`,
		border: "4px solid",
		borderColor: "#F1A637",
		borderRadius: "50%",
	};

	const buttonParams1 = {
		color: "#F1A637",
		position: "absolute", 
		left: `${(width * 2)/100}px`,
		top: `${(width * 6) / 100}px`,
		backgroundColor: "rgb(0,0,0,0)",
		border: "rgba(0,0,0,0)",
	};

	const buttonParams2 = {
		color: "#F1A637",
		position: "absolute", 
		right: `${(width * 2)/100}px`,
		top: `${(width * 6) / 100}px`,
		backgroundColor: "rgb(0,0,0,0)",
		border: "rgba(0,0,0,0)",

	};

	return (
		<Flex     
			backgroundColor="#FADDB6"
			flexDirection="row"
			justifyContent="center"
			{...container}>
			<CarouselProvider
				style={{
					width: "100%",
					position: "relative",
					height: `${(width * 20)/100}px`,
					paddingLeft: `${(width * 8)/100}px`,
					paddingRight: `${(width * 8)/100}px`
				}}
				naturalSlideWidth={100}
				visibleSlides={3}
				naturalSlideHeight={70}
				totalSlides={Object.keys(logos).length}>
				<ButtonBack style={buttonParams1}> 
					<ArrowLeft w={width} /> 
				</ButtonBack>
				<ButtonNext style={buttonParams2}> 
					<ArrowRight w={width} /> 
				</ButtonNext>
				<Slider> 
					{logos.map((l, i) => (
						<Slide key={`${l}-${i}`} index={i}>
							<Flex justifyContent="center" key={`#brandlist${i}`} mr={`${(width * 2.5) / 100}px`}>
								<LogoLink
									l={l}
									src={landingBrands[l].logo(lW, lH)}
									logoPrams={logoParams}
								/>
							</Flex>
						</Slide> 
					))}
				</Slider>
			</CarouselProvider>
		</Flex> 
	);
};

const LogoLink = ({ l, src, logoPrams }) => {
 
	return (
		<div>
			<Link
				key={landingBrands[l].to}
				to={`/${landingBrands[l].to}`}
				style={{ textDecoration: "none" }}
			>
				<Image src={src} {...logoPrams} alt={`${l} logo`} />
			</Link>
		</div>
	);
};

export default BrandList;

