const firebaseConfig = {
	apiKey: "AIzaSyBE6x78idEYtfwMXB3im5UZ7fClJn-BieM",
	authDomain: "byte-kitchen.firebaseapp.com",
	databaseURL: "https://byte-kitchen-default-rtdb.firebaseio.com",
	projectId: "byte-kitchen",
	storageBucket: "byte-kitchen.appspot.com",
	messagingSenderId: "1010681339903",
	appId: "1:1010681339903:web:52805e4394743e790fd1ca",
	measurementId: "G-GY6GP018KB",
};

export default firebaseConfig;
