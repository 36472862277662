import React from "react";
import { Box, Flex, Image, Text, Button } from "noshery-ui";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";
import "./quote.css";
import styled from "styled-components";
import { altLanders } from "../../constants/landers";

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
  background-color: #344734;
`;

const ORDER_SAN_CARLOS = "https://online.ordernoshery.com/noshery-san-carlos?menu=7e53e925-a316-47e3-a718-5907227147b1";
const ORDER_SAN_MATEO = "https://online.ordernoshery.com/noshery-san-mateo?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6";
const ORDER_NOSHERY = "https://online.ordernoshery.com/";

const TopDishes = ({
	nameTitle,
	topDishes1,
	topDishesPadding,
	brandAlts, 
	title
}) => {
	const { isDesktop, isTablet } = useDeviceType();
	const { width } = useWindowSize();

	const boxParams = {
		marginTop: `${(width * 4) / 100}px`,
	};

	const titleParams1 = {
		fontSize: `${(width * 2.34)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
	};
	const titleParams2 = {
		fontSize: `${(width * 3.9)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
	};
	const titleParams3 = {
		fontSize: `${(width * 2.34)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
	};

	const imgW = parseInt((width * 21.14) / 100);
	const imgH = parseInt((width * 21.14) / 100);

	const TwoRowParams = {
		alignItems: "center",
		justifyContent: "center"
	};

	const buttonTextParams = isDesktop
		? {
			fontSize: `${(width * 2.3) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,
		}
		: {
			fontSize: `${(width * 4) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,
		};

	const boxShadow1 = `${(width * 0.1) / 100}px`;
	const boxShadow2 = `${(width * 1.0) / 100}px`;

	const buttonWidthPercentage = isDesktop || isTablet ? 31.4 : 28;
	const minButtonWidth = (width * buttonWidthPercentage) / 100;
	const buttonRightMargin = (width * 1.9) / 100;

	const buttonParams = {
		...buttonTextParams,
		minWidth: `${minButtonWidth + buttonRightMargin}px`,
		marginBottom: `${(width * 100) / 100}px`,
		borderRadius: `${(width * 0.72) / 100}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const buttonWrapperParams = {
		alignItems: "center",
		justifyContent: "center",
		paddingTop: `${(width * 2.6) / 100}px`,
	};

	return (
		<Box textAlign="center" {...boxParams}>
			<Box {...titleParams1} mb={`${(width * 1) / 100}px !important`}>
				<Text bold>PERFECT FOR MEAT-LOVERS, VEGETARIANS, AND VEGANS </Text>
			</Box>
			<Box {...titleParams2} mb={`${(width * 1) / 100}px !important`}>
				<Text bold>Popular Dishes from {nameTitle} </Text>
			</Box>
			<Box {...titleParams3} mb={`${(width * 1) / 100}px !important`}>
				<Text>See Noshery’s full {nameTitle === "Noshery Food Hall" ? "" : nameTitle} menu 
					<a href={ORDER_NOSHERY} style={{textDecoration: "none"}} target="_blank" rel="noopener noreferrer"> here </a>
				</Text>
			</Box>
			<Line />
			<Flex flexDirection="column" justifyContent="center">
				<Flex {...TwoRowParams}> 
					{
						topDishes1.map((item, i) => {
							const {title, imgSrc, description} = item;  
							return <ItemCard 
								key={`${i}-itemCards`} 
								i={i+1} 
								brandAlts={brandAlts}
								title={title} 
								topDishesPadding={topDishesPadding}
								img={imgSrc(imgW, imgH)} 
								description={description} />; 
						})
					}
				</Flex>
			</Flex> 
			<Flex
				justifyContent={"center"}
				{...buttonWrapperParams}
			>
				<Box paddingRight={`${(width * 2) / 100}px`}>
					<StyledButton
						isDesktop={isDesktop}
						onClick={() => window.open(title === "Place Your Group Order Today!" ? "https://catering.ordernoshery.com/?location=san-mateo" : ORDER_SAN_MATEO)}
						{...buttonParams}
					>
            Order San Mateo
					</StyledButton>
				</Box>
				<Box>     
					<StyledButton
						isDesktop={isDesktop}
						onClick={() => window.open(title === "Place Your Group Order Today!" ? "https://catering.ordernoshery.com/?location=san-carlos" : ORDER_SAN_CARLOS)}
						{...buttonParams}
					>
              Order San Carlos
					</StyledButton>  
				</Box>
			</Flex>
		</Box>
	);
};

export default TopDishes;

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 54) / 100}px`,
		borderBottom: `${`${(width * 0.34) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};

const ItemCard = ({title, description, img, i, topDishesPadding, brandAlts}) => {
	const { width } = useWindowSize();
	const wrapperParams = {
		width: `${(width * 21.14) / 100}px !important`,
		height: `${(width * 10.5) / 100}px !important`,
		alignItems: "center",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		paddingY: `${(width * topDishesPadding) / 100}px `,
	};
  
	const textParams2 = {
		color: "black",
		fontSize: `${(width * 1.5) / 100}px !important`,
		fontWeight: `${(width * 15.6) / 100} !important`,
		paddingTop: `${(width * 0.5) / 100}px !important`,
	};
    
	const textParams = {
		color: "black",
		fontSize: `${(width * 2) / 100}px !important`,
	};
    
	const imgParams = {
		width: `${(width * 21.14) / 100}px `,
		height: `${(width * 21.14) / 100}px `,
		borderRadius: `${(width * 2.7) / 100}px`,
		border: `${(width * .7) / 100}px solid`,
		borderColor: "#F1A638",
		objectFit: ""
	};

	const cardParams = {
		flexDirection: "column",
		alignItems: "center", 
		justifyContent: "center", 
		paddingX: `${(width * 3.5) / 100}px`,
		paddingY: `${(width * 1.5) / 100}px`,
	};


	return (
		<Flex {...cardParams} >
			<Image {...imgParams} alt={altLanders[brandAlts][`topDish${i}`]} src={img} /> 
			<Box {...wrapperParams}>
				<Text bold {...textParams}>
					{title === "BBQ Bacon MeltBurger" ? "BBQ Bacon" : title}
				</Text>
				{title === "BBQ Bacon MeltBurger" && <Text bold {...textParams}>
            Meltburger
				</Text>}
				<Text {...textParams2}>
					{description}
				</Text>
			</Box>
		</Flex>
	);
};
