import React from "react";
import { Box, Flex, Image, Text } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import "./quote.css";

const perks = [
	{
		title: "Dining Options", 
		description: "We offer delivery, pickup, and casual dine-in. Start your online order for San Mateo here and San Carlos here.",
		img: "https://res.cloudinary.com/dwbvlpae2/image/upload/v1663221911/Perks/options_joijmk.svg",
	},
	{
		title: "Family Friendly", 
		description: "Kids love our grilled cheese sandwiches, quesadillas, french fries, and cookies. Parents love that they can get flavors and dishes more interesting than what their kids are eating.",
		img: "https://res.cloudinary.com/dwbvlpae2/image/upload/v1663221911/Perks/family_eudesh.svg",
	},
	{
		title: "Gluten-Free Options", 
		description: "Whether you desire a gluten-free bun for your delicious MeltBurger, a gluten-free tortilla for a Little Chihuahua burrito, or healthy wheat-free salad options, you'll find it at Noshery.",
		img: "https://res.cloudinary.com/dwbvlpae2/image/upload/v1663221911/Perks/gf_yenwxn.svg",
	},
	{
		title: "Vegetarian & Vegan", 
		description: "Impossible burger patties, sweet potato fries, crispy tofu banh mi sandwiches, Indian curries, Mediterranean plates, dairy-free ice cream, and more. We've got vegan & vegetarian cravings covered!",
		img: "https://res.cloudinary.com/dwbvlpae2/image/upload/v1663221911/Perks/vegan_rdfpfh.svg",
	}
];

const LanderPerks = ({paddingTopBranList}) => {
	const { width } = useWindowSize();

	const boxParams = {
		marginTop: `${(width * paddingTopBranList) / 100}px`,
	};

	const titleParams1 = {
		fontSize: `${(width * 2)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
		paddingTop: "2%"
	};

	const titleParams2 = {
		fontSize: `${(width * 3)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
	};

	const ThreeRowParams = {
		flexDirection: "row"
	};

	const rows = {
		flexDirection: "column",
	};

	return (
		<Box height={`${(width * 61.5) / 100}px !important`} textAlign="center" bg="#FADDB6" {...boxParams}>
			<Box {...titleParams1} paddingTop={`${(width * 3) / 100}px !important`} mb={`${(width * 1) / 100}px !important`}>
				<Text bold> CUSTOMER PERKS </Text>
			</Box>
			<Box {...titleParams2} mb={`${(width * 1) / 100}px !important`}>
				<Text bold> Kids? Dietary Preferences? </Text>
			</Box>
			<Box {...titleParams2} mb={`${(width * 1) / 100}px !important`}>
				<Text bold> We love to make everyone happy </Text>
			</Box>

			<Line />
			<Flex height={"100% !important"} paddingTop={"1%"} paddingX="4.2%" justifyContent="center">
				<Flex {...ThreeRowParams}> 
					{
						perks.map((item, i) => {
							const {title, description, img} = item; 
							return (
								<Flex key={`${title}-${i}`} {...rows}> 
									<ItemCard title={title} description={description} img={img} />             
								</Flex>
							);
						})
					}
				</Flex>
			</Flex> 
		</Box>
	);
};

export default LanderPerks;

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 60) / 100}px`,
		borderBottom: `${`${(width * 0.34) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};

const ItemCard = ({title, description, img}) => {
	const { width } = useWindowSize();
	const wrapperParams = {
		width: `${(width * 21.5) / 100}px !important`,
		height: `${(width * 40.5) / 100}px !important`,
		borderRadius: `${(width * 2) / 100}px !important`,
		backgroundColor: "#8B6017",
	};

	const textParams = {
		color: "white",
		fontSize: `${(width * 2.5) / 100}px !important`,
		paddingX: `${(width * 1) / 100}px`,
	};

	const textParams2 = {
		color: "white",
		textAlign: "center !important",
		fontSize: `${(width * 1.5) / 100}px !important`,
		fontWeight: `${(width * 10) / 100}`,
		padding: `${(width * 1) / 100}px`,
	};

	const cardParams = {
		paddingX: `${(width * 1.2) / 100}px !important`,
	};

	const logoPrams = {
		height: `${(width * 9.7) / 100}px`,
		width: `${(width * 9.7) / 100}px`,
		paddingTop: `${(width * 2) / 100}px`,
	};
  
	return (
		<Flex {...cardParams} >
			<Box {...wrapperParams}>
				<Image {...logoPrams} src={img} /> 
				<Box paddingTop="10%"> 
					<Text bold {...textParams}>
						{title.includes("&") ? title.split("&")[0] + " &": title.split(" ")[0]}
					</Text>
					<Text bold {...textParams}>
						{title.includes("&") ? title.split("&")[1]: title.split(" ")[1]}
					</Text>
				</Box>
				<Box>
					<Text {...textParams2}>
						{description}
					</Text>
				</Box>
			</Box>
		</Flex>
	);
};
