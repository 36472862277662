import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import { Box } from "noshery-ui";

import MobileHeader from "../Header";
import MobileWhatWeDo from "../WhatWeDo"; 
import MobileFaq from "../Faq"; 
import MobileLocations from "../Locations";
import MobileBrandPartners from "../BrandPartners";
import MobileSidebar from "../Sidebar";
import MobileSocials from "../Socials"; 
import { useSwipeable } from "react-swipeable";

const AnimatedBox = animated(Box); 

const Landing = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false); 
	const [headerOpen, setHeaderOpen] = useState(true); 
	const [offset, setOffset] = useState(0);

	const { left } = useSpring({
		config: { duration: 200},
		from: { left: "0%"},
		left: sidebarOpen ? "60%" : "0%",
	});

	React.useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });

		return () => window.removeEventListener("scroll", onScroll);
	}, []);
 

	return (
		<Box overflow="hidden">
			<MobileSidebar isLanding setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
			<Box overflow="hidden">
				<AnimatedBox style={{ left: left, position: "relative"}}> 
					<MobileHeader  
						sidebarOpen={sidebarOpen} 
						setSidebarOpen={setSidebarOpen}
						headerOpen={headerOpen} 
						onScroll={offset}
					/>
					
					<MobileBrandPartners />  
				</AnimatedBox>
			</Box> 
		</Box>
	);
};

export default Landing;
