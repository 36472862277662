import Landing from "./pages/Landing";
import BrandPage from "./pages/BrandPage";
import Catering from "./pages/Catering"; 
import ContactUs from "./pages/ContactUs";
import Rewards from "./pages/Loyalty";
import LandersBrand from "./pages/Landers";
import Careers from "./pages/Careers";
import DraperUniversity from "./pages/DraperUniversity";
import PrivacyPolicyPage from "./pages/privacyPolicy";
import Cookie_Policy from "./pages/cookiesPolicy";
import ThanksgivingPage from "./pages/ThanksGiving";
import TermsAndConditions from "./pages/termsAndConditions";
import Blog from "./pages/Blog";
import { landingBrands } from "./constants";
import { LanderBrands, THANKSGIVING } from "./constants/landers";
import Revere from "./pages/Revere";
import SingleBlog from "./pages/SingleBlog";
import PressRelease from "./pages/PressRelease";
import Admin from "./pages/Admin";

const pages = () => {
	const brands = Object.values(landingBrands).map((l) => ({
		to: l.to,
		path: l.to,
		component: (p) => <BrandPage {...p} brand={l} />,
	}));

	const brandLanders = Object.values(LanderBrands).map((b) => ({
		to: b.to === "locations" ? "/coming-soon" : b.to, 
		path: b.to === "locations" ? "/coming-soon" : b.to, 
		component: (p) => <LandersBrand {...b} brand={b} /> 
	}));

	const thanksgiving = {
		to: THANKSGIVING.to, 
		path: THANKSGIVING.to,
		component: (p) => <ThanksgivingPage {...THANKSGIVING} brand={THANKSGIVING} />
	};

	const PrivacyPolicy = {
		to: "/privacy-policy",
		path: "/privacy-policy",
		component: () => <PrivacyPolicyPage />,
	};

	const TermsConditions = {
		to: "/terms-conditions",
		path: "/terms-conditions",
		component: () => <TermsAndConditions />,
	};

	const CookiePolicy = {
		to: "/cookie-policy",
		path: "/cookie-policy",
		component: () => <Cookie_Policy />,
	};

	const BlogPage = {
		to: "/blog",
		path: "/blog",
		component: () => <Blog />,
	};

	const SingleBlogPage = {
		to: "/blog/:id",
		path: "/blog/:id",
		component: () => <SingleBlog />,
	};

	const PressReleasePage = {
		to: "/press-release",
		path: "/press-release",
		component: () => <PressRelease />,
	};

	const AdminPage = {
		to: "/admin",
		path: "/admin",
		component: () => <Admin />,
	};

	return [
		{
			to: "/",
			path: "/",
			component: (p) => <Landing {...p} />,
		},

		// {
		// 	to: "/contact-us",
		// 	path: "/contact-us",
		// 	component: (p) => <ContactUs {...p} />,
		// },
		// ...brands,
		// {
		// 	to: "/catering",
		// 	path: "/catering",
		// 	component: (p) => <Catering {...p} /> 
		// },
		// {
		// 	to: "/careers",
		// 	path: "/careers",
		// 	component: (p) => <Careers {...p} /> 
		// }
		// ,
		// {
		// 	to: "/rewards",
		// 	path: "/rewards",
		// 	component: (p) => <Rewards {...p} /> 
		// },
		// {
		// 	to: "/rewards",
		// 	path: "/rewards",
		// 	component: (p) => <Rewards {...p} /> 
		// },
		// {
		// 	to: "/draper-university",
		// 	path: "/draper-university",
		// 	component: () => <DraperUniversity /> 
		// },
		// {
		// 	to: "/revere",
		// 	path: "/revere",
		// 	component: () => <Revere /> 
		// },
		// ...brandLanders,
		// thanksgiving,
		// PrivacyPolicy,
		// TermsConditions,
		// CookiePolicy,
		// BlogPage,
		// SingleBlogPage,
		// PressReleasePage,
		// AdminPage
	];
};

export default pages;
