import React from "react";
import { Box, Flex, Image, Text, Button } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import { altLanders } from "../../constants/landers";

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
  background-color: #344734;
`;

const ORDER_SAN_CARLOS = "https://online.ordernoshery.com/noshery-san-carlos?menu=7e53e925-a316-47e3-a718-5907227147b1";
const ORDER_SAN_MATEO = "https://online.ordernoshery.com/noshery-san-mateo?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6";
const ORDER_NOSHERY = "https://online.ordernoshery.com/";

const TopDishes = ({
	nameTitle,
	topDishes1,
	mobileTopDishes,
	brandAlts,
	title
}) => {
	const { width } = useWindowSize();

	const boxParams = {
		marginTop: `${(width * 1) / 100}px`,
	};

	const titleParams1 = {
		fontSize: `${(width * 5)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
	};
	const titleParams2 = {
		fontSize: `${(width * 7.9)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
	};
	const titleParams3 = {
		fontSize: `${(width * 6)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
	};

	const imgW = parseInt((width * 38) / 100);
	const imgH = parseInt((width * 38) / 100);

	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1.3) / 100}px`;

	const buttonWidthPercentage = 70;
	const buttonHeightPercentage = 14;

	const minButtonWidth = (width * buttonWidthPercentage) / 100;
  
	const minButtonHeigth = (width * buttonHeightPercentage) / 100;

	const buttonParams = {
		fontSize: `${(width * 8) / 100}px`,
		lineHeight: `${(width * 9) / 100}px`,
		textAlign: "center",
		color: "white !important",
		height: `${minButtonHeigth}px !important`,
		width: `${minButtonWidth}px !important`,
		borderRadius: `${(width * 4) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const buttonWrapperParams = {
		marginTop: `${(width * 1.7) / 100}px`,
		marginBottom: `${(width * 1.7) / 100}px`,
	};

	return (
		<Box textAlign="center" {...boxParams}>
			<Box {...titleParams1} mb={`${(width * 2.8) / 100}px !important`}>
				<Text bold>PERFECT FOR MEAT-LOVERS, VEGETARIANS, AND VEGANS  </Text>
			</Box>
			<Box {...titleParams2} mb={`${(width * 2.8) / 100}px !important`}>
				<Text bold>Popular Dishes from <br />  {nameTitle}   </Text>
			</Box>
			<Box {...titleParams3} mb={`${(width * 2.8) / 100}px !important`}>
				<Text bold>See Noshery’s full menu 
					<a href={ORDER_NOSHERY} style={{textDecoration: "none"}} target="_blank" rel="noopener noreferrer"> here </a>
				</Text>
			</Box>
			<Line />
			<Flex flexDirection="column" justifyContent="center">
				{
					topDishes1.map((item, i) => {
						const {title, imgSrc, description} = item;  
						return <ItemCard 
							key={description} 
							i={i+1} 
							brandAlts={brandAlts}
							mobileTopDishes={mobileTopDishes}
							title={title} 
							img={imgSrc(imgW, imgH)} 
							description={description} />; 
					})
				}
			</Flex> 
			<Flex
				flexDirection="column"
				justifyContent={"flex-start"}
				alignItems={"center"}
				{...buttonWrapperParams}
			>
				<Box pb={`${(width * 2.6) / 100}px`}>
					<StyledButton
						onClick={() => window.open(title === "Place Your Group Order Today!" ? "https://catering.ordernoshery.com/" : ORDER_SAN_MATEO)}
						isDesktop={false}
						{...buttonParams}
					>
            Order San Mateo
					</StyledButton>
				</Box>
				<Box pb={`${(width * 2.6) / 100}px`}>
					<StyledButton
						onClick={() => window.open(title === "Place Your Group Order Today!" ? "https://catering.ordernoshery.com/" : ORDER_SAN_CARLOS)}
						isDesktop={false}
						{...buttonParams}
					>
            Order San Carlos
					</StyledButton>
				</Box>
			</Flex>
		</Box>
	);
};

export default TopDishes;

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 80) / 100}px`,
		borderBottom: `${`${(width * 0.5) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};

const ItemCard = ({title, description, img, i, mobileTopDishes, brandAlts}) => {
	const { width } = useWindowSize();
	const wrapperParams = {
		width: `${(width * 65) / 100}px !important`,
		height: `${(width * 50) / 100}px !important`,
		alignItems: "center",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		paddingY: `${(width * mobileTopDishes) / 100}px `,
	};
  
	const textParams2 = {
		color: "black",
		fontSize: `${(width *  5) / 100}px !important`,
		fontWeight: `${(width * 15.6) / 100} !important`,
		width: `${(width * 60) / 100}px !important`,
	};
    
	const textParams = {
		color: "black",
		fontSize: `${(width * 9) / 100}px !important`,
		marginBottom: `${(width * 4) / 100}px !important`,
	};
    
	const imgParams = {
		width: `${(width * 51) / 100}px `,
		height: `${(width * 51) / 100}px `,
		borderRadius: `${(width * 2.7) / 100}px`,
		border: `${(width * .7) / 100}px solid`,
		borderColor: "#F1A638",
		objectFit: "cover"
	};

	const cardParams = {
		flexDirection: "column",
		alignItems: "center", 
		justifyContent: "center", 
		paddingX: `${(width * 3.5) / 100}px`,
		paddingY: `${(width * 1.5) / 100}px`,
	};


	return (
		<Flex {...cardParams} >
			<Image {...imgParams} alt={altLanders[brandAlts][`topDish${i}`]} src={img} /> 
			<Box {...wrapperParams}>
				<Text bold {...textParams}>
					{title}
				</Text>
				<Text {...textParams2}>
					{description}
				</Text>
			</Box>
		</Flex>
	);
};
