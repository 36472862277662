import React from "react";
import { Box, Flex, Text, Button } from "noshery-ui";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import useDeviceType from "../../hooks/useDeviceType"; 
import "./quote.css";

const ORDER_SAN_CARLOS = "https://online.ordernoshery.com/noshery-san-carlos?menu=7e53e925-a316-47e3-a718-5907227147b1";
const ORDER_SAN_MATEO = "https://online.ordernoshery.com/noshery-san-mateo?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6";

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
  background-color: #344734;
`;

const LanderMenu = ({
	menuItems,
	menuTitle,
	menuRef,
	menuItemHeight
}) => {
	const { width } = useWindowSize();
	const {isDesktop, isTablet} = useDeviceType(); 
	const boxParams = {
		marginTop: `${(width * 4) / 100}px`,
	};

	const titleParams = {
		fontSize: `${(width * 3)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
	};

	const ThreeRowParams = {
		flexDirection: "row"
	};

	const rows = {
		flexDirection: "column"
	};

	const buttonWidthPercentage = isDesktop || isTablet ? 30 : 28;
	const minButtonWidth = (width * buttonWidthPercentage) / 100;
	const buttonRightMargin = (width * 1.9) / 100;

	const boxShadow1 = `${(width * 0.1) / 100}px`;
	const boxShadow2 = `${(width * 1.0) / 100}px`;

	const buttonTextParams = isDesktop
		? {
			fontSize: `${(width * 2.3) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,
		}
		: {
			fontSize: `${(width * 4) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,

		};

	const buttonParams = {
		...buttonTextParams,
		minWidth: `${minButtonWidth + buttonRightMargin}px`,
		borderRadius: `${(width * 0.72) / 100}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const buttonWrapperParams = {
		alignItems: "center",
		justifyContent: "center",
	};

	if(!menuTitle) return null;
  
	return (
		<Box ref={menuRef} id="#landers-menu" height={`${(width * menuItemHeight) / 100}px !important`} textAlign="center" {...boxParams}>
			<Box {...titleParams} mb={`${(width * 1) / 100}px !important`}>
				<Text bold> {menuTitle} </Text>
			</Box>
			<Line />
			<Flex height={"90% !important"} paddingTop={"1%"}  justifyContent="center">
				<Flex {...ThreeRowParams}> 
					<Flex {...rows}> 
						{
							Object.keys(menuItems[1]).map((item, i) => {
								return <ItemCard key={item} title={item} subItems={menuItems[1][item]} i={i+1} />; 
							})
						}
					</Flex>
					<Flex {...rows}> 
						{
							Object.keys(menuItems[2]).map((item, i) => {
								return <ItemCard key={item} title={item} subItems={menuItems[2][item]} i={i+1} />; 
							})
						}
					</Flex>
					<Flex {...rows}> 
						{
							Object.keys(menuItems[3]).map((item, i) => {
								return <ItemCard key={item} title={item} subItems={menuItems[3][item]} i={i+1} />; 
							})
						}
					</Flex>
				</Flex>
			</Flex> 
			<Flex
				justifyContent={"center"}
				{...buttonWrapperParams}
			>
				<Box paddingRight={`${(width * 2) / 100}px`}>
					<StyledButton
						isDesktop={isDesktop}
						onClick={() => window.open(ORDER_SAN_MATEO)}
						{...buttonParams}
					>
            Order San Mateo
					</StyledButton>
				</Box>
				<Box>     
					<StyledButton
						isDesktop={isDesktop}
						onClick={() => window.open(ORDER_SAN_CARLOS)}
						{...buttonParams}
					>
              Order San Carlos
					</StyledButton>  
				</Box>
			</Flex>
		</Box>
	);
};

export default LanderMenu;

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 60) / 100}px`,
		borderBottom: `${`${(width * 0.34) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};

const ItemCard = ({subItems, title}) => {
	const { width } = useWindowSize();
	const wrapperParams = {
		width: `${(width * 33) / 100}px !important`,
		height: "100% !important",
		paddingX: "4%",
	};

	const textParams = {
		color: "black",
		textAlign: "left !important",
		fontSize: `${(width * 3.75) / 100}px !important`,
	};

	const textParams2 = {
		color: "black",
		textAlign: "left !important",
		fontSize: `${(width * 2) / 100}px !important`,
		fontWeight: `${(width * 50) / 100} !important`,
		lineHeight:  `${(width * 3) / 100}px`
	};

	const textParams3 = {
		color: "black",
		textAlign: "left !important",
		fontSize: `${(width * 1.5) / 100}px !important`,
		fontWeight: `${(width * 10) / 100}`,
	};

	const cardParams = {
	};

  
	return (
		<Flex {...cardParams} >
			<Box {...wrapperParams}>
				<Text bold {...textParams}>
					{title}
				</Text>
				{
					subItems.map((item) => {
						const {description, name} = item; 
						return (
							<Box key={`${name}-itemcard`} mb="3%">
								<Text {...textParams2}>
									{name}
								</Text>
								<Text {...textParams3}>
									{description}
								</Text>
							</Box>
						);
					})
				}
			</Box>
		</Flex>
	);
};
