export const whatwedo =
  "At Noshery, we partner with popular restaurants across the country to bring their fabulous food directly to you. Choose from a variety of craveable options made famous by our beloved partners -- it will be prepared fresh, for pick-up or delivery, in a kitchen near you.";

export const DraperUniversity = 
  "We’re all about partnerships at Noshery, that’s why we’re excited to work with Draper University. As a startup ourselves, we know the long nights and hard work that goes into building something from the ground up, and the fuel it takes to make it happen. If you’d like to learn more about Byte Kitchen, our startup that keeps Noshery running, read more here.";

export const SAN_MATEO_ORDER_URL = "https://online.ordernoshery.com/noshery-san-mateo?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6"; 

export const bytekitchenAboutUs = "https://www.bytekitchen.io/about-us";

export const mixandmatch =
  "Deciding what to order can be hard. Dietary restrictions, cuisine preferences, and even your mood can turn ordering for a group into an ordeal. At Noshery, our wide variety of quality brands makes it easy for everyone to get just what they want!";

export const whynoshery1 =
  "At Noshery, we firmly believe there is only one way to build a sustainable business model in the food delivery industry – be a true partner to restaurants. We don’t pretend to have all the answers. But we do have an obsessive focus to figure them out and we want every single person’s input on the team to do so.";

export const whynoshery2 =
  "If you want to be part of a dynamic team that is transforming the way restaurants expand, please reach out to us. ";

export const whoarewe = [
	{
		title: "customer obsessed",
		text:
      "we serve two customers – our restaurant partners and the end consumers. Every decision we take is focused on improving experience for both",
	},
	{
		title: "hungry",
		text:
      "to build a solution which is a true partnership for restaurants; to create the kitchen of the future; to bring the best food to our customers every day",
	},
	{
		title: "colloborative",
		text:
      "we have no time for office politics, everyone takes out the trash, all opinions are not only welcome but highly encouraged",
	},
];

export const jobs = [
	// {
	// 	position: "Host/Hostess",
	// 	positionValue: "host",
	// 	locationValue: "SanCarlos",
	// 	location: "San Carlos, CA",
	// 	link:
	//   "https://www.workstream.us/j/84bc5aed/byte-kitchen/san-carlos-36527/host-hostess-efe4e910",
	// },
	// {
	// 	position: "Noshery Kitchen Team Member",
	// 	positionValue: "kitchenTM",
	// 	locationValue: "SanCarlos",
	// 	location: "San Carlos, CA",
	// 	link:
	//   "https://www.workstream.us/j/84bc5aed/byte-kitchen/san-carlos-36527/noshery-kitchen-team-member-6dc7dc8b",
	// },
	{
		position: "Host/Hostess",
		locationValue: "SanMateo",
		positionValue: "host",
		location: "San Mateo, CA",
		link:
      "https://www.workstream.us/j/84bc5aed/byte-kitchen/san-mateo-19422/host-hostess-9512f53c",
	},
	{
		position: "Noshery Kitchen Team Member",
		locationValue: "SanMateo",
		positionValue: "kitchenTM",
		location: "San Mateo, CA",
		link:
      "https://www.workstream.us/j/84bc5aed/byte-kitchen/san-mateo-19422/noshery-kitchen-team-member-742ef1ac",
	},
];

export const jobLocationOptions = [
	{ label: "San Mateo, CA", value: "SanMateo" },
	// { label: "San Carlos, CA", value: "SanCarlos" },
];

export const jobCategoryOptions = [
	{ label: "Kitchen Member", value: "kitchenTM" },
	{ label: "Host", value: "host" },
];

export const landingBrands = {
	themelt: {
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/themelt_ityvv4.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/brands/themeltbg_gboba4.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/er00cstijlmbrpbdghy5.webp`,
		landerBg: (w=0, h=0) => 
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landers/themelt_snrykq.webp`,
		altImg:
      "Grilled cheese sandwich being pulled apart featuring stretched melted cheese between each slice. The bread is golden brown and toasted, and the cheese is a blend of yellow and white cheeses.",
		altLogo: "",
		pageImgAlts: {
			header:
        "Grilled cheese sandwich being pulled apart featuring stretched melted cheese between each slice. The bread is golden brown and toasted, and the cheese is a blend of yellow and white cheeses",
			thefood: [
				"Three halves of different grilled cheese or melt sandwiches with various fillings like sauteed onions and veggies, melty cheeses, and grilled steak",
				"Two cheeseburgers stacked upon each other with a toothpick holding them in place. Both burgers have oozing melted cheese and other toppings. In the foreground are scattered french fries",
				"Close up of creamy melted macaroni and cheese. The mac and cheese is garnished with diced crispy bacon and shredded cheese",
				"Two sliced grilled cheese sandwiches placed on top of each other, each with melted oozing cheese. Around the sandwiches are other sides including french fries and mac and cheese",
			],
		},
		name: "themelt",
		menuId: "",
		to: "https://www.themelt.com/",
		finalImage: "/Noshery Brand Cards Closing 2-02.png",
		title: "The Melt",
		description: "",
		shortDescription: "",
		longDescription:
      "Started in San Francisco, The Melt’s menu features handcrafted Angus-Wagyu MeltBurgers, melty grilled cheeses, creamy mac 'n' cheese, crispy fries and wholesome soup.",
		quote: "",
		author: "",
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/themeltfood1_permom.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/themeltfood2_tdq8bh.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/themeltfood3_izsrvu.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/TM_4_io4wlh.webp`,
		food5: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/themeltfood5_dg1dwc.webp`,
	},
	// roosterAndRice: {
	// 	logo: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/vsetiocxuheopwlslv6j.webp`,
	// 	bg: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/RoosterAndRice/x4hhu0ozirnq7afgfty8.webp`,
	// 	bgBrand: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/RoosterAndRice/x4hhu0ozirnq7afgfty8.webp`,
	// 	landerBg: (w=0, h=0) => 
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/RoosterAndRice/x4hhu0ozirnq7afgfty8.webp`,
	// 	altImg:
	//   "",
	// 	altLogo: "",
	// 	pageImgAlts: {
	// 		header:
	//     "Grilled cheese sandwich being pulled apart featuring stretched melted cheese between each slice. The bread is golden brown and toasted, and the cheese is a blend of yellow and white cheeses",
	// 		thefood: [
	// 			"Three halves of different grilled cheese or melt sandwiches with various fillings like sauteed onions and veggies, melty cheeses, and grilled steak",
	// 			"Two cheeseburgers stacked upon each other with a toothpick holding them in place. Both burgers have oozing melted cheese and other toppings. In the foreground are scattered french fries",
	// 			"Close up of creamy melted macaroni and cheese. The mac and cheese is garnished with diced crispy bacon and shredded cheese",
	// 			"Two sliced grilled cheese sandwiches placed on top of each other, each with melted oozing cheese. Around the sandwiches are other sides including french fries and mac and cheese",
	// 		],
	// 	},
	// 	name: "roosterAndRice",
	// 	menuId: "",
	// 	to: "rooster-and-rice",
	// 	title: "Rooster and Rice",
	// 	description: "",
	// 	shortDescription: "",
	// 	longDescription:
	//   "Rooster & Rice, a fast-casual concept specializing in Organic Thai-Style Chicken Rice, was created by lifelong restaurateurs Bryan Lew and Tommy Charoen. Seeking to offer a simple, healthy, and clean food experience based on Asian recipes, they partnered with Aroi Hospitality Group (AHG) in 2019, which includes former founders of the food delivery app, Caviar, to expand the brand nationwide.",
	// 	quote: "",
	// 	author: "",
	// 	food1: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/RoosterAndRice/food/oinnohlu6l6q9liadsrz.webp`,
	// 	food2: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill/brands/RoosterAndRice/food/qtjsd54cmdo4npnzlc39.webp`,
	// 	food3: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/RoosterAndRice/food/wsak5highmb62drzgjsv.webp`,
	// 	food4: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/RoosterAndRice/food/wbx6io2efuulhjg0zclx.webp`,

	// 	food5: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto//brands/RoosterAndRice/food/bpamiexpmnhfdir0oqwz.webp`,
	// },
	sushirrito: {
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/wrchtbzrzqjpuy5j7bcv.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sushirrito/i4tfwejg1c12za0ehxar.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sushirrito/i4tfwejg1c12za0ehxar.webp`,
		landerBg: (w=0, h=0) => 
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sushirrito/i4tfwejg1c12za0ehxar.webp`,
		altImg:
      "Grilled cheese sandwich being pulled apart featuring stretched melted cheese between each slice. The bread is golden brown and toasted, and the cheese is a blend of yellow and white cheeses.",
		altLogo: "",
		pageImgAlts: {
			header:
        "Grilled cheese sandwich being pulled apart featuring stretched melted cheese between each slice. The bread is golden brown and toasted, and the cheese is a blend of yellow and white cheeses",
			thefood: [
				"Three halves of different grilled cheese or melt sandwiches with various fillings like sauteed onions and veggies, melty cheeses, and grilled steak",
				"Two cheeseburgers stacked upon each other with a toothpick holding them in place. Both burgers have oozing melted cheese and other toppings. In the foreground are scattered french fries",
				"Close up of creamy melted macaroni and cheese. The mac and cheese is garnished with diced crispy bacon and shredded cheese",
				"Two sliced grilled cheese sandwiches placed on top of each other, each with melted oozing cheese. Around the sandwiches are other sides including french fries and mac and cheese",
			],
		},
		name: "sushirrito",
		menuId: "",
		to: "https://www.sushirrito.com/",
		finalImage: "/Noshery Brand Cards Closing 2-04.png",
		title: "Sushirrito",
		description: "",
		shortDescription: "",
		longDescription:
      "Sushirrito® revolutionizes sushi by combining it with burritos, offering fresh, convenient, and flavor-packed hand-held rolls. Founded by Peter Yen and launched in January 2011 with the collaboration of Ty Mahler, Sushirrito® provides a unique and efficient alternative to traditional sushi restaurants and pre-made options. With a focus on infusing multicultural tastes into every bite, Sushirrito® redefines the sushi experience.",
		quote: "",
		author: "",
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sushirrito/food/lxvy7b2qzdzyrhfos5a5.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sushirrito/food/ghwo4qpyjkjxu6fkvwmk.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sushirrito/food/sajefeqrailslbsygwe6.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sushirrito/food/yaq45kzkzrrtglt9xitx.webp`,
		food5: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sushirrito/food/vxjpilqizrby7sckuvmq.webp`,
	},
	// alamo: {
	// 	logo: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/bsnetebl2g0zhga8ulqp`,
	// 	bg: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/alomo/i89ys15qmatlcm6mvrgf`,
	// 	bgBrand: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/alomo/i89ys15qmatlcm6mvrgf`,
	// 	landerBg: (w=0, h=0) => 
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/alomo/i89ys15qmatlcm6mvrgf`,
	// 	altImg:
	//   "Grilled cheese sandwich being pulled apart featuring stretched melted cheese between each slice. The bread is golden brown and toasted, and the cheese is a blend of yellow and white cheeses.",
	// 	altLogo: "",
	// 	pageImgAlts: {
	// 		header:
	//     "Grilled cheese sandwich being pulled apart featuring stretched melted cheese between each slice. The bread is golden brown and toasted, and the cheese is a blend of yellow and white cheeses",
	// 		thefood: [
	// 			"Three halves of different grilled cheese or melt sandwiches with various fillings like sauteed onions and veggies, melty cheeses, and grilled steak",
	// 			"Two cheeseburgers stacked upon each other with a toothpick holding them in place. Both burgers have oozing melted cheese and other toppings. In the foreground are scattered french fries",
	// 			"Close up of creamy melted macaroni and cheese. The mac and cheese is garnished with diced crispy bacon and shredded cheese",
	// 			"Two sliced grilled cheese sandwiches placed on top of each other, each with melted oozing cheese. Around the sandwiches are other sides including french fries and mac and cheese",
	// 		],
	// 	},
	// 	name: "alamo-burger",
	// 	menuId: "",
	// 	to: "alamo-burger",
	// 	title: "Alamo Burger",
	// 	description: "",
	// 	shortDescription: "",
	// 	longDescription:
	//   "Alamo Burger celebrates American classics discovered in restaurants across the country. Infused with the passion and culinary knowledge across all our kitchens, the chefs at Noshery crafted the recipes shaping Alamo Burger. By blending flavors from menus across the US, Alamo Burger explores favorites like crispy chicken and melty American cheeseburgers.",
	// 	quote: "",
	// 	author: "",
	// 	food1: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/alomo/ybmsk6wijse5sjzlbyc8`,
	// 	food2: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/alomo/ob8ntpztsptrwwhyahak`,
	// 	food3: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/alomo/kdkbyl0y1oo9gyiyussi`,
	// 	food4: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/alomo/uvrtwdyohld3oz40yibi`,
	// 	food5: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/alomo/bmjknewpg6mqxt77ng6v`,
	// },
	// sumoDog: {
	// 	logo: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sumo Dog/az3q1fpifjktbsd9zive.webp`,
	// 	bg: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sumo Dog/nhq946a9rgkzcjwl3daa.webp`,
	// 	bgBrand: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sumo Dog/nhq946a9rgkzcjwl3daa.webp`,
	// 	landerBg: (w=0, h=0) => 
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sumo Dog/nhq946a9rgkzcjwl3daa.webp`,
	// 	altImg:
	//     "Grilled cheese sandwich being pulled apart featuring stretched melted cheese between each slice. The bread is golden brown and toasted, and the cheese is a blend of yellow and white cheeses.",
	// 	altLogo: "",
	// 	pageImgAlts: {
	// 		header:
	//       "Grilled cheese sandwich being pulled apart featuring stretched melted cheese between each slice. The bread is golden brown and toasted, and the cheese is a blend of yellow and white cheeses",
	// 		thefood: [
	// 			"Three halves of different grilled cheese or melt sandwiches with various fillings like sauteed onions and veggies, melty cheeses, and grilled steak",
	// 			"Two cheeseburgers stacked upon each other with a toothpick holding them in place. Both burgers have oozing melted cheese and other toppings. In the foreground are scattered french fries",
	// 			"Close up of creamy melted macaroni and cheese. The mac and cheese is garnished with diced crispy bacon and shredded cheese",
	// 			"Two sliced grilled cheese sandwiches placed on top of each other, each with melted oozing cheese. Around the sandwiches are other sides including french fries and mac and cheese",
	// 		],
	// 	},
	// 	name: "sumoDog",
	// 	menuId: "",
	// 	to: "sumo-dog",
	// 	title: "Sumo Dog",
	// 	description: "",
	// 	shortDescription: "",
	// 	longDescription:
	//     "Sumo Dog takes the American Classic hot dog and redefines it with Japanese ingredients, inspiration and tradition. It is the brainchild of Jeffrey Lunak, a veteran of “Iron Chef” Morimoto’s Restaurant Group. Sumo Dog explores the combinations of the American classic with a heavy nod to the Japanese Izakaya.",
	// 	quote: "",
	// 	author: "",
	// 	food1: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sumo Dog/luwiezcgg41wtkvyrupv.webp`,
	// 	food2: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sumo Dog/nakjckr36os9tvcmkshm.webp`,
	// 	food3: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sumo Dog/bgptmysakjxa4akzeoaz.webp`,
	// 	food4: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sumo Dog/ezldv1a2ygl13vmnhxbm.webp`,
	// 	food5: (w=0, h=0) => 
	// 	  `https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Sumo Dog/qvicxt0ycycgyxl6nm78.webp`,
	// },
	// lazySusan: {
	// 	logo: (w = 0, h = 0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w : 1},h_${
	// 			h ? h : 1
	// 		},c_fill,g_auto,f_auto/brands/Lazy Susan/gvchvnxsoxck1x229cx0.webp`,
	// 	bg: (w = 0, h = 0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w : 1},h_${
	// 			h ? h : 1
	// 		},c_fill,g_auto,f_auto//brands/Lazy Susan/suras5648ozim0ft8x58.webp`,
	// 	bgBrand: (w = 0, h = 0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w : 1},h_${
	// 			h ? h : 1
	// 		},c_fill,g_auto,f_auto/brands/Lazy Susan/suras5648ozim0ft8x58.webp`,
	// 	landerBg: (w = 0, h = 0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w : 1},h_${
	// 			h ? h : 1
	// 		},c_fill,g_auto,f_auto/brands/Lazy Susan/suras5648ozim0ft8x58.webp`,
	// 	altImg:
	//   "Grilled cheese sandwich being pulled apart featuring stretched melted cheese between each slice. The bread is golden brown and toasted, and the cheese is a blend of yellow and white cheeses.",
	// 	altLogo: "",
	// 	pageImgAlts: {
	// 		header:
	//     "Grilled cheese sandwich being pulled apart featuring stretched melted cheese between each slice. The bread is golden brown and toasted, and the cheese is a blend of yellow and white cheeses",
	// 		thefood: [
	// 			"Three halves of different grilled cheese or melt sandwiches with various fillings like sauteed onions and veggies, melty cheeses, and grilled steak",
	// 			"Two cheeseburgers stacked upon each other with a toothpick holding them in place. Both burgers have oozing melted cheese and other toppings. In the foreground are scattered french fries",
	// 			"Close up of creamy melted macaroni and cheese. The mac and cheese is garnished with diced crispy bacon and shredded cheese",
	// 			"Two sliced grilled cheese sandwiches placed on top of each other, each with melted oozing cheese. Around the sandwiches are other sides including french fries and mac and cheese",
	// 		],
	// 	},
	// 	name: "lazySusan",
	// 	menuId: "",
	// 	to: "lazy-susan",
	// 	title: "Lazy Susan",
	// 	description: "",
	// 	shortDescription: "",
	// 	longDescription:
	//   "Lazy Susan was co-founded by Hanson Li, who emigrated from Hong Kong in the 90s, and Tiffany Yam, who grew up in her family’s Chinese restaurant in Pittsburgh. The idea came from Li’s father’s desire for a “Domino’s of Chinese food,” while Yam wants to honor her dad’s 30+ years of experience in the industry. Their goal is to provide quality Chinese food, one eggroll at a time.",
	// 	quote: "",
	// 	author: "",
	// 	food1: (w = 0, h = 0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w : 1},h_${
	// 			h ? h : 1
	// 		},c_fill,g_auto,f_auto/brands/Lazy Susan/cy9duog26svuopczxjln.webp`,
	// 	food2: (w = 0, h = 0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w : 1},h_${
	// 			h ? h : 1
	// 		},c_fill,g_auto,f_auto/brands/Lazy Susan/jacsbfbcggrg5dzlo7un.webp`,
	// 	food3: (w = 0, h = 0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w : 1},h_${
	// 			h ? h : 1
	// 		},c_fill,g_auto,f_auto/brands/Lazy Susan/vfnocjbmedpoazcno9mb.webp`,
	// 	food4: (w = 0, h = 0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w : 1},h_${
	// 			h ? h : 1
	// 		},c_fill,g_auto,f_auto/brands/Lazy Susan/ymwsb3l1yb6ejget5wjj.webp`,
	// 	food5: (w = 0, h = 0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w : 1},h_${
	// 			h ? h : 1
	// 		},c_fill,g_auto,f_auto/brands/Lazy Susan/zcb0uticfcmasykdiaec.webp`,
	// },
	eastsidebanhmi: {
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/eastsidebanhmi_r6etvn.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/brands/eastsidebanhmibg_ybrlh4.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/ESBM_H_jyrxvf.webp`,
		landerBg: (w=0, h=0) => 
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landers/esbm_fbsslf.webp`,
		altImg:
      "Photo of three banh mi sandwiches with one centered in the front prominently. The main sandwich is on a French baguette and has cilantro, thin sliced carrots, and roasted chicken.",
		altLogo: "",
		pageImgAlts: {
			header:
        "Two banh mi sandwiches, one in the foreground features roasted chicken, sliced cucumbers and jalapeño peppers, cilantro, sliced carrots and daikon radishes, seasonings and shallot mayo, all on a toasted french baguette.",
			thefood: [
				"Banh Mi sandwich with chopped jalapeños, cilantro, cucumbers, carrots, and daikon radishes, marinated pork, on a French baguette. A hand is reaching out from the bottom right corner and is holding the sandwich in the air.",
				"Roasted chicken banh mi with cilantro, sliced cucumber and jalapeño, chopped veggies, on a French baguette. The sandwich is resting on a metal dining tray on an outdoor dining table in direct sunlight.",
				"Banh Mi sandwich being held out in someone's hand reaching to the right. The sandwich has chopped veggies, cilantro and carrots, and is on a crispy baguette.",
				"Someone unwrapping their banh mi sandwich out of brown butcher paper. The sandwich has roasted chicken and assorted veggies on it.",
			],
		},
		name: "eastsidebanhmi",
		menuId: "cac55961-2ec4-45dc-9395-1b9162a247eb",
		to: "https://www.eastsidebanhmi.com/",
		finalImage: "/Noshery Brand Cards Closing 2-05.png",
		title: "East Side Banh Mi",
		description: "Bangin’ Banh Mis",
		longDescription:
      "Husband and wife team Chad Newton and Grace Nguyen were inspired to create this Nashville-area hit by Chef Grace's Vietnamese heritage and southern upbringing in Houston, with each sandwich being traditional in spirit yet with an added southern twist.",
		shortDescription: "",
		quote:
      "The chicken and pork deluxe bahn mi's were delicious. They load these babies up with all sorts of deliciousness",
		author: "Crystal P.",
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/eastsidebanhmifood1_us9dan.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/eastsidebanhmifood2_xagdfp.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/eastsidebanhmifood3_somgoh.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/eastsidebanhmifood4_q1qlsx.webp`,
		food5: (w=0, h=0) => "",
	},
	orenshummus: {
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/orenshummus_dv4knl.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/brands/orenshummusbg_feqgq7.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/orenshummus/OH_H_skxo7s.webp`,
		landerBg: (w=0, h=0) => 
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landers/orens_prlkwx.webp`,
		altImg:
      "Photo of a pita sandwich with chicken and veggies, three pitas in the background, and a small ramekin of green sauce",
		altLogo: "",
		pageImgAlts: {
			header:
        "Photo of a pita sandwich with roasted chicken, diced cucumbers and tomatoes, hummus, and tahini dressing. The pita bread is toasted and golden brown.",
			thefood: [
				"Overhead display of a dinner table covered in many white plates with various entrees including hummus dips with eggplant, fattoush salad, turmeric rice, Israeli salads, and other mediterranean menu items and entrees.",
				"Overhead display of dinner table with a falafel rice bowl with turmeric basmati rice and five falafels.",
				"Overhead shot of a white dinner plate with a chicken kabab, hummus dip with olive oil, tahini, and oregano za'atar seasoning. Also a small bowl of israeli salad, toasted pita breads, dipping sauces, and cabbage salad.",
				"Tabletop shot of five white rimmed bowls of hummus with different toppings including eggplant, beets, chicken, and Moroccan beef.",
			],
		},
		finalImage: "/Noshery Brand Cards Closing 2-01.png",
		name: "orenshummus",
		to: "https://orenshummus.com/",
		title: "Oren's Hummus",
		description: "Authentic Israeli",
		longDescription: "A technologist with 4 successful start-ups under his belt, Oren Dobronsky and his wife and experienced restaurateur Nancy brought Silicon Valley the one thing missing from his home back in Tel Aviv: authentic Israeli foods, starting with creamy Hummus, fresh pita, & Mediterranean salads",
		shortDescription: "",
		quote:
      "The beef was perfectly cooked, there were a lot of vegetables in the sandwich and the perfect amount of tahini. The sauces come on the side and were fabulous!",
		author: "Alice G.",
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/orenshummus/OH_1_yzvkat.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands//orenshummus/orenhummusfood2_upanzz.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands//orenshummus/orenhummusfood3_orclqe.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/orenshummus/OH_4_p8wcwl.webp`,
		food5: (w=0, h=0) => "",
	},
	thelittlechihuahua: {
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/thelittlechihuahua_ihgrxe.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/brands/thelittlechihuahuabg_1_fpklhh.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/TLC_H_okbpoa.webp`,
		landerBg: (w=0, h=0) => 
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landers/tlc_ezjpap.webp`,
		altImg:
      "Photo of a burrito cut in half showing the fillings: plantains, black beans, shrimp, and salsa. In the background is a plate with fried plantains, and two glasses.",
		altLogo: "",
		pageImgAlts: {
			header:
        "Photo of a burrito cut in half showing the fillings: plantains, black beans, shrimp, and salsa. In the background is a plate with fried plantains, and two glasses.",
			thefood: [
				"Close up of a taco with chopped barbequed steak, white onions, fresh salsa, and cilantro on a double stacked corn tortilla",
				"Sliced plantain burrito with brown rice and black beans, spicy salsa, rolled into a golden toasted flour tortilla. On the plate also sits a small serving of shredded cabbage.",
				"Two tacos next to one another, with shredded veggies, cubes of grilled steak, fresh pico de gallo salsa, placed on a wooden table with small bowls of salsa and lime wedges behind.",
				"Top shot of a burrito wrapped in tin foil, peeled to reveal the fillings including chicken, guacamole, chopped veggies, and brown spanish rice.",
			],
		},
		name: "thelittlechihuahua",
		to: "https://www.thelittlechihuahua.com/",
		finalImage: "/Noshery Brand Cards Closing 2-03.png",
		title: "The Little Chihuahua",
		description: "California Mexican",
		shortDescription: "",
		longDescription:
      "Avid surfer and chef Andrew Johnstone founded The Little Chihuahua with his wife Camila Fernandez in San Francisco’s Lower Haight in 2007 to provide wholesome Mexican food by offering organic ingredients where possible and sourcing quality proteins and produce from suppliers they know and trust.",
		quote:
      "The sweet plantain coupled with the savory hints from the fried bits and the black beans, and the refreshing burst from the roasted bell peppers. I've never tasted so much flavor in one bite???",
		author: "Eva L.",
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/TLC_1_ikbegu.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/thelittlechihuahuafood2_xjhbkd.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/thelittlechihuahuafood3_zpqiql.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/thelittlechihuahuafood4_ockegz.webp`,
		food5: (w=0, h=0) => "",
	},
	// asianBox: {
	// 	logo: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/asianbox_s2hbcy.webp`,
	// 	bg: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/brands/asianbox_so3hco.webp`,
	// 	bgBrand: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/asianbox/AB_H_nncsgw.webp`,
	// 	landerBg: (w=0, h=0) => 
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/asianbox/bg_qo98jc.webp`,
	// 	altImg:
	//   "Six-Spice Chicken, Brown Rice, Extra Steamed Veggies, Bean Sprouts, Pickled Veggies, Herbs, Tamarind Sauce.",
	// 	altLogo: "",
	// 	pageImgAlts: {
	// 		header:
	//     "Six-Spice Chicken, Brown Rice, Extra Steamed Veggies, Bean Sprouts, Pickled Veggies, Herbs, Tamarind Sauce",
	// 		thefood: [
	// 			"Six-Spice Chicken Tossed with a Blend of Our Signature HotBoxit™ and Tamarind Sauces, Local Honey, Jasmine Rice, Crispy Shallots, Peanuts",
	// 			"Asian Box 6 dishes.",
	// 			"Asian Box 4 dishes.",
	// 			"Lemongrass Pork, Chilled Noodles, Sauce Tossed Veggies, Caramel Egg, All Toppers {No Jalapeños}, Extra Fish Sauce",
	// 			"Six-Spice Chicken Tossed with a Blend of Our Signature HotBoxit™ and Tamarind Sauces, Local Honey, Jasmine Rice, Crispy Shallots, Peanuts"
	// 		],
	// 	},
	// 	name: "asianbox",
	// 	menuId: "75c7921f-b6bb-4de7-a188-98744cacc361",
	// 	to: "asian-box",
	// 	title: "Asian Box",
	// 	description: "",
	// 	shortDescription: "",
	// 	longDescription:
	//   "Asian Box is home to the freshest Vietnamese street food bringing the flavors of Vietnam and curries from Southeast Asia to Life! Their menu is fresh, never frozen, and fully customizable creating a made to order experience in a healthy, authentic way. With a 100% gluten-free menu including a variety of proteins like their popular six-spice chicken and yellow coconut curry tofu, Asian Box is the #1 choice for your healthy lifestyle.",
	// 	quote:
	//   "",
	// 	author: "",
	// 	food1: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto//brands/asianbox/thefood1_skzdqh.webp`,
	// 	food2: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/asianbox/thefood2_fmdsuv.webp`,
	// 	food3: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/asianbox/AB_3_ypy8qd.webp`,
	// 	food4: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto//brands/asianbox/thefood4_guny0v.webp`,
	// 	food5: (w=0, h=0) => 
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto//brands/asianbox/thefood5_hykkkq.webp`,
	// },
	// kasa: {
	// 	logo: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/kasa_dnzwmw.webp`,
	// 	bg: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/brands/kasabg_sz3mss.webp`,
	// 	bgBrand: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/kasa/kasabg_nomamt.webp`,
	// 	landerBg: (w=0, h=0) => 
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landers/kasa_vbs19s.webp`,
	// 	altImg:
	//   "Two aluminum foil wrapped naan rolls filled with rice, veggies, and various proteins. Two samosas and two small ramekins of dipping sauces are also along the edge of the photo.",
	// 	altLogo: "",
	// 	pageImgAlts: {
	// 		header:
	//     "Hand reaching out with a spoon of chicken tikka masala onto a bed of seasonings and sauce on a toasted naan flatbread. The naan rests on a wooden pizza peel, and in the background is a small wooden bowl with toppings.",
	// 		thefood: [
	// 			"Stone bowl filled with seared chopped chicken, smaller bowls and spoons of various seasonings and peppers.",
	// 			"A person rolling a naan roll filled with chicken tikka masala, seasonings, and sliced red onions. There are two bowls in the background filled with other Indian toppings and seasonings",
	// 			"A plate with quartered slices of toasted naan bread and a bowl of masala sauce with braised chicken pieces.",
	// 			"A segmented thali plate with multiple sides and a main entree. Various chopped veggies, masala sauce, proteins, and a bed of white rice.",
	// 		],
	// 	},
	// 	name: "kasa",
	// 	menuId: "a286296d-2047-42f3-8bd7-981e4f3840af",
	// 	to: "kasa-indian-eatery",
	// 	title: "Kasa Indian Eatery",
	// 	description: "Homestyle Indian",
	// 	longDescription:
	//   "Kasa Indian Eatery was founded in 2008 by Anamika Khanna and her husband Suresh to bring healthy, accessible homestyle Indian food to the Bay Area that aims to change the trend of restaurants in America serving Indian cuisine more suited to special occasions than daily consumption.",
	// 	shortDescription: "",
	// 	quote:
	//   "My favourite is the chicken tikka masala roll washed down with a yummy mango lassi. This is by far, the best place to try authentic Punjabi dishes!",
	// 	author: "Pushy S.",
	// 	food1: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/kasa/KASA_1_akehk3.webp`,
	// 	food2: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/kasa/kasafood2_k1d8xx.webp`,
	// 	food3: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/kasa/kasafood3_nvaag2.webp`,
	// 	food4: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/kasa/kasafood4_qnfctt.webp`,
	// 	food5: (w=0, h=0) => "",
	// },
	// curryhouse: {
	// 	logo: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/curryhouse_jnuylt.webp`,
	// 	bg: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/brands/curryhousebg_kw5buh.webp`,
	// 	bgBrand: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/curryhouse/curryhousebg_gjqiic.webp`,
	// 	landerBg: (w=0, h=0) => 
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landers/c_c_olt1lg.webp`,
	// 	altImg:
	//     "Photo shot from above of four bowls of various Vietnamese curries and rice. On the table there are also bunches of cilantro, mint, peanuts, silverware, and a cloth napkin.",
	// 	altLogo: "",
	// 	pageImgAlts: {
	// 		header:
	//       "Three bowls of differing vietnamese curries: a red steak curry, a green tofu curry, and a yellow chicken curry. Each bowl has a portion of white rice and a lime wedge. The curries are garnished with chopped peanuts and other south asian seasonings.",
	// 		thefood: [
	// 			"Bowl of yellow chicken garnished in fried shallots, chopped peanuts, and other vietnamese toppings. On the table there is also a fork and spoon, a cloth napkin, and a set of unused plates.",
	// 			"A man holding two rectangular to-go plates of different vietnamese curries. He is wearing a shirt with the C&C Curry House by Asian Box logo. ",
	// 			"Three takeout dishes of different curries.",
	// 			"A takeout dish of red vietnamese curry with grilled steak, potatoes, carrots, and other toppings, served with a side of white steamed rice and a lime wedge.",
	// 		],
	// 	},
	// 	name: "curryhouse",
	// 	menuId: "d508a10b-0435-49f3-bc30-0447d49093c1",
	// 	to: "cc-curry-house",
	// 	title: "C&C Curry House",
	// 	description: "Vietnamese Curry",
	// 	shortDescription: "",
	// 	longDescription:
	//     "C & C Curry House was created by the founders of the ever popular Bay Area chain Asian Box in order to serve delivery and pickup to customers with an expanded selection of their best selling Vietnamese curries. ",
	// 	quote:
	//     "The chicken curry was delish! It was piping hot, and soaked in flavor.",
	// 	author: "Sumedha R.",
	// 	food1: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/curryhouse/curryhousefood1_lsmuwh.webp`,
	// 	food2: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/curryhouse/curryhousefood2_appwxu.webp`,
	// 	food3: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/curryhouse/curryhousefood3_jtdbea.webp`,
	// 	food4: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/curryhouse/curryhousefood4_ob4tzw.webp`,
	// 	food5: (w=0, h=0) => "",
	// },
	humphryslocombe: {
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/humphryslocombe_jjp08p.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/brands/humphryslocombebg_ot7g5o.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/humphryslocombe/humphryslocombebg_vd1fzo.webp`,
		landerBg: (w=0, h=0) => 
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landers/humphry_o0zqlf.webp`,
		altImg:
      "Six blue pint containers of ice cream with four opened with two scoops of ice cream on each extending above the rim of the container, each with the Humphry Slocombe logo on the container.",
		altLogo: "",
		pageImgAlts: {
			header:
        "Overhead shot of multiple pints of Humphry Slocombe ice cream, some oriented with the open rim up, others with the lid on, and some on their sides featuring the front of the package and logo. In the open containers are various different creamy ice cream flavors",
			thefood: [
				"Pint of Blue Bottle Vietnamese Coffee Humphry Slocombe ice cream with a small glass bowl of multiple scoops of brown coffee ice cream",
				"A scoop of ice cream in a sugar cone beginning to melt. On the scoop is a criss-cross drizzle of honey which is dripping down to the cone",
				"Overhead of twelve cups of ice cream with a scoop of assorted different Humphry Slocombe flavors in each cup. In the cups include chocolate, vanilla, strawberry, and other flavors.",
				"Six blue pint containers of ice cream with four opened with two scoops of ice cream on each extending above the rim of the container, each with the Humphry Slocombe logo on the container.",
			],
		},
		name: "humphryslocombe",
		menuId: "320d82b1-e4f3-443f-8d68-fda9de230bee",
		to: "https://humphryslocombe.com/",
		finalImage: "/Noshery Brand Cards Closing 2-06.png",
		title: "Humphry Slocombe",
		description: "Adult Ice Cream",
		shortDescription: "",
		longDescription: "Founders Jake Godby and Sean Vahey combine their own bizarre, delicious, frozen universe of ice cream and their love of British comedy with Humphrey Slocombe, named after the two lead characters from the fabulous 1970's sitcom \"Are You Being Served?\".",
		quote:
      "Humphry is definitely one of my favorite ice creams in San Francisco, the Bay Area, or even possibly the entire world! My favorite will always be the Secret Breakfast, but sometimes I branch out and enjoy other flavors such as Matcha Snickerdoodle!",
		author: "Jeanette S.",
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/humphryslocombe/humphryslocombefood1_yownku.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/humphryslocombe/humphryslocombefood2_e6dxys.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/humphryslocombe/HS_3_pg99nt.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/humphryslocombe/humphryslocombefood4_bbqjas.webp`,
		food5: (w=0, h=0) => "",
	}, 
	comingsoon: {
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/comingsoon_kyocia.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/brands/comingsoon_x3r5zb.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/brands/comingsoon_x3r5zb.webp`,
		landerBg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/brands/comingsoon_x3r5zb.webp`,
		altImg:
      "Coming soon written in white text with orange and white borders on a green background.",
		altLogo: "",
		name: "comingsoon",
		menuId: "",
		to: "",
		title: "Coming Soon",
		description: "",
		shortDescription: "",
		longDescription: "",
		qoute: "",
		author: "",
	},
};

export const locations = [
	{
		src: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/location/location_cgekgg.webp`,
		value: "sanmateo",
		title: "San Mateo",
		address1: "5 South Ellsworth Avenue",
		address2: "San Mateo, CA 94401",
		phone: "(650) 393-5547",
		openHours: [{ days: "Mon-Sun: ", time: "11:00AM-11:00PM" }],
		order: "https://online.ordernoshery.com/noshery-san-mateo?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6"
	},
	// {
	// 	src: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/location/location2_hjnlb1.webp`,
	// 	value: "sanmateo",
	// 	title: "San Carlos",
	// 	address1: "1754 Laurel St",
	// 	address2: "San Carlos, CA 94070",
	// 	phone: "(650) 453-3009",
	// 	openHours: [{ days: "Mon-Sun: ", time: "11:00AM-11:00PM" }],
	// 	order: "https://online.ordernoshery.com/noshery-san-carlos?menu=7e53e925-a316-47e3-a718-5907227147b1"
	// },
	// {
	// 	src: (w=0, h=0) =>
	// 		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/location/piaatvkhilebln4pphdx.webp`,
	// 	value: "sanramon",
	// 	title: "San Ramon",
	// 	address1: "2426 San Ramon Valley Blvd", 
	// 	address2: "San Ramon, CA 94583",
	// 	phone: "(925) 235-0161",
	// 	openHours: [{ days: "Mon-Sun", time: "11:00AM-11:00PM" }],
	// 	order: "https://online.ordernoshery.com/noshery-san-ramon?menu=2a87dc3e-4239-4434-bc2d-56deaed15997",
	// 	comingSoon: false,
	// },
];

export const ALT_IMGS = {
	whatwedo: {
		altImg:
      "Spread of multiple entrees from the Noshery menu featuring (clockwise): a cheeseburger with veggies and toasted bun and french fries, two pita flatbreads, a bowl of hummus with olive oil and seasonings, a banh mi sandwich with veggies, a tray of red steak curry, and various dipping sauces.",
	},
	faq: {
		altImg:
      "Overhead view of table featuring multiple menu items including a bowl of yellow chicken curry and rice, two chocolate chip cookies in a brown bag, and a cheeseburger and fries partially obscured due to the crop of the image.",
	},
	landingHeader: {
		altImg:
      "Photo of multiple lunch or dinner entrees including (clockwise from the top) a Banh Mi sandwich on a wood plate, two cookies in a brown bag, a cheeseburger and basket of french fries on a wooden slab, and a bowl of curry and rice.",
	},
	logo: {
		altImg:
      "Noshery logo featuring the word Noshery in lower case with a fork pointed to the right, all colored orange",
	},
	locations: {
		sanmateo: {
			altImg:
        "Exterior of the San Mateo, California Noshery location from the street corner. The windows have circular window cling stickers displaying the logos of Noshery's brand partners.",
		},
		sanramon: {
			altImg:
        "Exterior of the San Mateo, California Noshery location from the street corner. The windows have circular window cling stickers displaying the logos of Noshery's brand partners.",
		},
		comingsoon: {
			altImg:
        "Coming soon written in white text with orange and white borders on a green background.",
		},
	},
};

export const ORDER_URL = "https://online.ordernoshery.com/noshery-san-mateo";

export const DELIVERY_URL =
  "https://online.ordernoshery.com/nosherydelivery?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6";

export const PICKUP_URL =
  "https://online.ordernoshery.com/nosherypickup?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6";
