import React, { useState, useRef } from "react";
import { Box } from "noshery-ui";
import { animated, useSpring } from "react-spring";
import MobileLanderHeader from "../LandersHeader";
import LandersTheFood from "../LandersTheFood";
import LandersBrandList from "../LandersBrandList";
import MobileSidebar from "../Sidebar";
import { useSwipeable } from "react-swipeable";
import LandersWhatWeDo from "../LandersWhatWeDo";
import LandersTopDishes from "../LandersTopDishes";
import Locations from "../Locations";
import LandersMenu from "../LandersMenu";
import LandersPerk from "../LandersPerk";
import MetadataHelmet from "../../MetadataHelmet";
import MobileWhatWeDo from "../WhatWeDo"; 
import Banner from "../Banner";

const AnimatedBox = animated(Box);

const LanersBrand = (brand) => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [headerOpen, setHeaderOpen] = useState(true);
	const [offset, setOffset] = useState(0);
	const menuRef = useRef();

	const { metaTitle, metaDescription, metaTags, to } = brand;

	const { left } = useSpring({
		config: { duration: 200 },
		from: { left: "0%" },
		left: sidebarOpen ? "60%" : "0%",
	});

	React.useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	const handlers = useSwipeable({
		onSwipedLeft: () => setSidebarOpen(false),
		onSwipedRight: () => setSidebarOpen(true),
		onSwipedDown: () => setHeaderOpen(true),
		onSwipedUp: () => setHeaderOpen(false),
		swipeDuration: 500,
		delta: { up: 0, down: 0, left: 80, right: 100 },
		trackMouse: true,
	});

	let name = brand.to;
	return (
		<Box {...handlers} overflow="hidden">
			<MetadataHelmet {...brand} />
			<MobileSidebar
				setSidebarOpen={setSidebarOpen}
				sidebarOpen={sidebarOpen}
			/>
			<Box overflow="hidden">
				<AnimatedBox style={{ left: left, position: "relative" }}>
					<MobileLanderHeader
						sidebarOpen={sidebarOpen}
						headerOpen={headerOpen}
						menuRef={menuRef}
						setSidebarOpen={setSidebarOpen}
						onScroll={offset}
						{...brand}
					/>
					{

						name === "locations" ? <>
							<MobileWhatWeDo isLander={true} />
							<LandersPerk {...brand} />
							<Locations {...brand} />
						</> :
						 <>
								<LandersWhatWeDo {...brand} />
								<LandersTopDishes {...brand} />
								<LandersTheFood {...brand} />
								<LandersMenu menuRef={menuRef} {...brand} />
								{brand.banner && <Banner /> }
								<LandersBrandList {...brand} />
								<LandersPerk {...brand} />
								<Locations {...brand} />
						 </>
					}
				</AnimatedBox>
			</Box>
		</Box>
	);
};

export default LanersBrand;
