import React, { useState } from "react";
import { Box, Flex, Text, Image, Button } from "noshery-ui";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { validateEmail, validatePhone } from "../../utils";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";
import "react-datepicker/dist/react-datepicker.css";

const DateSelect = styled.input`
  outline: none;
  background-color: #ededed;
  box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: ${(p) => `${(p.width * 2) / 100}px`} !important;
  padding: ${(p) =>
		`${(p.width * 1.3) / 100}px ${(p.width * 1.8) / 100}px`} !important;
  width: ${(p) => `calc(100% - ${(p.width * 4.1) / 100}px)`} !important;
  border: ${(p) => p.error ? "3px solid red" : "none"} !important;
  cursor: pointer;
  font-size: ${(p) =>
		`${(p.width * (p.isDesktop ? 1.2 : 1.8)) / 100}px`} !important;
`;

const StyledButton = styled(Button)`
  box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.25);
  border-radius: 60px;
`;
const API_URL = process.env.REACT_APP_API_URL;

const getTomorrow = () => {
	let today = new Date();
	let tomorrow = new Date();
	return tomorrow.setDate(today.getDate() + 1);
};

const SUCCESS_MESSAGE = "Your request is submitted successfully. We will reach out to you within 24hrs.";
const ERROR_MESSAGE =
  "Error happened while submitting. Please try again later.";

const CateringForm = () => {
	const recaptchaRef = React.createRef();
	const { isDesktop, isTablet } = useDeviceType();
	const { width } = useWindowSize();

	const [firstname, setFirstname] = useState(""); 
	const [lastname, setLastname] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [phone, setPhone] = useState("");
	const [guest, setGuest] = useState("");
	const [location, setLocation] = useState("San Mateo");

	const [date, setDate] = useState("");

	const [verified, setVerified] = useState(null);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [errors, setErrors] = useState({
		email: null,
		firstname: null,
		fullname: null, 
		message: null,
		phone: null,
		date: null,
		guest: null,
		location: null,
	});

	const validateInputs = () => {
		setErrors({
			email: validateEmail(email)
				? null
				: "Please enter a valid email address.",
			firstname: firstname ? null : "Fullname is required.",
			lastname: lastname ? null : "Fullname is required.",
			message: message ? null : "Message is required.",
			phone: validatePhone(phone) ? null : "Phone nubmer is invalid.",
			date: date ? null : "Required.",
			guest: guest ? null : "Required. ",
			location: location ? null : "Required. ",
		});
	};

	const clearState = () => {
		setFirstname(""); 
		setLastname(""); 
		setEmail("");
		setMessage("");
		setPhone("");
		setLocation("San Mateo");
		setDate(getTomorrow());
		setShowErrorMessage(false);
		setShowSuccessMessage(false);
		setSubmitting(false);
	};

	const onClickSubmit = async () => {
		validateInputs();
		if (!firstname || !lastname || !email || !message || !phone || !date || !guest || !location) {
			return;
		}
		recaptchaRef.current.reset();
		setSubmitting(true);
		const res = await fetch(`${API_URL}/cateringEmail`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				name: firstname + " " + lastname,
				message,
				phone: phone,
				guest: guest,
				date: date,
				location: location,
				captcha: verified,
			}),
		});

		const { error } = await res.json();

		if (error) {
			setShowErrorMessage(true);
		} else {
			setShowSuccessMessage(true);
		}

		setTimeout(clearState, 5000);
	};

	const onChange = (captcha) => {
		setVerified(captcha);
	};

	const submitBParams = {
		fontSize: `${(width * (isDesktop ? 2.2 : 4)) / 100}px`,
		lineHeight: `${(width * 3.1) / 100}px`,
		paddingLeft: `${(width * 1) / 100}px`,
		paddingRight: `${(width * 1) / 100}px`,
	};

	let disabled = !verified || submitting;

	const wrapperParams = {
		marginTop: `${(width * 1) / 100}px`,
	};

	const messageWrapperParams = {
		marginBottom: `${(width * 1) / 100}px`,
	};

	const inputContainer = {
		paddingRight: `${(width * 3.5) / 100}px`,
		paddingLeft: `${(width * .5) / 100}px`
	};

	const errorParams = {
		fontSize: `${(width * 2) / 100}px`,
		height: `${(width * 6) / 100}px`,
		borderRadius: `${(width * 2) / 100}px`,
		color: "white",
		width: `${(width * (isDesktop ? 65 : 80)) / 100}px`,
		backgroundColor: "#8B6017",
		flexDirection: "column",
		justifyContent: "center",
		boxShadow: "6.02787px 6.02787px 30.1393px 6.02787px rgba(0, 0, 0, 0.25)",
	};

	const captchaParams =
    isDesktop || isTablet
    	? {}
    	: {
    		transform: "scale(0.8)",
    		transformOrigin: "0 0",
    	};

	const recaptchaWrapper =
    isDesktop || isTablet ? { justifyContent: "center" } : {};

	const textParams = {
		fontSize: `${(width * (isDesktop ? 2 : 2.8)) / 100}px`,
		width: `${(width * (isDesktop ? 65 : 80)) / 100}px`,
		lineHeight: `${(width * 5) / 100}px`,
		alignSelf: "center",
		textAlign: "center",
		color: "#344734",
	};

	return (
		<Box {...wrapperParams}>
			<Flex
				style={{ padding: `${(width * 5) / 100}px 0px` }}
				flexDirection="column"
				alignItems="center"
			>
				<Text
					bold
					style={{ fontSize: `${(width * 2.4) / 100}px` }}
					{...textParams}
				>
					{" "}
          Interested in placing a large order for a party or gathering? Let us
          help!{" "}
				</Text>
			</Flex>
			<Flex flexDirection="column" alignItems="center">
				<Flex paddingTop={!isDesktop && `${(width * 2)/100}px`}>
					<Box {...inputContainer}>
						<SmallInputbox
							title="First Name"
							value={firstname}
							type="text"
							error={errors["firstname"]}
							onChange={(e) => {
								setFirstname(e.target.value);
								setErrors({
									...errors,
									firstname: e.target.value ? null : "Firstname is required.",
								});
							}}
						/>
					</Box>
					<Box> 
						<SmallInputbox
							title="Last Name"
							value={lastname}
							type="text"
							error={errors["lastname"]}
							onChange={(e) => {
								setLastname(e.target.value);
								setErrors({
									...errors,
									lastname: e.target.value ? null : "Lastname is required.",
								});
							}}
						/>
					</Box>
				</Flex>
				<Flex paddingTop={!isDesktop && `${(width * 2)/100}px`}>
					<Box {...inputContainer}>
						<SmallInputbox
							title="Email Address"
							value={email}
							type="text"
							error={errors["email"]}
							onChange={(e) => {
								setEmail(e.target.value);
								setErrors({
									...errors,
									email: validateEmail(e.target.value)
										? null
										: "Please enter a valid email address.",
								});
							}}
						/>
					</Box>
					<Box>
						<SmallInputbox
							title="Phone Number"
							placeholder={"XXX-XXX-XXXX"}
							value={phone}
							error={errors["phone"]}
							onChange={(e) => {
								setPhone(e.target.value);
								setErrors({
									...errors,
									phone: validatePhone(e.target.value)
										? null
										: "Phone is required.",
								});
							}}
						/>
					</Box> 
				</Flex>
				<Flex paddingTop={!isDesktop && `${(width * 2)/100}px`} >
					<Box {...inputContainer}>
						<SmallInputbox
							title="Number Of Guest"
							type="number"
							optional={true}
							value={guest}
							onChange={(e) => {
								setGuest(e.target.value);
							}}
						/>
					</Box>
					<Box> 
						<SmallInputbox
							title="Date of Event"
							optional={true}
							value={date}
							type={null}
							onChange={(e) => {
								setDate(e);
							}}
						/>
					</Box>
				</Flex>

				<Flex paddingTop={!isDesktop && `${(width * 2)/100}px`} >
					<Box {...inputContainer}>
						<SmallSelectBox
							title="Location" 
							optional={false}
							value={location}
							onChange={(e) => {
								setLocation(e.target.value);
							}}
						/>
					</Box>
				</Flex>

				<Textbox
					title="Your Message"
					optional={true}
					value={message}
					onChange={(e) => {
						setMessage(e.target.value);
					}}
				/>

				<Flex
					mb={isDesktop ? "32px" : isTablet ? "16px" : "8px"}
					width={isDesktop ? "950px" : isTablet ? "490px" : "247px"}
					{...recaptchaWrapper}
				>
					<ReCAPTCHA
						ref={recaptchaRef}
						sitekey="6LeOpP8fAAAAAMsTede0daz-ENLXVI_IZTOu_WyC"
						onChange={onChange}
						style={{ ...captchaParams }}
					/>
				</Flex>
				<Box {...messageWrapperParams}>
					{showErrorMessage && (
						<Flex {...errorParams}>
							<Flex>
								<Box paddingLeft={"10px"} paddingRight={"10px"}>
									<Image
										alt="error image"
										style={{
											width: `${(width * 4) / 100}px`,
											height: `${(width * 4) / 100}px`,
										}}
										src={"error.png"}
									/>
								</Box>
								<Flex
									style={{ justifyContent: "center", flexDirection: "column" }}
								>
									<Text bold>{ERROR_MESSAGE}</Text>
								</Flex>
							</Flex>
						</Flex>
					)}
					{showSuccessMessage && (
						<Flex style={{ backgroundColor: "#60785e" }} {...errorParams}>
							<Flex>
								<Box paddingLeft={"10px"} paddingRight={"10px"}>
									<Image
										alt="success image"
										style={{
											width: `${(width * 4) / 100}px`,
											height: `${(width * 4) / 100}px`,
										}}
										src={"success.png"}
									/>
								</Box>
								<Flex
									style={{ justifyContent: "center", flexDirection: "column" }}
								>
									<Text bold>{SUCCESS_MESSAGE}</Text>
								</Flex>
							</Flex>
						</Flex>
					)}
				</Box>
				<Box mb={isDesktop ? "60px" : isTablet ? "52px" : "65px"}>
					<StyledButton onClick={onClickSubmit} disabled={disabled}>
						<Text bold {...submitBParams} textAlign="center" color="white">
              Submit
						</Text>
					</StyledButton>
				</Box>
			</Flex>
		</Box>
	);
};

export default CateringForm;

const SmallInputbox = ({
	title,
	value,
	onChange,
	error,
	placeholder,
	type,
	optional
}) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();

	const wrapperParams = {
		width: `${(width * (isDesktop ? 31 : 38.5)) / 100}px`,
	};

	const textParams = {
		fontSize: `${(width * 1.4) / 100}px`,
		lineHeight: `${(width * 1.4) / 100}px`,
		paddingBottom: `${(width * (isDesktop ? 1 : 2)) / 100}px`,
		position: "absolute",
		color: error ? "red" : "black"
	};

	const inputHeigth = (width * 6);

	const marginBottomtext = (width * 1) / 100;

	return (
		<Box {...wrapperParams}>
			<Flex
				mb={`${marginBottomtext}px`}
				justifyContent="space-between"
				alignItems="center"
			>
				<Text bold {...textParams}>
					{title} {optional ? "" : "*"}
				</Text>
			</Flex>
			<div style={{ height: `${inputHeigth / 100}px` }}>
				{type !== null ? (
					title === "Phone Number" ? 
						<InputMask
							mask="(999)999-9999"
							alwaysShowMask
							value={value}
							onChange={onChange}
							style={{
								outline: "none",
								backgroundColor: "#EDEDED",
								boxShadow: "4px 4px 20px 4px rgba(0, 0, 0, 0.25)",
								borderRadius: `${(width * 2) / 100}px`,
								padding: `${(width * 1.4) / 100}px ${(width * 1.8) / 100}px`,
								width: `calc(100% - ${(width * 4.1) / 100}px)`,
								border: error ? "3px solid red" : "none",
								fontSize: `${(width * (isDesktop ? 1.2 : 1.8)) / 100}px`,
							}}
						/> 
						:
						<input
							value={value}
							onChange={onChange}
							type={type}
							min={0}
							placeholder={placeholder ? placeholder : title}
							style={{
								outline: "none",
								backgroundColor: "#EDEDED",
								boxShadow: "4px 4px 20px 4px rgba(0, 0, 0, 0.25)",
								borderRadius: `${(width * 2) / 100}px`,
								padding: `${(width * 1.3) / 100}px ${(width * 1.8) / 100}px`,
								width: `calc(100% - ${(width * 4.1) / 100}px)`,
								border: error ? "4px solid red" : "none",
								fontSize: `${(width * (isDesktop ? 1.2 : 1.8)) / 100}px`,
							}}
						/>
				) : (
					<DatePicker
						minDate={getTomorrow()}
						customInput={
							<DateSelect width={width} isDesktop={isDesktop} value={value} />
						}
						placeholderText="Date of Event"
						selected={value}
						onChange={(date) => onChange(date)}
					/>
				)}
			</div>
		</Box>
	);
};

const SmallSelectBox = ({
	title,
	value,
	onChange,
	error,
	placeholder,
	type,
	optional
}) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();

	const wrapperParams = {
		width: `${(width * (isDesktop ? 31 : 38.5)) / 100}px`,
	};

	const textParams = {
		fontSize: `${(width * 1.4) / 100}px`,
		lineHeight: `${(width * 1.4) / 100}px`,
		paddingBottom: `${(width * (isDesktop ? 1 : 2)) / 100}px`,
		position: "absolute",
		color: error ? "red" : "black"
	};

	const inputHeigth = (width * 6);

	const marginBottomtext = (width * 1) / 100;

	return (
		<Box {...wrapperParams}>
			<Flex
				mb={`${marginBottomtext}px`}
				justifyContent="space-between"
				alignItems="center"
			>
				<Text bold {...textParams}>
					{title} {optional ? "" : "*"}
				</Text>
			</Flex>
			<div style={{ height: `${inputHeigth / 100}px` }}>
				<select
					value={value}
					onChange={onChange}
					type={type}
					min={0}
					placeholder={placeholder ? placeholder : title}
					style={{
						outline: "none",
						backgroundColor: "#EDEDED",
						boxShadow: "4px 4px 20px 4px rgba(0, 0, 0, 0.25)",
						borderRadius: `${(width * 2) / 100}px`,
						padding: `${(width * 1.3) / 100}px ${(width * 1.8) / 100}px`,
						width: `calc(100% - ${(width * 4.1) / 100}px)`,
						border: error ? "4px solid red" : "none",
						fontSize: `${(width * (isDesktop ? 1.2 : 1.8)) / 100}px`,
					}}
				>

					<option value="San Mateo">San Mateo</option>
					{/* <option value="San Carlos">San Carlos</option> */}
				</select>
				
			</div>
		</Box>
	);
};

const Textbox = ({ title, value, onChange, error, optional }) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();

	const wrapperParams = {
		width: `${(width * (isDesktop ? 65 : 80)) / 100}px`,
		paddingTop: `${(width * (isDesktop ? 0 : 2)) / 100}px`
	};

	const textParams = {
		fontSize: `${(width * (isDesktop ? 1.4 : 2)) / 100}px`,
		lineHeight: `${(width * 1.4) / 100}px`,
		color: error ? "red" : "black"
	};

	return (
		<Box {...wrapperParams}>
			<Flex
				mb={`${(width * 1) / 100}px`}
				justifyContent="space-between"
				alignItems="center"
			>
				<Text bold {...textParams}>
					{title} {optional ? "" : "*"}
				</Text>
			</Flex>
			<div style={{ position: "relative", height: `${(width * 12) / 100}px` }}>
				<textarea
					value={value}
					onChange={onChange}
					placeholder={title}
					style={{
						resize: "none",
						outline: "none",
						backgroundColor: "#EDEDED",
						boxShadow: "4px 4px 20px 4px rgba(0, 0, 0, 0.25)",
						borderRadius: `${(width * 2) / 100}px`,
						padding: `${(width * 1.3) / 100}px ${(width * 1.8) / 100}px`,
						width: `calc(100% - ${(width * 3.6) / 100}px)`,
						border: error ? "4px solid red" : "none",
						position: "absolute",
						top: 0,
						fontSize: `${(width * (isDesktop ? 1.5 : 2)) / 100}px`,
						height: `${(width * 6) / 100}px`,
					}}
				/>
			</div>
		</Box>
	);
};
