import React from "react";
import { Box, Flex, Text, Button } from "noshery-ui";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import useDeviceType from "../../hooks/useDeviceType"; 

const ORDER_SAN_CARLOS = "https://online.ordernoshery.com/noshery-san-carlos?menu=7e53e925-a316-47e3-a718-5907227147b1";
const ORDER_SAN_MATEO = "https://online.ordernoshery.com/noshery-san-mateo?menu=bbeef3b6-4ec2-4230-9422-48e7cebc30f6";

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
  background-color: #344734;
`;

const LanderMenu = ({
	menuItemsMobile,
	menuTitle,
	menuRef,
	mobileMenuHeight
}) => {
	const { width } = useWindowSize();
	const boxParams = {
		marginTop: `${(width * 4) / 100}px`,
	};

	const titleParams = {
		fontSize: `${(width * 8)/100}px`,
		fontWeight:  `${(width * 31.25)/100}`,
	};

	if(!menuTitle) return null; 

	const ThreeRowParams = {
		flexDirection: "row"
	};

	const rows = {
		flexDirection: "column"
	};
	const buttonWidthPercentage = 70;
	const buttonHeightPercentage = 14;

	const minButtonWidth = (width * buttonWidthPercentage) / 100;
  
	const minButtonHeigth = (width * buttonHeightPercentage) / 100;

	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1.3) / 100}px`;

	const buttonParams = {
		fontSize: `${(width * 8) / 100}px`,
		lineHeight: `${(width * 9) / 100}px`,
		textAlign: "center",
		color: "white !important",
		height: `${minButtonHeigth}px !important`,
		width: `${minButtonWidth}px !important`,
		borderRadius: `${(width * 4) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const buttonWrapperParams = {
	};

	return (
		<Box ref={menuRef} id="#landers-menu" height={`${(width * mobileMenuHeight) / 100}px !important`} textAlign="center" {...boxParams}>
			<Box {...titleParams} mb={`${(width * 1) / 100}px !important`}>
				<Text bold> {menuTitle.split("at")[0]} at </Text>
				<Text bold> {menuTitle.split("at")[1]} </Text>
			</Box>
			<Line />
			<Flex height={"80% !important"} paddingTop={"1%"} justifyContent="center">
				<Flex {...ThreeRowParams}> 
					<Flex {...rows}> 
						{
							Object.keys(menuItemsMobile[1]).map((item, i) => {
								return <ItemCard key={item} title={item} subItems={menuItemsMobile[1][item]} i={i+1} />; 
							})
						}
					</Flex>
					<Flex {...rows}> 
						{
							Object.keys(menuItemsMobile[2]).map((item, i) => {
								return <ItemCard key={item} title={item} subItems={menuItemsMobile[2][item]} i={i+1} />; 
							})
						}
					</Flex>
				</Flex>
			</Flex> 
			<Flex
				flexDirection="column"
				justifyContent={"flex-start"}
				alignItems={"center"}
				{...buttonWrapperParams}
			>
				<Box pb={`${(width * 2.6) / 100}px`}>
					<StyledButton
						onClick={() => window.open(ORDER_SAN_MATEO)}
						isDesktop={false}
						{...buttonParams}
					>
            Order San Mateo
					</StyledButton>
				</Box>
				<Box pb={`${(width * 2.6) / 100}px`}>
					<StyledButton
						onClick={() => window.open(ORDER_SAN_CARLOS)}
						isDesktop={false}
						{...buttonParams}
					>
            Order San Carlos
					</StyledButton>
				</Box>
			</Flex>
		</Box>
	);
};

export default LanderMenu;

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 80) / 100}px`,
		borderBottom: `${`${(width * 0.5) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};

const ItemCard = ({subItems, title}) => {
	const { width } = useWindowSize();
	const wrapperParams = {
		width: `${(width * 50) / 100}px !important`,
		height: "100% !important",
		paddingX: "4%",
	};

	const textParams = {
		color: "black",
		textAlign: "left !important",
		fontSize: `${(width * 5.5) / 100}px !important`,
	};

	const textParams2 = {
		color: "black",
		textAlign: "left !important",
		fontSize: `${(width * 3.5) / 100}px !important`,
		lineHeight:  `${(width * 4) / 100}px`
	};

	const textParams3 = {
		color: "black",
		textAlign: "left !important",
		fontSize: `${(width * 3) / 100}px !important`,
		fontWeight: `${(width * 10) / 100}`,
	};

	const cardParams = {
	};

  
	return (
		<Flex {...cardParams} >
			<Box {...wrapperParams}>
				<Text bold {...textParams}>
					{title}
				</Text>
				{
					subItems.map((item) => {
						const {description, name} = item; 
						return (
							<Box key={`${name}-subiItems`} mb="3%">
								<Text bold {...textParams2}>
									{name}
								</Text>
								<Text {...textParams3}>
									{description}
								</Text>
							</Box>
						);
					})
				}
			</Box>
		</Flex>
	);
};
