import React from "react";
import { Box } from "noshery-ui";

import Header from "../Header3";

const Landing = () => { 

	return (
		<Box>
			<Header isDraper={false}/>
		</Box>
	);
};

export default Landing;
