import React from "react";
import { Box, Flex, Image, Text } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import { altLanders } from "../../constants/landers";

const TheFood = ({
	brandAlts,
	quote,
	author,
	food1,
	food2,
	food3,
	food4,
}) => {
	const { width } = useWindowSize();

	const boxParams = {
		marginTop: `${(width * 4) / 100}px`,
	};

	const boxShadow1 = `${(width * 0.24) / 100}px`;
	const boxShadow2 = `${(width * 1.2) / 100}px`;

	const f1W = 89.3;
	const f1H = 89.3;

	const f1Width = parseInt((width * f1W) / 100);
	const f1Height = parseInt((width * f1H) / 100);

	const food1Params = {
		width: `${f1Width}px !important`,
		height: `${f1Height}px !important`,
		maxWidth: `${f1Width}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 2.7) / 100}px`,
		marginBottom: `${(width * 1.1) / 100}px !important`,
	};

	const f2H = 43.9;
	const f2W = 43.9;

	const f2Width = parseInt((width * f2W) / 100);
	const f2Height = parseInt((width * f2H) / 100);

	const food2Params = {
		width: `${f2Width}px !important`,
		height: `${f2Height}px !important`,
		maxWidth: `${f2Width}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 2.7) / 100}px`,
		marginBottom: `${(width * 1.1) / 100}px !important`,
	};

	const f3H = 89.3;
	const f3W = 43.9;

	const f3Width = parseInt((width * f3W) / 100);
	const f3Height = parseInt((width * f3H) / 100);

	const food3Params = {
		width: `${f3Width}px !important`,
		height: `${f3Height}px !important`,
		maxWidth: `${f3Width}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 2.7) / 100}px`,
		marginBottom: `${(width * 1.1) / 100}px !important`,
	};



	return (
		<Box textAlign="center" {...boxParams}>
			<Box mb={`${(width * 10) / 100}px !important`}>
				<Line /> 
			</Box>
			<Flex justifyContent="center">
				<Flex flexDirection="column">
					<Image
						alt={altLanders[brandAlts]["food1"]}
						style={{ objectFit: "cover" }}
						src={food1(f1Width, f1Height)}
						{...food1Params}
					/>
					<Flex
						flexDirection="row"
						textAlign="left"
						width={food2Params.width}
					>
						<Flex flexDirection="row">
							<Quote quote={quote} author={author} /> 
							<Image
								alt={altLanders[brandAlts]["food4"]}
								style={{ objectFit: "cover" }}
								src={food4(f3Width, f3Height)}
								{...food3Params}
							/>
						</Flex> 
					</Flex>
					<Flex paddingRight={`${(width * 1.4)/100}px`}> 
						<Image
							alt={altLanders[brandAlts]["food2"]}
							style={{ objectFit: "cover" }}
							src={food2(f2Width, f2Height)}
							{...food2Params}
						/>
						<Image
							alt={altLanders[brandAlts]["food3"]}
							style={{ objectFit: "cover" }}
							src={food3(f2Width, f2Height)}
							{...food2Params}
						/>
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
};

const Line = () => {
	const { width } = useWindowSize();
  
	const boxParams = {
		width: `${(width * 70) / 100}px`,
		borderBottom: `${`${(width * 1) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};

const Quote = ({ quote, author }) => {
	const { width } = useWindowSize();
	const yelpImg = "yelp.png";
  
	const boxParams = {
		width: `${(width * 43.9) / 100}px !important`,
		height: `${(width * 89.3) / 100}px`,
		paddingTop: `${(width * 5) / 100}px`,
		paddingRight: `${(width * 3) / 100}px`,
		textAlign: "right"
	};

	return (
		<Box {...boxParams} mb={`${(width * 2.5) / 100}px`}>
			<Text bold fontSize={`${(width * 4.5) / 100}px !important`}>
				<q> {quote} </q>
			</Text>
			<Box mt={`${(width * 3) / 100}px`}>
				<Text bold fontSize={`${(width * 7) / 100}px`}>
            - {author}
				</Text>
			</Box>
			<Box>
				<Image style={{ width: `${(width * 24) / 100}px` }} src={yelpImg} />
			</Box>
		</Box>
	);
};
  


export default TheFood;


