import React from "react";
import { Box, Image, Text, Flex, Button } from "noshery-ui";
import styled from "styled-components";
import Footer from "../Footer";
import NavButtons from "../NavButtons";
import Logo from "../Logo2";
import useDeviceType from "../../../hooks/useDeviceType";
import useWindowSize from "../../../hooks/useWindowSize";
import Select from "react-select";
import Perks from "../Perks";
import WhyNoshery from "../WhyNoshery";
import WhoAreWe from "../WhoAreWe";
import {
	jobs,
	jobCategoryOptions,
	jobLocationOptions,
} from "../../../constants/index";

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const Careers = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();
	const [filterLocation, setfilterLocation] = React.useState(null);
	const [filterCategory, setfilterCatergory] = React.useState(null);

	const imageHeight = parseInt((width * (isDesktop ? 45 : 80)) / 100);

	const imageParams = {
		height: `${imageHeight}px !important`,
	};

	const textParams = {
		fontSize: `${(width * (isDesktop ? 3 : 3)) / 100}px !important`,
		color: "#344734",
		padding: `${(width * (isDesktop ? 2 : 2)) / 100}px`,
		marginTop: "0px",
		marginBottom: "0px",
	};

	const smallTitleParams = {
		fontSize: `${(width * (isDesktop ? 2.5 : 2.5)) / 100}px !important`,
		color: "#344734",
		paddingTop: `${(width * (isDesktop ? 2 : 2)) / 100}px`,
	};

	return (
		<Box position="relative" overflowX="hidden">
			<Logo />
			<NavButtons />
			<Box position="relative">
				<Image
					{...imageParams}
					src={`https://res.cloudinary.com/dwbvlpae2/image/upload/h_${imageHeight},c_fill,g_auto,f_auto/careers/JOB_H_uxflip.webp`}
					width="100%"
					alt="A person is cooking image.in the career page."
					style={{ objectFit: "cover" }}
				/>
				<ImageText />
			</Box>

			<Flex flexDirection="column" alignItems="center">
				<Text as="h1" bold {...textParams}>
					{" "}
          Careers{" "}
				</Text>
				<Flex>
					<Box
						style={{ paddingRight: `${(width * (isDesktop ? 8 : 4)) / 100}px` }}
					>
						<Dropdown
							filter={filterCategory}
							setFilter={setfilterCatergory}
							title={"CATEGORY"}
							options={jobCategoryOptions}
						/>
					</Box>
					<Dropdown
						filter={filterLocation}
						setFilter={setfilterLocation}
						title={"LOCATIONS"}
						options={jobLocationOptions}
					/>
				</Flex>
			</Flex>

			<Flex flexDirection="column" alignItems="center">
				<Text bold {...smallTitleParams}>
					{" "}
          Featured Positions{" "}
				</Text>
				<GridBox>
					{jobs.map((job, i) => {
						if (
							(filterLocation && filterLocation.value !== job.locationValue) ||
              (filterCategory && filterCategory.value !== job.positionValue)
						)
							return false;
						return (
							<JobsCard
								key={`#job${i}`}
								title={job.position}
								location={job.location}
								link={job.link}
							/>
						);
					})}
				</GridBox>
			</Flex>

			<WhyNoshery />

			<Perks />

			<WhoAreWe />

			<Footer /> 
		</Box>
	);
};

export default Careers;

const ImageText = () => {
	const { width } = useWindowSize();

	const { isDesktop } = useDeviceType();
	const imageTextParams = {
		marginTop: `-${(width * 3.3) / 100}px`,
	};

	const topTextParams = {
		fontSize: `${(width * (isDesktop ? 2 : 3)) / 100}px`,
		width: `${(width * (isDesktop ? 85 : 15)) / 100}px`,
	};

	const bottomTextParams = {
		fontSize: `${(width * (isDesktop ? 10 : 13)) / 100}px`,
	};

	const topText = `${(width * (isDesktop ? 30 : 30)) / 100}px`;
	const leftText = `${(width * (isDesktop ? 85 : 85)) / 100}px`;

	return (
		<StyledBox
			{...imageTextParams}
			left={`${leftText} !important`}
			top={topText}
		>
			<Flex position="relative">
				<Box
					top={`${(width * (isDesktop ? 1.5 : 20)) / 100}px`}
					right={`${(width * (isDesktop ? -37 : -10)) / 100}px`}
					position="absolute"
				>
					<Text color="white" {...topTextParams} bold>
            JOIN THE
					</Text>
				</Box>
				{isDesktop ? (
					<Text color="white" {...bottomTextParams} bold>
            Team
					</Text>
				) : (
					<Box
						top={`${(width * (isDesktop ? 1.5 : 20)) / 100}px`}
						right={`${(width * (isDesktop ? 13 : -10)) / 100}px`}
						position="absolute"
					>
						<Text color="white" {...bottomTextParams} bold>
              Team
						</Text>
					</Box>
				)}
			</Flex>
		</StyledBox>
	);
};

const JobsCard = ({ title, location, link }) => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();
	const cardParams = {
		backgroundColor: "#344734",
		width: `${(width * (isDesktop ? 28 : 36)) / 100}px !important`,
		height: `${(width * (isDesktop ? 18 : 25)) / 100}px !important`,
		borderRadius: "10px",
		padding: `${(width * (isDesktop ? 1.2 : 2)) / 100}px !important`,
	};
	const titleParams = {
		color: "#F1A638",
		wordWrap: "break-word",
		fontSize: `${(width * (isDesktop ? 2 : 3)) / 100}px !important`,
	};
	const buttonParams = {
		fontWeight: "bold",
		borderRadius: "15px",
		fontSize: `${(width * (isDesktop ? 2.3 : 2)) / 100}px !important`,
	};
	const locationParams = {
		color: "white",
		fontSize: `${(width * (isDesktop ? 1.5 : 2.5)) / 100}px !important`,
	};
	return (
		<Box padding={`${(width * (isDesktop ? 2 : 2)) / 100}px !important`}>
			<Flex position="relative" flexDirection="column" {...cardParams}>
				<Text bold {...titleParams}>
					{title}
				</Text>
				<Text {...locationParams}>{location}</Text>
				<Flex
					bottom={`${(width * (isDesktop ? 2 : 2)) / 100}px`}
					right={`${(width * (isDesktop ? 2 : 2)) / 100}px`}
					position="absolute"
				>
					<Button onClick={() => window.open(link)} {...buttonParams}>
						{" "}
            Apply Now{" "}
					</Button>
				</Flex>
			</Flex>
		</Box>
	);
};

const Dropdown = ({ title, options, filter, setFilter }) => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			color: "black",
			fontFamily: "Uni Neue",
			textAlign: "left",
			paddingLeft: "5px",
			fontWeight: "bold",
			fontSize: `${(width * (isDesktop ? 2 : 2)) / 100}px !important`,
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "white",
			fontFamily: "Uni Neue",
			textAlign: "left",
			paddingLeft: "5px",
			fontWeight: "bold",
			fontSize: `${(width * (isDesktop ? 2 : 2)) / 100}px !important`,
		}),
		control: () => ({
			backgroundColor: "#F1A638",
			borderRadius: "10px",
			display: "flex",
			padding: `${(width * (isDesktop ? 0.4 : 0.1)) / 100}px !important`,
			height: `${(width * (isDesktop ? 4 : 8)) / 100}px !important`,
			width: `${(width * (isDesktop ? 18 : 30)) / 100}px !important`,
			alignItems: "center",
		}),
		dropdownIndicator: () => ({
			color: "white",
		}),
		clearIndicator: () => ({
			paddingRight: "10px",
			transform: `${isDesktop ? "scale(1.3)" : "scale(1)"}`,
		}),
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "white",
				fontFamily: "Uni Neue",
				textAlign: "left",
				paddingRight: "8px",
				fontWeight: "bold",
				fontSize: `${(width * (isDesktop ? 2 : 2)) / 100}px !important`,
			};
		},
	};
	return (
		<Box>
			<Select
				isClearable
				defaultValue={filter}
				options={options}
				onChange={setFilter}
				styles={customStyles}
				placeholder={title}
			/>
		</Box>
	);
};
