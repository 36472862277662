import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import { Box } from "noshery-ui";
import MobileSidebar from "../Sidebar";
import { useSwipeable } from "react-swipeable";
import PressReleaseHeader from "../Press/PressHeader";
import PressRelease from "../Press/PressRelease";

const AnimatedBox = animated(Box); 

const Landing = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false); 
	const [headerOpen, setHeaderOpen] = useState(true); 
	const [offset, setOffset] = useState(0);

	const { left } = useSpring({
		config: { duration: 200},
		from: { left: "0%"},
		left: sidebarOpen ? "60%" : "0%",
	});

	React.useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	const handlers = useSwipeable({
		onSwipedLeft: () => setSidebarOpen(false),
		onSwipedRight: () => setSidebarOpen(true),
		onSwipedDown: () => setHeaderOpen(true),
		onSwipedUp: () => setHeaderOpen(false),
		swipeDuration: 500,
		delta: { up: 0, down: 0, left: 80, right: 100 },
		trackMouse: true
	});

	return (
		<Box {...handlers} overflow="hidden">
			<MobileSidebar isLanding setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
			<Box overflow="hidden">
				<AnimatedBox style={{ left: left, position: "relative"}}> 
					<PressReleaseHeader
						sidebarOpen={sidebarOpen} 
						setSidebarOpen={setSidebarOpen}
						headerOpen={headerOpen} 
						onScroll={offset} />
					<PressRelease />
				</AnimatedBox>
			</Box> 
		</Box>
	);
};

export default Landing;
