import React from "react";
import { Box, Image, Text } from "noshery-ui";
import styled from "styled-components";
import MobileQuestions from "./MobileQuestions";
import useWindowSize from "../../hooks/useWindowSize";
import { ALT_IMGS } from "../../constants";

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const Faq = () => {
	const { width } = useWindowSize();

	const imageParams = { height: `${(width * 41) / 100}px !important` };
	const coverHeight = parseInt((width * 41) / 100);
	const imageCoverParams = {
		width: "100%",
		height: `${coverHeight}px !important`,
		backgroundColor: "rgba(241, 166, 56, 0.5)",
	};

	return (
		<Box id="#mobilefaq">
			<Box position="relative">
				<Box {...imageCoverParams} position="absolute" top={0} />
				<Image
					alt={ALT_IMGS["faq"].altImg}
					{...imageParams}
					src={`https://res.cloudinary.com/dwbvlpae2/image/upload/h_${coverHeight ? coverHeight: 1},c_fill,g_auto,f_auto/Landing/LP_FAQ_nuej6n.webp`}
					width="100%"
					style={{ objectFit: "cover" }}
				/>
				<ImageText />
			</Box>
			<MobileQuestions />
		</Box>
	);
};

export default Faq;

const ImageText = () => {
	const { width } = useWindowSize();
	const imageTextParams = {
		top: `${(width * 4) / 100}px`,
	};

	const titleParams = {
		fontSize: `${(width * 8) / 100}px`,
		lineHeight: `${(width * 8) / 100}px`,
	};

	const faqTextParams = { fontSize: `${(width * 17) / 100}px` };

	return (
		<StyledBox {...imageTextParams}>
			<Box>
				<Text color="white" {...titleParams} bold>
          How can we help?
				</Text>
			</Box>
			<Box>
				<Text color="white" {...faqTextParams} bold>
          FAQ
				</Text>
			</Box>
		</StyledBox>
	);
};
