import React from "react";
import { Flex, Box, Image, Text } from "noshery-ui";
import styled from "styled-components";
import Questions from "./Questions2";
import RewardsCard from "./RewardsCards";
import useWindowSize from "../../hooks/useWindowSize";
import { qAsLoyalty } from "../../constants/faq";
import Logo from "./Logo2";
import { ALT_IMGS } from "../../constants";
import NavButtons from "./NavButtons";

const StyledBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const FaqLoyalty = () => {
	const { width } = useWindowSize();
	const [chosenQ, setChosenQ] = React.useState(0);

	const boxParams = {
	};

	const imageParams = { height: `${(width * 39.72) / 100}px !important` };
	const title = {
		paddingTop: `${(width * 4.1) / 100}px !important`,
		paddingBototm: `${(width * 4.1) / 100}px !important`
	};

	return (
		<Box {...boxParams}>
			<Box position="relative">
				<Logo /> 
				<NavButtons /> 
				<Image
					alt={ALT_IMGS["faq"].altImg}
					{...imageParams}
					src={"https://res.cloudinary.com/dwbvlpae2/image/upload/v1661544505/Rewards/rewardsbg_ddnko4.webp"}
					width="100%"
					style={{ objectFit: "cover" }}
				/>
				<ImageText />
			</Box>
			<RewardsCard />
			<Flex {...title} justifyContent="center"> 
				<Text color="#344734" bold fontSize={`${(width * 8.86) / 100}px !important`}> Rewards FAQ </Text>
			</Flex>
			<Flex>
				<Box style={{ marginTop: `${(width * 5.1) / 100}px` }}>
					{qAsLoyalty.map((qna, i) => {
						return (
							<SingleQ
								key={i}
								chosen={chosenQ === i}
								value={i}
								setChosenQ={setChosenQ}
								q={qna.q}
							/>
						);
					})}
				</Box>
				<Box>
					<Questions chosenQ={chosenQ} />
				</Box>
			</Flex>
		</Box>
	);
};

export default FaqLoyalty;

const BoxQ = styled(Box)`
  color: ${(p) => (p.chosen ? "#8B6017" : "rgba(0, 0, 0, 0.35)")};
`;

const SingleQ = ({ q, setChosenQ, value, chosen }) => {
	const { width } = useWindowSize();

	const params = {
		fontSize: `${(width * 1.5) / 100}px`,
		lineHeight: `${(width * 3) / 100}px`,
	};

	return (
		<Box
			onClick={() => {
				setChosenQ(value);
			}}
			a
			style={{
				paddingLeft: `${(width * 4) / 100}px`,
				paddingRight: `${(width * 6) / 100}px`,
				cursor: "pointer",
			}}
		>
			<BoxQ {...params} chosen={chosen} mb={`${(width * 3) / 100}px`}>
				<Text bold>{q}</Text>
			</BoxQ>
		</Box>
	);
};

const ImageText = () => {
	const { width } = useWindowSize();
	const imageTextParams = {
		top: `${(width * 12) / 100}px`,
	};

	const faqTextParams = { 
		fontSize: `${(width * 15.62) / 100}px`, 
		width: width,
		marginTop: "0px",
		marginBottom: "0px",
	};

	return (
		<StyledBox {...imageTextParams}>
			<Box>
				<Text as="h1" color="white" {...faqTextParams} bold>
          Nosh Points
				</Text>
			</Box>
		</StyledBox>
	);
};
