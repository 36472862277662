import React from "react";
import { Link } from "react-router-dom";
import { Box, Text, Flex } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";

import useDeviceType from "../../hooks/useDeviceType";

const NavButtons = () => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType(); 

	const boxShadow1 = `${(width * 0.24) / 100}px`;
	const boxShadow2 = `${(width * 1.2) / 100}px`;

	const rigthContact = isDesktop ? `
    ${(width * 3) / 100}px` : `${(width * 1.5) / 100}px`; 

	const paddingButtons = isDesktop ? 
		`${(width * 1) / 100}px ${(width * 2) / 100}px` :  `${(width * 2) / 100}px ${(width * 2.3) / 100}px`; 

	const ContactParams = {
		zIndex: 9,
		top: `${(width * 3) / 100}px`,
		right: rigthContact,
		padding: paddingButtons,
		borderRadius: `${(width * .72) / 100}px`,
		minWidth: `${(width * 12) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};
 
	const textRatio = isDesktop ? `${(width * 2) / 100}px` : `${(width * 2.4) / 100}px`;

	const textParams = {
		fontSize: textRatio, 
		lineHeight: `${(width * 2.2) / 100}px`,
	};


	return (
		<Flex > 
			<Box position="absolute" bg="white"  {...ContactParams}>
				<Link 
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						if(window !== undefined) window.open("mailto:contact@ordernoshery.com");
					}} 
					to="" style={{ textDecoration: "none" }} color="black">
					<Text bold {...textParams} color="black">
             Contact Us 
					</Text>
				</Link>
			</Box>
		</Flex>
	);
};

export default NavButtons;
