import React, { useState } from "react";
import { Box, Flex, Text, Image, Button } from "noshery-ui";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { validateEmail } from "../../utils/";

import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

const StyledButton = styled(Button)`
  box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.25);
  border-radius: ${(p) => `${(p.w * 1)/100}px`};
  width: ${(p) => `${(p.w * 26)/100}px`};
  height: ${(p) => `${(p.w * 11)/100}px`};
`;

const API_URL = process.env.REACT_APP_API_URL;

const SUCCESS_MESSAGE = "Your form was submitted successfully!";
const ERROR_MESSAGE =
  "Error happened while submitting. Please try again later.";

const ContactUsForm = () => {
	const recaptchaRef = React.createRef();
	const { isDesktop, isTablet } = useDeviceType();
	const { width } = useWindowSize();

	const [fullname, setFullname] = useState("");
	const [email, setEmail] = useState("");
	const [location, setLocation] = useState("San Mateo"); 
	const [message, setMessage] = useState("");
	const [verified, setVerified] = useState(null);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [errors, setErrors] = useState({
		email: null,
		fullname: null,
		message: null,
	});

	const validateInputs = () => {
		setErrors({
			email: validateEmail(email)
				? null
				: "Please enter a valid email address.",
			fullname: fullname ? null : "Fullname is required.",
			location: location ? null : "Location is required.",
			message: message ? null : "Message is required.",
		});
	};

	const clearState = () => {
		setFullname("");
		setEmail("");
		setLocation("San Mateo");
		setMessage("");
		setShowErrorMessage(false);
		setShowSuccessMessage(false);
		setSubmitting(false);
	};

	const onClickSubmit = async () => {
		validateInputs();
		if (!fullname || !email || !message) {
			return;
		}
		recaptchaRef.current.reset();
		setSubmitting(true);
		const res = await fetch(`${API_URL}/sendEmail`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				name: fullname,
				message,
				location,
				captcha: verified,
			}),
		});

		const { error } = await res.json();

		if (error) {
			setShowErrorMessage(true);
		} else {
			setShowSuccessMessage(true);
		}

		setTimeout(clearState, 5000);
	};

	const onChange = (captcha) => {
		setVerified(captcha);
	};

	const submitBParams = {
		fontSize: `${(width * 4) / 100}px`,
		lineHeight: `${(width * 3.1) / 100}px`,
		paddingLeft: `${(width * 1) / 100}px`,
		paddingRight: `${(width * 1) / 100}px`,
	};

	let disabled = !verified || submitting;

	const wrapperParams = {
		marginTop: `${(width * 7.4) / 100}px`,
		paddingTop:  `${(width * 25) / 100}px`,
	};

	const messageWrapperParams = {
		marginBottom: `${(width * 1) / 100}px`,
	};

	const errorParams = {
		fontSize: `${(width * 2) / 100}px`,
		height: `${(width * 6) / 100}px`,
		borderRadius: `${(width * 2) / 100}px`,
		color: "white",
		width: `${(width * 64.5) / 100}px`,
		backgroundColor: "#8B6017",
		flexDirection: "column",
		justifyContent: "center",
		boxShadow: "6.02787px 6.02787px 30.1393px 6.02787px rgba(0, 0, 0, 0.25)",
	};

	const captchaParams =
    isDesktop || isTablet
    	? {}
    	: {
    		transform: "scale(0.8)",
    		transformOrigin: "0 0",
    	};

	const recaptchaWrapper =
    isDesktop || isTablet ? { justifyContent: "center" } : {};

	return (
		<Box {...wrapperParams}>
			<Flex flexDirection="column" alignItems="center">
				<Box> 
					<Inputbox
						title="Full Name"
						value={fullname}
						error={errors["fullname"]}
						onChange={(e) => {
							setFullname(e.target.value);
							setErrors({
								...errors,
								fullname: e.target.value ? null : "Fullname is required.",
							});
						}}
					/>
				</Box>
				<Box> 
					<Inputbox
						title="Email Address"
						value={email}
						error={errors["email"]}
						onChange={(e) => {
							setEmail(e.target.value);
							setErrors({
								...errors,
								email: validateEmail(e.target.value)
									? null
									: "Please enter a valid email address.",
							});
						}}
					/>   
				</Box>

				<Box> 
					<Selectbox
						title="Location"
						value={location} 
						onChange={(e) => {
							setLocation(e.target.value);
						}}
					/>   
				</Box>

				<Textbox
					title="Your Message"
					value={message}
					error={errors["message"]}
					onChange={(e) => {
						setMessage(e.target.value);
						setErrors({
							...errors,
							message: e.target.value ? null : "Message is required.",
						});
					}}
				/>

				<Flex
					mb={isDesktop ? "32px" : isTablet ? "16px" : "8px"}
					width={isDesktop ? "950px" : isTablet ? "490px" : "247px"}
					{...recaptchaWrapper}
				>
					<ReCAPTCHA
						ref={recaptchaRef}
						sitekey="6LeOpP8fAAAAAMsTede0daz-ENLXVI_IZTOu_WyC"
						onChange={onChange}
						style={{ ...captchaParams }}
					/>
				</Flex>
				<Box {...messageWrapperParams}>
					{showErrorMessage && (
						<Flex {...errorParams}>
							<Flex>
								<Box paddingLeft={"10px"} paddingRight={"10px"}>
									<Image
										alt="error image"
										style={{
											width: `${(width * 4) / 100}px`,
											height: `${(width * 4) / 100}px`,
										}}
										src={"error.png"}
									/>
								</Box>
								<Flex
									style={{ justifyContent: "center", flexDirection: "column" }}
								>
									<Text bold>{ERROR_MESSAGE}</Text>
								</Flex>
							</Flex>
						</Flex>
					)}
					{showSuccessMessage && (
						<Flex style={{ backgroundColor: "#60785e" }} {...errorParams}>
							<Flex>
								<Box paddingLeft={"10px"} paddingRight={"10px"}>
									<Image
										alt="success image"
										style={{
											width: `${(width * 4) / 100}px`,
											height: `${(width * 4) / 100}px`,
										}}
										src={"success.png"}
									/>
								</Box>
								<Flex
									style={{ justifyContent: "center", flexDirection: "column" }}
								>
									<Text bold>{SUCCESS_MESSAGE}</Text>
								</Flex>
							</Flex>
						</Flex>
					)}
				</Box>
				<Box mb={"26px"}>
					<StyledButton w={width} onClick={onClickSubmit} disabled={disabled}>
						<Text bold {...submitBParams} textAlign="center" color="white">
              Submit
						</Text>
					</StyledButton>
				</Box>
			</Flex>
		</Box>
	);
};

export default ContactUsForm;

const Inputbox = ({
	title,
	value,
	onChange,
	error,
	placeholder,
	type,
}) => {
	const { width } = useWindowSize();
  
	const wrapperParams = {
		width: `${(width * 78.6) / 100}px`,
		height: `${(width * 20) / 100}px`,
	};
  
	const textParams = {
		fontSize: `${(width * 3.8) / 100}px`,
		lineHeight: `${(width * 3.8) / 100}px`,
		position: "absolute",
		color: error ? "red" : "black",
		paddingBottom: `${(width * 2.4) / 100}px`,
	};
  
	const inputHeigth = (width * 9.42);
  
	const marginBottomtext = (width * 2) / 100;
  
	return (
		<Box {...wrapperParams}>
			<Flex
				mb={`${marginBottomtext}px`}
				justifyContent="space-between"
				alignItems="center"
			>
				<Text bold {...textParams}>
					{title}*
				</Text>
			</Flex>
			<div >
				<input
					value={value}
					onChange={onChange}
					type={type}
					min={0}
					placeholder={placeholder ? placeholder : title}
					style={{
						height: `${inputHeigth / 100}px`,
						outline: "none",
						backgroundColor: "#EDEDED",
						boxShadow: "4px 4px 20px 4px rgba(0, 0, 0, 0.25)",
						borderRadius: `${(width * 2.1) / 100}px`,
						width: `calc(100% - ${(width * 4.1) / 100}px)`,
						border: error ? "3px solid red" : "none",
						fontSize: `${(width * 3) / 100}px`,
					}}
				/>
			</div>
		</Box>
	);
};

const Selectbox = ({
	title,
	value,
	onChange,
	error,
	placeholder,
	type,
}) => {
	const { width } = useWindowSize();
  
	const wrapperParams = {
		width: `${(width * 78.6) / 100}px`,
		height: `${(width * 20) / 100}px`,
	};
  
	const textParams = {
		fontSize: `${(width * 3.8) / 100}px`,
		lineHeight: `${(width * 3.8) / 100}px`,
		position: "absolute",
		color: error ? "red" : "black",
		paddingBottom: `${(width * 2.4) / 100}px`,
	};
  
	const inputHeigth = (width * 9.42);
  
	const marginBottomtext = (width * 2) / 100;
  
	return (
		<Box {...wrapperParams}>
			<Flex
				mb={`${marginBottomtext}px`}
				justifyContent="space-between"
				alignItems="center"
			>
				<Text bold {...textParams}>
					{title}*
				</Text>
			</Flex>
			<div>
				<select
					value={value}
					onChange={onChange}
					type={type}
					min={0}
					placeholder={placeholder ? placeholder : title}
					style={{
						outline: "none",
						backgroundColor: "#EDEDED",
						height: `${inputHeigth / 100}px`,
						boxShadow: "4px 4px 20px 4px rgba(0, 0, 0, 0.25)",
						borderRadius: `${(width * 2) / 100}px`,
						width: `calc(100% - ${(width * 4.1) / 100}px)`,
						border: error ? "4px solid red" : "none",
						fontSize: `${(width * 3.8) / 100}px`,
					}}
				>
					<option value="San Mateo">San Mateo</option>
					{/* <option value="San Carlos">San Carlos</option> */}
				</select>
			</div>
		</Box>
	);
};

const Textbox = ({ title, value, onChange, error }) => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType();

	const wrapperParams = {
		width: `${(width * 78.6) / 100}px`,
		paddingTop: `${(width * (isDesktop ? 0 : 2)) / 100}px`
	};

	const textParams = {
		fontSize: `${(width *  3.8) / 100}px`,
		lineHeight: `${(width * 3.8) / 100}px`,
		color: error ? "red" : "black",
		paddingBottom: `${(width * 2.4) / 100}px`,
	};

	return (
		<Box {...wrapperParams}>
			<Flex
				mb={`${(width * 2) / 100}px`}
				justifyContent="space-between"
				alignItems="center"
			>
				<Text bold {...textParams}>
					{title}*
				</Text>
			</Flex>
			<div style={{ position: "relative", height: `${(width * 30) / 100}px`,}}>
				<textarea
					value={value}
					onChange={onChange}
					placeholder={title}
					style={{
						resize: "none",
						outline: "none",
						height: `${(width * 25) / 100}px`,
						backgroundColor: "#EDEDED",
						boxShadow: "4px 4px 20px 4px rgba(0, 0, 0, 0.25)",
						borderRadius: `${(width * 2) / 100}px`,
						width: `calc(100% - ${(width * 3.6) / 100}px)`,
						border: error ? "4px solid red" : "none",
						position: "absolute",
						top: 0,
						fontSize: `${(width * 3.8) / 100}px`,
					}}
				/>
			</div>
		</Box>
	);
};

