import React from "react";
import { Box, Heading, Flex } from "noshery-ui";
import styled from "styled-components";

import SingleBrand from "./SingleBrand";
import { landingBrands } from "../../constants";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize";

const Title = styled(Heading)`
  text-align: center;
  font-weight: 800;
`;

const BrandPartners = () => {
	const { isDesktop } = useDeviceType();
	const { width } = useWindowSize();

	const wrapperParams = {
		marginTop: `${(width * 10) / 100}px`,
	};

	const titleParams = {
		fontSize: `${(width * (2)) / 100}px`,
		lineHeight: `${(width * (2)) / 100}px`, 
	};

	return (
		<Box {...wrapperParams}>
			<Title {...titleParams} bold>
			Please continue to support our <br /> fantastic restaurant partners!
			</Title>

			<Flex
				flexWrap="wrap"
				alignItems="center"
				justifyContent="center"
				maxWidth={"90%"}
				margin="auto"
			>
				{Object.keys(landingBrands).map((l) => {
					const brand = landingBrands[l];
					if(brand.name === "comingsoon") return false;
					return <SingleBrand key={l} {...brand} />;
				})}
			</Flex>
		</Box>
	);
};

export default React.memo(BrandPartners);
