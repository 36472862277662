import React from "react"; 
import { Box, Flex, Text, } from "noshery-ui";
import Logo from "./Logo";
import NavButtons from "./NavButtons";
import DraperLogo from "./DraperLogo";
import DraperLogo2 from "./DraperLogo2";
import DraperNav from "./DraperNav";
import useDeviceType from "../../hooks/useDeviceType";
import useWindowSize from "../../hooks/useWindowSize"; 
import BrandPartners from "./BrandPartners";

const Header = ({isDraper}) => { 
	const { isDesktop } = useDeviceType();
   
	return (
		<Box position="relative" overflowX="hidden">
			<Logo />
			{isDraper && isDesktop && <DraperLogo />}
			{isDraper && !isDesktop && <DraperLogo2 />}
			{isDraper ? <DraperNav /> : <NavButtons />}
			{/* <HolidayHours /> */}
			<Flex justifyContent={"space-between"} position="relative">
				{isDesktop && <Content isDraper={isDraper} />}
				{!isDesktop && <Content isDraper={isDraper} />}
			 	<BrandPartners />
			</Flex>
		</Box>
	);
};

export default Header;

const Content = () => {
	const { width } = useWindowSize();
	const { isDesktop, isTablet } = useDeviceType(); 
	const styledHeadingParams = (isDesktop || isTablet)
    	? {
    		fontSize: `${(width * 1.7) / 100}px`,
    		lineHeight: `${(width * 2) / 100}px`,
    		marginTop: "0px",
    		marginBottom: "0px",
    	}
    	: {
    		fontSize: `${(width * 1.8) / 100}px`,
    		lineHeight: `${(width * 1) / 100}px`,
    		marginTop: "0px",
    		marginBottom: "0px",
    	};
	const styledTextParams = (isDesktop || isTablet)
    	? {
    		fontSize: `${(width * 1.5) / 100}px !important`,
    		lineHeight: `${(width * 2) / 100}px !important`,
    	}
    	: {
    		fontSize: `${(width * 4) / 100}px !important`,
    		lineHeight: `${(width * 1.5) / 100}px !important`,
    	};  
	const buttonWidthPercentage = (isDesktop || isTablet) ? 17.1 : 28; 
	const minButtonWidth = (width * buttonWidthPercentage) / 100; 
	const buttonRightMargin = (width * 1.9) / 100; 
 
	const wrapperParams = isDesktop
		? {
			marginLeft: `${(width * 8.1) / 100}px`,
			marginTop: `${(width * 10.7) / 100}px`,
		}
		: {
			marginLeft: `${(width * 8.1) / 100}px`,
			marginTop: `${(width * 20.7) / 100}px`,
		};  

	return ( 
		<Box {...wrapperParams}>
			<Flex width={`${2 * minButtonWidth + buttonRightMargin}px !important`} flexDirection="column">
				<Text as="h1" bold {...styledHeadingParams}>
				We’re excited to introduce Swing Shift, a new operation from an old friend, who has taken over our San Mateo location.  
				</Text>
				<Text as="h1" {...styledTextParams}>
				Swing Shift features a diverse menu of urban comfort food (think all of your favorites, in one place).
				</Text>
				<Text as="h1" {...styledTextParams}>
				Learn more here: <a href="https://myswingshift.com/" target="_blank" rel="noreferrer"> https://myswingshift.com/ </a>
				</Text> 
				<Text as="h1" bold {...styledHeadingParams} style={{
					marginTop: "30px"
				}} >
					The Noshery Team 
				</Text>
			</Flex>  
		</Box>
	);
};

Content.defaultProps = {
	title: "Eight Restaurants. One Order.",
	description: "",
};
