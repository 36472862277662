import React from "react";
import { Link } from "react-router-dom";
import { Box, Text, Flex } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";

import useDeviceType from "../../hooks/useDeviceType";

const NavButtons = () => {
	const { width } = useWindowSize();
	const { isDesktop } = useDeviceType(); 

	const boxShadow1 = `${(width * 0.24) / 100}px`;
	const boxShadow2 = `${(width * 1.2) / 100}px`;

	const rigthContact = isDesktop ? `
    ${(width * 3) / 100}px` : `${(width * 1.5) / 100}px`; 

	const rigthLoyalty = isDesktop ?
		`${ 2.4 * ((width * 6.3) / 100)}px` : `${ 3.5 * ((width * 4.8)/ 100)}px`; 
   
	const paddingButtons = isDesktop ? 
		`${(width * 1) / 100}px ${(width * 2) / 100}px` :  `${(width * 2) / 100}px ${(width * 2.3) / 100}px`; 

	const ContactParams = {
		zIndex: 9,
		top: `${(width * 3) / 100}px`,
		right: rigthContact,
		padding: paddingButtons,
		borderRadius: `${(width * .72) / 100}px`,
		minWidth: `${(width * 12) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};

	const Seperator1 = isDesktop ?
		`${ ((width * 15.7) / 100)}px` : `${((width * 17.3) / 100)}px`; 
	const SeperatorParams1 = {
		zIndex: 20,
		textAlign: "center", 
		top: `${(width * (isDesktop ? 3.8 : 4.8)) / 100}px`,
		right: Seperator1,
		fontSize: `${(width * 2.4) / 100}px`,
		position: "absolute",
		opacity: ".2"
	};

	const boxShadow3 = `${(width * 10) / 100}px`;

	const LoyaltyParams = {
		zIndex: 10,
		textAlign: "center", 
		top: `${(width * 3) / 100}px`,
		borderRadius: `${(width * .72) / 100}px`,
		right: rigthLoyalty,
		padding: paddingButtons,
		minWidth: `${(width * 12) / 100}px`,
		boxShadow: `0 ${boxShadow3}px ${boxShadow3}px -${boxShadow3}px rgba(0, 0, 0, 0.25), 0 -${boxShadow3}px ${boxShadow3}px -${boxShadow3}px rgba(0, 0, 0, 0.25) `,
	};

	const textRatio = isDesktop ? `${(width * 2) / 100}px` : `${(width * 2.4) / 100}px`;

	const textParams = {
		fontSize: textRatio, 
		lineHeight: `${(width * 2.2) / 100}px`,
	};


	return (
		<Flex > 
			<div style={SeperatorParams1}> | </div> 
			<Box position="absolute" bg="white"  {...LoyaltyParams}>
				<Link to="/rewards"
					style={{ textDecoration: "none" }}>
					<Text bold {...textParams} color="black">
              Rewards 
					</Text>
				</Link>
			</Box>
			<Box position="absolute" bg="white"  {...ContactParams}>
				<Link to="/contact-us" style={{ textDecoration: "none" }} color="black">
					<Text bold {...textParams} color="black">
             Contact Us 
					</Text>
				</Link>
			</Box>
		</Flex>
	);
};

export default NavButtons;
