import React from "react";
import { Box } from "noshery-ui";

import useDeviceType from "../hooks/useDeviceType";
import MobileLandersPage from "../components/mobile/pages/Landers"; 
import DesktopLandersPage from "../components/desktop/pages/LandersPage";

const Landing = ({brand}) => {
	const { isDesktop, isTablet } = useDeviceType();

	return (
		<Box>
			{isDesktop || isTablet ? 
				<DesktopLandersPage {...brand} /> : 
				<MobileLandersPage {...brand} />}
		</Box>
	);
};

export default Landing;
