import React from "react";
import { Box, Flex, Text } from "noshery-ui";
import styled from "styled-components";
import {qAs} from "../../constants/faq";

import useWindowSize from "../../hooks/useWindowSize";


const Questions = ({chosenQ}) => {
	const { width } = useWindowSize();

	const wrapperParams = {
		marginTop: `${(width * 5.1) / 100}px`,
	};

	const boxParams = {
		width: `${(width * 55) / 100}px`,
	};

	return (
		<Flex justifyContent="center" alignItems="center" {...wrapperParams}>
			<Box {...boxParams}>
				<SingleQA 
					q={qAs[chosenQ].q} 
					a={qAs[chosenQ].a}     
				/>
			</Box>
		</Flex>
	);
};

export default Questions;

const SingleQA = ({ q, a }) => {
	const { width } = useWindowSize();
	const boxShadow1 = `${(width * 0.2) / 100}px`;
	const boxShadow2 = `${(width * 1) / 100}px`;

	const params = {
		fontSize: `${(width * 1.8) / 100}px`,
		lineHeight: `${(width * 2.5) / 100}px`,
		padding: `${(width * 2.1) / 100}px ${(width * 2.4) / 100}px`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
		borderRadius: `${(width * 2) / 100}px`,
	};

	return (
		<Box >
			<BoxQ {...params} mb={`${(width * 2.5) / 100}px`}>
				<Text bold>{q}</Text>
			</BoxQ>
			<BoxA {...params} mb={`${(width * 2.5) / 100}px`}>
				{
					(q === "How do I register for rewards?" &&
              <HowToRegister a={a} />) 
            ||
              (q === "How do I check my rewards balance?" &&
              <HowDoICheck a={a} />)
            ||
              (q === "How do I earn Nosh points?" &&
              <HowDoIEarn a={a} />)
            ||
            a
				}
			</BoxA>
		</Box>
	);
};

const BoxQ = styled(Box)`
  background: #f1a638;
  color: #ffffff;
`;

const BoxA = styled(Box)`
  background: #ededed;
  color: #000000;
  transition: 1s;
  transform: scaleY(1);
  transform-origin: top;
`;

const HowToRegister = ({a}) => {
	return (
		<span> 
      Register for rewards
			<a href="https://my.spendgo.com/index.html#/register/noshery" target="_blank" rel="noopener noreferrer" > here. </a>
			{a}
		</span>
	);
};

const HowDoICheck = ({a}) => {
	return (
		<span>
			{a}
			<a href="https://my.spendgo.com/index.html#/signIn/noshery" target="_blank" rel="noopener noreferrer" > here. </a>
		</span>
	);
};

const HowDoIEarn = ({a}) => {
	let answer = a.split("website purchases");
	return (
		<span>
			{answer[0]}
			<span style={{fontWeight: "900"}}> website purchases </span>
			{answer[1]}
		</span>
	);
};
