import React from "react";
import { Box } from "noshery-ui";
import BrandHeader from "../BrandHeader2";
import TheFood from "../TheFood";
import Footer from "../Footer";
import BrandList from "../BrandList"; 

const BrandPage = ( brand ) => {
	return (
		<Box>
			<BrandHeader {...brand} /> 
			<TheFood {...brand} />     
			<BrandList {...brand} />   
			<Footer /> 
		</Box>
	);
};

export default BrandPage;
