import React from "react";
import { Box, Flex, Button, Text } from "noshery-ui";
import useDeviceType from "../../hooks/useDeviceType";
import { landingBrands } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";

const StyledButton = styled(Button)`
  font-family: "Uni Neue Heavy";
  background-color: #344734;
`;

const ThanksgivingBanner = ({ paddingTopBranList }) => {
	const { width } = useWindowSize();
	const { isDesktop, isTablet } = useDeviceType();
	const logos = Object.keys(landingBrands);

	const containerParams = {
		padding: `${(width * paddingTopBranList) / 100}px 0px !important`,
		height: `${(width * 20) / 100}px !important`,
		alignItems: "center",
		flexDirection: "column",
		marginTop: "5%"
	};

	const textParams = {
		fontSize: `${(width * 3) / 100}px !important`,
	};

	const textParams2 = {
		fontSize: `${(width * 2) / 100}px !important`,
		width: `${(width * 50) / 100}px !important`,
		fontWeight: "bold",
	};

	const buttonTextParams = isDesktop
		? {
			fontSize: `${(width * 2.3) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,
		}
		: {
			fontSize: `${(width * 4) / 100}px`,
			lineHeight: `${(width * 3.1) / 100}px`,

		};
  
	const buttonWidthPercentage = isDesktop || isTablet ? 20 : 28;
	const minButtonWidth = (width * buttonWidthPercentage) / 100;
	const buttonRightMargin = (width * 1.9) / 100;

	const boxShadow1 = `${(width * 0.1) / 100}px`;
	const boxShadow2 = `${(width * 1.0) / 100}px`;

	const buttonParams = {
		...buttonTextParams,
		minWidth: `${minButtonWidth + buttonRightMargin}px`,
		borderRadius: `${(width * 0.72) / 100}px !important`,
		boxShadow: `${boxShadow1} ${boxShadow1} ${boxShadow2} ${boxShadow1} rgba(0, 0, 0, 0.25)`,
	};


	return (
		<Flex 
			{...containerParams}>
			<Box textAlign="center" marginBottom="0"> 
				<Text bold {...textParams}> Use code THANKSGIVING20 </Text> 
				<Text {...textParams2}> for 20% off ANY order over $40 - this weekend only. Mix and match from any restaurant brand partner at Noshery, all in one order. Valid November 25-28, 2022.  </Text>
			</Box>
			<Line /> 
			<StyledButton onClick={() => window.open("https://online.ordernoshery.com/")} {...buttonParams}> Order Now </StyledButton> 
		</Flex>
	);
};

const Line = () => {
	const { width } = useWindowSize();

	const boxParams = {
		width: `${(width * 65) / 100}px`,
		borderBottom: `${`${(width * 0.34) / 100}px`} solid`,
		borderRadius: `${(width * 0.4) / 100}px`,
	};
	return <Box {...boxParams} borderColor="#F1A638" margin="auto" />;
};

export default ThanksgivingBanner;
