import React from "react";
import { Box } from "noshery-ui";
import useWindowSize from "../../hooks/useWindowSize";
import Hamburger from "./Hamburger";
import Logo from "./Logo3";
import DraperLogo from "./DraperLogo";
import { animated, useSpring } from "react-spring";

const AnimatedContainer = animated(Box);

const Nav = ({
	sidebarOpen,
	setSidebarOpen,
	scrolling,
	headerOpen,
	isDraper,
}) => {
	const { width } = useWindowSize();
 

	const { backgroundColor, boxShadow } = useSpring({
		config: {
			duration: 200,
		},
		from: {
			backgroundColor: "rgba(0, 0, 0, 0)",
			boxShadow: "0px 0px 0px rgb(0, 0, 0, 0)",
		},
		backgroundColor: scrolling !== 0 ? "white" : "rgba(0, 0, 0, 0)",
		boxShadow:
      scrolling !== 0
      	? "0px 4px 4px rgba(0, 0, 0, 0.25)"
      	: "0px 0px 0px rgb(0, 0, 0, 0)",
	});

	const { top } = useSpring({
		config: {
			duration: 100,
		},
		from: {
			top: 0,
		},
		top: headerOpen || scrolling === 0 ? 0 : -500,
	});

	return (
		<AnimatedContainer zIndex={15} position="fixed" style={{ top: top }}>
			<AnimatedContainer
				style={{
					position: "absolute",
					zIndex: 5,
					width: `${width}px`,
					boxShadow: boxShadow,
					height: `${(width * 35) / 100}px`,
					borderRadius: `0px 0px ${(width * 8.2) / 100}px ${
						(width * 8.2) / 100
					}px`,
					backgroundColor: backgroundColor,
				}} 
			> 
				<Logo variant={scrolling !== 0 || sidebarOpen ? 1 : 0} />
				{isDraper && !scrolling ? <DraperLogo /> : null}
			</AnimatedContainer>
		</AnimatedContainer>
	);
};

export default Nav;
