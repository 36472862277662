import React from "react";
import { Box } from "noshery-ui";

import useDeviceType from "../hooks/useDeviceType"; 
import DesktopBrandPage from "../components/desktop/pages/BrandPage";
import MobileBrandPage from "../components/mobile/pages/BrandPage"; 

const BrandPage = ({ brand }) => {
	const {isDesktop, isTablet} = useDeviceType();

	return (
		<Box>
			{isDesktop || isTablet ?
				<DesktopBrandPage {...brand} /> : 
				<MobileBrandPage {...brand} />}
		</Box>
	);
};

export default BrandPage;
