import React from "react";
import { Flex, Box, Text } from "noshery-ui";
import { whatwedo, mixandmatch, DraperUniversity } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";

const DraperLogo = styled.img`
  width: ${(p) => p.width};
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;
const NosheryLogo = styled.img`
  width: ${(p) => p.width};
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-size: contain;
  background-position: center center;
  filter: brightness(0) invert(1);
  background-repeat: no-repeat;
`;
const WhatWeDo = () => {
	const { width } = useWindowSize();

	const wrapperParams = {
		marginTop: `${(width * 6.4) / 100}px`,
		height: `${(width * 225) / 100}px`,
	};

	return (
		<Flex
			position={"relative"}
			alignItems={"center"}
			{...wrapperParams}
		>
			<MixandMatch />
			<WhatWeDoCircle /> 
		</Flex>
	);
};

const MixandMatch = () => {
	const { width } = useWindowSize();
	const yy = 100;

	const bgSrc = `https://res.cloudinary.com/dwbvlpae2/image/upload/w_${parseInt(
		((width * yy) / 100) ? ((width * yy) / 100) : 1
	)},h_${parseInt(
		((width * yy) / 100) ? ((width * yy) / 100) : 1
	)},c_fill,g_auto,f_auto/DraperUni/draperstudents_jm1iix.webp`;
 
	const backgroundProps = {
		backgroundSize: `${width * 1}px`,
		backgroundColor: "#344734",
		backgroundImage: `url(${bgSrc})`,
		borderRadius: "50%",
		position: "absolute",
		height: `${(width * 130) / 100}px`,
		width: `${(width * 130) / 100}px`,
		display: "flex",
		alignItems: "center",
		objectFit: "cover",
		right: `-${(width * 15) / 100}px`,
		top: `${(width * 130) / 100}px`,
	};

	return (
		<Box style={backgroundProps} > 
		</Box>
	);
};

const WhatWeDoCircle = () => {
	const { width } = useWindowSize(); 
	const backgroundProps = {
		backgroundSize: "cover",
		backgroundColor: "#F1A738",
		borderRadius: "50%",
		position: "absolute",
		overflow: "hidden",
		height: `${(width * 180) / 100}px`,
		width: `${(width * 200) / 100}px`,
		display: "flex",
		alignItems: "center",
		right:  `-${(width * 50) / 100}px`,
		top: 0,
		zIndex: -1 
	};

	const descriptionParams = {
		fontSize: `${(width * 4) / 100}px`,
		lineHeight: `${(width * 6) / 100}px`,
		color: "#ffffff",
		textAlign: "left",
		filter: "brightness(100%) !important",
	};

	const textWrapper = {
		paddingX: `${(width * 60) / 100}px`,
		paddingBottom: `${(width * 50) / 100}px`,
		filter: "brightness(85%) !important",
	};

	const nosheryratio = 50;
	const nosherylogoWidth = (width * nosheryratio) / 100;

	const draperratio = 70;
	const draperlogoWidth = (width * draperratio) / 100;
  
      
	const imageSrc = "https://res.cloudinary.com/dwbvlpae2/image/upload/v1666049242/DraperUni/Draper_Logo_xdgj3g.webp";

	return (
		<Box style={backgroundProps} > 
			<Box {...textWrapper}>
				<Flex flexDirection="column" alignItems="center"> 
					<NosheryLogo
						src={"nosherylogo.png"}
						style={{margin: 0}}
						width={`${nosherylogoWidth}px`}
						height={`${nosherylogoWidth/2}px`}
					/>
					<p style={{color: "white", margin: 0, fontSize: `${(width * 6) / 100}px`}}> & </p>
					<DraperLogo
						src={imageSrc}
						style={{margin: 0}}
						width={`${draperlogoWidth}px`}
						height={`${draperlogoWidth/2}px`}
					/>
				</Flex  >
				<Box>
					<Text {...descriptionParams}>{DraperUniversity}</Text>
				</Box>
			</Box>
		</Box>
	);
};

export default WhatWeDo;
