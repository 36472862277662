import React, { useRef } from "react";
import { Box } from "noshery-ui";

import LanderHeader from "../LanderHeader";
import TheFood from "../TheFood";
import LadnerWhatWeDo2 from "../LanderWhatWeDo2";
import OurLocations from "../LanderLocation";
import LanderTopDishes from "../LanderTopDish";
import Footer from "../Footer";
import LandersMenu from "../LanderMenu";
import LanderBrandList from "../LanderBrandList";
import LanderPerks from "../LanderPerks";
import MetadataHelmet from "../../MetadataHelmet";
import Banner from "../Banner";
import WhatWeDo from "../WhatWeDo";
import LocationsLander from "../LocationsBrandList";

const Landing = (brand) => {
	const menuRef = useRef();
	let name = brand.to;
	return (
		<Box>
			{
				name === "locations" ? 
					<>
						<MetadataHelmet {...brand} />
						<LanderHeader menuRef={menuRef} {...brand} />
						<WhatWeDo isDraper={false} />
						<LanderPerks {...brand} />
						<LocationsLander />
						<OurLocations isLocations={true} />
						<Footer />
					</>
					: 
					<>
						<MetadataHelmet {...brand} />
						<LanderHeader menuRef={menuRef} {...brand} />
						<LadnerWhatWeDo2 {...brand} />
						<LanderTopDishes {...brand} />
						<TheFood {...brand} />
						{brand.banner && <Banner />}
						<LandersMenu menuRef={menuRef} {...brand} />
						<LanderBrandList {...brand} />
						<LanderPerks {...brand} />
						<Footer />
					</>
			}
		</Box>
	);
};

export default Landing;
