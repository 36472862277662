export const LanderBrands = {
	Orens: {
		to: "orens-hummus-mediterranean-restaurant",
		metaTitle: "Noshery’s Mediterranean Restaurant Partner – Oren’s Hummus", 
		metaDescription: "Noshery brings the deliciously-spiced Mediterranean and Middle Eastern dishes of Oren’s Hummus to San Mateo and San Carlos. Discover their food and order online!",
		metaTags: "Noshery, Oren’s Hummus, San Mateo Restaurant, San Carlos Restaurant, Mediterranean Food, Middle Eastern Food, Mediterranean Restaurant, Hummus, Falafel, Pita Sandwich", 
		altImg:
          "Photo of a pita sandwich with chicken and veggies, three pitas in the background, and a small ramekin of green sauce",
		altLogo: "",
		paddingTopBranList: 5, 
		mobilepaddingTopBranList: 2.7,  
		mobilePaddingTopLocation: 5.5,
		mobilePerksHeight: 100, 
		mobilePaddingTopPerks: 0,
		brandAlts: "Orens",
		pageImgAlts: {
			header:
            "Photo of a pita sandwich with roasted chicken, diced cucumbers and tomatoes, hummus, and tahini dressing. The pita bread is toasted and golden brown.",
			thefood: [
				"Oren’s Hummus Noshery Beef Kebab Rice Bowl Turmeric spiced Basmati rice simmered with tomatoes, garlic, onions, mint and parsley. Topped with beef.",
				"Oren’s Hummus Noshery Pita Beef Kebab Ground beef and lamb kebab, hummus, cucumber, tomato, pickles and tahini.",
				"Oren’s Hummus Noshery Beet and Goat Cheese Salad",
				"Oren’s Hummus Noshery sweet potato fries, pita, red pepper mayo",
			],
		},
		topDishes1: [
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140//brands//orenshummus/landers/topDish5_tecobp.webp`,
				title: "Hummus with Moroccan Beef", 
				description: "Hummus with Moroccan spiced ground beef, pine nuts, and tahini. (Gluten Free)"
			},
			{
				imgSrc:(w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/orenshummus/landers/whatwedo1_zjtdjv.webp`,
				title: "Pita Falafel", 
				description: "Hummus, falafel, cucumber, tomato, pickles, and tahini. (vegan + gluten free)"
			},
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140//brands//orenshummus/landers/orensDish4_fcsmfx.webp`,   
				title: "Sweet Potato Fries", 
				description: "Sweet Potato Fries with red pepper mayo (vegan + gluten free)"
			},
		],
		whatWeDoImg: {
			imgSrc: (w=0, h=0) =>
				`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/orenshummus/landers/whatwedo2_blt7lf.webp`,   
		},
		topDishesPadding: 1,
		mobileTopDishes: 20,
		nameTitle: "Oren's Hummus",
		name: "orenshummus",
		title: "Oren's Hummus",
		subTitle: "An Authentic Mediterranean & Middle Eastern Restaurant",
		description: "Authentic Israeli",
		longDescription: "Oren Dobronskey loved life in California after finding success in Silicon Valley. But something was missing - the taste of home - Tel Aviv. Inspired by the nostalgia for a creamy plate of hummus, pita, and Mediterranean food, Oren’s Hummus was born.",
		shortDescription: "",
		quote:
          "The beef was perfectly cooked, there were a lot of vegetables in the sandwich and the perfect amount of tahini. The sauces come on the side and were fabulous!",
		author: "Alice G.",
		whatWeDo1: {
			heading: "Casual Group and Family Dining Made Simple",
			mainTitle: "Noshery is a Curated (but convenient!) Food Hall",
			subHeader: "Discover beloved restaurants, enjoy a variety of cuisines, and satisfy picky eaters",
			text1: "Noshery partners with beloved restaurants from the Bay Area and all over the country to recreate their signature menu items for pickup, delivery, and casual dine in. Noshery features a diverse range of cuisines and delicious dishes made famous by our restaurant partners, and made fresh by our expert chefs.",
			text2: "These include the flavorful Mediterranean food of Oren’s Hummus, from creamy hummus to warm pita sandwiches; the tasty Vietnamese sandwiches and salads of East Side Banh Mi; irresistible grilled cheeses and burgers from The Melt; perfectly-seasoned burritos, tacos, and quesadillas from The Little Chihuahua; savory green and yellow curries from C&C Curry House; crispy samosas and fluffy naan from Kasa Indian; cookies and ice cream for a sweet treat; and more! Noshery meets the needs of all including vegetarians, vegans, families, and gluten-free eaters. We are thrilled to be in San Mateo and San Carlos!"
		}, 
		whatWeDo2: {
			mainTitle: "Noshery is thrilled to recreate the celebrated recipes of Oren’s Hummus",
			header: "The perfect hearty dinner or quick lunch on the go",
			mainText: "Noshery is a curated and convenient food hall located in San Mateo and San Carlos freshly preparing the signature dishes of our beloved restaurant partners. To learn more about us visit ordernoshery.com."
		},
		menuTitle: "Oren’s Hummus at Noshery - Menu", 
		reviews: [
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/orenshummus/landers/review1_zi84k0.webp`,
				author: "Shreya B.",
				description: "Pita Falafel and Hummus Beets were so fresh and flavorful. Loved the experience! Definitely the best Israeli food I have eaten in a while.",
				source: "Yelp",
				title: "⭐⭐⭐⭐⭐",
			},
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/orenshummus/landers/review2_nhpcma.webp`,
				author: "Claire N.",
				description: "DELICIOUS hummus. Incredible quality of ingredients and great service. Will be ordering this for healthy lunches every week!",
				source: "Yelp",
				title: "⭐⭐⭐⭐⭐",
			},
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/orenshummus/landers/review3_a5xgpb.webp`,
				author: "Ryan O.",
				description: "Finally! Oren’s Hummus in San Mateo! Pita, Chicken, Hummus, Moroccan beef — always hits the spot.",
				source: "Yelp",
				title: "⭐⭐⭐⭐⭐",
			},
		],
		menuItems: {
			1: {
				"Hummus Bowls": [
					{
						name: "Hummus Classic",
						description: "Hummus with imported tahini, olive oil, and our secret sauce (gf, v)"
					},
					{
						name: "Hummus with Moroccan Beef",
						description: "Hummus with Moroccan spiced ground beef, pine nuts, and tahini."
					},
					{
						name: "Hummus Chicken",
						description: "Hummus with spiced and chopped chicken thigh and tahini (gf)", 
					},
					{
						name: "Hummus Beets",
						description: "Hummus with marinated beets, feta cheese, chopped walnuts, orange zest, cilantro, and balsamic reduction (gf)", 
					},
					{
						name: "Hummus Eggplant",
						description: "Hummus with chunky eggplant, stewed tomatoes, and garlic (gf, v)", 
					}
				],
				"Rice Bowls": [
					{
						name: "Oren’s Chicken Rice Bowl",
						description: "Turmeric spiced Basmati rice simmered with tomatoes, garlic, onions, mint and parsley. Topped with chicken."
					},
					{
						name: "Oren’s Beef Rice Bowl",
						description: "Turmeric spiced Basmati rice simmered with tomatoes, garlic, onions, mint and parsley. Topped with beef."
					}
				],
			},
			2: {
				"Pita Sandwiches": [
					{
						name: "Pita Beef Kebab",
						description: "Ground beef and lamb kebab, hummus, cucumber, tomato, pickles and tahini.",
					},               
					{
						name: "Pita Falafel",
						description: "Hummus, falafel, cucumber, tomato, pickles, and tahini (v, gf)",
					},                
					{
						name: "Pita Chicken",
						description: "Hummus, chicken, cucumber, tomato, pickles, and tahini (gf)",
					},
				],
				"Dips and Sides": [
					{
						name: "Classic Hummus 8oz",
						description: "Hummus with imported tahini, olive oil, and our secret sauce (gf, v)",
					},                
					{
						name: "Babaganoush Eggplant 8oz",
						description: "Fire-roasted eggplant mashed with tahini, garlic, and cilantro (gf, v)",
					},                
					{
						name: "Greek Style Eggplant 8oz",
						description: "A puree of eggplant, tomato, dill, parsley, and garlic (gf, v)",
					},                
					{
						name: "Chunky Eggplant 8oz",
						description: "Chunky eggplant stewed with tomatoes and garlic (gf, v)",
					},                
					{
						name: "Labane 8oz",
						description: "Strained yogurt cheese topped with za'atar, tomatoes, and olive oil (gf)",
					},                
					{
						name: "Side of Falafel (5)",
						description: "Five falafel balls with tahini. (gf, v)",
					},                
					{
						name: "Sweet Potato Fries with Red Pepper Mayonnaise",
						description: "",
					},                
					{
						name: "French Fries with Ketchup",
						description: "",
					},
				],
			},
			3: {
				"Skewer & Kebabs": [
					{
						name: "Chicken Skewer",
						description: "All-natural chicken thigh with secret spice blend (gf)",
					},                
					{
						name: "Beef Kebab Skewer",
						description: "All-natural ground beef and lamb with blended herbs and garlic.",
					},                
					{
						name: "Chicken Skewer a la carte",
						description: "",
					},                
					{
						name: "Beef Kebab a la carte",
						description: "",
					},
				],
				"Salads": [
					{
						name: "Large Israeli Salad",
						description: "Chopped cucumber, tomato, red onion, and parsley, served with lemon vinaigrette",
					},                
					{
						name: "Beet & Goat Cheese Salad",
						description: "Chopped Romaine, steamed beets, goat cheese, and toasted walnuts, with tahini-balsamic dressing. The goat cheese and dressing are on the side.",
					},                
					{
						name: "Fattoush Salad",
						description: "Tomato, cucumber, onion, feta cheese, and crushed pita chips in lemon & vinaigrette.",
					},
				]
			}
		},
		menuItemHeight: 100.5,
		mobileMenuHeight: 260, 
		menuItemsMobile: {
			1: {
				"Hummus Bowls": [
					{
						name: "Hummus Classic",
						description: "Hummus with imported tahini, olive oil, and our secret sauce (gf, v)"
					},
					{
						name: "Hummus with Moroccan Beef",
						description: "Hummus with Moroccan spiced ground beef, pine nuts, and tahini."
					},
					{
						name: "Hummus Chicken",
						description: "Hummus with spiced and chopped chicken thigh and tahini (gf)", 
					},
					{
						name: "Hummus Beets",
						description: "Hummus with marinated beets, feta cheese, chopped walnuts, orange zest, cilantro, and balsamic reduction (gf)", 
					},
					{
						name: "Hummus Eggplant",
						description: "Hummus with chunky eggplant, stewed tomatoes, and garlic (gf, v)", 
					}
				],
				"Dips and Sides": [
					{
						name: "Classic Hummus 8oz",
						description: "Hummus with imported tahini, olive oil, and our secret sauce (gf, v)",
					},                
					{
						name: "Babaganoush Eggplant 8oz",
						description: "Fire-roasted eggplant mashed with tahini, garlic, and cilantro (gf, v)",
					},                
					{
						name: "Greek Style Eggplant 8oz",
						description: "A puree of eggplant, tomato, dill, parsley, and garlic (gf, v)",
					},                
					{
						name: "Chunky Eggplant 8oz",
						description: "Chunky eggplant stewed with tomatoes and garlic (gf, v)",
					},                
					{
						name: "Labane 8oz",
						description: "Strained yogurt cheese topped with za'atar, tomatoes, and olive oil (gf)",
					},                
					{
						name: "Side of Falafel (5)",
						description: "Five falafel balls with tahini. (gf, v)",
					},                
					{
						name: "Sweet Potato Fries with Red Pepper Mayonnaise",
						description: "",
					},                
					{
						name: "French Fries with Ketchup",
						description: "",
					},
				],
			},
			2: {
				"Pita Sandwiches": [
					{
						name: "Pita Beef Kebab",
						description: "Ground beef and lamb kebab, hummus, cucumber, tomato, pickles and tahini.",
					},               
					{
						name: "Pita Falafel",
						description: "Hummus, falafel, cucumber, tomato, pickles, and tahini (v, gf)",
					},                
					{
						name: "Pita Chicken",
						description: "Hummus, chicken, cucumber, tomato, pickles, and tahini (gf)",
					},
				],
				"Skewer & Kebabs": [
					{
						name: "Chicken Skewer",
						description: "All-natural chicken thigh with secret spice blend (gf)",
					},                
					{
						name: "Beef Kebab Skewer",
						description: "All-natural ground beef and lamb with blended herbs and garlic.",
					},                
					{
						name: "Chicken Skewer a la carte",
						description: "",
					},                
					{
						name: "Beef Kebab a la carte",
						description: "",
					},
				],
				"Rice Bowls": [
					{
						name: "Oren’s Chicken Rice Bowl",
						description: "Turmeric spiced Basmati rice simmered with tomatoes, garlic, onions, mint and parsley. Topped with chicken."
					},
					{
						name: "Oren’s Beef Rice Bowl",
						description: "Turmeric spiced Basmati rice simmered with tomatoes, garlic, onions, mint and parsley. Topped with beef."
					}
				],
                
				"Salads": [
					{
						name: "Large Israeli Salad",
						description: "Chopped cucumber, tomato, red onion, and parsley, served with lemon vinaigrette",
					},                
					{
						name: "Beet & Goat Cheese Salad",
						description: "Chopped Romaine, steamed beets, goat cheese, and toasted walnuts, with tahini-balsamic dressing. The goat cheese and dressing are on the side.",
					},                
					{
						name: "Fattoush Salad",
						description: "Tomato, cucumber, onion, feta cheese, and crushed pita chips in lemon & vinaigrette.",
					},
				]
			},
		},
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/Landing/logo/orenshummus_dv4knl.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662053702/brands//orenshummus//landers/orensheader_nbensi.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill/v1662060140//brands//orenshummus//landers/orensheader_nbensi.webp`,
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140//brands//orenshummus/landers/orensDish1_sqatzn.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140//brands//orenshummus/landers/orensDish2_qmht7i.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140//brands//orenshummus/landers/orensDish3_lgpoa1.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140//brands//orenshummus/landers/orensDish4_fcsmfx.webp`,
		food5: (w=0, h=0) => "",
	},
	ESBM: {
		to: "east-side-banh-mi-vietnamese-sandwich-restaurant",
		metaTitle: "Noshery’s Vietnamese Restaurant Partner – East Side Banh Mi", 
		metaDescription: "Noshery brings the creative and delicious Vietnamese sandwiches, salads, and healthy noodle and rice bowls of East Side Banh Mi to the Bay Area! Order online.",
		metaTags: "Noshery, East Side Banh Mi, San Mateo Restaurant, San Carlos Restaurant, Vietnamese Food, Banh Mi, Vietnamese Restaurant, Sandwich Shop, Vegetarian Restaurant.", 
		brandAlts: "ESBM",  
		altImg:
          "Noshery’s East Side Banh Mi vegan banh mi Vietnamese sandwich. Chili crisp tofu & roasted eggplant.",
		altLogo: "",
		paddingTopBranList: 5, 
		mobilepaddingTopBranList: 2.7,  
		mobilePaddingTopLocation: 5.5,
		mobilePerksHeight: 100, 
		mobilePaddingTopPerks: 0,
		pageImgAlts: {
			header:
            "Noshery’s East Side Banh Mi vegan banh mi Vietnamese sandwich. Chili crisp tofu & roasted eggplant.",
			thefood: [
				"Noshery’s East Side Banh Mi pork banh mi Vietnamese sandwich made with roasted black pepper caramel pork shoulder.",
				"Noshery San Mateo East Side Banh Mi noodle bowl with lemongrass chicken, roasted caramel pork, or chili crisp tofu.",
				"Noshery East Side Banh Mi noodle bowl topped with mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
				"Noshery’s East Side Banh Mi chili crisp tofu & roasted eggplant vietnamese banh mi sandwich for vegans and vegetarians. ",
			],
		},
		topDishes1: [
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140//brands/eastsidebanhmi/Landers/topDish1_mn8vup.webp`,
				title: "Roasted Caramel Pork Salad", 
				description: "Roasted caramel pork, mixed greens, shredded green papaya, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette."
			},
			{
				imgSrc:(w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/Landers/topDish2_pumuis.webp`,
				title: "Vegan Banh Mi", 
				description: "Chili Crisp Tofu & Roasted Eggplant with vegan shallot \"mayo\", pickled veggies, cilantro, cucumber, jalapeños, and maggi seasoning on a 8\" baguette."
			},
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140//brands/eastsidebanhmi/Landers/topDish3_pinems.webp`,   
				title: "Chili Crisp Tofu & Eggplant Noodle Bowl", 
				description: " Fried tofu with chili crisp and eggplant, chilled vermicelli rice noodles, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette."
			},
		],
		whatWeDoImg: {
			imgSrc: (w=0, h=0) =>
				`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140//brands/eastsidebanhmi/Landers/whatwedo2_lkaoax.webp`,   
		},
		topDishesPadding: 8,
		mobileTopDishes: 40,
		nameTitle: "East Side Banh Mi",
		name: "esbm",
		title: "East Side Banh Mi",
		subTitle: "Vietnamese Sandwiches, Salads, Rice & Noodle Bowls",
		description: "",
		longDescription: "In the delicious dishes of East Side Banh Mi, Gracie Nguyen creatively blends her Vietnamese heritage with her southern upbringing to create inspired dishes like banh mi sandwiches featuring roasted black pepper pork, lemongrass chicken, or vegan chili crisp tofu.",
		shortDescription: "",
		quote: "Went with the chicken banh mis which were absolutely delicious! I would recommend this place if you have a group of people who can't decide on one place to eat!",
		author: "Carmen L.",
		whatWeDo1: {
			heading: "Casual Group and Family Dining Made Simple",
			mainTitle: "Noshery is a Curated (but convenient!) Food Hall",
			subHeader: "Discover beloved restaurants, enjoy a variety of cuisines, and satisfy picky eaters",
			text1: "Noshery partners with beloved restaurants from the Bay Area and all over the country to recreate their signature menu items for pickup, delivery, and casual dine in. Noshery features a diverse range of cuisines and delicious dishes made famous by our restaurant partners, and made fresh by our expert chefs.",
			text2: "These include the flavorful Mediterranean food of Oren’s Hummus, from creamy hummus to warm pita sandwiches; the tasty Vietnamese sandwiches and salads of East Side Banh Mi; irresistible grilled cheeses and burgers from The Melt; perfectly-seasoned burritos, tacos, and quesadillas from The Little Chihuahua; savory green and yellow curries from C&C Curry House; crispy samosas and fluffy naan from Kasa Indian; cookies and ice cream for a sweet treat; and more! Noshery meets the needs of all including vegetarians, vegans, families, and gluten-free eaters. We are thrilled to be in San Mateo and San Carlos!"
		}, 
		whatWeDo2: {
			mainTitle: "Noshery is thrilled to prepare the critically-acclaimed dishes of East Side Banh Mi",
			header: "The perfect hearty dinner or quick lunch on the go",
			mainText: "Noshery is a curated and convenient food hall located in San Mateo and San Carlos freshly preparing the signature dishes of our beloved restaurant partners. To learn more about us visit ordernoshery.com."
		},
		menuTitle: "East Side Banh Mi at Noshery - Menu", 
		menuItems: {
			1: {
				"Banh Mi": [
					{
						name: "Pork Banh Mi",
						description: "Roasted black pepper-caramel pork shoulder with fried shallot mayo, pickled veggies, cilantro, cucumber, jalapeños, and maggi seasoning on a 8\" baguette."
					},
					{
						name: "Pork Deluxe Banh Mi",
						description: "Pork Banh Mi with a generous spread of chicken liver & pork pate!"
					},
					{
						name: "Chicken Banh Mi",
						description: "Roasted lemongrass chicken with fried shallot mayo, pickled veggies, cilantro, cucumber, jalapeños, and maggi seasoning on a 8\" baguette.", 
					},
					{
						name: "Chicken Deluxe Banh Mi",
						description: "Chicken Banh Mi with a generous spread of chicken liver & pork pate!", 
					},
					{
						name: "Vegan Banh Mi",
						description: "Chili Crisp Tofu & Roasted Eggplant with vegan shallot \"mayo\", pickled veggies, cilantro, cucumber, jalapeños, and maggi seasoning on a 8\" baguette.", 
					}
				],

			},
			2: {
				"Rice Bowls": [
					{
						name: "Lemongrass Chicken Rice Bowl",
						description: "Roasted lemongrass chicken, jasmine rice, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},               
					{
						name: "Roasted Caramel Pork Rice Bowl",
						description: "Roasted caramel pork, jasmine rice, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},                
					{
						name: "Chili Crisp Tofu & Eggplant Rice Bowl",
						description: "Fried tofu with chili crisp and eggplant, jasmine rice, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},                    {
						name: "Citrus Soy Rice Bowl",
						description: "Jasmine rice, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},
				],
				"Salads": [
					{
						name: "Lemongrass Chicken Salad",
						description: "Lemongrass chicken, mixed greens, shredded green papaya, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},                
					{
						name: "Roasted Caramel Pork Salad",
						description: "Roasted caramel pork, mixed greens, shredded green papaya, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},                
					{
						name: "Chili Crisp Tofu & Eggplant Salad",
						description: "Fried tofu with chili crisp and eggplant, mixed greens, shredded green papaya, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},
					{
						name: "Vietnamese Salad",
						description: "Mixed greens, shredded green papaya, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},
				],

			},
			3: {
				"Noodle Bowls": [
					{
						name: "Lemongrass Chicken Noodle Bowl",
						description: "Roasted lemongrass chicken, chilled vermicelli rice noodles, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.Roasted lemongrass chicken, chilled vermicelli rice noodles, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette."
					},
					{
						name: "Roasted Caramel Pork Noodle Bowl",
						description: "Roasted caramel pork shoulder, chilled vermicelli rice noodles, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette."
					},                  
					{
						name: "Chili Crisp Tofu & Eggplant Noodle Bowl",
						description: "Fried tofu with chili crisp and eggplant, chilled vermicelli rice noodles, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette."
					},
					{
						name: "Citrus Soy Noodle Bowl",
						description: "Chilled vermicelli rice noodles, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette."
					}
				],
			}
		},
		menuItemHeight: 110.5,
		mobileMenuHeight: 300, 
		menuItemsMobile: {
			1: {
				"Banh Mi": [
					{
						name: "Pork Banh Mi",
						description: "Roasted black pepper-caramel pork shoulder with fried shallot mayo, pickled veggies, cilantro, cucumber, jalapeños, and maggi seasoning on a 8\" baguette."
					},
					{
						name: "Pork Deluxe Banh Mi",
						description: "Pork Banh Mi with a generous spread of chicken liver & pork pate!"
					},
					{
						name: "Chicken Banh Mi",
						description: "Roasted lemongrass chicken with fried shallot mayo, pickled veggies, cilantro, cucumber, jalapeños, and maggi seasoning on a 8\" baguette.", 
					},
					{
						name: "Chicken Deluxe Banh Mi",
						description: "Chicken Banh Mi with a generous spread of chicken liver & pork pate!", 
					},
					{
						name: "Vegan Banh Mi",
						description: "Chili Crisp Tofu & Roasted Eggplant with vegan shallot \"mayo\", pickled veggies, cilantro, cucumber, jalapeños, and maggi seasoning on a 8\" baguette.", 
					}
				],
				"Rice Bowls": [
					{
						name: "Lemongrass Chicken Rice Bowl",
						description: "Roasted lemongrass chicken, jasmine rice, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},               
					{
						name: "Roasted Caramel Pork Rice Bowl",
						description: "Roasted caramel pork, jasmine rice, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},                
					{
						name: "Chili Crisp Tofu & Eggplant Rice Bowl",
						description: "Fried tofu with chili crisp and eggplant, jasmine rice, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},                    {
						name: "NO PROTEIN Rice Bowl",
						description: "Jasmine rice, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},
				],
			},
			2: {
				"Salads": [
					{
						name: "Lemongrass Chicken Salad",
						description: "Lemongrass chicken, mixed greens, shredded green papaya, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},                
					{
						name: "Roasted Caramel Pork Salad",
						description: "Roasted caramel pork, mixed greens, shredded green papaya, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},                
					{
						name: "Chili Crisp Tofu & Eggplant Salad",
						description: "Fried tofu with chili crisp and eggplant, mixed greens, shredded green papaya, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},
					{
						name: "NO PROTEIN Salad",
						description: "Mixed greens, shredded green papaya, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
					},
				],             
				"Noodle Bowls": [
					{
						name: "Lemongrass Chicken Noodle Bowl",
						description: "Roasted lemongrass chicken, chilled vermicelli rice noodles, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.Roasted lemongrass chicken, chilled vermicelli rice noodles, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette."
					},
					{
						name: "Roasted Caramel Pork Noodle Bowl",
						description: "Roasted caramel pork shoulder, chilled vermicelli rice noodles, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette."
					},                  
					{
						name: "Chili Crisp Tofu & Eggplant Noodle Bowl",
						description: "Fried tofu with chili crisp and eggplant, chilled vermicelli rice noodles, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette."
					},
					{
						name: "NO PROTEIN Noodle Bowl",
						description: "Chilled vermicelli rice noodles, mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette."
					}
				],
			},
		},
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/eastsidebanhmi_r6etvn.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/Landers/header_r3o9mt.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/Landers/header_r3o9mt.webp`,
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/Landers/thefood1_fkbrvp.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/Landers/thefood2_qochun.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/Landers/thefood3_hnauxa.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/Landers/thefood4_xpffgn.webp`,
		food5: (w=0, h=0) => "",
	},
	THEMELT: {
		to: "the-melt-family-burgers",
		metaTitle: "Noshery x The Melt - Crave-Worthy Cheese, Burgers, & Fries", 
		metaDescription: "Indulge in warm, gooey, cheese and juicy Wagyu or Impossible burger patties perfect for meat-lovers and vegetarians, kids and families. Everyone loves Noshery x The Melt. Order Now.",
		metaTags: "Noshery, Family Restaurant, Veggie Burger, The Melt, American Restaurant, Burger, Fries, Grilled Cheese, Cheeseburger, Kids Menu.", 
		brandAlts: "THEMELT",  
		altImg:
          "Noshery x The Melt BBQ Bacon MeltBurger. Angus and Wagyu beef filled with melted cheddar, smoked bacon, crispy onions, and a jalapeño–pickle mix with Melt sauce.",
		altLogo: "",
		paddingTopBranList: 5, 
		mobilepaddingTopBranList: 2.7,  
		mobilePaddingTopLocation: 5.5,
		mobilePerksHeight: 100, 
		pageImgAlts: {
			header:
            "Noshery x The Melt BBQ Bacon MeltBurger. Angus and Wagyu beef filled with melted cheddar, smoked bacon, crispy onions, and a jalapeño–pickle mix with Melt sauce.",
			thefood: [
				"Noshery x The Melt BBQ Bacon MeltBurger, Garlic Parmesan Fries, and Cheddar Bacon Fries.",
				"Noshery x The Melt MeltBurger with Angus and Wagyu beef or Impossible burger patty. Sweet potato fries from Oren’s Hummus.",
				"Noshery x The Melt Patty Melt. Grilled Angus and Wagyu beef patty, caramelized onions, melted swiss and provolone cheese with spicy mustard on artisan french bread.",
				"Noshery x The Melt BBQ Bacon MeltBurger. Angus and Wagyu beef, melted cheddar, smoked bacon, crispy onions, Memphis-style BBQ sauce.",
			],
		},
		topDishes1: [
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/themelt/Landers/topdish1_qi4owp.webp`,
				title: "Three Cheese Classic Grilled Cheese", 
				description: "Melted sharp cheddar, muenster, and fontina cheeses on artisan french bread."
			},
			{
				imgSrc:(w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/Landers/topdish2_ka6vkl.webp`,
				title: "Double BBQ Bacon MeltBurger", 
				description: "Two Angus & Wagyu beef patties, chopped, grilled & filled with melted cheddar, smoked bacon, crispy onions, Memphis-style BBQ sauce on a toasted, artisan bun."
			},
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/themelt/Landers/topdish3_eryoxp.webp`,   
				title: "Impossible MeltBurger", 
				description: "Signature MeltBurger made with an Impossible™ plant-based patty, Get it with or without cheese to make it vegan!"
			},
		],
		whatWeDoImg: {
			imgSrc: (w=0, h=0) =>
				`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/themelt/Landers/whatwedo2_ytdei5.webp`,   
		},
		topDishesPadding: 8,
		mobileTopDishes: 40,
		mobilePaddingTopPerks: 0,
		nameTitle: "The Melt",
		name: "themelt",
		title: "The Melt",
		subTitle: "The Meltiest Artisan Cheeses Paired with the Perfect Burgers",
		description: "",
		longDescription: "Nothing brings the family together like lots and lots of cheese. Especially when that cheese is paired with tender Wagyu beef, delectable Impossible burger patties, and crispy french fries. That’s right – it’s Noshery x The Melt family night – and there's smiles all around.",
		shortDescription: "",
		quote: "Absolutely delicious… just look at that cheese dripping down the side of the burger. Super tasty and great customer service, too.",
		author: "Chris G.",
		whatWeDo1: {
			heading: "Casual Group and Family Dining Made Simple",
			mainTitle: "Noshery is a Curated (but convenient!) Food Hall",
			subHeader: "Discover beloved restaurants, enjoy a variety of cuisines, and satisfy picky eaters",
			text1: "Noshery partners with beloved restaurants from the Bay Area and all over the country to recreate their signature menu items for pickup, delivery, and casual dine in. Noshery features a diverse range of cuisines and delicious dishes made famous by our restaurant partners, and made fresh by our expert chefs.",
			text2: "These include the flavorful Mediterranean food of Oren’s Hummus, from creamy hummus to warm pita sandwiches; the tasty Vietnamese sandwiches and salads of East Side Banh Mi; irresistible grilled cheeses and burgers from The Melt; perfectly-seasoned burritos, tacos, and quesadillas from The Little Chihuahua; savory green and yellow curries from C&C Curry House; crispy samosas and fluffy naan from Kasa Indian; cookies and ice cream for a sweet treat; and more! Noshery meets the needs of all including vegetarians, vegans, families, and gluten-free eaters. We are thrilled to be in San Mateo and San Carlos!"
		}, 
		whatWeDo2: {
			mainTitle: "Noshery is thrilled to recreate the your melty, gooey favorites from The Melt.",
			header: "The perfect hearty dinner or quick lunch on the go",
			mainText: "Noshery is a curated and convenient food hall located in San Mateo and San Carlos freshly preparing the signature dishes of our beloved restaurant partners. To learn more about us visit ordernoshery.com."
		},
		menuTitle: "The Melt at Noshery - Menu", 
		menuItems: {
			1: {

			},
			2: {
				"Melt Burgers": [
					{
						name: "MeltBurger",
						description: "More than 1/3 lb of our signature Angus & Wagyu beef, chopped, grilled and filled with loads of melted cheddar and our jalapeño–pickle mix (not too spicy, we promise) with Melt sauce on a toasted, artisan bun (grilled, medium-medium well and juicy). A Gluten Free bun available!"
					},
					{
						name: "Double Meltburger",
						description: "More than 1/2 pound of our signature Angus & Wagyu beef, chopped, grilled and filled with loads of melted cheddar, jalapeños (not too spicy, we promise), pickles and Melt sauce on a toasted, artisan bun. (cooked medium, pink & juicy)"
					},
					{
						name: "BBQ Bacon MeltBurger",
						description: "More than 1/3 pound of our signature Angus & Wagyu beef, chopped, grilled & filled with melted cheddar, smoked bacon, crispy onions, Memphis-style BBQ sauce, all topped with a toasted, artisan bun. Remove crispy onions + add GF bun to make this dish GF!", 
					},
					{
						name: "Double BBQ Bacon MeltBurger",
						description: "Two Angus & Wagyu beef patties, chopped, grilled & filled with melted cheddar, smoked bacon, crispy onions, Memphis-style BBQ sauce, all topped with a toasted, artisan bun.", 
					},
					{
						name: "Impossible MeltBurger",
						description: "Our signature MeltBurger made with an Impossible™ plant-based patty that looks & tastes just like beef. Loaded with melted cheddar, jalapeño-pickle mix (not too spicy, we promise) and Melt sauce on a toasted, artisan bun.", 
					}
				],

			},
			3: {
				"Fries": [
					{
						name: "Fries",
						description: "Thin-cut Idaho russets tossed with our seasoned salt."
					},
					{
						name: "Cheddar Bacon Fries",
						description: "Smothered in our cheddar sauce, crispy bacon, shredded cheddar, and ranch."
					},                  
					{
						name: "Garlic Parmesan Fries",
						description: "Tossed with garlic butter, fresh garlic and shredded parmesan"
					},
				],
				"Grilled Cheese": [
					{
						name: "Three Cheese Classic",
						description: "Sharp cheddar, muenster, and fontina cheeses on artisan french bread. (San Mateo Only)",
					},               
					{
						name: "Patty Melt",
						description: "Grilled Angus & Wagyu beef patty, sweet, caramelized onions, melted swiss and provolone cheese with spicy mustard on artisan french bread. (San Mateo Only)",
					},                
					{
						name: "Kid's Grilled Cheese",
						description: "Sharp cheddar on artisan french bread. (San Mateo Only)",
					},
				],
			}
		},
		menuItemHeight: 90.5,
		mobileMenuHeight: 230, 
		menuItemsMobile: {
			1: {
				"Melt Burgers": [
					{
						name: "MeltBurger",
						description: "More than 1/3 lb of our signature Angus & Wagyu beef, chopped, grilled and filled with loads of melted cheddar and our jalapeño–pickle mix (not too spicy, we promise) with Melt sauce on a toasted, artisan bun (grilled, medium-medium well and juicy). A Gluten Free bun available!"
					},
					{
						name: "Double Meltburger",
						description: "More than 1/2 pound of our signature Angus & Wagyu beef, chopped, grilled and filled with loads of melted cheddar, jalapeños (not too spicy, we promise), pickles and Melt sauce on a toasted, artisan bun. (cooked medium, pink & juicy)"
					},
					{
						name: "BBQ Bacon MeltBurger",
						description: "More than 1/3 pound of our signature Angus & Wagyu beef, chopped, grilled & filled with melted cheddar, smoked bacon, crispy onions, Memphis-style BBQ sauce, all topped with a toasted, artisan bun. Remove crispy onions + add GF bun to make this dish GF!", 
					},
					{
						name: "Double BBQ Bacon MeltBurger",
						description: "Two Angus & Wagyu beef patties, chopped, grilled & filled with melted cheddar, smoked bacon, crispy onions, Memphis-style BBQ sauce, all topped with a toasted, artisan bun.", 
					},
					{
						name: "Impossible MeltBurger",
						description: "Our signature MeltBurger made with an Impossible™ plant-based patty that looks & tastes just like beef. Loaded with melted cheddar, jalapeño-pickle mix (not too spicy, we promise) and Melt sauce on a toasted, artisan bun.", 
					}
				],

			},
			2: {
				"Fries": [
					{
						name: "Fries",
						description: "Thin-cut Idaho russets tossed with our seasoned salt."
					},
					{
						name: "Cheddar Bacon Fries",
						description: "Smothered in our cheddar sauce, crispy bacon, shredded cheddar, and ranch."
					},                  
					{
						name: "Garlic Parmesan Fries",
						description: "Tossed with garlic butter, fresh garlic and shredded parmesan"
					},
				],
				"Grill Cheese": [
					{
						name: "Three Cheese Classic",
						description: "Sharp cheddar, muenster, and fontina cheeses on artisan french bread.",
					},               
					{
						name: "Patty Melt",
						description: "Grilled Angus & Wagyu beef patty, sweet, caramelized onions, melted swiss and provolone cheese with spicy mustard on artisan french bread",
					},                
					{
						name: "Kid's Grilled Cheese",
						description: "Sharp cheddar on artisan french bread.",
					},
				],
			},
		},
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/themelt_ityvv4.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/Landers/header_hiyayp.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/Landers/header_hiyayp.webp`,
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/Landers/thefood1_nqb2d1.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/Landers/thefood2_lqba2h.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/Landers/thefood3_qtdfph.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/Landers/thefood4_d7d3kh.webp`,
		food5: (w=0, h=0) => "",
	},
	TLC: {
		to: "the-little-chihuahua-mexican-cuisine",
		metaTitle: "Noshery x The Little Chihuahua - Burritos, Tacos, & Quesadillas", 
		metaDescription: "Noshery recreates The Little Chihuahua’s wholesome Mexican cuisine at our food halls including tacos, burritos, & quesadillas. Vegan, vegetarian, & gluten free options available. ",
		metaTags: "Noshery, Mexican Food, Mexican Restaurant, The Little Chihuahua, Burrito, Taco, Quesadilla, Vegetarian Food, Mexican San Mateo, Mexican San Carlos", 
		brandAlts: "TLC",  
		altImg:
          "Noshery x The Little Chihuahua Fried Plantain and Black Bean Burrito with jack cheese, roasted bell peppers, rice, smoky chile salsa, pico de gallo, sour cream, chopped onions & cilantro.",
		altLogo: "",
		pageImgAlts: {
			header:
            "Noshery x The Little Chihuahua Garlic Shrimp Burrito with avocado, rice, black beans, smoky chile salsa, pico de gallo, garlic salsa, chopped cilantro & onions.",
			thefood: [
				"Noshery x The Little Chihuahua Classic Tacos with pollo asado and carne asada topped with chopped cilantro and onions. ",
				"Noshery x The Little Chihuahua tacos including pollo asado, carne asada, garlic shrimp, and vegan fried plantain and black beach tacos.",
				"Noshery x The Little Chihuahua classic burrito with pollo asado or carne asada. Gluten free available.",
				"Noshery x The Little Chihuahua vegetarian and vegan tacos made with fried plantains and black beans",
			],
		},
		topDishes1: [
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/thelittlechihuahua/Landers/topDish1_menylh.webp`,
				title: " Fajita Cheese Quesadilla", 
				description: "Toasted tortilla filled with jack cheese, poblano peppers and onions. Gluten free available."
			},
			{
				imgSrc:(w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/Landers/topDish2_grz8da.webp`,
				title: "Classic Tacos", 
				description: "Pollo Asado or Carne Asada topped with smokey chile salsa, chopped cilantro and onions. Add guacamole, sour cream, or cheese."
			},
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/thelittlechihuahua/Landers/topDish3_ez4v8e.webp`,   
				title: "Fried Plantain & Black Bean Burrito", 
				description: "On a Toasted tortilla, with jack cheese, and roasted bell peppers. Enjoy vegan or gluten free upon request."
			},
		],
		whatWeDoImg: {
			imgSrc: (w=0, h=0) =>
				`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/thelittlechihuahua/Landers/whatwedo2_n7ovdg.webp`,   
		},
		paddingTopBranList: 5, 
		topDishesPadding: 8,
		mobileTopDishes: 32,
		mobilePaddingTopPerks: 0,
		mobilepaddingTopBranList: 2.7, 
		mobilePaddingTopLocation: 5.5,
		mobilePerksHeight: 100, 
		nameTitle: "The Little Chihuahua",
		name: "tlc",
		title: "The Little Chihuahua",
		subTitle: "Flavorful & Family-Friendly Mexican Cuisine ",
		description: "",
		longDescription: "The Little Chihuahua creates Mexican cuisine with a twist and only the best ingredients. Enjoy tacos, burritos, quesadillas, and rice bowls with traditionally spiced pollo asado or carne asada, or delicious vegan fried plantains and black beans. Don’t forget the fresh chips and guacamole to share!",
		shortDescription: "",
		quote: "Got the pollo rice bowl for lunch today from The Little Chihuahua at Noshery and man was it good!! The ingredients were really fresh and the chicken was well cooked and seasoned. And the guacamole was super, super good.",
		author: "Alexandria W.",
		whatWeDo1: {
			heading: "Casual Group and Family Dining Made Simple",
			mainTitle: "Noshery is a Curated (but convenient!) Food Hall",
			subHeader: "Discover beloved restaurants, enjoy a variety of cuisines, and satisfy picky eaters",
			text1: "Noshery partners with beloved restaurants from the Bay Area and all over the country to recreate their signature menu items for pickup, delivery, and casual dine in. Noshery features a diverse range of cuisines and delicious dishes made famous by our restaurant partners, and made fresh by our expert chefs.",
			text2: "These include the flavorful Mediterranean food of Oren’s Hummus, from creamy hummus to warm pita sandwiches; the tasty Vietnamese sandwiches and salads of East Side Banh Mi; irresistible grilled cheeses and burgers from The Melt; perfectly-seasoned burritos, tacos, and quesadillas from The Little Chihuahua; savory green and yellow curries from C&C Curry House; crispy samosas and fluffy naan from Kasa Indian; cookies and ice cream for a sweet treat; and more! Noshery meets the needs of all including vegetarians, vegans, families, and gluten-free eaters. We are thrilled to be in San Mateo and San Carlos!"
		}, 
		whatWeDo2: {
			mainTitle: "Noshery is thrilled to recreate The Little Chihuahua’s inspired Mexican dishes.",
			header: "The perfect hearty dinner or quick lunch on the go",
			mainText: "Noshery is a curated and convenient food hall located in San Mateo and San Carlos freshly preparing the signature dishes of our beloved restaurant partners. To learn more about us visit ordernoshery.com."
		},
		menuTitle: "The Little Chihuahua at Noshery - Menu", 
		menuItems: {
			1: {
				"Tacos": [
					{
						name: "Classic Tacos (2)",
						description: "Corn tortillas served with cabbage salad, your choice of pollo asado or carne asada, and smokey chile salsa. Topped with chopped cilantro & onions."
					},
					{
						name: "Fried Plantain & Black Bean Tacos (2)",
						description: "Two corn tortillas with fried plantains, black beans, jack cheese, roasted red bell peppers, sour cream, smoky chile salsa, pico de gallo, chopped onions & cilantro."
					},
					{
						name: "Garlic Shrimp Tacos (2)",
						description: "Two corn tortillas with sautéed shrimp, avocado, black beans, smoky chile salsa, pico de gallo, garlic salsa, chopped cilantro & onions."
					}
				],
			},
			2: {
				"Burritos": [
					{
						name: "Classic Burritos",
						description: "Toasted tortilla, your choice of pollo asado or carne asada, rice, black beans, pico de gallo, cabbage salad, and smoky chile salsa."
					},
					{
						name: "Fried Plantain & Black Bean Burrito",
						description: "Toasted tortilla filled with fried plantains, black beans, jack cheese, roasted red bell peppers, rice, sour cream, smoky chile salsa, pico de gallo, chopped onions & cilantro."
					},
					{
						name: "Garlic Shrimp Burrito",
						description: "Sautéed shrimp, avocado, rice, black beans, smoky chile salsa, pico de gallo, garlic salsa, chopped cilantro & onions.", 
					}
				],
				"Quesadilla": [
					{
						name: "Fajita Cheese Quesadilla",
						description: "Toasted tortilla filled with jack cheese, poblano peppers & onions."
					},
					{
						name: "Classic Fajita Quesadillas",
						description: "Toasted tortilla filled with jack cheese, poblano peppers & onions, and your choice of pollo asado or carne asada."
					}
				],
			},
			3: {
				"Rice Bowls": [
					{
						name: "Classic Rice Bowls",
						description: "Rice, your choice of pollo asado or carne asada, black beans, pico de gallo, cabbage salad, and smoky chile salsa."
					},
					{
						name: "Fried Plantain & Black Bean Rice Bowl",
						description: "Fried plantains, black beans, jack cheese, roasted red bell peppers, rice, sour cream, smoky chile salsa, and pico de gallo served on top of rice."
					},
					{
						name: "Garlic Shrimp Rice Bowl",
						description: "Sautéed shrimp, avocado, black beans, smoky chile salsa, pico de gallo, and garlic salsa served on top of rice.", 
					}
				],

				"Tortilla Chips": [
					{
						name: "Guac & Chips",
						description: "Corn tortilla chips with fresh made guacamole."
					},
					{
						name: "Salted Corn Tortilla Chips",
						description: "Salted Corn Tortilla Chips"
					},                  
				],
			}
		},
		menuItemHeight: 70.5,
		mobileMenuHeight: 210, 
		menuItemsMobile: {
			1: {
				"Tacos": [
					{
						name: "Classic Tacos (2)",
						description: "Corn tortillas served with cabbage salad, your choice of pollo asado or carne asada, and smokey chile salsa. Topped with chopped cilantro & onions."
					},
					{
						name: "Fried Plantain & Black Bean Tacos (2)",
						description: "Two corn tortillas with fried plantains, black beans, jack cheese, roasted red bell peppers, sour cream, smoky chile salsa, pico de gallo, chopped onions & cilantro."
					},
					{
						name: "Garlic Shrimp Tacos (2)",
						description: "Two corn tortillas with sautéed shrimp, avocado, black beans, smoky chile salsa, pico de gallo, garlic salsa, chopped cilantro & onions."
					}
				],
				"Burritos": [
					{
						name: "Classic Burritos",
						description: "Toasted tortilla, your choice of pollo asado or carne asada, rice, black beans, pico de gallo, cabbage salad, and smoky chile salsa."
					},
					{
						name: "Fried Plantain & Black Bean Burrito",
						description: "Toasted tortilla filled with fried plantains, black beans, jack cheese, roasted red bell peppers, rice, sour cream, smoky chile salsa, pico de gallo, chopped onions & cilantro."
					},
					{
						name: "Garlic Shrimp Burrito",
						description: "Sautéed shrimp, avocado, rice, black beans, smoky chile salsa, pico de gallo, garlic salsa, chopped cilantro & onions.", 
					}
				],

			},
			2: {
				"Rice Bowls": [
					{
						name: "Classic Rice Bowls",
						description: "Rice, your choice of pollo asado or carne asada, black beans, pico de gallo, cabbage salad, and smoky chile salsa."
					},
					{
						name: "Fried Plantain & Black Bean Rice Bowl",
						description: "Fried plantains, black beans, jack cheese, roasted red bell peppers, rice, sour cream, smoky chile salsa, and pico de gallo served on top of rice."
					},
					{
						name: "Garlic Shrimp Rice Bowl",
						description: "Sautéed shrimp, avocado, black beans, smoky chile salsa, pico de gallo, and garlic salsa served on top of rice.", 
					}
				],
				"Quesadilla": [
					{
						name: "Fajita Cheese Quesadilla",
						description: "Toasted tortilla filled with jack cheese, poblano peppers & onions."
					},
					{
						name: "Classic Fajita Quesadillas",
						description: "Toasted tortilla filled with jack cheese, poblano peppers & onions, and your choice of pollo asado or carne asada."
					}
				],
				"Tortilla Chips": [
					{
						name: "Guac & Chips",
						description: "Corn tortilla chips with fresh made guacamole."
					},
					{
						name: "Salted Corn Tortilla Chips",
						description: "Salted Corn Tortilla Chips"
					},                  
				],
			},
		},
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/themelt_ityvv4.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/Landers/header_gjxig3.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/Landers/header_gjxig3.webp`,
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/Landers/thefood1_cwwtuo.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/Landers/thefood2_il4oqs.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/Landers/thefood3_fst6c8.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/Landers/thefood4_woqb0n.webp`,
		food5: (w=0, h=0) => "",
	},
	AB: {
		to: "asian-box-gluten-free-vietnamese-food",
		metaTitle: "Noshery x Asian Box - Gluten-Free Vietnamese Rice Bowls & Salads", 
		metaDescription: "Noshery recreates the tasty, healthy, and filling Vietnamese rice bowls and salads from Asian Box at our San Carlos food hall. Check out our gluten free Asian Box menu and order today. ",
		metaTags: "Noshery, Asian Box, Vietnamese Food, Rice Bowl, Vietnamese Restaurant, Gluten Free Food, Vietnamese Cuisine, Chicken Rice Bowl, Asian Rice Bowl, Asian Food", 
		brandAlts: "AB",  
		altImg:
          "Noshery x Asian Box The Workout. Six-spice chicken, brown rice, steamed veggies, bean sprouts, pickled veggies, fresh herbs and tamarind sauce.",
		altLogo: "",
		pageImgAlts: {
			header:
            "Noshery x Asian Box Chef’s Salad. Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette.",
			thefood: [
				"Noshery x Asian Box at Noshery food hall in San Carlos. Featured dishes include Chicken Curry, the Garden Box with organic tofu, and Vietnamese Hot Chicken.",
				"Noshery x Asian Box at Noshery food hall in San Carlos. Dishes include The Workout with six-spice chicken and steamed veggies and the Chef’s Salad with soy vinaigrette.",
				"Noshery x Asian Box Garden Box. Organic tofu, yellow coconut curry, veggies, brown rice, fresh herbs, crispy shallots, peanuts, scallion oil, lime wedge and Asian Street Dust.",
				"Noshery x Asian Box Vietnamese Hot Chicken. Six-spice chicken with a blend of signature sauces, local honey, jasmine rice, crispy shallots and peanuts.",
			],
		},
		topDishes1: [
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/asianbox/landers/topDish1_qkl2bt.webp`,
				title: "Garden Box", 
				description: "Organic tofu, yellow coconut curry, steamed veggies, brown rice, veggies and herbs, crispy shallots, chopped peanuts, scallion oil, lime wedge and Asian Street Dust. (vegan + vegetarian)."
			},
			{
				imgSrc:(w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/asianbox/landers/topDish2_sfhun4.webp`,
				title: "Vietnamese Hot Chicken", 
				description: "Six-spice chicken tossed with a blend of our signature HotBoxIt and tamarind sauces, local honey, jasmine rice, crispy shallots and peanuts."
			},
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/asianbox/landers/topDish3_tvoitw.webp`,   
				title: "Chicken Curry", 
				description: "Six-spice chicken, yellow coconut curry, potatoes, carrots, jasmine rice, fresh herbs, peanuts and crispy shallots."
			},
		],
		whatWeDoImg: {
			imgSrc: (w=0, h=0) =>
				`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/v1662060140/brands/asianbox/landers/whatwedo2_rgqctf.webp`,   
		},
		topDishesPadding: 8,
		mobileTopDishes: 32,
		paddingTopBranList: 7, 
		mobilePaddingTopPerks: 30, 
		mobilePaddingTopLocation: 10,
		mobilePerksHeight: 130, 
		nameTitle: "Asian Box",
		name: "ab",
		title: "Asian Box",
		subTitle: "Gluten Free & Protein-Packed Rice Bowls & Salads",
		description: "",
		longDescription: "Naturally gluten free and loaded with both veggies and delicious protein options like Coconut Curry Tofu, Six Spice Chicken, and Vietnamese Hot Chicken, Asian Box’s signature offerings at Noshery are perfect for anyone in need of a healthy, protein-packed meal. Each inspired dish is topped with Asian Box’s signature spice, sauce, or dressing - the ideal weekday meal!",
		shortDescription: "",
		quote: "Tried the garden box for lunch today and this vegetarian couldn’t be happier!! Tofu was firm and delicious and I just loved the yellow curry sauce. So many veggies and the perfect amount of crunch and tang with the peanut and lime wedge toppings.",
		author: "Talor W.",
		whatWeDo1: {
			heading: "Casual Group and Family Dining Made Simple",
			mainTitle: "Noshery is a Curated (but convenient!) Food Hall",
			subHeader: "Discover beloved restaurants, enjoy a variety of cuisines, and satisfy picky eaters",
			text1: "Noshery partners with beloved restaurants from the Bay Area and all over the country to recreate their signature menu items for pickup, delivery, and casual dine in. Noshery features a diverse range of cuisines and delicious dishes made famous by our restaurant partners, and made fresh by our expert chefs.",
			text2: "These include the flavorful Mediterranean food of Oren’s Hummus, from creamy hummus to warm pita sandwiches; the tasty Vietnamese sandwiches and salads of East Side Banh Mi; irresistible grilled cheeses and burgers from The Melt; perfectly-seasoned burritos, tacos, and quesadillas from The Little Chihuahua; savory green and yellow curries from C&C Curry House; crispy samosas and fluffy naan from Kasa Indian; cookies and ice cream for a sweet treat; and more! Noshery meets the needs of all including vegetarians, vegans, families, and gluten-free eaters. We are thrilled to be in San Mateo and San Carlos!"
		}, 
		whatWeDo2: {
			mainTitle: "Asian Box’s menu at Noshery is diverse, gluten free, and healthy.",
			header: "The perfect hearty dinner or quick lunch on the go",
			mainText: "Noshery is a curated and convenient food hall located in San Mateo and San Carlos freshly preparing the signature dishes of our beloved restaurant partners. To learn more about us visit ordernoshery.com."
		},
		menuTitle: "Asian Box at Noshery - Menu", 
		menuItems: {
			1: {
				"": [
					{
						name: "Garden Box",
						description: "Organic tofu, yellow coconut curry and steamed veggies, brown rice, pickled veggies, bean sprouts, fresh herbs, crispy shallots, chopped peanuts, scallion oil, lime wedge and Asian Street Dust."
					},
					{
						name: "Chef's Salad",
						description: "Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette."
					},
				],
			},
			2: {
				"": [
					{
						name: "Chicken Curry",
						description: "Six-spice chicken, yellow coconut curry, potatoes, carrots, jasmine rice, fresh herbs, peanuts and crispy shallots."
					},
					{
						name: "The Workout",
						description: "Six-spice chicken, brown rice, steamed veggies, bean sprouts, pickled veggies, fresh herbs and tamarind sauce."
					},
				],
			},
			3: {
				"": [
					{
						name: "Vietnamese Hot Chicken",
						description: "Six-spice chicken tossed with a blend of our signature HotBoxIt and tamarind sauces, local honey, jasmine rice, crispy shallots and peanuts."
					},
				],
			}
		},
		menuItemHeight: 35.5,
		mobileMenuHeight: 100, 
		mobilepaddingTopBranList: 30, 
		menuItemsMobile: {
			1: {
				"": [
					{
						name: "Garden Box",
						description: "Organic tofu, yellow coconut curry and steamed veggies, brown rice, pickled veggies, bean sprouts, fresh herbs, crispy shallots, chopped peanuts, scallion oil, lime wedge and Asian Street Dust."
					},
					{
						name: "Chef's Salad",
						description: "Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette."
					},
				],
			},
			2: {
				"": [
					{
						name: "Chicken Curry",
						description: "Six-spice chicken, yellow coconut curry, potatoes, carrots, jasmine rice, fresh herbs, peanuts and crispy shallots."
					},
					{
						name: "The Workout",
						description: "Six-spice chicken, brown rice, steamed veggies, bean sprouts, pickled veggies, fresh herbs and tamarind sauce."
					},
					{
						name: "Vietnamese Hot Chicken",
						description: "Six-spice chicken tossed with a blend of our signature HotBoxIt and tamarind sauces, local honey, jasmine rice, crispy shallots and peanuts."
					},
				],
			},
		},
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/asianbox_s2hbcy.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1665440235/brands/asianbox/landers/whatwedo2_rgqctf.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1665440235/brands/asianbox/landers/whatwedo2_rgqctf.webp`,
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/asianbox/landers/thefood1_g4dbiw.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/asianbox/landers/thefood2_eo41us.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/asianbox/landers/thefood3_bdzttn.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/asianbox/landers/thefood4_ko0xmj.webp`,
		food5: (w=0, h=0) => "",
	}, 
	"LS": {
		to: "lazy-susan-chinese-takeout",
		metaTitle: "Noshery x Asian Lazy Susan", 
		metaDescription: "Noshery recreates the tasty, healthy, and filling Vietnamese rice bowls and salads from Asian Box at our San Carlos food hall. Check out our gluten free Asian Box menu and order today. ",
		metaTags: "Noshery, Asian Box, Vietnamese Food, Rice Bowl, Vietnamese Restaurant, Gluten Free Food, Vietnamese Cuisine, Chicken Rice Bowl, Asian Rice Bowl, Asian Food", 
		brandAlts: "LS",  
		altImg:
          "Noshery x Asian Box The Workout. Six-spice chicken, brown rice, steamed veggies, bean sprouts, pickled veggies, fresh herbs and tamarind sauce.",
		altLogo: "",
		pageImgAlts: {
			header:
            "Noshery x Asian Box Chef’s Salad. Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette.",
			thefood: [
				"Noshery x Asian Box at Noshery food hall in San Carlos. Featured dishes include Chicken Curry, the Garden Box with organic tofu, and Vietnamese Hot Chicken.",
				"Noshery x Asian Box at Noshery food hall in San Carlos. Dishes include The Workout with six-spice chicken and steamed veggies and the Chef’s Salad with soy vinaigrette.",
				"Noshery x Asian Box Garden Box. Organic tofu, yellow coconut curry, veggies, brown rice, fresh herbs, crispy shallots, peanuts, scallion oil, lime wedge and Asian Street Dust.",
				"Noshery x Asian Box Vietnamese Hot Chicken. Six-spice chicken with a blend of signature sauces, local honey, jasmine rice, crispy shallots and peanuts.",
			],
		},
		topDishes1: [
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/Lazy Susan/Lander/iz5i0btoydeyzu4cipve.webp`,
				title: "General Tso’s Chicken", 
				description: "Crispy chicken wok-tossed in a mouth-watering, sweet, savory, and tangy sauce that's perfect for any occasion. Gluten-free, high in protein, and served with a side of fresh and crunchy broccoli."
			},
			{
				imgSrc:(w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Lazy Susan/Lander/belevw8qslrwdcdlp4of.webp`,
				title: "Beef & Broccoli", 
				description: "Savor the mouth-watering flavor of our seared flank steak, marinated in a savory sauce, and served with fresh broccoli. This gluten-free dish is high in protein, low in fat, and bursting with deliciousness."
			},
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/Lazy Susan/Lander/lwzdzdtztqcsv70jfjhy.webp`,   
				title: "Sautéed String Beans", 
				description: "Delicious vegetarian and vegan-friendly dish, stir-fried string beans in a spicy hoisin sauce that will satisfy your cravings. This gluten-free and flavorful dish is perfect for anyone who wants to enjoy a tasty and healthy meal."
			},
		],
		whatWeDoImg: {
			imgSrc: (w=0, h=0) =>
				`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/v1662060140/brands/Lazy Susan/Lander/rfpi3tmekrwazwk4qqid.webp`,   
		},
		topDishesPadding: 8.5,
		mobileTopDishes: 38,
		paddingTopBranList: 7, 
		mobilePaddingTopPerks: 30, 
		mobilePaddingTopLocation: 10,
		mobilePerksHeight: 130, 
		nameTitle: "Lazy Susan",
		name: "LS",
		title: "Lazy Susan",
		subTitle: "Delicious Chinese Classics",
		description: "",
		longDescription: "Lazy Susan was co-founded by Hanson Li, who emigrated from Hong Kong in the 90s, and Tiffany Yam, who grew up in her family’s Chinese restaurant in Pittsburgh. The idea came from Li’s father’s desire for a “Domino’s of Chinese food,” while Yam wants to honor her dad’s 30+ years of experience in the industry. Their goal is to provide quality Chinese food, one eggroll at a time.",
		shortDescription: "",
		quote: "Such delicious chinese food in San Carlos. I had the Chili Hoisin String Beans and they had a good kick to them. General tso's chicken is really good too- will be back.",
		author: "Justin H.",
		whatWeDo1: {
			heading: "Casual Group and Family Dining Made Simple",
			mainTitle: "Noshery is a Curated (but convenient!) Food Hall",
			subHeader: "Discover beloved restaurants, enjoy a variety of cuisines, and satisfy picky eaters",
			text1: "Noshery partners with beloved restaurants from the Bay Area and all over the country to recreate their signature menu items for pickup, delivery, and casual dine in. Noshery features a diverse range of cuisines and delicious dishes made famous by our restaurant partners, and made fresh by our expert chefs.",
			text2: "These include the flavorful Mediterranean food of Oren’s Hummus, from creamy hummus to warm pita sandwiches; the tasty Vietnamese sandwiches and salads of East Side Banh Mi; irresistible grilled cheeses and burgers from The Melt; perfectly-seasoned burritos, tacos, and quesadillas from The Little Chihuahua; savory green and yellow curries from C&C Curry House; crispy samosas and fluffy naan from Kasa Indian; cookies and ice cream for a sweet treat; and more! Noshery meets the needs of all including vegetarians, vegans, families, and gluten-free eaters. We are thrilled to be in San Mateo and San Carlos!"
		}, 
		whatWeDo2: {
			mainTitle: "Noshery is so excited to welcome Lazy Susan into our family of restaurant partners.",
			header: "The perfect hearty dinner or quick lunch on the go",
			mainText: "Noshery is a curated and convenient food hall located in San Mateo and San Carlos freshly preparing the signature dishes of our beloved restaurant partners. To learn more about us visit ordernoshery.com."
		},
		menuTitle: "Lazy Susan at Noshery - Menu", 
		menuItems: {
			1: {
				"": [
					{
						name: "Orange Chicken",
						description: "Crispy chicken wok-tossed with a brightly flavored orange sauce. Gluten-free."
					},
					{
						name: "General Tso's Chicken",
						description: "The definitive Chinese American favorite: Crispy chicken wok-tossed in a sweet, savory, and tangy sauce. Served with a side of broccoli. Gluten-free."
					},
					{
						name: "Kung Pao Chicken",
						description: "Diced chicken thigh stir fried with veggies and chilies in a classic kung pao sauce. Includes peanuts. Gluten-free."
					},
				],
			},
			2: {
				"": [
					{
						name: "Beef & Broccoli",
						description: "Savory sauce marinated and seared flank steak with broccoli. Gluten-free."
					},
					{
						name: "Sauteed String Beans",
						description: "Stir fried string beans in a spicy hoisin sauce. Gluten-free, vegetarian, and vegan"
					},
					{
						name: "Jasmine Rice",
						description: "Gluten-free, vegetarian, and vegan."
					},
					{
						name: "Brown Rice",
						description: "Gluten-free, vegetarian, and vegan"
					},
				],
			},
			3: {
				"": [
					{
						name: "Umami Crunch",
						description: "Craveable condiment good on everything: crispy garlic with fermented black beans and mushroom extract. Gluten-free, vegetarian, and vegan."
					},
					{
						name: "Lazy Susan Sriracha",
						description: "Sriracha enriched with a special blend of Chinese douban sauce, miso, and spices. Gluten-free, vegetarian and vegan."
					},
				],
			}
		},
		menuItemHeight: 35.5,
		mobileMenuHeight: 120, 
		mobilepaddingTopBranList: 30, 
		menuItemsMobile: {
			1: {
				"": [
					{
						name: "Orange Chicken",
						description: "Crispy chicken wok-tossed with a brightly flavored orange sauce. Gluten-free."
					},
					{
						name: "General Tso's Chicken",
						description: "The definitive Chinese American favorite: Crispy chicken wok-tossed in a sweet, savory, and tangy sauce. Served with a side of broccoli. Gluten-free."
					},
					{
						name: "Kung Pao Chicken",
						description: "Diced chicken thigh stir fried with veggies and chilies in a classic kung pao sauce. Includes peanuts. Gluten-free."
					},
					{
						name: "Beef & Broccoli",
						description: "Savory sauce marinated and seared flank steak with broccoli. Gluten-free."
					},
				],
			},
			2: {
				"": [
					{
						name: "Sauteed String Beans",
						description: "Stir fried string beans in a spicy hoisin sauce. Gluten-free, vegetarian, and vegan"
					},
					{
						name: "Jasmine Rice",
						description: "Gluten-free, vegetarian, and vegan."
					},
					{
						name: "Brown Rice",
						description: "Gluten-free, vegetarian, and vegan"
					},
					{
						name: "Umami Crunch",
						description: "Craveable condiment good on everything: crispy garlic with fermented black beans and mushroom extract. Gluten-free, vegetarian, and vegan."
					},
					{
						name: "Lazy Susan Sriracha",
						description: "Sriracha enriched with a special blend of Chinese douban sauce, miso, and spices. Gluten-free, vegetarian and vegan."
					},
				],
			},
		},
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/asianbox_s2hbcy.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1665440235/brands/Lazy Susan/ymwsb3l1yb6ejget5wjj.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1665440235/brands/Lazy Susan/ymwsb3l1yb6ejget5wjj.webp`,
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Lazy Susan/Lander/roakv0mezu2yoz8fyqa0.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto//brands/Lazy Susan/Lander/tuqpjc95pb1ffro2ymna.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Lazy Susan/Lander/ddxcslp1j61guf4kkthw.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/Lazy Susan/Lander/ozpsicyqfq7bg4n9njk7.webp`,
		food5: (w=0, h=0) => "",
	}, 
	CATERING: {
		to: "group-orders",
		banner: true,
		metaTitle: "Group Orders at Noshery", 
		metaDescription: "Perfect for catering occasions, office lunches, game day gatherings, and large family events.",
		metaTags: "Noshery, Catering Nearby, San Mateo Catering, San Carlos Catering, San Mateo Restaurant, San Calos Restaurant, Bay Area Group Orders, East Side Banh Mi, The Melt, Oren’s Hummus, Asian Box, Humphry Slocombe, Vegetarian Restaurant, Vegan Restaurant, Group Take Out, Local Group Orders", 
		brandAlts: "CATERING",  
		altImg:
          "Noshery Group Ordering.",
		altLogo: "",
		pageImgAlts: {
			header:
            "Noshery x Asian Box Chef’s Salad. Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette.",
			thefood: [
				"Noshery x Asian Box at Noshery food hall in San Carlos. Featured dishes include Chicken Curry, the Garden Box with organic tofu, and Vietnamese Hot Chicken.",
				"Noshery x Asian Box at Noshery food hall in San Carlos. Dishes include The Workout with six-spice chicken and steamed veggies and the Chef’s Salad with soy vinaigrette.",
				"Noshery x Asian Box Garden Box. Organic tofu, yellow coconut curry, veggies, brown rice, fresh herbs, crispy shallots, peanuts, scallion oil, lime wedge and Asian Street Dust.",
				"Noshery x Asian Box Vietnamese Hot Chicken. Six-spice chicken with a blend of signature sauces, local honey, jasmine rice, crispy shallots and peanuts.",
			],
		},
		topDishes1: [
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/themelt/Landers/topdish2_ka6vkl.webp`,
				title: "BBQ Bacon MeltBurger", 
				description: "More than 1/3 pound of our famous signature Angus & Wagyu beef; chopped, grilled & filled with melted cheddar, smoked bacon, crispyonions, Memphis-style BBQ sauce, all topped with a freshly toasted artisan bun."
			},
			{
				imgSrc:(w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/jsw9oqvhhifc8nom6ukm.webp`,
				title: "Garlic Shrimp Burrito", 
				description: "Delicious sautéed shrimp, fresh avocado, black beans, smoky chile salsa, pico de gallo, and garlic salsa served in a toasted tortilla. Make it “SUPER” with extra guac, sour cream, and cheese for only $2 more."
			},
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/orenshummus/landers/topDish8_w6ofxx.webp`,   
				title: "Chicken Rice Bowl", 
				description: "Oren’s Hummus’ famous turmeric spiced Basmati rice simmered with fresh tomatoes, garlic, onions, mint and parsley. Topped with flame roasted chicken thigh seasoned with Oren’s secret spice blend."
			},
		],
		whatWeDoImg: {
			imgSrc: (w=0, h=0) =>
				`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140//Landing/LP_H_yzzghe.webp`,   
		},
		topDishesPadding: 10,
		mobileTopDishes: 40,
		paddingTopBranList: 7, 
		mobilePaddingTopPerks: 30, 
		mobilePaddingTopLocation: 10,
		mobilePerksHeight: 130, 
		nameTitle: "Noshery Food Hall",
		name: "group-orders",
		title: "Place Your Group Order Today!",
		subTitle: "Perfect for catering occasions, office lunches, game day gatherings, and large family events.",
		description: "",
		longDescription: `Whether it’s for a holiday party, social event, or large family gathering, Noshery Catering is your
        go-to for serving large groups. Order directly from our San Mateo or San Carlos locations any
        day of the week.`,
		shortDescription: "",
		quote: "Everything is delicious (those cookies! ) and reasonably priced, and I love that you can mix and match between concepts!",
		author: "Yvette Y.",
		whatWeDo1: {
			heading: "Feeding A Large, Hangry Group? We Got You Covered.",
			mainTitle: "Noshery is a Curated (but convenient!) Food Hall",
			subHeader: "Discover beloved restaurants, enjoy a variety of cuisines, and satisfy picky eaters",
			text1: "Noshery partners with beloved restaurants from the Bay Area and all over the country to recreate their signature menu items for pickup, delivery, and casual dine in. Noshery features a diverse range of cuisines and delicious dishes made famous by our restaurant partners, and made fresh by our expert chefs.",
			text2: "These include the flavorful Mediterranean food of Oren’s Hummus, from creamy hummus to warm pita sandwiches; the tasty Vietnamese sandwiches and salads of East Side Banh Mi; irresistible grilled cheeses and burgers from The Melt; perfectly-seasoned burritos, tacos, and quesadillas from The Little Chihuahua; savory green and yellow curries from C&C Curry House; crispy samosas and fluffy naan from Kasa Indian; cookies and ice cream for a sweet treat; and more! Noshery meets the needs of all including vegetarians, vegans, families, and gluten-free eaters. We are thrilled to be in San Mateo and San Carlos!"
		}, 
		whatWeDo2: {
			mainTitle: "Feeding A Large, Hangry Group? We Got You Covered.",
			header: "The perfect hearty dinner or quick lunch on the go",
			mainText: "Noshery is a curated and convenient food hall located in San Mateo and San Carlos freshly preparing the signature dishes of our beloved restaurant partners. To learn more about us visit ordernoshery.com.",
			text2: "With new pre-packaged group and catering deals—and plenty of options to choose from spanning across eight different cuisines from our beloved restaurant brand partners—we make it easy for everyone to get just what they want, all in one order."
		}, 
		menuItemHeight: 35.5,
		mobileMenuHeight: 150, 
		mobilepaddingTopBranList: 30, 
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/asianbox_s2hbcy.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1665440235/brands/asianbox/thefood2_fmdsuv.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1665440235/brands/asianbox/thefood2_fmdsuv.webp`,
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/pvrqhm5uyf5czved6eoy.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/ljygnd6edhxhsra1hqaf.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/orenshummus/nuflfltuw9ekx2bexaa6.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/Landers/thefood4_xpffgn.webp`,
		food5: (w=0, h=0) => "",
	},
	"LOCATIONS": {
		to: "locations",
		metaTitle: "Locations Noshery", 
		metaDescription: "Perfect for catering occasions, office lunches, game day gatherings, and large family events.",
		metaTags: "Noshery, Catering Nearby, San Mateo Catering, San Carlos Catering, San Mateo Restaurant, San Calos Restaurant, Bay Area Group Orders, East Side Banh Mi, The Melt, Oren’s Hummus, Asian Box, Humphry Slocombe, Vegetarian Restaurant, Vegan Restaurant, Group Take Out, Local Group Orders", 
		brandAlts: "LOCATIONS",  
		altImg:
          "Noshery Group Ordering.",
		altLogo: "",
		pageImgAlts: {
			header:
            "Noshery x Asian Box Chef’s Salad. Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette.",
			thefood: [
				"Noshery x Asian Box at Noshery food hall in San Carlos. Featured dishes include Chicken Curry, the Garden Box with organic tofu, and Vietnamese Hot Chicken.",
				"Noshery x Asian Box at Noshery food hall in San Carlos. Dishes include The Workout with six-spice chicken and steamed veggies and the Chef’s Salad with soy vinaigrette.",
				"Noshery x Asian Box Garden Box. Organic tofu, yellow coconut curry, veggies, brown rice, fresh herbs, crispy shallots, peanuts, scallion oil, lime wedge and Asian Street Dust.",
				"Noshery x Asian Box Vietnamese Hot Chicken. Six-spice chicken with a blend of signature sauces, local honey, jasmine rice, crispy shallots and peanuts.",
			],
		},
		topDishes1: [
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/themelt/Landers/topdish2_ka6vkl.webp`,
				title: "BBQ Bacon MeltBurger", 
				description: "More than 1/3 pound of our famous signature Angus & Wagyu beef; chopped, grilled & filled with melted cheddar, smoked bacon, crispyonions, Memphis-style BBQ sauce, all topped with a freshly toasted artisan bun."
			},
			{
				imgSrc:(w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/jsw9oqvhhifc8nom6ukm.webp`,
				title: "Garlic Shrimp Burrito", 
				description: "Delicious sautéed shrimp, fresh avocado, black beans, smoky chile salsa, pico de gallo, and garlic salsa served in a toasted tortilla. Make it “SUPER” with extra guac, sour cream, and cheese for only $2 more."
			},
			{
				imgSrc: (w=0, h=0) =>
					`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140/brands/orenshummus/landers/topDish8_w6ofxx.webp`,   
				title: "Chicken Rice Bowl", 
				description: "Oren’s Hummus’ famous turmeric spiced Basmati rice simmered with fresh tomatoes, garlic, onions, mint and parsley. Topped with flame roasted chicken thigh seasoned with Oren’s secret spice blend."
			},
		],
		whatWeDoImg: {
			imgSrc: (w=0, h=0) =>
				`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1662060140//Landing/LP_H_yzzghe.webp`,   
		},
		topDishesPadding: 10,
		mobileTopDishes: 40,
		paddingTopBranList: 0, 
		mobilePaddingTopPerks: 30, 
		mobilePaddingTopLocation: 10,
		mobilePerksHeight: 140, 
		nameTitle: "Noshery Food Hall",
		name: "group-orders",
		title: "Now open in your neighborhood!",
		subTitle: "We’re open in San Ramon!",
		popup: true, 
		description: "Noshery is coming to you!",
		longDescription: "Explore all the ways you can mix and match all your favorite restaurant menus together on one order. Ordering has never been easier; put together your order with items from our menu of greatest hits and we’ll make it all fresh from scratch for you to pick up or have delivered!",
		shortDescription: "",
		quote: "Everything is delicious (those cookies! ) and reasonably priced, and I love that you can mix and match between concepts!",
		author: "Yvette Y.",
		whatWeDo1: {
			heading: "Feeding A Large, Hangry Group? We Got You Covered.",
			mainTitle: "Noshery is a Curated (but convenient!) Food Hall",
			subHeader: "Discover beloved restaurants, enjoy a variety of cuisines, and satisfy picky eaters",
			text1: "Noshery partners with beloved restaurants from the Bay Area and all over the country to recreate their signature menu items for pickup, delivery, and casual dine in. Noshery features a diverse range of cuisines and delicious dishes made famous by our restaurant partners, and made fresh by our expert chefs.",
			text2: "These include the flavorful Mediterranean food of Oren’s Hummus, from creamy hummus to warm pita sandwiches; the tasty Vietnamese sandwiches and salads of East Side Banh Mi; irresistible grilled cheeses and burgers from The Melt; perfectly-seasoned burritos, tacos, and quesadillas from The Little Chihuahua; savory green and yellow curries from C&C Curry House; crispy samosas and fluffy naan from Kasa Indian; cookies and ice cream for a sweet treat; and more! Noshery meets the needs of all including vegetarians, vegans, families, and gluten-free eaters. We are thrilled to be in San Mateo and San Carlos!"
		}, 
		whatWeDo2: {
			mainTitle: "Feeding A Large, Hangry Group? We Got You Covered.",
			header: "The perfect hearty dinner or quick lunch on the go",
			mainText: "Noshery is a curated and convenient food hall located in San Mateo and San Carlos freshly preparing the signature dishes of our beloved restaurant partners. To learn more about us visit ordernoshery.com.",
			text2: "With new pre-packaged group and catering deals—and plenty of options to choose from spanning across eight different cuisines from our beloved restaurant brand partners—we make it easy for everyone to get just what they want, all in one order."
		}, 
		menuItemHeight: 35.5,
		mobileMenuHeight: 150, 
		mobilepaddingTopBranList: 30, 
		logo: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/asianbox_s2hbcy.webp`,
		bg: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1665440235/Landing/LP_H_yzzghe.webp`,
		bgBrand: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/v1665440235/Landing/LP_H_yzzghe.webp`,
		food1: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/pvrqhm5uyf5czved6eoy.webp`,
		food2: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/themelt/ljygnd6edhxhsra1hqaf.webp`,
		food3: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/orenshummus/nuflfltuw9ekx2bexaa6.webp`,
		food4: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/Landers/thefood4_xpffgn.webp`,
		food5: (w=0, h=0) => "",
	},
};

export const THANKSGIVING = {
	to: "thanks-giving",
	metaTitle: "Noshery Thanks Giving", 
	metaDescription: "Noshery recreates the tasty, healthy, and filling Vietnamese rice bowls and salads from Asian Box at our San Carlos food hall. Check out our gluten free Asian Box menu and order today. ",
	metaTags: "Noshery, Thanksgiving.", 
	brandAlts: "THANKSGIVING",  
	altImg:
      "Noshery thanksgiving.",
	altLogo: "",
	pageImgAlts: {
		header:
        "Noshery x Asian Box Chef’s Salad. Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette.",
		thefood: [
			"Noshery x Asian Box at Noshery food hall in San Carlos. Featured dishes include Chicken Curry, the Garden Box with organic tofu, and Vietnamese Hot Chicken.",
			"Noshery x Asian Box at Noshery food hall in San Carlos. Dishes include The Workout with six-spice chicken and steamed veggies and the Chef’s Salad with soy vinaigrette.",
			"Noshery x Asian Box Garden Box. Organic tofu, yellow coconut curry, veggies, brown rice, fresh herbs, crispy shallots, peanuts, scallion oil, lime wedge and Asian Street Dust.",
			"Noshery x Asian Box Vietnamese Hot Chicken. Six-spice chicken with a blend of signature sauces, local honey, jasmine rice, crispy shallots and peanuts.",
		],
	},
	topDishes1: [
		{
			imgSrc: (w=0, h=0) =>
				`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/v1662060140/brands/themelt/Landers/topdish2_ka6vkl.webp`,
			title: "BBQ Bacon MeltBurger", 
			description: "More than 1/3 pound of our famous signature Angus & Wagyu beef; chopped, grilled & filled with melted cheddar, smoked bacon, crispyonions, Memphis-style BBQ sauce, all topped with a freshly toasted artisan bun."
		},
		{
			imgSrc:(w=0, h=0) =>
				`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/brands/thelittlechihuahua/garlicShrimRicebowl_wvwpuw.webp`,
			title: "Garlic Shrimp Rice Bowl", 
			description: "A bowl of delicious sautéed shrimp, fresh avocado, black beans, smoky chile salsa, pico de gallo, and garlic salsa served on a bed of rice. Make it “SUPER” with extra guac, sour cream, and cheese for only $2 more."
		},
		{
			imgSrc: (w=0, h=0) =>
				`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/v1662060140/brands/orenshummus/landers/review1_zi84k0.webp`,   
			title: "Beet & Goat Cheese Salad", 
			description: "Freshly chopped Romaine lettuce, steamed beets, goat cheese, and toasted walnuts, with a delicious tahini-balsamic dressing. The goat cheese and dressing are served on the side. Hummus and pita also available."
		},
	],
	whatWeDoImg: {
		imgSrc: (w=0, h=0) =>
			`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/v1662060140/Landing/LP_FAQ_nuej6n.webp`,   
	},
	topDishesPadding: 10,
	mobileTopDishes: 40,
	paddingTopBranList: 7, 
	mobilePaddingTopPerks: 30, 
	mobilePaddingTopLocation: 10,
	mobilePerksHeight: 130, 
	nameTitle: "Noshery Food Hall",
	name: "ab",
	title: "One-Time Holiday Offer!",
	subTitle: "Use Code THANKSGIVING20 For 20% Off Your Order (Valid 11/25-11/28)",
	description: "",
	longDescription: "This weekend only, enjoy 20% off ANY of our famous restaurant brand partners when you spend $40 or more (delivery or pick up). At Noshery, our wide variety of cuisines makes it easy for everyone to get just what they want, all in one order.",
	shortDescription: "",
	quote: "Went with the chicken banh mis which were absolutely delicious! I would recommend this place if you have a group of people who can’t decide on one place to eat!",
	author: "Carmen L.",
	whatWeDo1: {
		heading: "Casual Group and Family Dining Made Simple",
		mainTitle: "Noshery is a Curated (but convenient!) Food Hall",
		subHeader: "Discover beloved restaurants, enjoy a variety of cuisines, and satisfy picky eaters",
		text1: "Noshery partners with beloved restaurants from the Bay Area and all over the country to recreate their signature menu items for pickup, delivery, and casual dine in. Noshery features a diverse range of cuisines and delicious dishes made famous by our restaurant partners, and made fresh by our expert chefs.",
		text2: "These include the flavorful Mediterranean food of Oren’s Hummus, from creamy hummus to warm pita sandwiches; the tasty Vietnamese sandwiches and salads of East Side Banh Mi; irresistible grilled cheeses and burgers from The Melt; perfectly-seasoned burritos, tacos, and quesadillas from The Little Chihuahua; savory green and yellow curries from C&C Curry House; crispy samosas and fluffy naan from Kasa Indian; cookies and ice cream for a sweet treat; and more! Noshery meets the needs of all including vegetarians, vegans, families, and gluten-free eaters. We are thrilled to be in San Mateo and San Carlos!"
	}, 
	whatWeDo2: {
		mainTitle: "Celebrate friendsgiving this weekend without the hassle (and clean up).",
		header: "The perfect hearty dinner or quick lunch on the go",
		mainText: "Noshery is a curated and convenient food hall located in San Mateo and San Carlos freshly preparing the signature dishes of our beloved restaurant partners. This weekend only, use code THANKSGIVING20 to get 20% off your entire order through delivery or pick up at ordernoshery.com."
	},
	menuTitle: "Asian Box at Noshery - Menu", 
	menuItemHeight: 35.5,
	mobileMenuHeight: 150, 
	mobilepaddingTopBranList: 30, 
	logo: (w=0, h=0) =>
		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${h ? h: 1},c_fill,g_auto,f_auto/Landing/logo/asianbox_s2hbcy.webp`,
	bg: (w=0, h=0) =>
		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/v1665440235/Landing/LP_H_yzzghe.webp`,
	bgBrand: (w=0, h=0) =>
		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/v1665440235//Landing/LP_H_yzzghe.webp`,
	food1: (w=0, h=0) =>
		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/brands/orenshummus/landers/orensDish2_qmht7i.webp`,
	food2: (w=0, h=0) =>
		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/Landers/thefood2_qochun.webp`,
	food3: (w=0, h=0) =>
		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/brands/thepastrycupboard/thepastrycupboardfood3_me3lew.webp`,
	food4: (w=0, h=0) =>
		`https://res.cloudinary.com/dwbvlpae2/image/upload/w_${w ? w: 1},h_${w ? w: 1},c_fill,g_auto,f_auto/brands/eastsidebanhmi/Landers/thefood4_xpffgn.webp`,
	food5: (w=0, h=0) => "",
};

export const altLanders = {
	Orens: {
		header: "Oren’s Hummus Noshery classic hummus, hummus with moroccan beef, hummus with chicken, hummus with beats, hummus with eggplant",
		whatWeDo2: "Oren’s Hummus Noshery pita chicken skewer with secret spice blend, hummus, Israeli salad, Greek style eggplant, chunky eggplant, labane",
		food1: "Oren’s Hummus Noshery Beef Kebab Rice Bowl Turmeric spiced Basmati rice simmered with tomatoes, garlic, onions, mint and parsley. Topped with beef.",
		food2: "Oren’s Hummus Noshery Pita Beef Kebab Ground beef and lamb kebab, hummus, cucumber, tomato, pickles and tahini.",
		food3: "Oren’s Hummus Noshery Beet and Goat Cheese Salad", 
		food4: "Oren’s Hummus Noshery sweet potato fries, pita, red pepper mayo",
		topDish1: "Hummus with Moroccan Beef",
		topDish2: "Chicken Skewer",
		topDish3: "Pita Chicken Sandwich",
	},
	ESBM: {
		header:  "Noshery’s East Side Banh Mi vegan banh mi Vietnamese sandwich. Chili crisp tofu & roasted eggplant.",
		whatWeDo2: "Noshery’s East Side Banh Mi roasted lemongrass chicken banh mi Vietnamese sandwich.",
		food1: "Noshery’s East Side Banh Mi pork banh mi Vietnamese sandwich made with roasted black pepper caramel pork shoulder.",
		food2: "Noshery San Mateo East Side Banh Mi noodle bowl with lemongrass chicken, roasted caramel pork, or chili crisp tofu.",
		food3: "Noshery East Side Banh Mi noodle bowl topped with mixed greens, pickled veggies, herbs, fried shallots, roasted peanuts, citrus-soy vinaigrette.",
		food4: "Noshery’s East Side Banh Mi chili crisp tofu & roasted eggplant vietnamese banh mi sandwich for vegans and vegetarians. ",
		topDish1: "Roasted Caramel Pork Salad",
		topDish2: "Vegan Banh Mi",
		topDish3: "Chili Crisp Tofu & Eggplant Noodle Bowl",
	},
	THEMELT: {
		header:  "Noshery x The Melt BBQ Bacon MeltBurger. Angus and Wagyu beef filled with melted cheddar, smoked bacon, crispy onions, and a jalapeño–pickle mix with Melt sauce.",
		whatWeDo2: "Noshery x The Melt Three Classic Cheese grilled cheese. Sharp cheddar, muenster, and fontina cheeses on artisan french bread. Kids grilled cheese is also available.",
		food1: "Noshery x The Melt BBQ Bacon MeltBurger, Garlic Parmesan Fries, and Cheddar Bacon Fries.",
		food2: "Noshery x The Melt MeltBurger with Angus and Wagyu beef or Impossible burger patty. Sweet potato fries from Oren’s Hummus.",
		food3: "Noshery x The Melt Patty Melt. Grilled Angus and Wagyu beef patty, caramelized onions, melted swiss and provolone cheese with spicy mustard on artisan french bread.",
		food4: "Noshery x The Melt BBQ Bacon MeltBurger. Angus and Wagyu beef, melted cheddar, smoked bacon, crispy onions, Memphis-style BBQ sauce.",
		topDish1: "Melted sharp cheddar, muenster, and fontina cheeses on artisan french bread.",
		topDish2: "Two Angus & Wagyu beef patties, chopped, grilled & filled with melted cheddar, smoked bacon, crispy onions, Memphis-style BBQ sauce on a toasted, artisan bun.",
		topDish3: "Signature MeltBurger made with an Impossible™ plant-based patty, Get it with or without cheese to make it vegan!",
	},
	TLC: {
		header:  "Noshery x The Little Chihuahua Garlic Shrimp Burrito with avocado, rice, black beans, smoky chile salsa, pico de gallo, garlic salsa, chopped cilantro & onions.",
		whatWeDo2: "Noshery x The Little Chihuahua Fried Plantain and Black Bean Burrito with jack cheese, roasted bell peppers, rice, smoky chile salsa, pico de gallo, sour cream, chopped onions & cilantro.",
		food1: "Noshery x The Little Chihuahua Classic Tacos with pollo asado and carne asada topped with chopped cilantro and onions. ",
		food2: "Noshery x The Little Chihuahua tacos including pollo asado, carne asada, garlic shrimp, and vegan fried plantain and black beach tacos.",
		food3: "Noshery x The Little Chihuahua classic burrito with pollo asado or carne asada. Gluten free available.",
		food4: "Noshery x The Little Chihuahua vegetarian and vegan tacos made with fried plantains and black beans",
		topDish1: "Melted sharp cheddar, muenster, and fontina cheeses on artisan french bread.",
		topDish2: "Two Angus & Wagyu beef patties, chopped, grilled & filled with melted cheddar, smoked bacon, crispy onions, Memphis-style BBQ sauce on a toasted, artisan bun.",
		topDish3: "Signature MeltBurger made with an Impossible™ plant-based patty, Get it with or without cheese to make it vegan!",
	},
	AB: {
		header: "Noshery x Asian Box Chef’s Salad. Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette.",
		whatWeDo2: "Noshery x Asian Box The Workout. Six-spice chicken, brown rice, steamed veggies, bean sprouts, pickled veggies, fresh herbs and tamarind sauce.",
		food1: "Noshery x Asian Box at Noshery food hall in San Carlos. Featured dishes include Chicken Curry, the Garden Box with organic tofu, and Vietnamese Hot Chicken.",
		food2: "Noshery x Asian Box at Noshery food hall in San Carlos. Dishes include The Workout with six-spice chicken and steamed veggies and the Chef’s Salad with soy vinaigrette.",
		food3: "Noshery x Asian Box Garden Box. Organic tofu, yellow coconut curry, veggies, brown rice, fresh herbs, crispy shallots, peanuts, scallion oil, lime wedge and Asian Street Dust.",
		food4: "Noshery x Asian Box Vietnamese Hot Chicken. Six-spice chicken with a blend of signature sauces, local honey, jasmine rice, crispy shallots and peanuts.",
		topDish1: "Garden Box",
		topDish2: "Vietnamese Hot Chicken",
		topDish3: "Chicken Curry",
	},
	"LS": {
		header: "Noshery x Asian Box Chef’s Salad. Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette.",
		whatWeDo2: "Noshery x Asian Box The Workout. Six-spice chicken, brown rice, steamed veggies, bean sprouts, pickled veggies, fresh herbs and tamarind sauce.",
		food1: "Noshery x Asian Box at Noshery food hall in San Carlos. Featured dishes include Chicken Curry, the Garden Box with organic tofu, and Vietnamese Hot Chicken.",
		food2: "Noshery x Asian Box at Noshery food hall in San Carlos. Dishes include The Workout with six-spice chicken and steamed veggies and the Chef’s Salad with soy vinaigrette.",
		food3: "Noshery x Asian Box Garden Box. Organic tofu, yellow coconut curry, veggies, brown rice, fresh herbs, crispy shallots, peanuts, scallion oil, lime wedge and Asian Street Dust.",
		food4: "Noshery x Asian Box Vietnamese Hot Chicken. Six-spice chicken with a blend of signature sauces, local honey, jasmine rice, crispy shallots and peanuts.",
		topDish1: "Garden Box",
		topDish2: "Vietnamese Hot Chicken",
		topDish3: "Chicken Curry",
	},
	THANKSGIVING:  {
		header: "Noshery x Asian Box Chef’s Salad. Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette.",
		whatWeDo2: "Noshery x Asian Box The Workout. Six-spice chicken, brown rice, steamed veggies, bean sprouts, pickled veggies, fresh herbs and tamarind sauce.",
		food1: "Noshery x Asian Box at Noshery food hall in San Carlos. Featured dishes include Chicken Curry, the Garden Box with organic tofu, and Vietnamese Hot Chicken.",
		food2: "Noshery x Asian Box at Noshery food hall in San Carlos. Dishes include The Workout with six-spice chicken and steamed veggies and the Chef’s Salad with soy vinaigrette.",
		food3: "Noshery x Asian Box Garden Box. Organic tofu, yellow coconut curry, veggies, brown rice, fresh herbs, crispy shallots, peanuts, scallion oil, lime wedge and Asian Street Dust.",
		food4: "Noshery x Asian Box Vietnamese Hot Chicken. Six-spice chicken with a blend of signature sauces, local honey, jasmine rice, crispy shallots and peanuts.",
		topDish1: "Garden Box",
		topDish2: "Vietnamese Hot Chicken",
		topDish3: "Chicken Curry",
	},
	CATERING:  {
		header: "Noshery x Asian Box Chef’s Salad. Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette.",
		whatWeDo2: "Noshery x Asian Box The Workout. Six-spice chicken, brown rice, steamed veggies, bean sprouts, pickled veggies, fresh herbs and tamarind sauce.",
		food1: "Noshery x Asian Box at Noshery food hall in San Carlos. Featured dishes include Chicken Curry, the Garden Box with organic tofu, and Vietnamese Hot Chicken.",
		food2: "Noshery x Asian Box at Noshery food hall in San Carlos. Dishes include The Workout with six-spice chicken and steamed veggies and the Chef’s Salad with soy vinaigrette.",
		food3: "Noshery x Asian Box Garden Box. Organic tofu, yellow coconut curry, veggies, brown rice, fresh herbs, crispy shallots, peanuts, scallion oil, lime wedge and Asian Street Dust.",
		food4: "Noshery x Asian Box Vietnamese Hot Chicken. Six-spice chicken with a blend of signature sauces, local honey, jasmine rice, crispy shallots and peanuts.",
		topDish1: "Garden Box",
		topDish2: "Vietnamese Hot Chicken",
		topDish3: "Chicken Curry",
	},
	LOCATIONS:  {
		header: "Noshery x Asian Box Chef’s Salad. Six-spice chicken atop Asian salad, rice noodles, crispy shallots, peanuts, fresh herbs, scallion oil and soy vinaigrette.",
		whatWeDo2: "Noshery x Asian Box The Workout. Six-spice chicken, brown rice, steamed veggies, bean sprouts, pickled veggies, fresh herbs and tamarind sauce.",
		food1: "Noshery x Asian Box at Noshery food hall in San Carlos. Featured dishes include Chicken Curry, the Garden Box with organic tofu, and Vietnamese Hot Chicken.",
		food2: "Noshery x Asian Box at Noshery food hall in San Carlos. Dishes include The Workout with six-spice chicken and steamed veggies and the Chef’s Salad with soy vinaigrette.",
		food3: "Noshery x Asian Box Garden Box. Organic tofu, yellow coconut curry, veggies, brown rice, fresh herbs, crispy shallots, peanuts, scallion oil, lime wedge and Asian Street Dust.",
		food4: "Noshery x Asian Box Vietnamese Hot Chicken. Six-spice chicken with a blend of signature sauces, local honey, jasmine rice, crispy shallots and peanuts.",
		topDish1: "Garden Box",
		topDish2: "Vietnamese Hot Chicken",
		topDish3: "Chicken Curry",
	},
};
