import React from "react";
import { Box, Heading, Flex } from "noshery-ui";
import styled from "styled-components";

import SingleBrand from "./SingleBrand";
import { landingBrands } from "../../constants/index";
import useWindowSize from "../../hooks/useWindowSize";

const Title = styled(Heading)`
  text-align: center;
  font-weight: 800;
`;

const delays = [
	"1000",
	"1000",
	"1500",
	"1500",
	"2000",
	"2000",
	"2500",
	"2500",
];

const BrandPartners = () => {
	const [isVisible, setVisible] = React.useState(true);
	const componentRef = React.useRef(); 
	React.useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => setVisible(entry.isIntersecting));
		});
		observer.observe(componentRef.current);
		let current = componentRef.current;
		return () => observer.unobserve(current);
	}, []); 
	const { width } = useWindowSize(); 
	const wrapperParams = {
		marginTop: `${(width * 1) / 100}px`,
		
		marginBottom: `${(width * 20) / 100}px`,
	}; 
	const titleParams = {
		fontSize: `${(width * (6)) / 100}px`,
		lineHeight: `${(width * (9)) / 100}px`,
		paddingBottom: `${(width * (4)) / 100}px`,
	};

	return (
		<Box {...wrapperParams}>
			
			<Title {...titleParams} bold>
        		Please continue to support our <br /> fantastic restaurant partners!
			</Title> 
			<Flex
				flexWrap="wrap"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				maxWidth={"90%"}
				margin="auto"
				ref={componentRef}
			>
				{Object.keys(landingBrands).map((l, i) => {
					const brand = landingBrands[l];
					if(brand.name === "comingsoon") return;
					return (
						<SingleBrand 
							key={l}  
							delay={((delays[i]))} 
							isVisible={isVisible}
							{...brand} />
					);
				})}
			</Flex>
		</Box>
	);
};

export default React.memo(BrandPartners);
