import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Image } from "noshery-ui";
import { animated, useSpring } from "react-spring";
import useWindowSize from "../../hooks/useWindowSize";

const SingleBrand = ({ title, logo, bg, to, altImg, finalImage }) => {

	const { width } = useWindowSize();
	const [onHoover, setOnHoover] = React.useState(false);

	const w = 80;
	const h = 30;

	const params = {
		width: `${(width * w) / 100}px`,
		height: `${(width * h) / 100}px`,
		borderRadius: `${(width * 3.1) / 100}px`,
		marginLeft: `${(width * (2)) / 100}px`,
		marginRight: `${(width * 2) / 100}px`,
		marginTop: `${(width * (8)) / 100}px`,
		marginBottom: `${(width * 2.5) / 100}px`,
	};
 
 
	const bgParams = {
		width: "100%",
		height: "100%",
		borderRadius: `${(width * 3.1) / 100}px !important`,
		marginTop: ""
	}; 
 
	return (
		<Link onClick={() => {
			window.open(to, "_blank");
		}} to=""  style={{ textDecoration: "none", marginTop: "40px !important" }}>
			<Flex
				{...params}
				alignItems="center"
				flexDirection="column"
				style={{
					position: "relative",
					filter: "drop-shadow(0px 7.114px 7.114px rgba(0, 0, 0, 0.25))",
					transform: onHoover ? "scale(1.04)" : "scale(1)",
					transition: ".5s",
				}}
				onMouseEnter={() => {
					setOnHoover(true);
				}}
				onMouseLeave={() => {
					setOnHoover(false);
				}}
			>
				<Box mt={`${(width * 2) / 100}px !important`}>
					<Image
						alt={altImg}
						src={finalImage}
						{...bgParams}
						style={{ objectFit: "cover" }}
					/>
				</Box> 
			</Flex>
		</Link>
	);
};

export default React.memo(SingleBrand);
